import _ from "lodash";
import React from "react";
import { useFormikContext } from "formik";
import { Button } from "@material-ui/core";
import axios from "axios";

import { useStepContextLinkDevice } from "./step.provider";
import LoadingButton from "../../../../../components/loading-button/loading-button";
import { useGetInsurrancesPlansMicroservice } from "../../../../../services/microservice/sponsor/use-sponsor-microservice";
import { IDeviceLinkValues } from "./use-client-link-device.util";
import { toast } from "react-toastify";
import NotificationError from "../components/notifications/notification-error";

const ButtonsStepLinkDeviceComponent = ({
  isLoading,
}: {
  isLoading: boolean;
}) => {
  const formik = useFormikContext<IDeviceLinkValues>();
  const { currentStep, setCurrentStep, setPlanSelected } =
    useStepContextLinkDevice();
  const { mutateAsync: GetPlans, isLoading: isLoadingPlansMicroservice } =
    useGetInsurrancesPlansMicroservice();

  const isLastStep = currentStep === 2;
  const hasNextButton = currentStep < 2 && currentStep > 0;
  const hasBackButton = currentStep > 0;

  const handleBackStep = () => {
    setCurrentStep((step) => step - 1);
    if (currentStep === 1) {
      setPlanSelected(null);
      formik.resetForm({
        values: {
          ...formik.values,
          lanePrincipal: "",
          activationDate: null,
          linkDate: null,
          sendEmail: false,
          priceOption: null,
        },
      });
    }
  };

  const handleNextStep = async () => {
    const errors = await formik.validateForm();
    const errorFields = _.keys(errors);
    const hasErrors = !_.isEmpty(errorFields);
    if (hasErrors) {
      errorFields.map((field) => {
        formik.setFieldTouched(field, true);
        return true;
      });
    } else {
      if (currentStep === 0) {
        await handleGetPlans();
      } else {
        setCurrentStep((step) => step + 1);
      }
    }
  };

  const handleGetPlans = async () => {
    try {
      const res = await GetPlans({
        imei: String(formik.values.primaryImei),
        planID: String(formik.values.plan?.id),
        sponsorID: String(formik.values.sponsor?.id_system),
        disableImeiValidation: formik.values.disableImeiValidation,
      });
      setPlanSelected(res);
      setCurrentStep((step) => step + 1);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          toast.error(
            <NotificationError error={error.response.data.error.message} />
          );
        } else {
          toast.error(
            <NotificationError
              error={
                "No fue posible identificar el dispositivo. Comunicate con servicio tecnico."
              }
            />
          );
        }
      } else {
        toast.error(
          <NotificationError
            error={
              "No fue posible identificar el dispositivo. Comunicate con servicio tecnico."
            }
          />
        );
      }
    }
  };

  return (
    <div className={"mb-8 flex flex-row-reverse justify-between"}>
      {isLastStep && (
        <LoadingButton
          type={"submit"}
          color={"primary"}
          children={"Guardar"}
          variant={"contained"}
          isLoading={isLoading}
        />
      )}
      {currentStep === 0 && (
        <LoadingButton
          type={"button"}
          color={"primary"}
          children={"Siguiente"}
          variant={"contained"}
          onClick={() => handleNextStep()}
          isLoading={isLoadingPlansMicroservice}
        />
      )}
      {hasNextButton && (
        <Button
          type={"button"}
          color={"primary"}
          children={"Siguiente"}
          variant={"contained"}
          onClick={() => handleNextStep()}
        />
      )}
      {hasBackButton && (
        <Button
          type={"button"}
          color={"primary"}
          children={"Anterior"}
          variant={"contained"}
          onClick={() => handleBackStep()}
        />
      )}
    </div>
  );
};

export default ButtonsStepLinkDeviceComponent;
