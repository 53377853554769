import { TablePagination } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { ReactNode } from "react";
import { Fragment } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Default } from "react-if";
import { Case } from "react-if";
import { Switch } from "react-if";
import Panel from "../../components/panel/panel";
import { useGlobalStyles } from "../../styles/useGlobalStyles";
import FixedScrollSectionTemplate from "../fixed-scroll-section.template";
import empty from "./assets/undraw_empty_xct9.svg";

export interface ITableListPageState<F> {
  filters: F;
  perPage: number;
  count: number;
  currentPage: number;
  perPageOptions: number[];
}

export interface ITableListPageMethods {
  updateFilters: (params: any) => void;
  updateCurrentPage: (currentPage: number) => void;
  updatePerPage: (perPage: number) => void;
  updateCount: (perPage: number) => void;
}

interface ITableListPageTemplate {
  tableListMethods: ITableListPageMethods;
  tableListState: ITableListPageState<any>;
  isLoading: boolean;
  isEmpty: boolean;
  title: ReactNode;
  table: ReactNode;
}

export const getTableListMethods = (state: ITableListPageState<any>) => ({
  updateFilters(filters: any) {
    state.filters = filters;
  },
  updateCurrentPage(currentPage: number) {
    state.currentPage = currentPage;
  },
  updatePerPage(perPage: number) {
    state.perPage = perPage;
  },
  updateCount(count: number) {
    state.count = count;
  },
});

const TableListPageTemplate = (params: ITableListPageTemplate) => {
  const { title, isLoading, isEmpty } = params;
  const { table, tableListMethods, tableListState } = params;
  const { borderColor } = useGlobalStyles();
  const { t } = useTranslation();
  return (
    <FixedScrollSectionTemplate classname={"flex h-full"}>
      <div className={"flex-grow"}>
        <Panel title={title}>
          <Switch>
            <Case condition={isLoading}>
              <Skeleton height={"100%"} width={"100%"} variant={"rect"} />
            </Case>
            <Case condition={isEmpty}>
              <div className="h-full flex flex-col justify-center items-center gap-8">
                <img
                  className={"w-52"}
                  src={empty}
                  alt="undraw_empty_xct9.svg"
                />
                <Typography className={"w-96 text-center"} variant={"body1"}>
                  {t("templates.tableListPage.tableEmptyLabel")}
                </Typography>
              </div>
            </Case>
            <Default
              children={() => (
                <Fragment>
                  <div
                    className={clsx(
                      borderColor,
                      "h-full overflow-y-auto border"
                    )}
                  >
                    {table}
                  </div>
                  <div className={clsx(borderColor, "border border-t-0")}>
                    <TablePagination
                      variant={"head"}
                      component={"div"}
                      count={tableListState.count}
                      page={tableListState.currentPage}
                      rowsPerPage={tableListState.perPage}
                      rowsPerPageOptions={tableListState.perPageOptions}
                      onChangeRowsPerPage={(e) => {
                        const perPage = Number(e.target.value);
                        tableListMethods.updatePerPage(perPage);
                      }}
                      onChangePage={(event, page) => {
                        tableListMethods.updateCurrentPage(page);
                      }}
                    />
                  </div>
                </Fragment>
              )}
            />
          </Switch>
        </Panel>
      </div>
    </FixedScrollSectionTemplate>
  );
};

export default TableListPageTemplate;
