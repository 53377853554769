import React from "react";

import ActualizationUploadPanel from "./actualization-upload.panel";
import { useActualizationState } from "../context/actualization.provider";

const ActualizationUploadService = () => {
  const { actualization } = useActualizationState();
  return (
    <ActualizationUploadPanel
      height={300}
      width={"100%"}
      variant={"rect"}
      hasError={actualization.hasError}
      isLoading={actualization.isLoading}
    />
  );
};

export default ActualizationUploadService;
