import { AxiosRequestConfig } from "axios";
import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import { withFormData } from "../util/with-form-data";
import { ISelectCompensationTypeResPayload } from "./stage-service.interface";
import { ISelectCompensationTypeReq } from "./stage-service.interface";
import { IGetCompensationTypeRes } from "./stage-service.interface";
import { IGetCompensationType } from "./stage-service.interface";
import { IUploadDiagnosticResultResPayload } from "./stage-service.interface";
import { IUploadDiagnosticResultReq } from "./stage-service.interface";

export const uploadDiagnosticResult = (props: IUploadDiagnosticResultReq) => {
  const { coverage, stageOrderId, data } = props;
  const url = protegemeV2ApiRoutes.v2.stage.uploadDiagnosticResult(
    coverage,
    stageOrderId
  );
  const formData = withFormData(data);
  const config: AxiosRequestConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return protegemeV2ApiAxios
    .post<Error, IUploadDiagnosticResultResPayload>(url, formData, config)
    .then((res) => res.data);
};

export const getCompensationType = (props: IGetCompensationType) => {
  const { reclamationId } = props;
  const url = protegemeV2ApiRoutes.v2.stage.getCompensationType(reclamationId);
  return protegemeV2ApiAxios.get<IGetCompensationTypeRes>(url);
};

export const selectCompensationType = (props: ISelectCompensationTypeReq) => {
  const { data } = props;
  const url = protegemeV2ApiRoutes.v2.stage.selectCompensationType();
  return protegemeV2ApiAxios.post<ISelectCompensationTypeResPayload>(url, data);
};
