import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../../store";
import EAxios from "../../../axios/EAxios";
import Configuration from "../../../config/Configuration";
import { IPlanStageByEventState, IStage } from "../../../types/event";

const initialState: IPlanStageByEventState = {
  planStage: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "plan_stage_by_event",
  initialState,
  reducers: {
    getPlanStageByEventLoading: (state: IPlanStageByEventState) => {
      state.planStage = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getPlanStageByEventSuccess: (
      state: IPlanStageByEventState,
      action: PayloadAction<IStage[]>
    ) => {
      state.planStage = action.payload;
      state.isLoading = false;
    },
    getPlanStageByEventFailed: (state: IPlanStageByEventState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getPlanStageByEventRequest =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(PlanStageByEventActions.getPlanStageByEventLoading());
      const hostname = Configuration.api.hostname;
      const response = await EAxios.get<IStage[]>(
        `${hostname}/api/v1/etapa/ticket/${eventId}`
      );
      dispatch(
        PlanStageByEventActions.getPlanStageByEventSuccess(response.data)
      );
    } catch (error) {
      dispatch(PlanStageByEventActions.getPlanStageByEventFailed());
    }
  };

export const PlanStageByEventActions = {
  ...actions,
  getPlanStageByEventRequest,
};
export const PlanStageByEventReducer = reducer;
