import React, { FunctionComponent } from "react";

import PanelLoading from "../../../../panel/panel.loading";
import EventClientSection from "./event.client.section";
import { ILoadingComponentProps } from "../../../../loading/loading.component";
import { useStageState } from "../../../../../providers/stage/stage.provider";

const EventClientPanel: FunctionComponent<ILoadingComponentProps> = (props) => {
  const { event } = useStageState();
  const { hasError, isLoading } = event;
  return (
    <PanelLoading
      hasError={hasError}
      title={`Datos del Cliente`}
      isLoading={isLoading}
      width={props.width || "100%"}
      animation={props.animation}
      height={props.height || 200}
      variant={props.variant || "rect"}
      children={<EventClientSection />}
    />
  );
};

export default EventClientPanel;
