import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import { ICausalsByStageIdPayload } from "./causal-service.interface";

export const listCausalsByStageId = (candidate: string | number) => () => {
  const stageId = String(candidate);
  const url = protegemeV2ApiRoutes.v2.causal.listCausalsByStageId(stageId);
  return protegemeV2ApiAxios
    .get<Error, ICausalsByStageIdPayload>(url)
    .then((res) => res.data);
};
