import React from "react";
import { Table, TableBody } from "@material-ui/core";

import { IV1HistoryTicket } from "../../../types/v1HistoryTicket";
import V1HistoryTicketCellComponent from "./components/v1-history-ticket-cell/v1-history-ticket-cell.component";
import { useTranslation } from "react-i18next";

type IV1HistoryTicketComponentParams = {
  ticket: IV1HistoryTicket;
};

const V1HistoryTicketComponent = (props: IV1HistoryTicketComponentParams) => {
  const { t } = useTranslation();
  const { ticket } = props;
  return (
    <Table>
      <TableBody>
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventId")}
          value={ticket.id}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventNumber")}
          value={ticket.num_evento}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventType")}
          value={ticket.tipo_evento}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventCreateDate")}
          value={ticket.fecha_creacion}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventDate")}
          value={ticket.fecha_evento}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventLastStateDate")}
          value={ticket.fecha_ultimo_estado}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventState")}
          value={ticket.estado}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.devicePhoneNumber")}
          value={ticket.linea_movil}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.deviceModel")}
          value={ticket.modelo_movil}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.clientName")}
          value={ticket.nombre_cliente}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.clientIdNumber")}
          value={ticket.identificacion}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.clientEmail")}
          value={ticket.email}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.cdeName")}
          value={ticket.centro_experiencia}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.deviceImei")}
          value={ticket.imei}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventDeliveryDate")}
          value={ticket.fecha_entrega}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.ticketNumber")}
          value={ticket.ticket}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.reconsideration")}
          value={ticket.reconsideracion}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.userName")}
          value={ticket.usuario}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.deviceDeliveryImei")}
          value={ticket.imei_entregado}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.deviceDeliveryModel")}
          value={ticket.modelo_entregado}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.deviceGama")}
          value={ticket.gama}
        />
        <V1HistoryTicketCellComponent
          title={t("v1_history_ticket.eventCity")}
          value={ticket.ciudad_evento}
        />
      </TableBody>
    </Table>
  );
};

export default V1HistoryTicketComponent;
