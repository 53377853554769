import React from "react";
import UploadSignedPaymentOrderPanel from "./upload-signed-payment-order.panel";

const UploadSignedPaymentOrderService = () => {
  return (
    <UploadSignedPaymentOrderPanel
      height={500}
      width={"100%"}
      variant={"rect"}
      hasError={false}
      isLoading={false}
    />
  );
};

export default UploadSignedPaymentOrderService;
