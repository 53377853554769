import React from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../../../components/loading-button/loading-button";

interface Props {
  isLoading: boolean;
}

const FormButtons = ({ isLoading }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row justify-center mt-4">
      <LoadingButton
        type="submit"
        color="primary"
        className="w-1/4"
        variant="contained"
        isLoading={isLoading}
        children={t("clientPolicyCancel.panel.button")}
      />
    </div>
  );
};

export default FormButtons;
