import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FieldMetaProps } from "formik/dist/types";
import React, { FunctionComponent, Fragment } from "react";

import { IExperienceCenter } from "../../../types/event";
import ValidationLabelComponent from "../components/validation-label.component";

type IExperienceCenterAutocompleteField = FunctionComponent<{
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  options: IExperienceCenter[];
  name: string;
}>;

// Todo remove this component and use autocompletefield
const ExperienceCenterAutocompleteField: IExperienceCenterAutocompleteField = (
  props
) => {
  const { getFieldMeta, setFieldValue, options, name } = props;
  const hasError = Boolean(
    getFieldMeta(name).error && getFieldMeta(name).touched
  );
  const optionsSortedByCity = options.sort((a, b) =>
    a.ciudad.localeCompare(b.ciudad)
  );
  return (
    <Fragment>
      <Autocomplete
        size={"small"}
        disableClearable
        options={optionsSortedByCity}
        groupBy={(option) => String(option.ciudad)}
        getOptionLabel={(option) => `${option.nombre}`}
        onChange={(event, value) => setFieldValue(name, value.id)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Tienda"
            error={hasError}
            variant={"outlined"}
          />
        )}
      />
      <div className={"ml-4"}>
        <ValidationLabelComponent
          hasError={hasError}
          label={String(getFieldMeta(name).error)}
        />
      </div>
    </Fragment>
  );
};

export default ExperienceCenterAutocompleteField;
