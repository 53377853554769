import * as yup from "yup";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FormikHelpers } from "formik/dist/types";
import { toast } from "react-toastify";

import { ICancelFormPolicy } from "./cancel-policy-form.interface";
import { IClientPolicyCancelBody } from "../../../../../services/protegeme-v2/client/client-service.interface";
import { useCancelClientPolicy } from "../../../../../services/protegeme-v2/client/use-client";
import NotificatioCanceledPolicy from "./notifications/notification-canceled-policy";
import NotificatioErrorCanceled from "./notifications/notification-error-canceled";

interface Params {
  clientId: string;
  deviceId: string;
  policyId: string;
}

const useCancelPolicyForm = () => {
  const { t } = useTranslation();
  const { mutateAsync: cancelPolicy, isLoading } = useCancelClientPolicy();
  const { push } = useHistory();
  const params: Params = useParams();
  const initialValues: ICancelFormPolicy = {
    policyID: "",
    cancelationReason: "",
    cancelationDate: null,
  };
  const validationSchema = yup.object().shape({
    policyID: yup
      .string()
      .oneOf([params.policyId], t("form.errors.required"))
      .required(t("form.errors.required")),
    cancelationReason: yup
      .string()
      .max(500)
      .required(t("form.errors.required")),
    cancelationDate: yup.date().nullable().required(t("form.errors.required")),
  });
  const submitForm = async (
    values: ICancelFormPolicy,
    formikHelpers: FormikHelpers<ICancelFormPolicy>
  ) => {
    const data: IClientPolicyCancelBody = {
      policy_id: Number(values.policyID),
      cancelation_date: format(values.cancelationDate!, "yyy-MM-dd"),
      cancelation_reason: values.cancelationReason,
    };
    try {
      await cancelPolicy(data);
      toast.info(<NotificatioCanceledPolicy />);
    } catch (error) {
      toast.error(<NotificatioErrorCanceled />);
    } finally {
      formikHelpers.resetForm();
      push(`/client/${params.clientId}`);
    }
  };
  return { initialValues, validationSchema, submitForm, isLoading };
};

export default useCancelPolicyForm;
