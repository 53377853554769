import React from "react";
import Grid from "@material-ui/core/Grid";

import { StageProvider } from "../../providers/stage/stage.provider";
import EventViewComponent from "../../components/event/event-view/event-view.component";
import { useAuthorization } from "../../providers/authorization/authorization.provider";
import { AuthorizationComponent } from "../../providers/authorization/authorization.component";

const EventPage = () => {
  const { canReclamationReadAll, canReclamationUpdateAll } = useAuthorization();
  const canSeeThePage = canReclamationReadAll || canReclamationUpdateAll;
  return (
    <AuthorizationComponent isAuthorized={canSeeThePage} autoRedirect={true}>
      <StageProvider>
        <div className={"w-full"}>
          <Grid container direction="row">
            <Grid item xs={12} className={"p-6"}>
              <EventViewComponent />
            </Grid>
          </Grid>
        </div>
      </StageProvider>
    </AuthorizationComponent>
  );
};

export default EventPage;
