import { History } from "history";
import { AxiosRequestConfig } from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import EAxios from "../../axios/EAxios";
import Configuration from "../../config/Configuration";
import {
  IBackendResponse,
  IResponse,
  ISaveDocumentByStage,
} from "../../types/event";

const initialState: IBackendResponse<string> = {
  isLoading: false,
  hasError: false,
  response: undefined,
};

const { reducer, actions } = createSlice({
  name: "save_document_by_stage",
  initialState,
  reducers: {
    SaveDocumentByStageLoading: (state: IBackendResponse<string>) => {
      state.isLoading = true;
      state.hasError = false;
      state.response = undefined;
    },
    SaveDocumentByStageSuccess: (
      state: IBackendResponse<string>,
      action: PayloadAction<IResponse<string>>
    ) => {
      state.isLoading = false;
      state.response = action.payload;
    },
    SaveDocumentByStageFailed: (state: IBackendResponse<string>) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const SaveDocumentByStageRequest =
  ({ params, body }: ISaveDocumentByStage, history: History): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(SaveDocumentByStageActions.SaveDocumentByStageLoading());
      const hostname = Configuration.api.hostname;
      const url = `${hostname}/api/v1/documento/upload/etapa/orden/${params.stageId}/`;
      const config: AxiosRequestConfig = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file_to_save", body.file_to_save);
      formData.append("ticket_id", body.ticket_id);

      const response = await EAxios.post<IResponse<string>>(
        url,
        formData,
        config
      );
      dispatch(
        SaveDocumentByStageActions.SaveDocumentByStageSuccess(response.data)
      );
      if (response.status === 200 || response.status === 201) history.goBack();
    } catch (error) {
      dispatch(SaveDocumentByStageActions.SaveDocumentByStageFailed());
    }
  };

export const SaveDocumentByStageActions = {
  ...actions,
  SaveDocumentByStageRequest,
};
export const SaveDocumentByStageReducer = reducer;
