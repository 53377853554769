import React from "react";

import ActualizationHistoryPanel from "./actualization-history.panel";
import { useActualizationState } from "../context/actualization.provider";

const ActualizationHistoryService = () => {
  const { actualization } = useActualizationState();
  return (
    <ActualizationHistoryPanel
      height={300}
      width={"100%"}
      variant={"rect"}
      hasError={actualization.hasError}
      isLoading={actualization.isLoading}
    />
  );
};

export default ActualizationHistoryService;
