import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import ActualizationHistoryComponent from "./actualization-history.component";

const ActualizationHistoryPanel: FunctionComponent<ILoadingComponentProps> = (
  props
) => (
  <PanelLoading
    width={props.width}
    height={props.height}
    variant={props.variant}
    hasError={props.hasError}
    isLoading={props.isLoading}
    animation={props.animation}
    title={"Historial de Actualizaciones"}
    children={<ActualizationHistoryComponent />}
  />
);

export default ActualizationHistoryPanel;
