import { TextField as TextFieldMui } from "@material-ui/core";
import { Field } from "formik";
import { Form } from "formik";
import { Formik } from "formik";
import { TextField } from "formik-material-ui";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import { Autocomplete } from "formik-material-ui-lab";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import * as yup from "yup";
import HelpCounter from "../../../../../components/formik/HelpCounter";
import HorizontalField from "../../../../../components/formik/horizontal-field";
import LoadingButton from "../../../../../components/loading-button/loading-button";
import NotificationStandard from "../../../../../components/notification/notification-standard";
import { IFindAllClientDevice } from "../../../../../services/protegeme-v2/client/client-service.interface";
import { useUpdateClientDevice } from "../../../../../services/protegeme-v2/client/use-client";
import { IDeviceList } from "../../../../../services/protegeme-v2/device/device-service.interface";
import { useDeviceList } from "../../../../../services/protegeme-v2/device/use-device";

interface IUpdateForm {
  clientDevice: IFindAllClientDevice;
}

const UpdateForm = (props: IUpdateForm) => {
  const { clientDevice } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const updateClientDevice = useUpdateClientDevice();
  const [deviceFilter, setDeviceFilter] = useState<string>("");
  const [devicesFiltered, setDevicesFiltered] = useState<IDeviceList[]>([
    clientDevice.dispositivo,
  ]);
  const [debouncedDeviceFilter] = useDebounce<string>(deviceFilter, 500);
  const deviceList = useDeviceList({
    data: { params: { name: deviceFilter || clientDevice.dispositivo.nombre } },
    options: { enabled: false },
  });
  const { refetch: deviceRefetch, data: devices } = deviceList;

  useEffect(() => {
    if (_.isEmpty(debouncedDeviceFilter)) {
      setDevicesFiltered([]);
      return;
    }
    deviceRefetch();
  }, [setDevicesFiltered, debouncedDeviceFilter, deviceRefetch]);

  useEffect(() => {
    const candidates = devices?.data || [];
    setDevicesFiltered(candidates);
  }, [setDevicesFiltered, devices]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        device: clientDevice.dispositivo || null,
        lanePrincipal: clientDevice.linea_uno || "",
        laneSecondary: clientDevice.linea_dos || "",
        imeiPrincipal: clientDevice.imei_uno || "",
        imeiSecondary: clientDevice.imei_dos || "",
      }}
      validationSchema={yup.object().shape({
        lanePrincipal: yup
          .string()
          .matches(/^[0-9]*$/, ({ label }) =>
            t("form.errors.number", { label })
          )
          .length(10)
          .required()
          .label(t("clientDeviceUpdate.panel.form.fields.lanePrincipal.label")),
        laneSecondary: yup
          .string()
          .matches(/^[0-9]*$/, ({ label }) =>
            t("form.errors.number", { label })
          )
          .length(10)
          .label(t("clientDeviceUpdate.panel.form.fields.laneSecondary.label")),
        imeiPrincipal: yup
          .string()
          .matches(/^[0-9]*$/, ({ label }) =>
            t("form.errors.number", { label })
          )
          .length(15)
          .required()
          .label(t("clientDeviceUpdate.panel.form.fields.imeiPrincipal.label")),
        imeiSecondary: yup
          .string()
          .matches(/^[0-9]*$/, ({ label }) =>
            t("form.errors.number", { label })
          )
          .length(15)
          .label(t("clientDeviceUpdate.panel.form.fields.imeiSecondary.label")),
        device: yup
          .object()
          .shape({
            id: yup
              .number()
              .required()
              .label(t("clientDeviceUpdate.panel.form.fields.device.label")),
          })
          .nullable()
          .required()
          .label(t("clientDeviceUpdate.panel.form.fields.device.label")),
      })}
      onSubmit={(values, formikHelpers) => {
        const data = {
          deviceId: clientDevice.id,
          body: {
            dispositivo_id: values.device.id,
            imei_principal: values.imeiPrincipal,
            imei_secundario: values.imeiSecondary,
            linea_principal: values.lanePrincipal,
            linea_secundaria: values.laneSecondary,
          },
        };
        updateClientDevice
          .mutateAsync(data)
          .then(() => {
            toast.info(
              <NotificationStandard
                body={t("clientDeviceUpdate.panel.notifications.updated.body")}
              />
            );
            history.goBack();
          })
          .catch(() => {
            toast.error(
              <NotificationStandard
                body={t("clientDeviceUpdate.panel.notifications.error.body")}
              />
            );
          })
          .finally(() => {
            formikHelpers.resetForm();
          });
      }}
    >
      {(formik) => (
        <Form className={"flex flex-col gap-4"}>
          <HorizontalField
            isRequired={true}
            widthClass={"w-96"}
            label={t("clientDeviceUpdate.panel.form.fields.device.label")}
          >
            <Field
              size={"small"}
              name={"device"}
              fullWidth={true}
              options={devicesFiltered || []}
              loading={deviceList.isLoading}
              onInputChange={(event: any, value: any) => {
                setDeviceFilter(value);
              }}
              component={Autocomplete}
              getOptionSelected={(option: any, value: any) =>
                option.id === value.id
              }
              getOptionLabel={(option: any) =>
                `${option?.nombre} - ID_SYSTEM: ${option.id_system}` || ""
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMui
                  {...params}
                  variant={"outlined"}
                  error={formik.touched?.device && !!formik.errors?.device}
                  helperText={formik.errors.device}
                />
              )}
            />
          </HorizontalField>

          <HorizontalField
            isRequired={true}
            widthClass={"w-96"}
            label={t(
              "clientDeviceUpdate.panel.form.fields.lanePrincipal.label"
            )}
          >
            <Field
              type={"text"}
              size={"small"}
              fullWidth={true}
              variant={"outlined"}
              component={TextField}
              name={"lanePrincipal"}
            />
          </HorizontalField>

          <HorizontalField
            isRequired={false}
            widthClass={"w-96"}
            label={t(
              "clientDeviceUpdate.panel.form.fields.laneSecondary.label"
            )}
          >
            <Field
              type={"text"}
              size={"small"}
              fullWidth={true}
              variant={"outlined"}
              component={TextField}
              helperText={
                <HelpCounter
                  counter={0}
                  limit={10}
                  hasError={true}
                  hasTouched={false}
                />
              }
              name={"laneSecondary"}
            />
          </HorizontalField>

          <HorizontalField
            isRequired={true}
            widthClass={"w-96"}
            label={t(
              "clientDeviceUpdate.panel.form.fields.imeiPrincipal.label"
            )}
          >
            <Field
              type={"text"}
              size={"small"}
              fullWidth={true}
              variant={"outlined"}
              component={TextField}
              name={"imeiPrincipal"}
            />
          </HorizontalField>

          <HorizontalField
            isRequired={false}
            widthClass={"w-96"}
            label={t(
              "clientDeviceUpdate.panel.form.fields.imeiSecondary.label"
            )}
          >
            <Field
              type={"text"}
              size={"small"}
              fullWidth={true}
              variant={"outlined"}
              component={TextField}
              name={"imeiSecondary"}
            />
          </HorizontalField>

          <div className={"flex flex-row justify-center"}>
            <LoadingButton
              color={"primary"}
              isLoading={updateClientDevice.isLoading}
              type={"submit"}
              variant={"contained"}
              children={t("clientDeviceUpdate.panel.form.fields.submit.label")}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateForm;
