import React, { FunctionComponent } from "react";

import EventCausalSection from "./event.causal.section";
import PanelLoading from "../../../../panel/panel.loading";
import { useStageState } from "../../../../../providers/stage/stage.provider";
import { ILoadingComponentProps } from "../../../../loading/loading.component";

const EventCausalPanel: FunctionComponent<ILoadingComponentProps> = (props) => {
  const { causals } = useStageState();
  const { hasError, isLoading } = causals;
  return (
    <PanelLoading
      hasError={hasError}
      title={`Causales`}
      isLoading={isLoading}
      width={props.width || "100%"}
      animation={props.animation}
      height={props.height || 200}
      variant={props.variant || "rect"}
      children={<EventCausalSection />}
    />
  );
};

export default EventCausalPanel;
