import React from "react";
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

import { CurrencyFormat } from "../../../../../util/currency.util";
import { useStageState } from "../../../../../providers/stage/stage.provider";

const EventDeviceSection = () => {
  const { event } = useStageState();
  const device = event.data?.dispositivo_cliente;
  const hasImeiSecondary = Boolean(device?.imei_dos);
  const hasLineSecondary = Boolean(device?.linea_dos);
  return (
    <Table size={"small"}>
      <TableBody>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Dispositivo</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{device?.dispositivo.nombre}</span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Fabricante</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              {device?.dispositivo.fabricante.nombre}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              Valor Asegurado del Dispositivo
            </span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              <CurrencyFormat value={event.data?.plan.valor_asegurado} />
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              IMEI Principal Asegurado
            </span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{device?.imei_uno}</span>
          </TableCell>
        </TableRow>
        {hasImeiSecondary && (
          <TableRow>
            <TableCell align="left">
              <span className={"text-xs font-bold"}>
                IMEI Secundario Asegurado
              </span>
            </TableCell>
            <TableCell align="right">
              <span className={"text-xs"}>{device?.imei_dos}</span>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              Línea Principal Asegurada
            </span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{device?.linea_uno}</span>
          </TableCell>
        </TableRow>
        {hasLineSecondary && (
          <TableRow>
            <TableCell align="left">
              <span className={"text-xs font-bold"}>
                Línea Secundaria Asegurada
              </span>
            </TableCell>
            <TableCell align="right">
              <span className={"text-xs"}>{device?.linea_dos}</span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default EventDeviceSection;
