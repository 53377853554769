import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import EAxios from "../../axios/EAxios";
import Configuration from "../../config/Configuration";
import { EventState, IEvento } from "../../types/event";

const initialState: EventState = {
  event: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "event",
  initialState,
  reducers: {
    getEventsLoading: (state: EventState) => {
      state.event = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getEventsSuccess: (state: EventState, action: PayloadAction<IEvento>) => {
      state.event = action.payload;
      state.isLoading = false;
    },
    getEventsFailed: (state: EventState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getEventFetch =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(EventActions.getEventsLoading());
      const hostname = Configuration.api.hostname;
      const response = await EAxios.get<IEvento>(
        `${hostname}/api/v1/ticket/${eventId}`
      );
      dispatch(EventActions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(EventActions.getEventsFailed());
    }
  };

export const EventActions = { ...actions, getEventFetch };
export const EventReducer = reducer;
