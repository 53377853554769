import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadSignedTransferFormatComponent from "./upload-signed-transfer-format.component";

const UploadSignedTransferFormatPanel: FunctionComponent<
  ILoadingComponentProps
> = (props) => (
  <PanelLoading
    title={`Cargar Formato de Transpaso Firmado`}
    height={props.height}
    width={props.width}
    variant={props.variant}
    hasError={props.hasError}
    isLoading={props.isLoading}
    animation={props.animation}
    children={<UploadSignedTransferFormatComponent />}
  />
);

export default UploadSignedTransferFormatPanel;
