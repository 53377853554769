import React from "react";
import { useTranslation } from "react-i18next";
import { TableBody, TableRow, Table, TableCell } from "@material-ui/core";
import { When, Unless } from "react-if";

import { IFindAllClientDevice } from "../../../../../services/protegeme-v2/client/client-service.interface";
import EnableChip from "./components/enable-chip";
import RenewButton from "./components/renew-button";

interface Props {
  deviceClient: IFindAllClientDevice | undefined;
}

const RenewSection = ({ deviceClient }: Props) => {
  const { t } = useTranslation();
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell className="w-6/12">
            {t("clientPolicyRenew.panel.statusRenew")}
          </TableCell>
          <TableCell className="w-6/12">
            <div className="flex flex-row justify-start ml-4">
              <EnableChip
                isEnable={
                  Boolean(deviceClient?.policy[0].plan.allow_renewal) &&
                  Boolean(!deviceClient?.policy[0].fecha_cancelacion)
                }
              />
            </div>
          </TableCell>
        </TableRow>
        <When
          condition={
            Boolean(deviceClient?.policy[0].plan.allow_renewal) &&
            !deviceClient?.policy[0].fecha_cancelacion
          }
        >
          <TableRow>
            <TableCell>{t("clientPolicyRenew.panel.actionRenew")}</TableCell>
            <TableCell>
              <RenewButton />
            </TableCell>
          </TableRow>
        </When>
        <Unless
          condition={
            Boolean(deviceClient?.policy[0].plan.allow_renewal) &&
            !deviceClient?.policy[0].fecha_cancelacion
          }
        >
          <TableRow>
            <TableCell>{t("clientPolicyRenew.panel.motivoRenew")}</TableCell>
            <TableCell>
              <div className="font-bold ml-4">
                {t("clientPolicyRenew.panel.disableRenew")}
              </div>
            </TableCell>
          </TableRow>
        </Unless>
      </TableBody>
    </Table>
  );
};

export default RenewSection;
