import React from "react";
import { useTranslation } from "react-i18next";

import TablePair from "../../../../../components/table-pair/table-pair";
import { DateFormat } from "../../../../../util/date.util";
import { ClientFullName } from "../../../../../services/protegeme-v2/client/util/client-format-name";

const CallSection = (props: any) => {
  const { t } = useTranslation();
  const { call } = props;
  const values = [
    { key: t("callDetails.panel.call.id"), value: call.id },
    {
      key: t("callDetails.panel.call.date"),
      value: <DateFormat source={call.call_date} />,
    },
    {
      key: t("callDetails.panel.call.user"),
      value: (
        <div className="font-bold overflow-ellipsis overflow-hidden">
          <ClientFullName
            firstName={call.user_created_by.name}
            lastName={call.user_created_by.second_name}
          />
        </div>
      ),
    },
    {
      key: t("callDetails.panel.call.client"),
      value: (
        <div className="font-bold overflow-ellipsis overflow-hidden">
          <ClientFullName
            firstName={call.client.name}
            lastName={call.client.second_name}
          />
        </div>
      ),
    },
    {
      key: t("callDetails.panel.call.identificationNumber"),
      value: call.client.identification_number,
    },
    {
      key: t("callDetails.panel.call.incomingPhoneNumber"),
      value: call.incoming_phone_number,
    },
  ];
  return <TablePair data={values} valueClassName={"font-bold"} />;
};

export default CallSection;
