import clsx from "clsx";
import { When } from "react-if";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { CloudUpload } from "@material-ui/icons";
import { Button, makeStyles } from "@material-ui/core";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import { IField } from "../field";

const useStyle = makeStyles((theme) => ({
  text: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRight: 0,
    borderRadius: "4px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  textError: {
    border: `1px solid ${theme.palette.error.light}`,
  },
  button: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  buttonError: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.light}`,
  },
}));

const FileUploadInput: FunctionComponent<IField & DropzoneOptions> = (
  props
) => {
  const { field, meta, helpers, ...dropzoneProps } = props;
  const classes = useStyle();

  const { getInputProps, open } = useDropzone({
    ...dropzoneProps,
    onDrop: () => {
      helpers.setValue(null, false);
      helpers.setTouched(true, false);
    },
    onDropAccepted: (acceptedFiles) => helpers.setValue(acceptedFiles[0]),
    onDropRejected: (fileRejections) => {
      const errors = fileRejections[0].errors.map<string>(
        (error) => error.message
      );
      const message = errors.reduce(
        (previous, current) => `${previous} - ${current}`
      );
      helpers.setError(message);
    },
  });

  return (
    <React.Fragment>
      <input name={field.name} {...getInputProps()} />
      <div className={"flex flex-row justify-between"}>
        <div
          className={clsx(
            classes.text,
            meta.error && meta.touched && classes.textError,
            "flex flex-row justify-between items-center px-2 overflow-hidden overflow-hidden flex-grow"
          )}
        >
          <p className={"m-0 overflow-hidden whitespace-no-wrap truncate"}>
            {field?.value?.name}
          </p>
          <When condition={field.value}>
            <IconButton
              size={"small"}
              children={<Close fontSize={"small"} />}
              onClick={() => helpers.setValue(null)}
            />
          </When>
        </div>
        <div>
          <Button
            classes={{
              root: clsx(
                classes.button,
                meta.error && meta.touched && classes.buttonError
              ),
            }}
            onClick={open}
            variant="outlined"
            color="inherit"
            startIcon={<CloudUpload />}
          >
            Subir
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileUploadInput;
