import { useQuery } from "react-query";
import { userGetMe } from "./user-service";

import { listAllGenders } from "./user-service";
import { listAllDocumentType } from "./user-service";
import { IGetUserMe } from "./user-service.interface";
import { IUseUserGetMe } from "./user-service.interface";

export const useUserGetMe = (props?: IUseUserGetMe) => {
  const { options } = props || {};
  const key = ["user/me"];
  return useQuery<IGetUserMe>(
    key,
    () => userGetMe().then((res) => res.data),
    options
  );
};

export const useListAllGender = () => {
  return useQuery("user/genders", listAllGenders);
};

export const useListAllDocumentType = () => {
  return useQuery("user/documents", listAllDocumentType);
};
