import DateFnsUtils from "@date-io/date-fns";
import { TextField as TextFieldMui } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { parseISO } from "date-fns";
import { formatISO } from "date-fns";
import es from "date-fns/locale/es";
import { Field } from "formik";
import { Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import { Autocomplete } from "formik-material-ui-lab";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import HorizontalField from "../../../../components/formik/horizontal-field";
import LoadingButton from "../../../../components/loading-button/loading-button";
import { IClientFindOne } from "../../../../services/protegeme-v2/client/client-service.interface";
import { useClientUpdate } from "../../../../services/protegeme-v2/client/use-client";
import { IGender } from "../../../../types/event";
import NotificationClientUpdated from "../notifications/notification-client-updated";
import NotificationUpdateError from "../notifications/notification-update-error";

interface IClientUpdateForm {
  client: IClientFindOne;
  genders: IGender[];
}

const ClientUpdateForm = (props: IClientUpdateForm) => {
  const { client, genders } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const clientUpdate = useClientUpdate();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: client?.name || "",
        lastName: client?.second_name || "",
        email: client?.email || "",
        gender: {
          id: client.gender.id,
          name: client.gender.name,
        },
        birthday: client.birthday ? parseISO(client.birthday) : null,
        expeditionDate: client.identification_expedition_date
          ? parseISO(client.identification_expedition_date)
          : null,
        address: client.address || "",
        identificationNumber: client.identification_number || "",
        identificationType: client.identification_type.name || "",
      }}
      validationSchema={yup.object().shape({
        firstName: yup
          .string()
          .required()
          .label(t("clientUpdate.form.firstName.label")),
        lastName: yup
          .string()
          .required()
          .label(t("clientUpdate.form.lastName.label")),
        email: yup
          .string()
          .email()
          .required()
          .label(t("clientUpdate.form.email.label")),
        gender: yup
          .object()
          .shape({
            id: yup
              .number()
              .required()
              .label(t("clientUpdate.form.gender.label")),
          })
          .nullable()
          .required()
          .label(t("clientUpdate.form.gender.label")),
        birthday: yup
          .date()
          .nullable()
          .required()
          .label(t("clientUpdate.form.birthday.label")),
        expeditionDate: yup
          .date()
          .nullable()
          .required()
          .label(t("clientUpdate.form.expeditionDate.label")),
        address: yup
          .string()
          .required()
          .label(t("clientUpdate.form.address.label")),
      })}
      onSubmit={(values, formikHelpers) => {
        const request = {
          clientId: client.id,
          body: {
            email: values.email,
            name: values.firstName,
            gender_id: values.gender.id,
            second_name: values.lastName,
            birthday: formatISO(values.birthday! as unknown as Date, {
              representation: "date",
            }),
            address: values.address,
            identification_expedition_date: formatISO(
              values.expeditionDate! as unknown as Date,
              {
                representation: "date",
              }
            ),
          },
        };
        clientUpdate
          .mutateAsync(request)
          .then((res) => {
            if (res?.error) {
              toast.error(<NotificationUpdateError />);
              return;
            }
            toast.info(<NotificationClientUpdated />);
            history.goBack();
          })
          .catch(() => {
            toast.error(<NotificationUpdateError />);
          });
      }}
    >
      {(formik) => (
        <Form>
          <div className={"flex flex-col gap-4"}>
            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.firstName.label")}
            >
              <Field
                type={"text"}
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                component={TextField}
                name={"firstName"}
              />
            </HorizontalField>

            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.lastName.label")}
            >
              <Field
                type={"text"}
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                component={TextField}
                name={"lastName"}
              />
            </HorizontalField>

            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.email.label")}
            >
              <Field
                type={"text"}
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                component={TextField}
                name={"email"}
              />
            </HorizontalField>

            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.identificationType.label")}
            >
              <Field
                type={"text"}
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                component={TextField}
                name={"identificationType"}
                InputProps={{
                  readOnly: true,
                }}
              />
            </HorizontalField>

            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.identificationNumber.label")}
            >
              <Field
                type={"text"}
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                component={TextField}
                name={"identificationNumber"}
                InputProps={{
                  readOnly: true,
                }}
              />
            </HorizontalField>

            <HorizontalField
              widthClass={"w-96"}
              isRequired={true}
              label={t("clientUpdate.form.expeditionDate.label")}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <Field
                  autoOk={true}
                  openTo="year"
                  size={"small"}
                  fullWidth={true}
                  name={"expeditionDate"}
                  format="P"
                  maxDate={new Date().setHours(23, 59)}
                  inputVariant={"outlined"}
                  component={KeyboardDatePicker}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </HorizontalField>

            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.gender.label")}
            >
              <Field
                size={"small"}
                name={"gender"}
                fullWidth={true}
                options={genders}
                component={Autocomplete}
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) => option?.name}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextFieldMui
                    {...params}
                    variant={"outlined"}
                    error={formik.touched?.gender && !!formik.errors?.gender}
                    helperText={formik.errors.gender}
                  />
                )}
              />
            </HorizontalField>

            <HorizontalField
              widthClass={"w-96"}
              isRequired={true}
              label={t("clientUpdate.form.birthday.label")}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <Field
                  autoOk={true}
                  openTo="year"
                  size={"small"}
                  fullWidth={true}
                  name={"birthday"}
                  format="P"
                  maxDate={new Date().setHours(23, 59)}
                  inputVariant={"outlined"}
                  component={KeyboardDatePicker}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </HorizontalField>

            <HorizontalField
              isRequired={true}
              widthClass={"w-96"}
              label={t("clientUpdate.form.address.label")}
            >
              <Field
                type={"text"}
                size={"small"}
                fullWidth={true}
                variant={"outlined"}
                component={TextField}
                name={"address"}
              />
            </HorizontalField>

            <div className={"flex flex-row justify-center"}>
              <LoadingButton
                color={"primary"}
                isLoading={clientUpdate.isLoading}
                type={"submit"}
                variant={"contained"}
                children={t("clientUpdate.form.submitBtn.label")}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClientUpdateForm;
