import React from "react";
import { Row } from "react-table";
import { TableRow } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { IV1HistoryTicket } from "../../../../../types/v1HistoryTicket";

type IV1HistoryListTicketTableBody = {
  rows: Row<IV1HistoryTicket>[];
  prepareRow: (row: Row<IV1HistoryTicket>) => void;
};
const V1HistoryListTicketTableBody = (props: IV1HistoryListTicketTableBody) => {
  const { rows, prepareRow } = props;
  return (
    <TableBody>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <TableRow {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <TableCell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default V1HistoryListTicketTableBody;
