import { TimelineConnector } from "@material-ui/lab";
import React from "react";
import { useGlobalStyles } from "../../../../../../styles/useGlobalStyles";

interface IProgressConnector {
  variant?: "default" | "success" | "error";
}

const ProgressConnector = (props: IProgressConnector) => {
  const { variant = "default" } = props;
  const globalStyles = useGlobalStyles();
  const color = {
    default: globalStyles.bgGray,
    success: globalStyles.bgGreen,
    error: globalStyles.bgRed,
  };
  return <TimelineConnector classes={{ root: color[variant] }} />;
};

export default ProgressConnector;
