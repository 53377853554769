import React from "react";
import UploadPaymentSupportPanel from "./upload-payment-support.panel";

const UploadPaymentSupportService = () => {
  return (
    <UploadPaymentSupportPanel
      height={500}
      width={"100%"}
      variant={"rect"}
      hasError={false}
      isLoading={false}
    />
  );
};

export default UploadPaymentSupportService;
