import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import EAxios from "../../axios/EAxios";
import { ICausalByEventState, ICausalResult } from "../../types/event";

const initialState: ICausalByEventState = {
  causalByEvent: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "causal_by_event",
  initialState,
  reducers: {
    getCausalByEventLoading: (state: ICausalByEventState) => {
      state.causalByEvent = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getCausalByEventSuccess: (
      state: ICausalByEventState,
      action: PayloadAction<ICausalResult[]>
    ) => {
      state.causalByEvent = action.payload;
      state.isLoading = false;
    },
    getCausalByEventFailed: (state: ICausalByEventState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getCausalByEventRequest =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(CausalByEventActions.getCausalByEventLoading());
      const url = EAxios.endpoints.default.event.getCausalsByEvent(eventId);
      const response = await EAxios.get<ICausalResult[]>(url);
      dispatch(CausalByEventActions.getCausalByEventSuccess(response.data));
    } catch (error) {
      dispatch(CausalByEventActions.getCausalByEventFailed());
    }
  };

export const CausalByEventActions = { ...actions, getCausalByEventRequest };
export const CausalByEventReducer = reducer;
