import React from "react";

import StepProvider from "./steps/step.provider";
import { PanelLoading } from "../../panel/panel.loading";
import EventCreateComponent from "./event-create.component";

const EventCreatePanel = () => {
  return (
    <PanelLoading title={`Crear Reclamación`}>
      <StepProvider>
        <EventCreateComponent />
      </StepProvider>
    </PanelLoading>
  );
};

export default EventCreatePanel;
