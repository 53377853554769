import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { Case } from "react-if";
import { Switch } from "react-if";
import { useParams } from "react-router-dom";
import Panel from "../../../components/panel/panel";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { useClientFindOne } from "../../../services/protegeme-v2/client/use-client";
import { useListAllGender } from "../../../services/protegeme-v2/user/use-user";
import FixedScrollSectionTemplate from "../../../template/fixed-scroll-section.template";
import ClientUpdateForm from "./components/client-update-form";

const ClientUpdatePage = () => {
  const { canClientUpdate } = useAuthorization();
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();
  const clientFindOne = useClientFindOne({ clientId: Number(clientId) });
  const genders = useListAllGender();
  return (
    <AuthorizationComponent isAuthorized={canClientUpdate} autoRedirect={true}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-4/5 2xl:w-3/5"}>
                <Panel title={t("clientUpdate.panel.title")}>
                  <Switch>
                    <Case
                      condition={clientFindOne.isLoading || genders.isLoading}
                      children={
                        <Skeleton
                          width={"100%"}
                          height={500}
                          variant={"rect"}
                        />
                      }
                    />
                    <Case
                      condition={Boolean(clientFindOne.data)}
                      children={() => (
                        <ClientUpdateForm
                          client={clientFindOne.data!.data}
                          genders={genders.data!}
                        />
                      )}
                    />
                  </Switch>
                </Panel>
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ClientUpdatePage;
