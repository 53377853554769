import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../../store";
import EAxios from "../../../axios/EAxios";
import Configuration from "../../../config/Configuration";
import { IDocument, IDocumentByEventState } from "../../../types/event";

const initialState: IDocumentByEventState = {
  documents: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "document_by_event",
  initialState,
  reducers: {
    getDocumentByEventLoading: (state: IDocumentByEventState) => {
      state.documents = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getDocumentByEventSuccess: (
      state: IDocumentByEventState,
      action: PayloadAction<IDocument[]>
    ) => {
      state.documents = action.payload;
      state.isLoading = false;
    },
    getDocumentByEventFailed: (state: IDocumentByEventState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getDocumentByEventRequest =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(DocumentByEventActions.getDocumentByEventLoading());
      const hostname = Configuration.api.hostname;
      const response = await EAxios.get<IDocument[]>(
        `${hostname}/api/v1/documento/ticket/${eventId}`
      );
      dispatch(DocumentByEventActions.getDocumentByEventSuccess(response.data));
    } catch (error) {
      dispatch(DocumentByEventActions.getDocumentByEventFailed());
    }
  };

export const DocumentByEventActions = { ...actions, getDocumentByEventRequest };
export const DocumentByEventReducer = reducer;
