import React from "react";
import SelectTypeCompensationPanel from "./select-type-compensation.panel";

const SelectTypeCompensationService = () => {
  return (
    <SelectTypeCompensationPanel
      height={500}
      width={"100%"}
      variant={"rect"}
      hasError={false}
      isLoading={false}
    />
  );
};

export default SelectTypeCompensationService;
