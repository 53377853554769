import { IReclamationFind } from "../../../services/protegeme-v2/reclamation/reclamation-service.interface";
import { IEvento } from "../../../types/event";

type IGetReclamationStateKey =
  | "reclamation.state.progress"
  | "reclamation.state.rejected"
  | "reclamation.state.resolve"
  | "default.notFound";

export const getReclamationStateKey = (
  event?: IReclamationFind | IEvento
): IGetReclamationStateKey => {
  if (!event) return "default.notFound";
  switch (event.estado.id_system) {
    case "ENPROCESO":
      return "reclamation.state.progress";
    case "FINALIZADO": {
      return event.tipo_flujo.id_system === "RECHAZO"
        ? "reclamation.state.rejected"
        : "reclamation.state.resolve";
    }
    default:
      return "default.notFound";
  }
};

type IGetReclamationTypeKey =
  | "reclamation.type.simpleTheft"
  | "reclamation.type.qualifiedTheft"
  | "reclamation.type.accidentalTotalDamage"
  | "reclamation.type.screenFracture"
  | "reclamation.type.extendedWarranty"
  | "reclamation.type.parcialDamage"
  | "default.notFound";

export const getReclamationTypeKey = (
  event?: IReclamationFind
): IGetReclamationTypeKey => {
  if (!event) return "default.notFound";
  switch (event.tipo_cobertura.id_system) {
    case "HURTOSIMPLE":
      return "reclamation.type.simpleTheft";
    case "FRACTURAPANTALLA":
      return "reclamation.type.screenFracture";
    case "HURTOCALIFICADO":
      return "reclamation.type.qualifiedTheft";
    case "DAÑOTOTALACCIDENTAL":
      return "reclamation.type.accidentalTotalDamage";
    case "GARANTIAEXTENDIDA":
      return "reclamation.type.extendedWarranty";
    case "DAÑOPARCIAL":
      return "reclamation.type.parcialDamage";
    default:
      return "default.notFound";
  }
};
