import React, { FunctionComponent } from "react";

import PanelLoading from "../../../../panel/panel.loading";
import EventContinueSection from "./event.continue.section";
import { ILoadingComponentProps } from "../../../../loading/loading.component";
import { useStageState } from "../../../../../providers/stage/stage.provider";

const EventContinuePanel: FunctionComponent<ILoadingComponentProps> = (
  props
) => {
  const { event, stage } = useStageState();

  const hasError = event.hasError || stage.hasError;
  const isLoading = event.isLoading || stage.isLoading;

  return (
    <PanelLoading
      hasError={hasError}
      title={`Etapa Actual`}
      isLoading={isLoading}
      width={props.width || "100%"}
      animation={props.animation}
      height={props.height || 200}
      variant={props.variant || "rect"}
      children={<EventContinueSection />}
    />
  );
};

export default EventContinuePanel;
