import React, { Fragment } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface IActiveChip {
  isActive: boolean;
  className?: string;
}

const useStyle = makeStyles((theme) => {
  return {
    activeChip: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light),
    },
    inactiveChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
  };
});

const ActiveChip = (params: IActiveChip) => {
  const { isActive, className } = params;
  const style = useStyle();
  const { t } = useTranslation();
  const title = isActive
    ? t("components.activationChip.active")
    : t("components.activationChip.inactive");

  return (
    <Fragment>
      <Chip
        className={className}
        classes={{ root: isActive ? style.activeChip : style.inactiveChip }}
        label={<span className={"text-xs"}>{title}</span>}
        size={"small"}
      />
    </Fragment>
  );
};

export default ActiveChip;
