import React from "react";

import UploadSignedPurchaseOrderPanel from "./upload-signed-purchase-order.panel";
import { ILoadingComponentProps } from "../../loading/loading.component";

const UploadSignedPurchaseOrderService = () => {
  const loadingProps: ILoadingComponentProps = {
    isLoading: false,
    hasError: false,
    variant: "rect",
    width: "100%",
    height: 500,
  };

  return <UploadSignedPurchaseOrderPanel {...loadingProps} />;
};

export default UploadSignedPurchaseOrderService;
