import React from "react";
import { Field } from "formik";
import { Fragment } from "react";
import es from "date-fns/locale/es";
import { useFormikContext } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import { TextField } from "formik-material-ui";
import { Autocomplete } from "formik-material-ui-lab";
import { TextField as TextFieldMui } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";

import { IFormikValue } from "../client-create-form.interface";
import HorizontalField from "../../../../../../components/formik/horizontal-field";
import { useListAllGender } from "../../../../../../services/protegeme-v2/user/use-user";
import { useListAllDocumentType } from "../../../../../../services/protegeme-v2/user/use-user";

const FormFields = () => {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext<IFormikValue>();
  const { data: genders, isLoading: isGendersLoading } = useListAllGender();
  const { data: documents, isLoading: isDocumentsLoading } =
    useListAllDocumentType();

  return (
    <Fragment>
      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        label={t("userCreatePage.form.fields.firstName.label")}
      >
        <Field
          type={"text"}
          size={"small"}
          fullWidth={true}
          name={"firstName"}
          variant={"outlined"}
          component={TextField}
        />
      </HorizontalField>

      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        label={t("userCreatePage.form.fields.lastName.label")}
      >
        <Field
          type={"text"}
          size={"small"}
          fullWidth={true}
          name={"lastName"}
          variant={"outlined"}
          component={TextField}
        />
      </HorizontalField>

      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        label={t("userCreatePage.form.fields.identificationType.label")}
      >
        <Field
          size={"small"}
          fullWidth={true}
          component={Autocomplete}
          name={"identification.type"}
          loading={isDocumentsLoading}
          options={documents?.data || []}
          getOptionLabel={(option: any) => option?.name || ""}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextFieldMui
              {...params}
              variant={"outlined"}
              helperText={errors?.identification?.type}
              error={
                touched?.identification?.type && !!errors?.identification?.type
              }
            />
          )}
        />
      </HorizontalField>

      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        help={t("userCreatePage.form.fields.identificationNumber.help")}
        label={t("userCreatePage.form.fields.identificationNumber.label")}
      >
        <Field
          type={"text"}
          size={"small"}
          fullWidth={true}
          variant={"outlined"}
          component={TextField}
          name={"identification.number"}
        />
      </HorizontalField>

      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        label={t("userCreatePage.form.fields.email.label")}
      >
        <Field
          name="email"
          type="email"
          size={"small"}
          fullWidth={true}
          variant={"outlined"}
          component={TextField}
        />
      </HorizontalField>

      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        label={t("userCreatePage.form.fields.birthday.label")}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Field
            autoOk={true}
            openTo="year"
            size={"small"}
            fullWidth={true}
            name={"birthday"}
            format="dd/MM/yyyy"
            maxDate={new Date()}
            inputVariant={"outlined"}
            component={KeyboardDatePicker}
            views={["year", "month", "date"]}
          />
        </MuiPickersUtilsProvider>
      </HorizontalField>
      <HorizontalField
        isRequired={true}
        widthClass={"w-96"}
        label={t("userCreatePage.form.fields.gender.label")}
      >
        <Field
          size={"small"}
          name={"gender"}
          fullWidth={true}
          options={genders || []}
          component={Autocomplete}
          loading={isGendersLoading}
          getOptionLabel={(option: any) => option?.name || ""}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextFieldMui
              {...params}
              variant={"outlined"}
              error={touched?.gender && !!errors?.gender}
            />
          )}
        />
      </HorizontalField>
    </Fragment>
  );
};

export default FormFields;
