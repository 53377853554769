import { PulseLoader } from "react-spinners";
import { useTheme } from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode } from "react";
import { Box, Button, ButtonProps } from "@material-ui/core";

type ILoadingButton = {
  isLoading: boolean;
  component?: ReactNode;
  to?: string;
} & ButtonProps;

const LoadingButton: FunctionComponent<ILoadingButton> = (props) => {
  const { isLoading, children, ...buttonProps } = props;
  if (isLoading) return <LoadButton {...props} />;
  return <Button children={children} {...buttonProps} />;
};

const LoadButton: FunctionComponent<ILoadingButton> = (props) => {
  const theme = useTheme();
  return (
    <Button
      disabled={true}
      children={props.children}
      variant="outlined"
      color={props.color}
      endIcon={
        <Box color={"primary.contrastText"}>
          <PulseLoader
            size={5}
            css={"display:flex"}
            color={theme.palette.text.disabled}
            loading={true}
          />
        </Box>
      }
    />
  );
};

export default LoadingButton;
