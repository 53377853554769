import { IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoEye } from "react-icons/io5";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import TableStandard from "../../../../components/tables/table-standard";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { IClientList } from "../../../../services/protegeme-v2/client/client-service.interface";
import { ClientFullName } from "../../../../services/protegeme-v2/client/util/client-format-name";

interface IClientTable {
  data: Array<any>;
}

const ClientTable = (props: IClientTable) => {
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  const { canClientRead, canClientUpdate } = useAuthorization();

  const { data } = props;
  const columns: Array<Column<IClientList>> = [
    {
      Header: t("clientList.columns.name"),
      accessor: (row) => (
        <ClientFullName firstName={row.name} lastName={row.second_name} />
      ),
    },
    {
      id: "identification_number",
      Header: () => (
        <div className={"text-right"}>
          {t("clientList.columns.identification_number")}
        </div>
      ),
      accessor: (row) => (
        <div className={"text-right"}>{row.identification_number}</div>
      ),
    },
    {
      id: "email",
      Header: () => (
        <div className={"text-right"}>{t("clientList.columns.email")}</div>
      ),
      accessor: (row) => (
        <div className={"text-right overflow-ellipsis overflow-hidden"}>
          {row.email}
        </div>
      ),
    },
    {
      id: "actions",
      Header: () => (
        <div className={"flex flex-row justify-center"}>
          {t("clientList.columns.actions")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center gap-2"}>
          <When condition={canClientRead}>
            <IconButton
              component={Link}
              to={routesV2.clientDetails.route(row.id)}
              size={"small"}
              children={<IoEye />}
            />
          </When>
          <When condition={canClientUpdate}>
            <IconButton
              size="small"
              children={routesV2.clientUpdate.icon}
              component={Link}
              to={routesV2.clientUpdate.route(row.id)}
            />
          </When>
        </div>
      ),
    },
  ];

  return (
    <TableStandard
      style={{ border: "0px" }}
      className={"table-auto"}
      stickyHeader={true}
      size={"small"}
      columns={columns}
      data={data}
    />
  );
};

export default ClientTable;
