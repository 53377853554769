import React from "react";
import { Grid } from "@material-ui/core";

import { AuthorizationComponent } from "../../providers/authorization/authorization.component";
import { useAuthorization } from "../../providers/authorization/authorization.provider";
import FixedScrollSectionTemplate from "../../template/fixed-scroll-section.template";
import ReportCorePanel from "./components/report-core-panel";

const ReportsGeneratePage = () => {
  const { canGenerateReports } = useAuthorization();
  return (
    <AuthorizationComponent
      isAuthorized={canGenerateReports}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center my-6"}>
              <div className={"w-full"}>
                <ReportCorePanel />
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ReportsGeneratePage;
