import { Box } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { useEffect } from "react";
import { Fragment } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { Default } from "react-if";
import { Case } from "react-if";
import { Switch } from "react-if";
import { Else } from "react-if";
import { Then } from "react-if";
import { If } from "react-if";
import { When } from "react-if";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NotificationStandard from "../../../../components/notification/notification-standard";
import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { getClientFullName } from "../../../../services/protegeme-v2/client/util/client-format-name";
import { ClientFullName } from "../../../../services/protegeme-v2/client/util/client-format-name";
import { useReclamationFindOne } from "../../../../services/protegeme-v2/reclamation/use-reclamation";
import { useReclamationAuditDetails } from "../../../../services/protegeme-v2/reclamation/use-reclamation";

const ReclamationAuditDetailsPage = () => {
  const authorization = useAuthorization();
  const { t } = useTranslation();
  const history = useHistory();
  const routesV2 = useRoutesV2();
  const { reclamationId } = useParams<{ reclamationId: string }>();

  const reclamationFindOne = useReclamationFindOne({
    request: { reclamationId },
  });
  const reclamationAuditDetails = useReclamationAuditDetails({
    request: { reclamationId },
    options: { retry: false },
  });

  useEffect(() => {
    if (reclamationAuditDetails.isError) {
      toast.error(
        <NotificationStandard
          body={t("pendingCoverageDetails.notifications.notFound")}
        />
      );
      history.push(routesV2.reclamationListAll.route());
    }
  }, [reclamationAuditDetails.isError, history, routesV2, t]);

  return (
    <AuthorizationComponent
      isAuthorized={authorization.canReclamationAuditReadOne}
      autoRedirect={true}
    >
      <When
        condition={reclamationFindOne.isSuccess}
        children={() => (
          <Switch>
            <Case
              condition={
                reclamationFindOne.data!.estado.id_system !== "FINALIZADO"
              }
            >
              <Redirect to={routesV2.reclamationDetails.route(reclamationId)} />
            </Case>

            <Case condition={!reclamationFindOne.data!.audited}>
              <Redirect
                to={routesV2.reclamationAuditRegister.route({ reclamationId })}
              />
            </Case>

            <Default
              children={() => (
                <div className={"p-8 gap-8"}>
                  <Paper className={"flex flex-col"}>
                    <Toolbar variant={"regular"}>
                      <div
                        className={
                          "w-full flex flex-row items-center justify-between"
                        }
                      >
                        <div className={"text-2xl font-bold"}>
                          {t("reclamationAuditDetails.panel.title", {
                            reclamationId,
                          })}
                        </div>
                        <When
                          condition={reclamationAuditDetails.isLoading}
                          children={() => (
                            <Skeleton variant={"text"} width={"25rem"} />
                          )}
                        />
                        <When
                          condition={reclamationAuditDetails.isSuccess}
                          children={() => (
                            <div className={"flex flex-col"}>
                              <div className={"font-medium italic text-right"}>
                                <div className={"flex flex-row gap-1"}>
                                  {getClientFullName({
                                    firstName:
                                      reclamationAuditDetails.data!.data
                                        .auditor_user.name,
                                    lastName:
                                      reclamationAuditDetails.data!.data
                                        .auditor_user.second_name,
                                  })}
                                  <span>-</span>
                                  {
                                    reclamationAuditDetails.data!.data
                                      .auditor_user.user_type.name
                                  }
                                </div>
                              </div>
                              <div className={"text-xs italic text-right"}>
                                {format(
                                  parseISO(
                                    reclamationAuditDetails.data!.data.users[0]
                                      .result_final.audited_date
                                  ),
                                  "Pp"
                                )}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </Toolbar>
                    <Divider />
                    <Paper elevation={0} square={true}>
                      <div className={"px-6 py-6 flex flex-col gap-6"}>
                        <When
                          condition={reclamationAuditDetails.isLoading}
                          children={() => (
                            <Skeleton
                              variant={"rect"}
                              height={500}
                              width={"100%"}
                            />
                          )}
                        />
                        <When
                          condition={reclamationAuditDetails.isSuccess}
                          children={() =>
                            reclamationAuditDetails.data!.data.users.map(
                              (user, index) => (
                                <Fragment key={user.id}>
                                  <div className={"grid grid-cols-5 gap-4"}>
                                    <div
                                      className={
                                        "col-span-2 flex flex-col gap-6 p-4"
                                      }
                                    >
                                      <div>
                                        <div className={"font-medium text-xl"}>
                                          <ClientFullName
                                            firstName={user.name}
                                            lastName={user.second_name}
                                          />
                                        </div>
                                        <div className={"font-medium italic"}>
                                          {user.user_type.name}
                                        </div>
                                      </div>
                                      <div className={"flex flex-col gap-1"}>
                                        <div className={"font-medium"}>
                                          {t(
                                            "reclamationAuditRegister.panel.stages"
                                          )}
                                        </div>
                                        <ul className={"list-inside list-disc"}>
                                          {user.etapas.map((etapa) => (
                                            <li key={etapa.id}>
                                              {etapa.nombre}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div
                                        className={"w-full font-medium text-xl"}
                                      >
                                        {t(
                                          "reclamationAuditDetails.panel.result",
                                          {
                                            result: (
                                              user.result_final
                                                .total_porcentaje * 100
                                            ).toFixed(0),
                                          }
                                        )}
                                      </div>
                                    </div>
                                    <div className={"col-span-3"}>
                                      <Paper
                                        square={true}
                                        variant={"outlined"}
                                        className={"p-4"}
                                      >
                                        <div className={"flex flex-col gap-4"}>
                                          <div
                                            className={
                                              "text-xl font-medium text-center"
                                            }
                                          >
                                            {t(
                                              "reclamationAuditRegister.panel.form"
                                            )}
                                          </div>
                                          {user.secciones.map((section) => (
                                            <Fragment>
                                              <When
                                                condition={
                                                  section.tipo.id_system ===
                                                  "NOCRITICO"
                                                }
                                              >
                                                <div>
                                                  {t(
                                                    "reclamationAuditRegister.panel.noCriticalQuestions"
                                                  )}
                                                </div>
                                              </When>
                                              <When
                                                condition={
                                                  section.tipo.id_system ===
                                                  "CRITICO"
                                                }
                                              >
                                                <div>
                                                  {t(
                                                    "reclamationAuditRegister.panel.criticalQuestions"
                                                  )}
                                                </div>
                                              </When>
                                              <Table
                                                size={"small"}
                                                className={"table-fixed"}
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>
                                                      {t(
                                                        "reclamationAuditRegister.panel.question"
                                                      )}
                                                    </TableCell>
                                                    <TableCell
                                                      className={"w-32"}
                                                    >
                                                      <div
                                                        className={
                                                          "text-center"
                                                        }
                                                      >
                                                        {t(
                                                          "reclamationAuditRegister.panel.answer"
                                                        )}
                                                      </div>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {section.items_result.map(
                                                    (item, index) => (
                                                      <TableRow key={index}>
                                                        <TableCell>
                                                          {
                                                            item.item_evaluacion
                                                              .name
                                                          }
                                                        </TableCell>
                                                        <TableCell>
                                                          <If
                                                            condition={
                                                              item.resultado
                                                            }
                                                          >
                                                            <Then>
                                                              <Box
                                                                component={
                                                                  "div"
                                                                }
                                                                color={
                                                                  "success.main"
                                                                }
                                                                className="flex flex-row justify-center items-center"
                                                              >
                                                                <MdCheckCircle
                                                                  size={"1.5em"}
                                                                />
                                                              </Box>
                                                            </Then>
                                                            <Else>
                                                              <Box
                                                                component={
                                                                  "div"
                                                                }
                                                                color={
                                                                  "error.main"
                                                                }
                                                                className="flex flex-row justify-center items-center"
                                                              >
                                                                <MdCancel
                                                                  size={"1.5em"}
                                                                />
                                                              </Box>
                                                            </Else>
                                                          </If>
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Fragment>
                                          ))}
                                        </div>
                                      </Paper>
                                    </div>
                                  </div>
                                  <When
                                    condition={
                                      index + 1 <
                                      reclamationAuditDetails.data!.data.users
                                        .length
                                    }
                                  >
                                    <hr />
                                  </When>
                                </Fragment>
                              )
                            )
                          }
                        />
                      </div>
                    </Paper>
                  </Paper>
                </div>
              )}
            />
          </Switch>
        )}
      />
    </AuthorizationComponent>
  );
};

export default ReclamationAuditDetailsPage;
