import { IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoEye } from "react-icons/io5";
import { When } from "react-if";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import TableStandard from "../../../../components/tables/table-standard";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { IFindAllClientDevice } from "../../../../services/protegeme-v2/client/client-service.interface";

interface IDevicesSection {
  devices: IFindAllClientDevice[];
}

const DevicesSection = (props: IDevicesSection) => {
  const { devices } = props;
  const { t } = useTranslation();
  const { clientId } = useParams<any>();
  const routesV2 = useRoutesV2();
  const { canClientDeviceReadOne, canClientDeviceUpdateOne } =
    useAuthorization();
  const columns: Array<Column<IFindAllClientDevice>> = [
    {
      id: "id",
      Header: t("clientDetails.panel.device.id"),
      accessor: (row) => row.id,
    },
    {
      id: "model",
      Header: t("clientDetails.panel.device.model"),
      accessor: (row) => (
        <div className={"flex text-xs whitespace-pre-line"}>
          {String(row.dispositivo.nombre).replaceAll("_", " ")}
        </div>
      ),
    },
    {
      id: "imeiPrincipal",
      Header: t("clientDetails.panel.device.imeiPrincipal"),
      accessor: (row) => row.imei_uno,
    },
    {
      id: "actions",
      Header: () => (
        <div className={"text-center"}>
          {t("clientDetails.panel.device.actions")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center gap-2"}>
          <When condition={canClientDeviceReadOne}>
            <IconButton
              component={Link}
              to={routesV2.clientDeviceDetails.route({
                clientId,
                deviceId: row.id,
              })}
              size={"small"}
              children={<IoEye />}
            />
          </When>
          <When condition={canClientDeviceUpdateOne}>
            <IconButton
              component={Link}
              to={routesV2.clientDeviceUpdate.route({
                clientId,
                deviceId: row.id,
              })}
              size={"small"}
              children={routesV2.clientDeviceUpdate.icon}
            />
          </When>
        </div>
      ),
    },
  ];

  return <TableStandard size={"small"} columns={columns} data={devices} />;
};

export default DevicesSection;
