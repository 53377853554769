import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { IoPhonePortrait } from "react-icons/io5";
import { When } from "react-if";

const useStyle = makeStyles((theme) => ({
  marker: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
  },
}));

interface IDeviceMarker {
  label?: string;
  lat: number;
  lng: number;
}

const DeviceMarker = (props: IDeviceMarker) => {
  const { label } = props;
  const style = useStyle();
  return (
    <div className={clsx("w-24", style.marker)}>
      <IoPhonePortrait className={"w-full"} size={"3em"} />
      <When condition={Boolean(label)}>
        <div className={"text-center font-medium"}>{label}</div>
      </When>
    </div>
  );
};

export default DeviceMarker;
