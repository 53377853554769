import React from "react";

import V1HistoryTicketPanelComponent from "./v1-history-ticket.panel/v1-history-ticket-panel.component";
import { useProtegemeApi } from "../../hooks/useProtegemeApi/useProtegemeApi";
import EAxios from "../../axios/EAxios";
import { useParams } from "react-router-dom";

const V1HistoryTicketService = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const url = EAxios.endpoints.default.v1.ticketHistory(ticketId);
  const [{ data, loading, error }] = useProtegemeApi(url);
  return (
    <V1HistoryTicketPanelComponent
      ticket={data}
      height={500}
      width={"100%"}
      variant={"rect"}
      hasError={error}
      isLoading={loading}
    />
  );
};

export default V1HistoryTicketService;
