import _ from "lodash";
import React from "react";
import { useFormikContext } from "formik";
import { Button } from "@material-ui/core";

import { useStepContext } from "./step.provider";
import { IDocument } from "../../../../types/event";
import LoadingButton from "../../../loading-button/loading-button";

const ButtonsStepComponent = () => {
  const formik = useFormikContext();
  const { currentStep, setCurrentStep, isLoadingSubmit } = useStepContext();

  const isLastStep = currentStep === 3;
  const hasNextButton = currentStep < 3;
  const hasBackButton = currentStep > 0;

  const handleBackStep = () => {
    if (currentStep === 1) {
      formik.resetForm();
    }
    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = async () => {
    const errors = await formik.validateForm();
    const errorFields = _.keys(errors);
    const hasErrors = !_.isEmpty(errorFields);
    if (hasErrors) {
      errorFields.map((field) => {
        formik.setFieldTouched(field, true);
        if (Array.isArray(_.get(errors, field))) {
          _.get(errors, field).map((_: IDocument, index: number) =>
            formik.setFieldTouched(`${field}[${index}].file`, true)
          );
        }
        return true;
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <div className={"mb-8 flex flex-row-reverse justify-between"}>
      {isLastStep && (
        <LoadingButton
          type={"submit"}
          color={"primary"}
          children={"Guardar"}
          variant={"contained"}
          isLoading={isLoadingSubmit}
        />
      )}
      {hasNextButton && (
        <Button
          type={"button"}
          color={"primary"}
          children={"Siguiente"}
          variant={"contained"}
          onClick={() => handleNextStep()}
        />
      )}
      {hasBackButton && (
        <Button
          type={"button"}
          color={"primary"}
          children={"Anterior"}
          variant={"contained"}
          onClick={() => handleBackStep()}
        />
      )}
    </div>
  );
};

export default ButtonsStepComponent;
