import React, { FunctionComponent } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

import { useLogin } from "../../../hooks/useLogin";

type IProps = {
  component: FunctionComponent;
  restricted: boolean;
} & RouteProps;

const PublicRoute = (props: IProps) => {
  const { isLogin } = useLogin();
  const { component, restricted, ...otherProps } = props;
  return (
    <Route
      {...otherProps}
      render={(renderProps) =>
        isLogin && restricted ? (
          <Redirect to={"/"} />
        ) : (
          React.createElement(component, renderProps)
        )
      }
    />
  );
};

export default PublicRoute;
