import { Fragment, ReactNode } from "react";
import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { useLogin } from "../../../hooks/useLogin";

type IProps = { component: FunctionComponent } & RouteProps;

const PrivateRoute = (props: IProps) => {
  const { isLogin } = useLogin();
  const { component, ...otherProps } = props;
  return (
    <Route
      {...otherProps}
      render={(renderProps) =>
        isLogin ? (
          React.createElement(component, renderProps)
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

interface IPrivateRoutesV2 {
  children: ReactNode;
}

export const PrivateRouteV2 = (props: IPrivateRoutesV2) => {
  const { isLogin } = useLogin();
  const { children } = props;
  if (!isLogin) {
    return <Redirect to={"/login"} />;
  }
  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
