import { History } from "history";
import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import EAxios from "../../axios/EAxios";
import { IBackendResponse, ISaveCausalByStage } from "../../types/event";

const initialState: IBackendResponse<string> = {
  isLoading: false,
  hasError: false,
  response: undefined,
};

const { reducer, actions } = createSlice({
  name: "save_causal_by_stage",
  initialState,
  reducers: {
    saveCausalByStageLoading: (state: IBackendResponse<string>) => {
      state.isLoading = true;
      state.hasError = false;
      state.response = undefined;
    },
    saveCausalByStageSuccess: (state: IBackendResponse<string>) => {
      state.isLoading = false;
    },
    saveCausalByStageFailed: (state: IBackendResponse<string>) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const saveCausalByStageRequest =
  (
    { params, body }: ISaveCausalByStage,
    history: History,
    setLoadingSubmit: (b: boolean) => void
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(SaveCausalByStageActions.saveCausalByStageLoading());
      const url = EAxios.endpoints.default.stage.saveManualVerification(
        String(params.eventId)
      );
      setLoadingSubmit(true);
      const response = await EAxios.post(url, body);
      setLoadingSubmit(false);
      dispatch(SaveCausalByStageActions.saveCausalByStageSuccess());
      if (response.status === 200) history.goBack();
    } catch (error) {
      setLoadingSubmit(false);
      dispatch(SaveCausalByStageActions.saveCausalByStageFailed());
    }
  };

export const SaveCausalByStageActions = {
  ...actions,
  saveCausalByStageRequest,
};
export const SaveCausalByStageReducer = reducer;
