import React from "react";
import { Grid } from "@material-ui/core";

import FixedScrollSectionTemplate from "../../../template/fixed-scroll-section.template";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import V1HistoryTicketService from "../../../components/v1-history/v1-history-ticket.service";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";

export const V1HistoryTicketPage = () => {
  const { canHistoryTicketRead } = useAuthorization();
  return (
    <AuthorizationComponent
      isAuthorized={canHistoryTicketRead}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-full"}>
                <V1HistoryTicketService />
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};
