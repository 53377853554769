import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

type IMaterialTextField = {
  name: string;
  label: string;
} & TextFieldProps;

const MaterialTextField = (props: IMaterialTextField) => {
  const { name, ...textFieldProps } = props;
  const [field, meta] = useField<string>(name);
  return (
    <TextField
      {...textFieldProps}
      value={field.value}
      helperText={meta.error}
      error={Boolean(meta.error && meta.touched)}
      onBlur={(event) => field.onBlur(field.name)(event)}
      onChange={(event) => field.onChange(field.name)(event)}
    />
  );
};

export default MaterialTextField;
