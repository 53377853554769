import { Skeleton } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Case } from "react-if";
import { Switch } from "react-if";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import Panel from "../../../components/panel/panel";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { usePendingCoverageFindById } from "../../../services/protegeme-v2/pending-coverage/pending-coverage-hooks";
import { getDocumentSignUrl } from "../../../util/Document.util";
import PendingCoverageClientSection from "./components/clients-section";
import PendingCoverageImageSection from "./components/pending-coverage-image-section";
import LocalizationSection from "./components/localization-section";

const PendingCoverageDetailsContainer = () => {
  const { t } = useTranslation();
  const authorization = useAuthorization();
  const { pendingCoverageId } = useParams<any>();

  const pendingCoverageFindById = usePendingCoverageFindById({
    request: { pendingCoverageId },
  });

  const deviceImageId =
    pendingCoverageFindById.data?.data.image_dispositivo_qr.id;

  const deviceImage = useAsync(async () => {
    if (_.isNil(deviceImageId)) return null;
    const url = await getDocumentSignUrl(deviceImageId);
    return url;
  }, [deviceImageId]);

  return (
    <AuthorizationComponent
      isAuthorized={authorization.canPendingCoverageReadOne}
      autoRedirect={true}
    >
      <div className={"w-full"}>
        <Grid container direction="row">
          <Grid item xs={12} className={"p-6"}>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={6}>
                <Switch>
                  <Case
                    condition={pendingCoverageFindById.isLoading}
                    children={
                      <Skeleton height={200} width={"100%"} variant={"rect"} />
                    }
                  />
                  <Case
                    condition={Boolean(pendingCoverageFindById.data)}
                    children={() => (
                      <PendingCoverageClientSection
                        pendingCoverage={pendingCoverageFindById.data!.data}
                      />
                    )}
                  />
                </Switch>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Panel
                      title={
                        <div className="w-full h-full flex flex-row justify-between items-center">
                          <div>
                            {t("pendingCoverageDetails.panels.image.label")}
                          </div>
                        </div>
                      }
                    >
                      <Switch>
                        <Case
                          condition={pendingCoverageFindById.isLoading}
                          children={
                            <Skeleton
                              height={200}
                              width={"100%"}
                              variant={"rect"}
                            />
                          }
                        />
                        <Case
                          condition={Boolean(deviceImage.value)}
                          children={() => (
                            <PendingCoverageImageSection
                              src={deviceImage.value!}
                            />
                          )}
                        />
                      </Switch>
                    </Panel>
                  </Grid>
                  <Grid item>
                    <Panel
                      title={
                        <div className="w-full h-full flex flex-row justify-between items-center">
                          <div>
                            {t(
                              "pendingCoverageDetails.panels.localization.label"
                            )}
                          </div>
                        </div>
                      }
                    >
                      <Switch>
                        <Case
                          condition={pendingCoverageFindById.isLoading}
                          children={
                            <Skeleton
                              height={200}
                              width={"100%"}
                              variant={"rect"}
                            />
                          }
                        />
                        <Case
                          condition={Boolean(pendingCoverageFindById.data)}
                          children={() => (
                            <LocalizationSection
                              label={
                                pendingCoverageFindById.data!.data.dispositivo
                                  ?.nombre
                              }
                              latitude={
                                pendingCoverageFindById.data!.data.metadata
                                  .latitude
                              }
                              longitude={
                                pendingCoverageFindById.data!.data.metadata
                                  .longitude
                              }
                            />
                          )}
                        />
                      </Switch>
                    </Panel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </AuthorizationComponent>
  );
};

export default PendingCoverageDetailsContainer;
