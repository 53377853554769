import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
  createContext,
} from "react";
import {
  ISponsor,
  IPlan,
  IPlanSelected,
} from "../interfaces/link-device.types";
interface IContext {
  sponsors: ISponsor[];
  plans: IPlan[] | undefined;
  planSelected: IPlanSelected | null;
  currentStep: number;
  isLoading: boolean;
  setSponsors: Dispatch<SetStateAction<ISponsor[]>>;
  setPlans: Dispatch<SetStateAction<IPlan[] | undefined>>;
  setPlanSelected: Dispatch<SetStateAction<IPlanSelected | null>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const StepContextLinkDevice = createContext<IContext>({} as IContext);
export const useStepContextLinkDevice = () =>
  useContext<IContext>(StepContextLinkDevice);

const StepProviderLinkDevice: FunctionComponent = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [sponsors, setSponsors] = useState<ISponsor[]>([]);
  const [plans, setPlans] = useState<IPlan[] | undefined>([]);
  const [planSelected, setPlanSelected] = useState<IPlanSelected | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context: IContext = {
    sponsors,
    plans,
    planSelected,
    currentStep,
    isLoading,
    setSponsors,
    setPlans,
    setPlanSelected,
    setCurrentStep,
    setIsLoading,
  };
  return (
    <StepContextLinkDevice.Provider value={context}>
      {children}
    </StepContextLinkDevice.Provider>
  );
};
export default StepProviderLinkDevice;
