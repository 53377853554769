import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import * as yup from "yup";
import VerticalField from "../../../../components/formik/vertical-field";
import IconButtonMenu from "../../../../components/icon-button-menu/icon-button-menu";
import AutocompleteSelectChips from "./autocomplete-select-chips";
import { IReclamationListFilters } from "../reclamation-list-page";
import { useSponsorList } from "../../../../services/protegeme-v2/sponsor/use-sponsor";
import { useStatusReclamationList } from "../../../../services/protegeme-v2/reclamation/use-reclamation";

type IFilterButtonMenuContainer = {
  filters: IReclamationListFilters;
  updateFilters: (filters: IReclamationListFilters) => void;
  updateCurrentPage: (currentPage: number) => void;
};

const ReclamationListFilterMenuContainer = (
  props: IFilterButtonMenuContainer
) => {
  const { t } = useTranslation();
  const { filters, updateFilters, updateCurrentPage } = props;
  const { data: Sponsors, isLoading: isLoadingSponsors } = useSponsorList();
  const { data: Status, isLoading: isLoadingStatus } =
    useStatusReclamationList();

  const clearFilters = (popupState: any) => {
    popupState.close();
    updateFilters({
      email: "",
      reclamationId: "",
      phoneNumber: "",
      identificationNumber: "",
      sponsor: [],
      status: [],
    });
    updateCurrentPage(0);
  };

  return (
    <IconButtonMenu
      variant={"popover"}
      popupId={"filter-menu"}
      icon={<IoSearch />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      body={(popupState) => (
        <div className={"p-8"} style={{ width: "35rem" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              identificationNumber: filters.identificationNumber,
              email: filters.email,
              phoneNumber: filters.phoneNumber,
              reclamationId: filters.reclamationId,
              sponsor: filters.sponsor,
              status: filters.status,
            }}
            onSubmit={(values, helpers) => {
              updateFilters(values);
              helpers.resetForm();
              popupState.close();
            }}
            validationSchema={yup.object().shape({
              reclamationId: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("reclamationList.panel.filter.reclamationId")),
              phoneNumber: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("reclamationList.panel.filter.phoneNumber")),
              identificationNumber: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("reclamationList.panel.filter.identificationNumber")),
              email: yup
                .string()
                .label(t("reclamationList.panel.filter.email")),
            })}
          >
            <Form>
              <div className={"mb-7 font-bold"}>
                {t("reclamationList.panel.filter.label")}
              </div>
              <div className={"grid grid-cols-2 gap-x-8 gap-y-3"}>
                <VerticalField
                  bold={false}
                  label={t("reclamationList.panel.filter.reclamationId")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"reclamationId"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("reclamationList.panel.filter.identificationNumber")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"identificationNumber"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("reclamationList.panel.filter.phoneNumber")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"phoneNumber"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("reclamationList.panel.filter.email")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"email"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("reclamationList.panel.filter.sponsor")}
                >
                  <Field
                    multiple
                    disabled={isLoadingSponsors}
                    name={"sponsor"}
                    component={AutocompleteSelectChips}
                    options={Sponsors?.data}
                    getOptionLabel={(opt: any) => opt.nombre}
                    textFieldProps={{
                      fullWidth: true,
                      size: "small",
                      variant: "outlined",
                    }}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("reclamationList.panel.filter.status")}
                >
                  <Field
                    multiple
                    disabled={isLoadingStatus}
                    name={"status"}
                    component={AutocompleteSelectChips}
                    options={Status?.data}
                    getOptionLabel={(opt: any) => opt.nombre}
                    textFieldProps={{
                      fullWidth: true,
                      size: "small",
                      variant: "outlined",
                    }}
                  />
                </VerticalField>
                <div className={"flex flex-row-reverse gap-4 col-span-2 mt-8"}>
                  <Button
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    children={t("reclamationList.panel.filter.submit")}
                  />
                  <Button
                    onClick={() => clearFilters(popupState)}
                    variant={"outlined"}
                    color={"primary"}
                    children={t("reclamationList.panel.filter.clear")}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    />
  );
};

export default ReclamationListFilterMenuContainer;
