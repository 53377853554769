import clsx from "clsx";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalStyles } from "../../../../styles/useGlobalStyles";

interface IPendingCoverageImageSection {
  src: string;
}

const PendingCoverageImageSection = (props: IPendingCoverageImageSection) => {
  const { t } = useTranslation();
  const style = useGlobalStyles();
  const { src } = props;
  return (
    <Fragment>
      <img
        className={clsx("h-96 border object-contain", style.borderColor)}
        src={src}
        alt={t("pendingCoverageDetails.panels.image.imageAlt")}
      />
    </Fragment>
  );
};

export default PendingCoverageImageSection;
