import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import { IReclamationFindOneRes } from "./reclamation-service.interface";
import { IReclamationFindOneReq } from "./reclamation-service.interface";
import { IReclamationAuditSaveRes } from "./reclamation-service.interface";
import { IReclamationAuditSaveReq } from "./reclamation-service.interface";
import { IReclamationAuditDetailsRes } from "./reclamation-service.interface";
import { IReclamationAuditDetailsReq } from "./reclamation-service.interface";
import { IReclamationAuditListRes } from "./reclamation-service.interface";
import { IReclamationAuditListReq } from "./reclamation-service.interface";
import { IReclamationFindResponse } from "./reclamation-service.interface";
import { IReclamationFindRequest } from "./reclamation-service.interface";
import { IReclamationCreateCommentReq } from "./reclamation-service.interface";
import { IReclamationListAllCommentsRes } from "./reclamation-service.interface";
import { IReclamationExportRes } from "./reclamation-service.interface";
import { IUseListStatusReclamation } from "./reclamation-service.interface";

export const reclamationFind = (params: IReclamationFindRequest) => {
  const { pagination, filters } = params;
  const url = protegemeV2ApiRoutes.v2.reclamation.find();
  return protegemeV2ApiAxios.get<IReclamationFindResponse>(url, {
    params: {
      limit: pagination.pageSize,
      page: pagination.pageNumber,
      ticket_id: filters?.reclamationId || undefined,
      email: filters?.email || undefined,
      identification_number: filters?.identificationNumber || undefined,
      linea: filters?.phoneNumber || undefined,
      sponsor: filters?.sponsor || undefined,
      status: filters?.status || undefined,
    },
  });
};

export const reclamationFindOne = (params: IReclamationFindOneReq) => {
  const { reclamationId } = params;
  const url = protegemeV2ApiRoutes.v2.reclamation.findOne(reclamationId);
  const request = protegemeV2ApiAxios.get<IReclamationFindOneRes>(url);
  return request;
};

export const reclamationExport = () => {
  const url = protegemeV2ApiRoutes.v2.reclamation.export();
  return protegemeV2ApiAxios.get<IReclamationExportRes>(url);
};

export const reclamationListAllComments = (reclamationId: string) => {
  const url =
    protegemeV2ApiRoutes.v2.reclamation.listAllComments(reclamationId);
  return protegemeV2ApiAxios.get<IReclamationListAllCommentsRes>(url);
};

export const reclamationCreateComment = (
  props: IReclamationCreateCommentReq
) => {
  const { body, slugs } = props;
  const url = protegemeV2ApiRoutes.v2.reclamation.createComment(
    slugs.reclamationId
  );
  return protegemeV2ApiAxios.post<{}>(url, body);
};

export const reclamationAuditList = async (
  params: IReclamationAuditListReq
) => {
  const { reclamationId } = params;
  const url = protegemeV2ApiRoutes.v2.reclamation.auditList(reclamationId);
  const request = await protegemeV2ApiAxios.get<IReclamationAuditListRes>(url);
  if (request.data.length === 0) {
    throw new Error("EVALUATIONRESULTTICKERNOTFOUND");
  }
  return request;
};

export const reclamationAuditSave = async (
  params: IReclamationAuditSaveReq
) => {
  const { data, reclamationId } = params;
  const url = protegemeV2ApiRoutes.v2.reclamation.auditSave(reclamationId);
  const request = await protegemeV2ApiAxios.post<IReclamationAuditSaveRes>(
    url,
    data
  );
  return request;
};

export const reclamationAuditDetails = async (
  params: IReclamationAuditDetailsReq
) => {
  const { reclamationId } = params;
  const url = protegemeV2ApiRoutes.v2.reclamation.auditDetails(reclamationId);
  const request = await protegemeV2ApiAxios.get<IReclamationAuditDetailsRes>(
    url
  );
  if ((request.data as any)?.error) {
    throw new Error("EVALUATIONRESULTTICKERNOTFOUND");
  }
  return request;
};

export const statusReclamationList = () => {
  const url = protegemeV2ApiRoutes.v2.statusReclamtion.list();
  return protegemeV2ApiAxios
    .get<IUseListStatusReclamation>(url)
    .then((res) => res.data);
};
