import EAxios from "../../axios/EAxios";
import { IStageDispatch } from "./stage.reducer";
import {
  ICausalResult,
  IDocument,
  IEvento,
  IStage,
  IStageCompleted,
  IStageCurrent,
  IStageResult,
} from "../../types/event";

export const StageActions = {
  fetchEvent: (dispatch: IStageDispatch, eventId: string) => {
    const name = "event";
    const url = EAxios.endpoints.default.event.getEvent(eventId);
    dispatch({ type: "FETCH", payload: { name } });
    EAxios.get<IEvento>(url)
      .then((value) =>
        dispatch({ type: "SUCCESS", payload: { name, data: value.data } })
      )
      .catch(() => dispatch({ type: "ERROR", payload: { name } }));
  },

  fetchDocuments: (dispatch: IStageDispatch, eventId: string) => {
    const name = "documents";
    dispatch({ type: "FETCH", payload: { name } });
    const url = EAxios.endpoints.default.document.getDocumentsByEvent(eventId);
    EAxios.get<IDocument[]>(url)
      .then((value) =>
        dispatch({ type: "SUCCESS", payload: { name, data: value.data } })
      )
      .catch(() => dispatch({ type: "ERROR", payload: { name } }));
  },

  fetchCausals: (dispatch: IStageDispatch, eventId: string) => {
    const name = "causals";
    const url = EAxios.endpoints.default.event.getCausalsByEvent(eventId);
    dispatch({ type: "FETCH", payload: { name } });
    EAxios.get<ICausalResult[]>(url)
      .then((value) =>
        dispatch({ type: "SUCCESS", payload: { name, data: value.data } })
      )
      .catch(() => dispatch({ type: "ERROR", payload: { name } }));
  },

  fetchStage: (dispatch: IStageDispatch, eventId: string) => {
    const name = "stage";

    const currentUrl = EAxios.endpoints.default.event.getCurrentStage(eventId);
    const historyUrl = EAxios.endpoints.default.event.getHistoryStages(eventId);
    const expectedUrl =
      EAxios.endpoints.default.event.getExpectedStages(eventId);

    const currentPromise = EAxios.get<IStageCurrent[]>(currentUrl);
    const historyPromise = EAxios.get<IStageCompleted[]>(historyUrl);
    const expectedPromise = EAxios.get<IStage[]>(expectedUrl);

    dispatch({ type: "FETCH", payload: { name } });
    Promise.all([currentPromise, historyPromise, expectedPromise])
      .then((values: any) => {
        const [current, history, expected] = values;
        const stage: IStageResult = {
          current: current.data,
          history: history.data,
          expected: expected.data,
        };
        return dispatch({ type: "SUCCESS", payload: { name, data: stage } });
      })
      .catch(() => dispatch({ type: "ERROR", payload: { name } }));
  },
};
