import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import LoadingButton from "../../../../../../components/loading-button/loading-button";
import { useClientCreate } from "../../../../../../services/protegeme-v2/client/use-client";

const FormButtons = () => {
  const { t } = useTranslation();
  const { isLoading } = useClientCreate();
  return (
    <Fragment>
      <div className={"flex flex-row justify-center mt-4"}>
        <LoadingButton
          type={"submit"}
          color={"primary"}
          className={"w-1/4"}
          variant={"contained"}
          isLoading={isLoading}
          children={t("userCreatePage.form.submit")}
        />
      </div>
    </Fragment>
  );
};

export default FormButtons;
