import { Divider } from "@material-ui/core";
import React, { FunctionComponent, Fragment } from "react";

import RequestAcceptedComponent from "./components/request-accepted.component";
import RequestRejectedComponent from "./components/request-rejected.component";

const UploadDeviceRequestComponent: FunctionComponent = () => {
  return (
    <Fragment>
      <RequestAcceptedComponent />

      <div className={"my-12"}>
        <Divider />
      </div>

      <RequestRejectedComponent />
    </Fragment>
  );
};

export default UploadDeviceRequestComponent;
