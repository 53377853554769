import React from "react";
import { Field, useField } from "formik";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

import ValidationLabelComponent from "../components/validation-label.component";

type ICheckField = {
  name: string;
  label: string;
} & TextFieldProps;

const CheckField = (props: ICheckField) => {
  const { label, name } = props;
  const [, meta] = useField<string>(name);
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <div>
      <div className={"mb-8 flex flex-col"}>
        <div className={"flex flex-row items-center"}>
          <Field className={"w-4 h-4"} type="checkbox" name={name} />
          <span className={"ml-4"}>{label}</span>
        </div>
        <div className={"flex flex-row"}>
          <ValidationLabelComponent hasError={hasError} label={meta.error} />
        </div>
      </div>
    </div>
  );
};

export default CheckField;
