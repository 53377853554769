import React from "react";
import StepIndicatorLinkDeviceComponent from "./stepper/step-indicator.component";
import StepTitleLinkDeviceComponent from "./stepper/step-title.component";
import { Formik, Form } from "formik";
import StepComponentLinkDevice from "./stepper/step.component";
import ButtonsStepLinkDeviceComponent from "./stepper/buttons-step.component";
import useClientLinkDeviceUtil from "./stepper/use-client-link-device.util";
import { useStepContextLinkDevice } from "./stepper/step.provider";

const ClientLinkDeviceComponent = () => {
  const { initialValues, onSubmit, validationSchema, isLoading } =
    useClientLinkDeviceUtil();
  const { currentStep } = useStepContextLinkDevice();
  return (
    <div className="flex flex-col">
      <div className="w-full flex py-4 pr-10 border-b border-black border-opacity-25">
        <StepIndicatorLinkDeviceComponent />
      </div>
      <div className="w-full px-16 pt-8">
        <StepTitleLinkDeviceComponent />
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep]}
          onSubmit={onSubmit}
        >
          <Form>
            <StepComponentLinkDevice />
            <ButtonsStepLinkDeviceComponent isLoading={isLoading} />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ClientLinkDeviceComponent;
