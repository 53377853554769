import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Icon,
  IconButton,
  PaletteType,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Configuration from "../../config/Configuration";
import { RootState, useAppDispatch } from "../../redux/store";
import { ThemeActions } from "../../redux/theme/theme.slice";
import { useLogin } from "../../hooks/useLogin";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  acincoTypography: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800,
  },
  acincoLogo: {
    maxHeight: 50,
  },
  title: {
    width: Configuration.theme.topbar.title.width,
  },
}));

const Topbar = () => {
  const { logout } = useLogin();
  const history = useHistory();
  const isOpenDrawer = useSelector<RootState, boolean>(
    (state) => state.theme.isOpenDrawer
  );
  const type = useSelector<RootState, PaletteType>((state) => state.theme.type);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  const handleDrawerToggle = () => {
    dispatch(ThemeActions.drawerToggle());
  };

  const handleThemeTypeToggle = () => {
    dispatch(ThemeActions.typeToggle());
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, { [classes.appBarShift]: isOpenDrawer })}
    >
      <Toolbar className={"flex flex-row justify-between"}>
        <div className={"flex"}>
          <div
            className={clsx(classes.title, "flex flex-row items-center mr-6")}
          >
            <div>
              <img
                src={"/logo192.png"}
                alt={"logo.png"}
                className={clsx(classes.acincoLogo)}
              />
            </div>
            <div>
              <Typography
                className={clsx(classes.acincoTypography, "h-auto")}
                variant={"h5"}
              >
                Protégeme v2
              </Typography>
            </div>
          </div>
          <IconButton
            className={clsx(classes.menuButton)}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div>
          <IconButton
            edge={"end"}
            className={clsx(classes.menuButton, "ml-auto")}
            onClick={handleThemeTypeToggle}
          >
            <Icon>{type === "light" ? "brightness_3" : "brightness_5"}</Icon>
          </IconButton>
          <IconButton
            edge={"end"}
            className={clsx(classes.menuButton, "ml-6")}
            onClick={() => handleLogout()}
          >
            <ExitToAppIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
