import React from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Switch } from "react-if";
import { Case } from "react-if";
import { useTranslation } from "react-i18next";

import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import Panel from "../../../../components/panel/panel";
import CallSection from "./components/call-section";
import CallDetailsSection from "./components/call-details-section";
import { useCallFindOne } from "../../../../services/protegeme-v2/contac-center/call/use-call";

const CallDetailsPage = () => {
  const { canCallRead } = useAuthorization();
  const { callId } = useParams<any>();
  const { t } = useTranslation();
  const callFindOne = useCallFindOne({ callId: Number(callId) });
  return (
    <AuthorizationComponent isAuthorized={canCallRead} autoRedirect={true}>
      <div className={"p-8 grid grid-cols-2 gap-8"}>
        <Panel title={t("callDetails.panel.call.label")}>
          <Switch>
            <Case
              condition={callFindOne.isLoading}
              children={
                <Skeleton height={200} width={"100%"} variant={"rect"} />
              }
            />
            <Case
              condition={Boolean(callId)}
              children={<CallSection call={callFindOne.data} />}
            />
          </Switch>
        </Panel>
        <Panel title={t("callDetails.panel.call.details.label")}>
          <Switch>
            <Case
              condition={callFindOne.isLoading}
              children={
                <Skeleton height={200} width={"100%"} variant={"rect"} />
              }
            />
            <Case
              condition={Boolean(callId)}
              children={<CallDetailsSection callDetails={callFindOne.data} />}
            />
          </Switch>
        </Panel>
      </div>
    </AuthorizationComponent>
  );
};

export default CallDetailsPage;
