import produce, { Draft } from "immer";

import { IActualization } from "../../../types/actualization";

export type IActualizationState = {
  actualization: {
    data?: IActualization[];
    isLoading: boolean;
    hasError: boolean;
  };
};

type IActionOnlyType = {
  type: "FETCH_LIST_ALL" | "ERROR_LIST_ALL";
};

type IActionActualizationSuccess = {
  type: "SUCCESS_LIST_ALL";
  payload: IActualization[];
};

export type IActualizationAction =
  | IActionOnlyType
  | IActionActualizationSuccess;

export type IActualizationDispatch = (action: IActualizationAction) => void;

export const actualizationReducer = produce(
  (draft: Draft<IActualizationState>, action: IActualizationAction) => {
    switch (action.type) {
      case "FETCH_LIST_ALL": {
        draft.actualization.data = undefined;
        draft.actualization.isLoading = true;
        draft.actualization.hasError = false;
        return draft;
      }
      case "SUCCESS_LIST_ALL": {
        draft.actualization.data = action.payload;
        draft.actualization.isLoading = false;
        return draft;
      }
      case "ERROR_LIST_ALL": {
        draft.actualization.isLoading = false;
        draft.actualization.hasError = true;
        return draft;
      }
    }
  }
);

//TODO: uncomment this
export const actualizationInitialValue: IActualizationState = {
  // actualization: { data: undefined, hasError: false, isLoading: false },
  // isUploadActualizationLoading: false,
  actualization: {
    hasError: false,
    isLoading: false,
    data: [],
  },
};
