import React from "react";
import AutocompleteField from "../../../../../../components/formik/fields/autocomplete.field";
import { useStepContextLinkDevice } from "../../stepper/step.provider";

interface IPlanSelectField {
  name: string;
  label: string;
  help?: string;
}

const PlanSelectField = (props: IPlanSelectField) => {
  const { name, label, help } = props;
  const { plans } = useStepContextLinkDevice();
  return (
    <AutocompleteField
      name={name}
      label={label}
      help={help}
      size={"small"}
      options={plans || []}
      getOptionLabel={(option) => `${option.nombre}`}
      getOptionSelected={(option, value) => option.nombre === value.nombre}
    />
  );
};
export default PlanSelectField;
