import { Box } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ReactNode } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import { When } from "react-if";

type IHorizontalField = {
  children: ReactNode;
  label: string | ReactNode;
  isRequired?: boolean;
  widthClass?: string;
  help?: string;
};

const HorizontalField = (props: IHorizontalField) => {
  const { children, label, isRequired, help, widthClass } = props;
  return (
    <div className={"flex flex-row gap-2 md:flex-row md:gap-0 items-start"}>
      <div
        className={clsx("flex flex-row gap-1 px-2 pt-3 font-bold", widthClass)}
      >
        <When condition={isRequired}>
          <Box color={"error.main"}>*</Box>
        </When>
        {label}:
        <When condition={Boolean(help)}>
          <Tooltip
            arrow={true}
            placement={"top"}
            className={"cursor-pointer"}
            title={help!}
          >
            <Box>
              <IoAlertCircleOutline className={"text-xl"} color={"inherit"} />
            </Box>
          </Tooltip>
        </When>
      </div>
      {children}
    </div>
  );
};

export default HorizontalField;
