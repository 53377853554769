import React from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Panel from "../../../components/panel/panel";
import ClientCreateForm from "./components/client-create-form/client-create-form";
import FixedScrollSectionTemplate from "../../../template/fixed-scroll-section.template";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";

const ClientCreatePage = () => {
  const { canClientCreate } = useAuthorization();
  const { t } = useTranslation();
  return (
    <AuthorizationComponent isAuthorized={canClientCreate} autoRedirect={true}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center my-6"}>
              <div className={"w-full"}>
                <Panel title={t("userCreatePage.panel.title")}>
                  <ClientCreateForm />
                </Panel>
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ClientCreatePage;
