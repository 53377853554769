import React from "react";
import { Grid } from "@material-ui/core";

import EventCreatePanel from "../components/event/event-create/event-create.panel";
import FixedScrollSectionTemplate from "../template/fixed-scroll-section.template";
import { AuthorizationComponent } from "../providers/authorization/authorization.component";
import { useAuthorization } from "../providers/authorization/authorization.provider";

const EventCreatePage = () => {
  const { canReclamationCreate } = useAuthorization();
  return (
    <AuthorizationComponent
      isAuthorized={canReclamationCreate}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-full"}>
                <EventCreatePanel />
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default EventCreatePage;
