import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSearchParam } from "react-use";
import * as yup from "yup";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik/dist/types";

import { IFormikValue } from "./client-create-form.interface";
import NotificationAlreadyExist from "../../notifications/notification-already-exist";
import NotificationClientCreated from "../../notifications/notification-client-created";
import { useClientCreate } from "../../../../../services/protegeme-v2/client/use-client";
import { ICreateClientReqBody } from "../../../../../services/protegeme-v2/client/client-service.interface";

const formatData = (values: IFormikValue): ICreateClientReqBody => {
  const data = {};
  _.set(data, "name", values.firstName);
  _.set(data, "second_name", values.lastName);
  _.set(data, "number_identification", values.identification.number);
  _.set(data, "type_identification_id", Number(values.identification.type?.id));
  _.set(data, "email", values.email);
  _.set(data, "gender_id", Number(values.gender?.id));
  _.set(data, "birthday", format(values.birthday!, "yyy-MM-dd"));
  return data as ICreateClientReqBody;
};

const useClientCreateFormUtil = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const returnPage = useSearchParam("return");
  const { mutateAsync: clientCreate } = useClientCreate();

  const initialValues: IFormikValue = {
    firstName: "",
    lastName: "",
    identification: {
      type: null,
      number: "",
    },
    email: "",
    gender: null,
    birthday: null,
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t("form.errors.required")),
    lastName: yup.string().required(t("form.errors.required")),
    identification: yup.object().shape({
      type: yup
        .object({
          id: yup.number().required(t("form.errors.required")),
          name: yup.string().required(t("form.errors.required")),
        })
        .typeError(t("form.errors.required"))
        .required(t("form.errors.required")),
      number: yup
        .string()
        .matches(
          /^[1-9]\d*$/,
          t("userCreatePage.form.fields.identificationNumber.errors.cc")
        )
        .typeError(t("form.errors.digits"))
        .required(t("form.errors.required")),
    }),
    email: yup.string().email().required(t("form.errors.required")),
    gender: yup
      .object({
        id: yup.number().required(t("form.errors.required")),
        name: yup.string().required(t("form.errors.required")),
      })
      .typeError(t("form.errors.required"))
      .required(t("form.errors.required")),
    birthday: yup.date().nullable().required(t("form.errors.required")),
  });

  const submitForm = async (
    values: IFormikValue,
    formikHelpers: FormikHelpers<IFormikValue>
  ) => {
    const data = formatData(values);

    const response = await clientCreate({ body: data });

    if (response?.error?.id_system === "USERALREADYEXISTS") {
      toast.error(<NotificationAlreadyExist />);
      return;
    }

    toast.info(<NotificationClientCreated />);
    formikHelpers.resetForm();

    if (returnPage) {
      history.push(returnPage);
      return;
    }

    return;
  };

  return { initialValues, validationSchema, submitForm };
};

export default useClientCreateFormUtil;
