import { format } from "date-fns";
import { parseISO } from "date-fns";
import { es } from "date-fns/locale";
import React from "react";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import LoadingButton from "../../../../components/loading-button/loading-button";
import NotificationStandard from "../../../../components/notification/notification-standard";
import TablePair from "../../../../components/table-pair/table-pair";
import { usePendingCoverageIdentifyDeviceById } from "../../../../services/protegeme-v2/pending-coverage/pending-coverage-hooks";
import { IPendingCoverageFindById } from "../../../../services/protegeme-v2/pending-coverage/pending-coverage-interface";
import { DateFormat } from "../../../../util/date.util";
import PanelDropdown from "../../../../components/panel/panel.dropdown";

interface IDataReceivedSection {
  pendingCoverage: IPendingCoverageFindById;
}

const PendingCoverageClientSection = (props: IDataReceivedSection) => {
  const { pendingCoverage } = props;
  const { t } = useTranslation();
  const recognizeMutation = usePendingCoverageIdentifyDeviceById();
  const clientData = [
    {
      key: t("pendingCoverageDetails.panels.data.clientId"),
      value: pendingCoverage.cliente.id,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientFirstname"),
      value: pendingCoverage.cliente.name,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientLastname"),
      value: pendingCoverage.cliente.second_name,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientBirthday"),
      value: <DateFormat source={pendingCoverage.cliente.birthday} />,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientEmail"),
      value: pendingCoverage.cliente.email,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientGender"),
      value: pendingCoverage.cliente.gender.name,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientIdentificationNumber"),
      value: pendingCoverage.cliente.identification_number,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientIdentificationType"),
      value: pendingCoverage.cliente.identification_type.name,
    },
    {
      key: t("pendingCoverageDetails.panels.data.clientType"),
      value: pendingCoverage.cliente.user_type.id_system,
    },
  ];
  const deviceData = [
    {
      key: t("pendingCoverageDetails.panels.data.deviceId"),
      value: pendingCoverage?.dispositivo?.id,
    },
    {
      key: t("pendingCoverageDetails.panels.data.deviceName"),
      value: pendingCoverage?.dispositivo?.nombre,
    },
    {
      key: t("pendingCoverageDetails.panels.data.deviceManufacturer"),
      value: pendingCoverage?.dispositivo?.fabricante?.nombre,
    },
  ];
  const rawData = [
    {
      key: t("pendingCoverageDetails.panels.data.id"),
      value: pendingCoverage.id,
    },
    {
      key: t("pendingCoverageDetails.panels.data.key"),
      value: (
        <div className={"flex flex-row gap-4 items-center"}>
          <div>{pendingCoverage.dispositivo_key}</div>
          <When
            condition={
              pendingCoverage.estado.id_system === "DOESNOTMATCHDISPOSITIVO"
            }
          >
            <LoadingButton
              variant={"outlined"}
              color={"primary"}
              onClick={async () => {
                const response = await recognizeMutation.mutateAsync({
                  request: { pendingCoverageId: pendingCoverage.id },
                });
                if (
                  response.data.estado.id_system !== "DOESNOTMATCHDISPOSITIVO"
                ) {
                  toast.info(
                    <NotificationStandard
                      body={t(
                        "pendingCoverageDetails.panels.data.notifications.recognizeSuccess"
                      )}
                    />
                  );
                } else {
                  toast.error(
                    <NotificationStandard
                      body={t(
                        "pendingCoverageDetails.panels.data.notifications.recognizeError"
                      )}
                    />
                  );
                }
              }}
              size={"small"}
              isLoading={recognizeMutation.isLoading}
              children={"Identificar"}
            />
          </When>
        </div>
      ),
    },
    {
      key: t("pendingCoverageDetails.panels.data.createdAt"),
      value: format(parseISO(pendingCoverage.date_created), "PPPpp", {
        locale: es,
      }),
    },
    {
      key: t("pendingCoverageDetails.panels.data.status"),
      value: pendingCoverage.estado?.estado,
    },
    // {
    //   key: t("pendingCoverageDetails.panels.data.imageQR"),
    //   value: (
    //     <Tooltip
    //       title={t("pendingCoverageDetails.panels.data.imageQRTooltip", {
    //         name: pendingCoverage.image_dispositivo_qr.nombre,
    //       })}
    //     >
    //       <IconButton
    //         onClick={() =>
    //           openUrlInNewTab(pendingCoverage.image_dispositivo_qr.id)
    //         }
    //         size={"small"}
    //         children={<IoEye />}
    //       />
    //     </Tooltip>
    //   ),
    // },
    // {
    //   key: t("pendingCoverageDetails.panels.data.metadata"),
    //   value: (
    //     <pre className={"overflow-x-auto"}>
    //       {JSON.stringify(pendingCoverage.metadata)}
    //     </pre>
    //   ),
    // },
  ];
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <PanelDropdown
          title={
            <div className="w-full h-full flex flex-row justify-between items-center">
              <div className={"font-medium"}>
                Datos del cliente identificado
              </div>
            </div>
          }
          show={true}
        >
          <TablePair data={clientData} tableClassName={"table-fixed"} />
        </PanelDropdown>
      </Grid>
      <When condition={Boolean(pendingCoverage.dispositivo)}>
        <Grid item>
          <PanelDropdown
            title={
              <div className="w-full h-full flex flex-row justify-between items-center">
                <div className={"font-medium"}>
                  Datos del dispositivo identificado
                </div>
              </div>
            }
            show={true}
          >
            <TablePair data={deviceData} tableClassName={"table-fixed"} />
          </PanelDropdown>
        </Grid>
      </When>
      <Grid item>
        <PanelDropdown
          title={
            <div className="w-full h-full flex flex-row justify-between items-center">
              <div className={"font-medium"}>
                Datos recibidos por Proteccion Movil (RAW)
              </div>
            </div>
          }
          show={true}
        >
          <TablePair data={rawData} tableClassName={"table-fixed"} />
        </PanelDropdown>
      </Grid>
    </Grid>
  );
};

export default PendingCoverageClientSection;
