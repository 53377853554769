import React from "react";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import * as yup from "yup";
import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { TextField as TextFieldMui } from "@material-ui/core";
import { Autocomplete } from "formik-material-ui-lab";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";

import VerticalField from "../../../../components/formik/vertical-field";
import IconButtonMenu from "../../../../components/icon-button-menu/icon-button-menu";
import { IClientListStateFilters } from "../client-list-page";
import { IClientListState } from "../client-list-page";
import { useSponsorList } from "../../../../services/protegeme-v2/sponsor/use-sponsor";

type IFilterPanel = {
  state: IClientListState;
  updateFilters: (filters: IClientListStateFilters) => void;
  updateCurrentPage: (currentPage: number) => void;
};

const FilterButtonMenu = (props: IFilterPanel) => {
  const { t } = useTranslation();
  const { state, updateFilters, updateCurrentPage } = props;
  const { data: Sponsors, isLoading: isLoadingSponsors } = useSponsorList();

  const clearFilters = (popupState: any) => {
    popupState.close();
    updateFilters({
      documentNumber: "",
      sponsor: null,
      name: "",
      phoneNumber: "",
      imei: "",
      lastName: "",
    });
    updateCurrentPage(0);
  };

  return (
    <IconButtonMenu
      variant={"popover"}
      popupId={"filter-menu"}
      icon={<IoSearch />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      body={(popupState) => (
        <div className={"p-8"} style={{ width: "35rem" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              documentNumber: state.filters.documentNumber,
              sponsor: state.filters.sponsor,
              phoneNumber: state.filters.phoneNumber,
              imei: state.filters.imei,
              name: state.filters.name,
              lastName: state.filters.lastName,
            }}
            onSubmit={(values, helpers) => {
              updateFilters(values);
              helpers.resetForm();
              popupState.close();
              updateCurrentPage(0);
            }}
            validationSchema={yup.object().shape({
              documentNumber: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("clientList.filter.documentNumberInput")),
              sponsor: yup
                .object()
                .shape({
                  nombre: yup.string(),
                  id_system: yup.string(),
                })
                .nullable(),
              name: yup.string().label(t("clientList.filter.name")),
              lastName: yup.string().label(t("clientList.filter.lastName")),
              imei: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("clientList.filter.imei")),
              phoneNumber: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("clientList.filter.phoneNumber")),
            })}
          >
            <Form>
              <div className={"mb-7 font-bold"}>
                {t("clientList.filter.label")}
              </div>
              <div className={"grid grid-cols-2 gap-x-8 gap-y-3"}>
                <VerticalField bold={false} label={t("clientList.filter.name")}>
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"name"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("clientList.filter.lastName")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"lastName"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("clientList.filter.documentNumberInput")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"documentNumber"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("clientList.filter.phoneNumber")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"phoneNumber"}
                  />
                </VerticalField>
                <VerticalField bold={false} label={t("clientList.filter.imei")}>
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"imei"}
                  />
                </VerticalField>
                <VerticalField
                  bold={false}
                  label={t("clientList.filter.sponsor")}
                >
                  <Field
                    size={"small"}
                    name={"sponsor"}
                    fullWidth={true}
                    options={Sponsors?.data || []}
                    component={Autocomplete}
                    loading={isLoadingSponsors}
                    getOptionLabel={(option: any) => option?.nombre || ""}
                    getOptionSelected={(option: any, value: any) =>
                      option.nombre === value.nombre
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextFieldMui {...params} variant={"outlined"} />
                    )}
                  />
                </VerticalField>
                <div className={"flex flex-row-reverse gap-4 col-span-2 mt-5"}>
                  <Button
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    children={t("clientList.filter.submitBtn")}
                  />
                  <Button
                    onClick={() => clearFilters(popupState)}
                    variant={"outlined"}
                    color={"primary"}
                    children={t("clientList.filter.clearBtn")}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    />
  );
};

export default FilterButtonMenu;
