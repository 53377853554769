import { reportsMicroserviceApiAxios } from "../reports-microservice-api.axios";
import { reportsMicroserviceApiRoutes } from "../reports-microservice-api.routes";
import {
  IAuthUserReqData,
  IAuthUserResData,
} from "./auth-reports-microservice.interface";

export const authUserReportsMicroservice = (body: IAuthUserReqData) => {
  const url = reportsMicroserviceApiRoutes.auth.users();
  return reportsMicroserviceApiAxios.post<IAuthUserResData>(url, body);
};
