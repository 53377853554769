import React from "react";
import { Form, Formik } from "formik";

import FormButtons from "./components/form-buttons";
import FormFields from "./components/form-fields";
import useCancelPolicyForm from "./use-cancel-policy-form";
import { IFindAllClientDevice } from "../../../../../services/protegeme-v2/client/client-service.interface";

interface Props {
  deviceClient: IFindAllClientDevice | undefined;
}

const CancelForm = ({ deviceClient }: Props) => {
  const { initialValues, submitForm, validationSchema, isLoading } =
    useCancelPolicyForm();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="grid grid-cols-2 2xl:grid-col-3 gap-4">
          <FormFields
            minDate={deviceClient?.policy[0].fecha_activacion}
            maxDate={deviceClient?.policy[0].end_date}
          />
          <div className="col-span-2 2xl:col-span-3">
            <FormButtons isLoading={isLoading} />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default CancelForm;
