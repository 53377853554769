import React from "react";
import { Column, useTable } from "react-table";
import Table from "@material-ui/core/Table/Table";

import { IV1HistoryTicket } from "../../../../../types/v1HistoryTicket";
import V1HistoryListTicketTableHead from "./v1-history-list-ticket-table-head";
import V1HistoryListTicketTableBody from "./v1-history-list-ticket-table-body";

type IV1HistoryListTicketTable = {
  tickets: IV1HistoryTicket[];
  columns: Array<Column<IV1HistoryTicket>>;
};

const V1HistoryListTicketTable = (props: IV1HistoryListTicketTable) => {
  const { tickets, columns } = props;
  const tableOptions = { columns, data: tickets };
  const { getTableProps, headerGroups, rows, prepareRow } =
    useTable(tableOptions);

  return (
    <Table {...getTableProps()}>
      <V1HistoryListTicketTableHead headerGroups={headerGroups} />
      <V1HistoryListTicketTableBody prepareRow={prepareRow} rows={rows} />
    </Table>
  );
};

export default V1HistoryListTicketTable;
