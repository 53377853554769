import React from "react";
import StagePageTemplate from "../../template/stage-page.template";
import SelectTypeCompensationService from "../../components/stage/select-type-compensation/select-type-compensation.service";

const SelectTypeCompensationPage = () => {
  return (
    <StagePageTemplate>
      <SelectTypeCompensationService />
    </StagePageTemplate>
  );
};

export default SelectTypeCompensationPage;
