import React from "react";
import { useFormikContext } from "formik";

import EAxios from "../../../../../axios/EAxios";
import { useStepContext } from "../../steps/step.provider";
import AutocompleteField from "../../../../formik/fields/autocomplete.field";
import {
  IClientDevicePlan,
  IClientDevicePlanCoverage,
} from "../../../../../types/event";

type IPlanSelectField = {
  name: string;
  label: string;
  conditionalName: string;
  help?: string;
};

const PlanSelectField = (props: IPlanSelectField) => {
  const { name, label, conditionalName, help } = props;
  const { plans, setCoverages } = useStepContext();
  const { setFieldValue } = useFormikContext();

  const updateCoverageList = async (value: IClientDevicePlan) => {
    const url = EAxios.endpoints.default.coverage.listByClientDevicePlan(
      value.id
    );
    const response = await EAxios.get<IClientDevicePlanCoverage[]>(url);
    const coverages = response.data.map((clientDevicePlanCoverage) => {
      return clientDevicePlanCoverage.tipos_cobertura_cobertura;
    });
    setCoverages(coverages.flat());
  };

  const clearCoverage = () => {
    setFieldValue(conditionalName, null);
    setCoverages([]);
  };

  const handleOnChangeEvent = (value: IClientDevicePlan | undefined | null) => {
    setFieldValue(name, value);
    if (!value) clearCoverage();
    else updateCoverageList(value);
  };

  return (
    <AutocompleteField
      name={name}
      help={help}
      label={label}
      size={"small"}
      options={plans}
      getOptionLabel={(option) => option.plan.nombre}
      getOptionSelected={(option, value) => option?.id === value?.id}
      onInputChange={() => clearCoverage()}
      onChange={(_event, value) => handleOnChangeEvent(value)}
    />
  );
};

export default PlanSelectField;
