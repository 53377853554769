import * as yup from "yup";
import to from "await-to-js";
import { Form, Formik, FormikValues } from "formik";
import { useHistory, useParams } from "react-router-dom";
import React, { FunctionComponent, useState } from "react";

import EAxios from "../../../axios/EAxios";
import { useCoverage } from "../../../hooks/useCoverage";
import LoadingButton from "../../loading-button/loading-button";
import DeviceTableField from "../../formik/fields/device-table.field";
import { IDeviceSponsor, IExperienceCenter } from "../../../types/event";
import ExperienceCenterAutocompleteField from "../../formik/fields/experience-center.autocomplete.field";

export interface ISelectDeviceToDeliverComponentProps {
  devicesToDeliver: IDeviceSponsor[];
  experienceCenters: IExperienceCenter[];
}

const SelectDeviceToDeliverComponent: FunctionComponent<
  ISelectDeviceToDeliverComponentProps
> = (props) => {
  const history = useHistory();
  const cobertura = useCoverage();
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const { devicesToDeliver, experienceCenters } = props;
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const initialValues = {
    device: null,
    experienceCenter: null,
  };

  const validationSchema = yup.object({
    device: yup
      .string()
      .nullable()
      .typeError("Requerido *")
      .required("Requerido *"),
    experienceCenter: yup
      .string()
      .nullable()
      .typeError("Requerido *")
      .required("Requerido *"),
  });

  const onSubmit = async (values: FormikValues) => {
    const { device, experienceCenter } = values;
    const url = EAxios.endpoints.default.stage.saveDeviceAndCDE(
      cobertura,
      stageId
    );
    const data = {
      ticket_id: eventId,
      cde_id: experienceCenter,
      dispositivo_sponsor_id: device,
    };

    setLoadingSubmit(true);
    const [error, response] = await to(EAxios.post<string>(url, data));
    setLoadingSubmit(false);

    if (error) return;
    if (response?.status === 200 || response?.status === 201) history.goBack();
  };
  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ getFieldMeta, setFieldValue }) => (
        <Form>
          <div className={"mb-5"}>
            <label htmlFor={"device"}>
              1- Seleccionar el dispositivo a entregar
            </label>
            <div className={"mt-5"}>
              <DeviceTableField
                name={"device"}
                getFieldMeta={getFieldMeta}
                devicesToDeliver={devicesToDeliver}
              />
            </div>
          </div>

          <div className={"mb-5"}>
            <label htmlFor="cde">2- Seleccionar tienda</label>
            <div className={"mt-5"}>
              <ExperienceCenterAutocompleteField
                name={"experienceCenter"}
                options={experienceCenters}
                getFieldMeta={getFieldMeta}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>

          <div className={"flex flex-row-reverse"}>
            <LoadingButton
              type={"submit"}
              color={"primary"}
              children={"Guardar"}
              variant={"contained"}
              isLoading={isLoadingSubmit}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SelectDeviceToDeliverComponent;
