import { AxiosError } from "axios";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";

export interface ILoadingComponentProps {
  isLoading?: boolean;
  hasError?: boolean | Error | AxiosError | null;
  height?: number | string;
  width?: number | string;
  animation?: "pulse" | "wave" | false;
  variant?: "text" | "rect" | "circle";
}

const useStyle = makeStyles((theme) => ({
  skeletonRoot: {
    backgroundColor: theme.palette.error.light,
  },
}));

const LoadingComponent: FunctionComponent<ILoadingComponentProps> = ({
  children,
  ...props
}) => {
  const classes = useStyle();
  const { isLoading, hasError, ...skeletonProps } = props;

  if (isLoading || hasError)
    return (
      <Skeleton
        {...skeletonProps}
        className={clsx(hasError && classes.skeletonRoot)}
      />
    );

  return <>{children}</>;
};

export default LoadingComponent;
