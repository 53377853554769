import React, { Fragment } from "react";
import { IoSearch } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@material-ui/core";

import { useV1HistoryListState } from "../../providers/v1-history-list.provider";
import { useV1HistoryListActions } from "../../providers/v1-history-list.actions";
import V1HistoryListTicketPanelFilter from "./v1-history-list-ticket-panel-filter";

const V1HistoryListTicketPanelTitle = () => {
  const { t } = useTranslation();
  const { filter } = useV1HistoryListState();
  const { identificationNumber, phoneNumber, eventId } = filter;
  const { setFormRef } = useV1HistoryListActions();

  const isFiltered = identificationNumber || phoneNumber || eventId;

  const handleOpenFilterPanel = (event: any) => {
    setFormRef(event.currentTarget);
  };

  return (
    <Fragment>
      <Typography variant={"h6"} className={"m-0"}>
        {t("v1HistoryListTicketPanel.label")}
      </Typography>
      <div className={"flex-grow"} />
      <div>
        <IconButton
          edge="end"
          color={isFiltered ? "secondary" : "inherit"}
          aria-haspopup="true"
          children={<IoSearch />}
          onClick={handleOpenFilterPanel}
        />
      </div>
      <V1HistoryListTicketPanelFilter />
    </Fragment>
  );
};

export default V1HistoryListTicketPanelTitle;
