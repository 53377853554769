import React from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../../providers/user/user.provider";
import { useReclamationCreateComment } from "../../../../../services/protegeme-v2/reclamation/use-reclamation";
import { useReclamationListAllComments } from "../../../../../services/protegeme-v2/reclamation/use-reclamation";
import CommentarySectionView from "./commentary-section.view";

const CommentarySectionContainer = () => {
  const { eventId: reclamationId } = useParams<any>();
  const user = useUser();
  const commentaries = useReclamationListAllComments(reclamationId);
  const createCommentary = useReclamationCreateComment();
  return (
    <CommentarySectionView
      me={user!}
      isLoading={commentaries.isLoading}
      reclamationId={reclamationId}
      commentaries={commentaries.data?.data}
      onCreate={createCommentary.mutateAsync}
      isCreating={createCommentary.isLoading}
    />
  );
};

export default CommentarySectionContainer;
