import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadRejectionOrderComponent from "./upload-rejection-order.component";

const UploadRejectionOrderPanel: FunctionComponent<ILoadingComponentProps> = ({
  ...loadingProps
}) => (
  <PanelLoading title={`Orden de Rechazo`} {...loadingProps}>
    <UploadRejectionOrderComponent />
  </PanelLoading>
);

export default UploadRejectionOrderPanel;
