import React, { Fragment } from "react";
import { Box, Typography } from "@material-ui/core";

import AcincoFont from "../../font/acinco.font";

const LoginHeader = () => {
  return (
    <Fragment>
      <div className={"mb-5 flex justify-center"}>
        <img
          className="mx-auto h-24 w-auto"
          src={"/a5-logo.png"}
          alt="A5-logo"
        />
      </div>
      <div className={"mb-5"}>
        <Typography variant={"h4"} className={"text-center"}>
          <AcincoFont>
            <Box color={"primary.main"} component={"span"}>
              Protégeme v2
            </Box>
          </AcincoFont>
        </Typography>
        <Typography
          color={"textSecondary"}
          variant={"h6"}
          className={"text-center"}
        >
          Bienvenido
        </Typography>
      </div>
    </Fragment>
  );
};

export default LoginHeader;
