import _ from "lodash";
import React from "react";
import { Fragment } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

type IDateFormat = {
  source: string | number | Date | undefined;
  format?: string;
};
export const DateFormat = (props: IDateFormat) => {
  if (!props.source) return null;
  const date = _.isDate(props.source) ? props.source : new Date(props.source);
  return (
    <Fragment>{format(date, props.format || "PPP", { locale: es })}</Fragment>
  );
};
