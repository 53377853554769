import * as yup from "yup";
import { useTranslation } from "react-i18next";

const useCallListFilterFormUtil = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    incomingPhoneNumber: yup
      .number()
      .typeError(({ label }) => t("form.errors.number", { label }))
      .label(t("callList.filter.identificationNumber")),
    identificationNumber: yup
      .number()
      .typeError(({ label }) => t("form.errors.number", { label }))
      .label(t("callList.filter.identificationNumber")),
    laneSaved: yup
      .number()
      .typeError(({ label }) => t("form.errors.number", { label }))
      .label(t("callList.filter.identificationNumber")),
    maxDate: yup
      .date()
      .nullable()
      .typeError(({ label }) => t("form.errors.number", { label }))
      .label(t("callList.filter.identificationNumber")),
    minDate: yup
      .date()
      .nullable()
      .typeError(({ label }) => t("form.errors.number", { label }))
      .label(t("callList.filter.identificationNumber")),
  });

  return { validationSchema };
};

export default useCallListFilterFormUtil;
