import _ from "lodash";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import { callAuditDetails } from "./call-service";
import { callAuditSave } from "./call-service";
import { callAuditList } from "./call-service";
import { callCreate } from "./call-service";
import { reasonList } from "./call-service";
import { callList } from "./call-service";
import { callFindOne } from "./call-service";
import { typeCallList } from "./call-service";
import { callReport } from "./call-service";
import { ICallReportResData } from "./call-service.interface";
import { ICallFindOneResData } from "./call-service.interface";
import { IUseCallAuditDetails } from "./call-service.interface";
import { ICallListReqQuery } from "./call-service.interface";
import { ICallExcelReqQuery } from "./call-service.interface";
import { ICallFindResponse } from "./call-service.interface";
import { ICallAuditSaveReq } from "./use-call.interface";
import { IUseCallAuditList } from "./use-call.interface";
import { IUseCreateCall } from "./use-call.interface";
import { IUseCallFindOne } from "./use-call.interface";

export const useCallCreate = () => {
  return useMutation((props: IUseCreateCall) =>
    callCreate(props.body).then((res) => res)
  );
};

export const useReasonList = () => {
  return useQuery("call/reasons", reasonList);
};

export const useTypeCallList = () => {
  return useQuery("call/types", () => typeCallList().then((res) => res.data));
};

export const useCallAuditList = (params: IUseCallAuditList) => {
  const { options, request } = params;
  const key = ["call/audit/list", request.callId];
  const query = useQuery(
    key,
    () => callAuditList(request).then((res) => res.data),
    options
  );
  return query;
};

export const useCallAuditSave = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: ICallAuditSaveReq) =>
      callAuditSave(params).then((res) => res.data),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries(["call/audit/details", req.callId]);
        queryClient.invalidateQueries(["call/audit/list", req.callId]);
      },
    }
  );
  return mutation;
};

export const useCallAuditDetails = (params: IUseCallAuditDetails) => {
  const { options, request } = params;
  const key = ["call/audit/details", request.callId];
  const query = useQuery(
    key,
    () => callAuditDetails(request).then((res) => res.data),
    options
  );
  return query;
};

export const useCallList = (query: ICallListReqQuery) => {
  const { options, request } = query;
  return useQuery<ICallFindResponse>(
    ["call/list", request],
    callList(request),
    options
  );
};

export const useCallFindOne = (data: IUseCallFindOne) => {
  const { callId, options } = data;
  return useQuery<ICallFindOneResData>(
    ["call/findOne", callId],
    () => callFindOne(callId).then((res) => res.data),
    options
  );
};

export const useCallReport = (
  query: ICallExcelReqQuery,
  options?: UseQueryOptions<ICallReportResData>
) => {
  const queryParams = {};
  !_.isEmpty(query.cedula) && _.set(queryParams, "cedula", query.cedula);
  !_.isNull(query.max_date) && _.set(queryParams, "max_date", query.max_date);
  !_.isNull(query.min_date) && _.set(queryParams, "min_date", query.min_date);
  !_.isEmpty(query.ensured_line) &&
    _.set(queryParams, "ensured_line", query.ensured_line);
  !_.isEmpty(query.incoming_phone_number) &&
    _.set(queryParams, "incoming_phone_number", query.incoming_phone_number);
  return useQuery<ICallReportResData>(
    "call/report",
    ({ signal }) => callReport({ ...queryParams, signal }),
    options
  );
};
