import { Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import { Unless } from "react-if";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useRoutesV2BreadcrumbList } from "../../providers/router/routes";
import LinkRouter from "./components/link-router";

const useStyle = makeStyles((theme) => ({
  pageBar: {
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  hoverPrimary: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const Breadcrumb = () => {
  const classes = useStyle();
  const routesV2Array = useRoutesV2BreadcrumbList();
  const breadcrumbs = useBreadcrumbs(routesV2Array, { disableDefaults: true });
  return (
    <Toolbar variant={"dense"} className={clsx(classes.pageBar, "shadow")}>
      {breadcrumbs.map(({ match, key, breadcrumb }) => {
        const isLast = _.last(breadcrumbs)?.match.url === match.url;
        return (
          <div
            key={match.url}
            className={"flex flex-row items-center gap-4 mr-4"}
          >
            <LinkRouter
              underline={"none"}
              className={"flex"}
              color="inherit"
              to={match.url}
              key={key}
              children={<div className={"hover:underline"}>{breadcrumb}</div>}
            />
            <Unless condition={isLast}>
              <MdChevronRight className={"text-3xl"} />
            </Unless>
          </div>
        );
      })}
    </Toolbar>
  );
};

export default Breadcrumb;
