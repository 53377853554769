import React, { useEffect } from "react";

import { IFetchTicketListParams } from "../../../../types/v1HistoryTicket";
import { useV1HistoryListState } from "../providers/v1-history-list.provider";
import { useV1HistoryListActions } from "../providers/v1-history-list.actions";
import V1HistoryListTicketPanel from "./v1-history-list-ticket-panel/v1-history-list-ticket-panel";

const V1HistoryListTicketService = () => {
  const { setTicketList } = useV1HistoryListActions();
  const { filter, pagination } = useV1HistoryListState();
  const { eventId, phoneNumber, identificationNumber } = filter;
  const { pageSize, pageNumber } = pagination;

  useEffect(() => {
    const fetchData: IFetchTicketListParams = {
      eventId,
      pageSize,
      pageNumber,
      phoneNumber,
      identificationNumber,
    };
    setTicketList(fetchData);
  }, [
    eventId,
    phoneNumber,
    identificationNumber,
    pageSize,
    pageNumber,
    setTicketList,
  ]);

  return (
    <div>
      <V1HistoryListTicketPanel height={924} width={"100%"} variant={"rect"} />
    </div>
  );
};

export default V1HistoryListTicketService;
