import React from "react";
import { useContext } from "react";
import { createContext } from "react";
import { FunctionComponent } from "react";

import { useUser } from "../user/user.provider";
import { getAbilityByRole } from "./authorization.util";
import { IAuthorization } from "../../types/authorization";
import { getAuthorizationByAbility } from "./authorization.util";

const AuthorizationContext = createContext<IAuthorization | undefined>(
  undefined
);

export const AuthorizationProvider: FunctionComponent = ({ children }) => {
  const user = useUser();
  const role = user?.user_type?.id_system;
  const abilities = getAbilityByRole(role);
  const policies = getAuthorizationByAbility(abilities);
  return <AuthorizationContext.Provider value={policies} children={children} />;
};

export const useAuthorization = (): IAuthorization => {
  const policies = useContext(AuthorizationContext);
  if (!policies) {
    throw new Error(
      "useAuthorization must be used within a AuthorizationProvider"
    );
  }
  return policies;
};
