import React from "react";
import { Fragment } from "react";
import { IconButton } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IoEye } from "react-icons/io5";
import { Column } from "react-table";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { If } from "react-if";
import { Else } from "react-if";
import { Then } from "react-if";
import { parseISO } from "date-fns";
import TableStandard from "../../../../components/tables/table-standard";
import { DateFormat } from "../../../../util/date.util";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { IFindAllClientDevice } from "../../../../services/protegeme-v2/client/client-service.interface";
import ActiveChip from "./active-chip";

interface IPoliciesSection {
  policies: Array<IFindAllClientDevice>;
}

const PolicySection = (props: IPoliciesSection) => {
  const { t } = useTranslation();
  const { policies } = props;
  const { clientId } = useParams<any>();
  const routesV2 = useRoutesV2();
  const authorization = useAuthorization();

  const columnsRaw: Array<Column<IFindAllClientDevice> | boolean> = [
    {
      id: "id",
      Header: t("clientDetails.panel.policy.id"),
      accessor: (row) => row.policy[0]?.id,
    },
    {
      id: "sponsor",
      Header: t("clientDetails.panel.policy.sponsor"),
      accessor: (row) => row.policy[0]?.plan.sponsor.nombre,
    },
    {
      id: "plan",
      Header: t("clientDetails.panel.policy.plan"),
      accessor: (row) => row.policy[0]?.plan.nombre,
    },
    {
      id: "is_active",
      Header: t("clientDetails.panel.policy.state"),
      accessor: (row) => (
        <div className="flex flex-row justify-center">
          <ActiveChip className="w-full" isActive={row.policy[0]?.is_active} />
        </div>
      ),
    },
    {
      id: "activationDate",
      Header: t("clientDetails.panel.policy.activationDate"),
      accessor: (row) => (
        <Fragment>
          <If condition={Boolean(row.policy[0])}>
            <Then
              children={() => (
                <DateFormat source={parseISO(row.policy[0].fecha_activacion)} />
              )}
            />
            <Else>
              <Box color={"error.main"} className={"font-bold"}>
                {t("clientDetails.panel.client.empty")}
              </Box>
            </Else>
          </If>
        </Fragment>
      ),
    },
    authorization.canClientPolicyReadOne && {
      id: "actions",
      Header: () => (
        <div className={"text-center"}>
          {t("clientDetails.panel.policy.actions")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center gap-2"}>
          <IconButton
            component={Link}
            to={routesV2.clientPolicyDetails.route({
              clientId,
              deviceId: row.id,
              policyId: row.policy[0]?.id,
            })}
            size={"small"}
            children={<IoEye />}
          />
        </div>
      ),
    },
  ];

  const columns: Array<Column<IFindAllClientDevice>> = columnsRaw.reduce<
    Array<Column<IFindAllClientDevice>>
  >((previousValue, currentValue) => {
    if (typeof currentValue === "boolean") {
      return previousValue;
    }
    return [...previousValue, currentValue];
  }, []);

  return <TableStandard size={"small"} data={policies} columns={columns} />;
};

export default PolicySection;
