import _ from "lodash";
import { IActualization } from "../types/actualization";

export const getActualizationsSorted = (
  actualizations: IActualization[] | undefined
) => {
  const actualizationSorted = _.orderBy<IActualization>(
    actualizations,
    ["fecha_actualizacion", "fecha_carga"],
    ["desc", "desc"]
  );
  return actualizationSorted;
};

export const getActualizationVigentDate = (
  actualizationsSorted: IActualization[]
) => {
  const vigentDate = actualizationsSorted.slice().shift()?.fecha_actualizacion;
  return vigentDate;
};

export const getActualizationsVigent = (
  actualizationsSorted: IActualization[]
) => {
  const vigentDate = getActualizationVigentDate(actualizationsSorted);
  const actualizationsVigent = actualizationsSorted.filter(
    (value) => value.fecha_actualizacion === vigentDate
  );
  return actualizationsVigent;
};
