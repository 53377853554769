import { useHistory } from "react-router-dom";
import React, { Fragment, ReactNode } from "react";

type IProps = {
  children: ReactNode;
  isAuthorized: boolean;
  redirect?: string;
  autoRedirect?: boolean;
};

export const AuthorizationComponent = (props: IProps) => {
  const history = useHistory();
  const { isAuthorized, autoRedirect, redirect, children } = props;
  if (!isAuthorized) {
    if (autoRedirect) history.push("403");
    if (redirect) history.push(redirect);
    return null;
  }
  return <Fragment>{children}</Fragment>;
};
