import React from "react";
import { Grid } from "@material-ui/core";

import FixedScrollSectionTemplate from "../template/fixed-scroll-section.template";
import { useAuthorization } from "../providers/authorization/authorization.provider";
import { AuthorizationComponent } from "../providers/authorization/authorization.component";
import { ActualizationProvider } from "../components/actualizacion/context/actualization.provider";
import ActualizationUploadService from "../components/actualizacion/actualizacion-upload/actualization-upload.service";
import ActualizationCurrentService from "../components/actualizacion/actualizacion-current/actualization-current.service";
import ActualizationHistoryService from "../components/actualizacion/actualizacion-history/actualization-history.service";

const ActualizationPage = () => {
  const { canActualizationRead, canActualizationUpload } = useAuthorization();
  const canSeeThePage = canActualizationRead || canActualizationUpload;
  return (
    <AuthorizationComponent isAuthorized={canSeeThePage} autoRedirect={true}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-full"}>
                <ActualizationProvider>
                  <div className={"flex flex-col"}>
                    <div className={"grid grid-cols-2 gap-6 mb-8"}>
                      <AuthorizationComponent
                        isAuthorized={canActualizationRead}
                        children={<ActualizationCurrentService />}
                      />
                      <AuthorizationComponent
                        isAuthorized={canActualizationUpload}
                        children={<ActualizationUploadService />}
                      />
                    </div>
                    <AuthorizationComponent
                      isAuthorized={canActualizationRead}
                      children={<ActualizationHistoryService />}
                    />
                  </div>
                </ActualizationProvider>
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ActualizationPage;
