import React from "react";
import { useFormikContext } from "formik";

import EAxios from "../../../../../axios/EAxios";
import { useStepContext } from "../../steps/step.provider";
import AutocompleteField from "../../../../formik/fields/autocomplete.field";
import { ICoverage, IDocumentRequired } from "../../../../../types/event";

type ICoverageSelectField = {
  name: string;
  label: string;
  help?: string;
};

const CoverageSelectField = (props: ICoverageSelectField) => {
  const { name, label, help } = props;
  const { setFieldValue } = useFormikContext();
  const { coverages, setDocuments } = useStepContext();

  const updateCoverageList = (value: ICoverage) => {
    const url =
      EAxios.endpoints.default.document.listRequiredDocumentByCoverage(
        value.id
      );
    EAxios.get<IDocumentRequired[]>(url).then((response) =>
      setDocuments(response.data)
    );
  };

  const clearCoverage = () => {
    setDocuments([]);
  };

  const handleOnChangeEvent = (value: ICoverage | undefined | null) => {
    setFieldValue(name, value);
    if (!value) clearCoverage();
    else updateCoverageList(value);
  };

  return (
    <AutocompleteField
      name={name}
      help={help}
      label={label}
      size={"small"}
      options={coverages}
      getOptionLabel={(option) => option.nombre}
      getOptionSelected={(option, value) => option.id === value.id}
      onInputChange={() => clearCoverage()}
      onChange={(_event, value) => handleOnChangeEvent(value)}
    />
  );
};

export default CoverageSelectField;
