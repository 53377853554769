import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

import PanelLoading from "../../../components/panel/panel.loading";
import FormFields from "./components/form-fields";
import useReportCoreFormUtil from "./report-core.util";
import FormButton from "./components/form-button";

const ReportCorePanel = () => {
  const { t } = useTranslation();
  const { initialValues, submitForm, validationSchema, loading } =
    useReportCoreFormUtil();
  return (
    <PanelLoading
      title={
        <div className="w-full h-full flex flex-row justify-between items-center">
          <div>
            <Typography variant={"h6"} className={"m-0"}>
              {t("reports.general.panel.title")}
            </Typography>
          </div>
        </div>
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        <Form>
          <FormFields />
          <FormButton loading={loading} />
        </Form>
      </Formik>
    </PanelLoading>
  );
};

export default ReportCorePanel;
