import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import ActualizationUploadComponent from "./actualization-upload.component";

const ActualizationUploadPanel: FunctionComponent<ILoadingComponentProps> = (
  props
) => (
  <PanelLoading
    width={props.width}
    height={props.height}
    variant={props.variant}
    hasError={props.hasError}
    isLoading={props.isLoading}
    animation={props.animation}
    title={"Cargar Actualización"}
    children={<ActualizationUploadComponent />}
  />
);

export default ActualizationUploadPanel;
