import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import ManualVerificationService from "../../components/stage/causal-manual/manual-verification.service";

const ManualVerificationPage = () => {
  return (
    <StagePageTemplate>
      <ManualVerificationService />
    </StagePageTemplate>
  );
};

export default ManualVerificationPage;
