import React from "react";
import StagePageTemplate from "../../template/stage-page.template";
import UploadSignedPaymentOrderService from "../../components/stage/upload-signed-payment-order/upload-signed-payment-order.service";

const UploadSignedPaymentOrderPage = () => {
  return (
    <StagePageTemplate>
      <UploadSignedPaymentOrderService />
    </StagePageTemplate>
  );
};

export default UploadSignedPaymentOrderPage;
