import { useQuery } from "react-query";
import { deviceList } from "./device-service";
import { IDeviceListRes } from "./device-service.interface";
import { IUseDeviceList } from "./use-device.interface";

export const useDeviceList = (props: IUseDeviceList) => {
  const { data, options } = props;
  return useQuery<IDeviceListRes>(
    "device/list",
    () => deviceList(data).then((res) => res.data),
    options
  );
};
