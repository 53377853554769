import React from "react";

import UploadRejectionOrderPanel from "./upload-rejection-order.panel";
import { ILoadingComponentProps } from "../../loading/loading.component";

const UploadRejectionOrderService = () => {
  const loadingProps: ILoadingComponentProps = {
    isLoading: false,
    hasError: false,
    variant: "rect",
    width: "100%",
    height: 500,
  };

  return <UploadRejectionOrderPanel {...loadingProps} />;
};

export default UploadRejectionOrderService;
