import { useContext } from "react";
import { createContext } from "react";
import { FunctionComponent } from "react";
import React from "react";
import { Redirect } from "react-router-dom";
import LoadingPageSpinner from "../../components/loading-page-spinner/loading.page-spinner";
import { useUserGetMe } from "../../services/protegeme-v2/user/use-user";
import { IUser } from "../../types/authorization";

const AuthenticationContext = createContext<IUser | undefined>(undefined);

export const UserProvider: FunctionComponent = ({ children }) => {
  const { data, isLoading, isError } = useUserGetMe({
    options: { retry: false },
  });
  if (isLoading) {
    return <LoadingPageSpinner />;
  }
  if (isError) {
    return <Redirect to={"/login"} />;
  }
  return <AuthenticationContext.Provider value={data} children={children} />;
};

export const useUser = (): IUser | null => {
  const user = useContext(AuthenticationContext);
  if (typeof user === "undefined") {
    throw new Error("useUser must be used within a UserProvider");
  }
  return user;
};
