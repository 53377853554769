import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Table, TableCell, TableBody, TableRow } from "@material-ui/core";

import { useStepContextLinkDevice } from "../step.provider";
import { IDeviceLinkValues } from "../use-client-link-device.util";
import { DateFormat } from "../../../../../../util/date.util";
import { CurrencyFormat } from "../../../../../../util/currency.util";
import CheckField from "../../../../../../components/formik/fields/check.field";

const DetailsStepComponent = () => {
  const { t } = useTranslation();
  const { planSelected } = useStepContextLinkDevice();
  const { values } = useFormikContext<IDeviceLinkValues>();
  return (
    <Fragment>
      <Table size={"small"}>
        <TableBody>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.linkDate.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <DateFormat source={String(values.linkDate)} />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.activationDate.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <DateFormat source={String(values.activationDate)} />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.imeiPrincipal.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>{planSelected?.imei}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.deviceName.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>{planSelected?.key}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.deviceBrand.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>{planSelected?.brand}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.insuredValue.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <CurrencyFormat value={String(planSelected?.insuredValue)} />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.coverages.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                {planSelected?.plan.coverages.map((cov) => (
                  <Fragment key={`${cov}`}>
                    {cov}
                    <br />
                  </Fragment>
                ))}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.lanePrincipal.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>{values.lanePrincipal}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.paymentFrecuency.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                {values.priceOption?.payment_frequency}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.taxedPriceUnit.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <CurrencyFormat
                  value={String(values.priceOption?.taxed_price_unit)}
                />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.taxedPriceUnit.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <CurrencyFormat
                  value={String(values.priceOption?.taxed_price_unit)}
                />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.taxes.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <CurrencyFormat value={String(values.priceOption?.taxes)} />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.priceWithouTaxes.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                <CurrencyFormat
                  value={String(values.priceOption?.price_unit)}
                />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"text-sm font-bold"}>
                {t("clientLinkDevice.form.fields.sendEmail.label")}
              </span>
            </TableCell>
            <TableCell>
              <span className={"text-sm"}>
                {values.sendEmail === true ? "Si" : "No"}
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={"mb-8 mt-4"}>
        <CheckField
          name={"validate"}
          label={t("clientLinkDevice.form.fields.validate.label")}
        />
      </div>
    </Fragment>
  );
};

export default DetailsStepComponent;
