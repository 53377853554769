import React from "react";
import { HeaderGroup } from "react-table";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

import { IV1HistoryTicket } from "../../../../../types/v1HistoryTicket";

type IV1HistoryListTicketTable = {
  headerGroups: HeaderGroup<IV1HistoryTicket>[];
};

const V1HistoryListTicketTableHead = (props: IV1HistoryListTicketTable) => {
  const { headerGroups } = props;
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <TableCell {...column.getHeaderProps()}>
              {column.render("Header")}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default V1HistoryListTicketTableHead;
