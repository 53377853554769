import _ from "lodash";
import { useMemo } from "react";
import { Dispatch } from "react";

import EAxios from "../../../../axios/EAxios";
import { IFormRef } from "../../../../types/v1HistoryTicket";
import { IActions } from "../../../../types/v1HistoryTicket";
import { IStateFilter } from "../../../../types/v1HistoryTicket";
import { useV1HistoryListDispatch } from "./v1-history-list.provider";
import { IFetchTicketListParams } from "../../../../types/v1HistoryTicket";
import { protegemeV2ApiAxios } from "../../../../services/protegeme-v2/protegeme-v2-api.axios";
import { AxiosRequestConfig } from "axios";

const setTicketList = _.curry(
  (dispatch: Dispatch<IActions>, params: IFetchTicketListParams) => {
    dispatch({ type: "TICKET_LIST_FETCH" });
    const { pageSize: limit, pageNumber: page } = params;
    const { eventId: evento, identificationNumber, phoneNumber } = params;
    const url = EAxios.endpoints.default.v1.listTicketHistory();
    const config: AxiosRequestConfig = {
      params: {
        limit,
        page,
        evento: evento ? evento : undefined,
        identificacion: identificationNumber ? identificationNumber : undefined,
        linea: phoneNumber ? phoneNumber : undefined,
      },
    };
    protegemeV2ApiAxios
      .get(url, config)
      .then((response) => {
        const { data } = response;
        dispatch({ type: "TICKET_LIST_SUCCESS", payload: data.results });
        dispatch({ type: "PAGINATION_SET_PAGETOTAL", payload: data.count });
      })
      .catch((error) =>
        dispatch({ type: "TICKET_LIST_ERROR", payload: error })
      );
  }
);

const clearFilter = _.curry((dispatch: Dispatch<IActions>) => () => {
  dispatch({ type: "FILTER_CLEAR" });
});

const setFilter = _.curry(
  (dispatch: Dispatch<IActions>, filter: IStateFilter) => {
    dispatch({ type: "FILTER_SET", payload: filter });
  }
);

const setPaginationPageSize = _.curry(
  (dispatch: Dispatch<IActions>, pageSize: number) => {
    dispatch({ type: "PAGINATION_SET_PAGESIZE", payload: pageSize });
  }
);

const setPaginationPageNumber = _.curry(
  (dispatch: Dispatch<IActions>, pageNumber: number) => {
    dispatch({ type: "PAGINATION_SET_PAGENUMBER", payload: pageNumber });
  }
);

const setFormRef = _.curry((dispatch: Dispatch<IActions>, ref: IFormRef) => {
  dispatch({ type: "FORM_REF_SET", payload: ref });
});

export const useV1HistoryListActions = () => {
  const dispatch = useV1HistoryListDispatch();

  const actions = useMemo(() => {
    return {
      clearFilter: clearFilter(dispatch),
      setFilter: setFilter(dispatch),
      setFormRef: setFormRef(dispatch),
      setTicketList: setTicketList(dispatch),
      setPaginationPageSize: setPaginationPageSize(dispatch),
      setPaginationPageNumber: setPaginationPageNumber(dispatch),
    };
  }, [dispatch]);

  return actions;
};
