import { Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import HelpIconComponent from "./help-icon.component";

type ILabelComponent = {
  label: string;
  help?: string;
};

const InputLabelComponent: FunctionComponent<ILabelComponent> = (props) => {
  const { label, help } = props;
  return (
    <div className={"mb-2 flex flex-row"}>
      <Typography>{label}</Typography>
      <HelpIconComponent tooltip={help} />
    </div>
  );
};

export default InputLabelComponent;
