import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import EAxios from "../../axios/EAxios";
import Configuration from "../../config/Configuration";
import { EventListState, IEvento } from "../../types/event";

const initialState: EventListState = {
  list: [],
  isLoading: false,
  error: undefined,
};

const { reducer, actions } = createSlice({
  name: "event",
  initialState,
  reducers: {
    getAllEventsLoading: (state: EventListState) => {
      state.list = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getAllEventsSuccess: (
      state: EventListState,
      action: PayloadAction<IEvento[]>
    ) => {
      state.list = action.payload;
      state.isLoading = false;
    },
    getAllEventsFailed: (
      state: EventListState,
      action: PayloadAction<String>
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});
const getAllEventFetch = (): AppThunk => async (dispatch) => {
  try {
    dispatch(EventListActions.getAllEventsLoading());
    const hostname = Configuration.api.hostname;
    const response = await EAxios.get<IEvento[]>(`${hostname}/api/v1/ticket/`);
    dispatch(EventListActions.getAllEventsSuccess(response.data));
  } catch (error: any) {
    dispatch(EventListActions.getAllEventsFailed(error.toString()));
  }
};

export const EventListActions = { ...actions, getAllEventFetch };
export const EventListReducer = reducer;
