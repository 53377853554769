import React, { Fragment } from "react";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import es from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Typography } from "@material-ui/core";

import TextBoxField from "../../../../../../components/formik/fields/textBoxField";
import { useStepContextLinkDevice } from "../step.provider";
import { IDeviceLinkValues } from "../use-client-link-device.util";
import CoveragesSelectField from "../../components/form/coverages-select.field";
import CheckField from "../../../../../../components/formik/fields/check.field";

const CoverageStepComponent = () => {
  const { getFieldMeta } = useFormikContext<IDeviceLinkValues>();
  const { t } = useTranslation();
  const { planSelected } = useStepContextLinkDevice();

  return (
    <Fragment>
      <div className="mb-2">
        <Typography>
          {t("clientLinkDevice.form.fields.linkDate.label")}
        </Typography>
      </div>
      <div className="mb-2">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Field
            autoOk={true}
            openTo="year"
            size={"small"}
            fullWidth={true}
            name={"linkDate"}
            format="dd/MM/yyyy"
            maxDate={new Date().setHours(23, 59)}
            inputVariant={"outlined"}
            component={KeyboardDatePicker}
            views={["year", "month", "date"]}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="mb-2">
        <Typography>
          {t("clientLinkDevice.form.fields.activationDate.label")}
        </Typography>
      </div>
      <div className="mb-2">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Field
            autoOk={true}
            openTo="year"
            size={"small"}
            fullWidth={true}
            name={"activationDate"}
            format="dd/MM/yyyy"
            maxDate={new Date().setHours(23, 59)}
            inputVariant={"outlined"}
            component={KeyboardDatePicker}
            views={["year", "month", "date"]}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div className="mb-2">
        <TextBoxField
          name={"primaryImei"}
          value={getFieldMeta("primaryImei").value}
          label={t("clientLinkDevice.form.fields.imeiPrincipal.label")}
          disabled
        />
      </div>
      <div className="mb-2">
        <TextBoxField
          name={"deviceName"}
          value={planSelected?.key}
          label={t("clientLinkDevice.form.fields.deviceName.label")}
          disabled
        />
      </div>
      <div className="mb-2">
        <TextBoxField
          name={"deviceBrand"}
          value={planSelected?.brand}
          label={t("clientLinkDevice.form.fields.deviceBrand.label")}
          disabled
        />
      </div>
      <div className="mb-2">
        <TextBoxField
          name={"insuredValue"}
          value={`$ ${
            planSelected?.insuredValue
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") || 0
          }`}
          label={t("clientLinkDevice.form.fields.insuredValue.label")}
          disabled
        />
      </div>
      <div className="mb-2">
        <TextBoxField
          name={"coverages"}
          value={`${planSelected?.plan.coverages} `}
          label={t("clientLinkDevice.form.fields.coverages.label")}
          disabled
        />
      </div>
      <div className="mb-2">
        <TextBoxField
          name={"lanePrincipal"}
          limit={10}
          value={getFieldMeta("lanePrincipal").value}
          label={t("clientLinkDevice.form.fields.lanePrincipal.label")}
        />
      </div>
      {!planSelected?.oldPlan && (
        <div className="mb-2">
          <CoveragesSelectField
            name={"priceOption"}
            label={t("clientLinkDevice.form.fields.pricingOptions.label")}
          />
        </div>
      )}
      <div className={"mb-8 mt-4"}>
        <CheckField
          name={"sendEmail"}
          label={t("clientLinkDevice.form.fields.sendEmail.label")}
        />
      </div>
    </Fragment>
  );
};

export default CoverageStepComponent;
