import * as yup from "yup";
import { useFormikContext } from "formik";
import React, { useEffect, Fragment } from "react";

import { useStepContext } from "../../steps/step.provider";
import FileUploadField from "../../../../formik/fields/file-upload/file-upload.field";

type IDocumentField = {
  name: string;
};

const DocumentField = (props: IDocumentField) => {
  const { name } = props;
  const { documents } = useStepContext();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, []);
    setFieldTouched(name, false);
    documents.forEach((document, index) => {
      setFieldValue(`${name}[${index}].id`, document.id);
      setFieldValue(`${name}[${index}].name`, document.nombre_documento_save);
      setFieldValue(`${name}[${index}].file`, null);
    });
  }, [documents, name, setFieldTouched, setFieldValue]);

  const validate = async (value: File): Promise<string | void> => {
    try {
      const schema = yup.mixed().required("Requerido *");
      await schema.validate(value);
      return undefined;
    } catch (error: any) {
      return error.message;
    }
  };

  return (
    <Fragment>
      {documents.map((document, index) => (
        <div key={document.id} className={"mb-8"}>
          <FileUploadField
            name={`${name}[${index}].file`}
            maxFiles={1}
            validate={document.required ? validate : undefined}
            maxSize={1024 * 1024 * 2}
            accept={"application/pdf,image/jpeg"}
            help={`${
              document.required ? "Obligatorio -" : ""
            } Formato PDF o JPG - Máximo 2MB`}
            label={`Documento ${index + 1}: ${document.nombre_documento_save}`}
          />
        </div>
      ))}
    </Fragment>
  );
};

export default DocumentField;
