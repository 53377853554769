import React from "react";
import { Grid } from "@material-ui/core";

import { V1HistoryListProvider } from "./providers/v1-history-list.provider";
import V1HistoryListTicketService from "./components/v1-history-list-ticket-service";
import FixedScrollSectionTemplate from "../../../template/fixed-scroll-section.template";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";

export const V1HistoryListTicketPage = () => {
  const { canHistoryTicketReadAll } = useAuthorization();
  return (
    <AuthorizationComponent
      isAuthorized={canHistoryTicketReadAll}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-full"}>
                <V1HistoryListProvider>
                  <V1HistoryListTicketService />
                </V1HistoryListProvider>
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};
