import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { IUsePendingCoverageIdentifyDeviceById } from "./pending-coverage-interface";
import { IPendingCoverageFindByIdResponse } from "./pending-coverage-interface";
import { IUsePendingCoverageFindById } from "./pending-coverage-interface";
import { IListAllResponse } from "./pending-coverage-interface";
import { IUseListAll } from "./pending-coverage-interface";
import { pendingCoverageIdentifyDeviceById } from "./pending-coverage-service";
import { pendingCoverageFindById } from "./pending-coverage-service";
import { pendingCoverageListAll } from "./pending-coverage-service";

export const usePendingCoverageListAll = (props: IUseListAll) => {
  const { request, options } = props;
  const key = ["pending-coverage/listAll", request];
  return useQuery<IListAllResponse>(
    key,
    () => pendingCoverageListAll(request).then((res) => res.data),
    options
  );
};

export const usePendingCoverageFindById = (
  props: IUsePendingCoverageFindById
) => {
  const { request, options } = props;
  const key = [
    "pending-coverage/find-by-id",
    { pendingCoverageId: request.pendingCoverageId },
  ];
  return useQuery<IPendingCoverageFindByIdResponse>(
    key,
    () => pendingCoverageFindById(request).then((res) => res.data),
    options
  );
};

export const usePendingCoverageIdentifyDeviceById = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (props: IUsePendingCoverageIdentifyDeviceById) =>
      pendingCoverageIdentifyDeviceById(props.request).then((res) => res.data),
    {
      onSuccess: (data, variables) => {
        const key = ["pending-coverage/find-by-id"];
        queryClient.invalidateQueries(key);
      },
    }
  );
};
