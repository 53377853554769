import * as yup from "yup";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatISO } from "date-fns";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";

import {
  IPlan,
  ISponsor,
  PricingOption,
} from "../interfaces/link-device.types";
import { useStepContextLinkDevice } from "./step.provider";
import { ICreateLinkDeviceClientBody } from "../../../../../services/microservice/sponsor/sponsor-microservice.interface";
import { useCreateLinkDeviceClientMicroservice } from "../../../../../services/microservice/sponsor/use-sponsor-microservice";
import NotificationDeviceLinked from "../components/notifications/notification-device.linked";
import NotificationError from "../components/notifications/notification-error";
import NotificationDeviceNotLinked from "../components/notifications/notification-device-not-linked";

export interface IDeviceLinkValues {
  client: number;
  primaryImei: string | null;
  sponsor: ISponsor | null;
  plan: IPlan | null;
  lanePrincipal: string | null;
  linkDate: null;
  activationDate: null;
  sendEmail: boolean;
  validate: boolean;
  disableImeiValidation: boolean;
  priceOption?: PricingOption | null;
}

const useClientLinkDeviceUtil = () => {
  const { clientId } = useParams<any>();
  const history = useHistory();
  const { t } = useTranslation();
  const { planSelected } = useStepContextLinkDevice();
  const { mutateAsync: LinkClientDevice, isLoading } =
    useCreateLinkDeviceClientMicroservice();

  const initialValues: IDeviceLinkValues = {
    client: clientId,
    primaryImei: null,
    sponsor: null,
    sendEmail: false,
    plan: null,
    lanePrincipal: "",
    linkDate: null,
    activationDate: null,
    priceOption: null,
    validate: false,
    disableImeiValidation: false,
  };

  const validationOne = {
    primaryImei: yup
      .string()
      .required(({ label }) => `${label} es requerido`)
      .label(t("clientLinkDevice.form.fields.imeiPrincipal.label")),
    sponsor: yup
      .object({ nombre: yup.string().required() })
      .typeError(({ label }) => `${label} es requerido`)
      .required(({ label }) => `${label} es requerido`)
      .label(t("clientLinkDevice.form.fields.sponsor.label")),
    plan: yup
      .object({
        id: yup.number().required(),
        nombre: yup.string().required(),
      })
      .typeError(({ label }) => `${label} es requerido`)
      .required(({ label }) => `${label} es requerido`)
      .label(t("clientLinkDevice.form.fields.plan.label")),
    disableImeiValidation: yup.boolean().optional(),
  };

  const validationTwo = {
    linkDate: yup
      .date()
      .typeError(({ label }) => t("form.errors.date", { label }))
      .nullable()
      .max(new Date())
      .required(({ label }) => `${label} es requerida`)
      .label(t("clientLinkDevice.form.fields.linkDate.label")),
    activationDate: yup
      .date()
      .typeError(({ label }) => t("form.errors.date", { label }))
      .nullable()
      .max(new Date())
      .required(({ label }) => `${label} es requerida`)
      .label(t("clientLinkDevice.form.fields.activationDate.label")),
    lanePrincipal: yup
      .string()
      .matches(/^[0-9]*$/, ({ label }) => t("form.errors.number", { label }))
      .length(10)
      .required(({ label }) => `${label} es requerida`)
      .label(t("clientLinkDevice.form.fields.lanePrincipal.label")),
    sendEmail: yup.boolean().required("* Requerido"),
    priceOption: yup
      .object({ id: yup.number().required() })
      .nullable()
      .when("oldPlan", (show, schema) => {
        if (!Boolean(planSelected?.oldPlan)) {
          return schema.required(({ label }) => `${label} es requerido`);
        }
        return schema;
      })
      .label(t("clientLinkDevice.form.fields.pricingOptions.label")),
  };
  const validationThree = {
    validate: yup
      .boolean()
      .required("* Requerido")
      .oneOf([true], "* Requerido"),
  };

  const validationSchema = [
    yup.object(validationOne).required("* Requerido"),
    yup.object(validationTwo).required("* Requerido"),
    yup.object(validationThree).required("* Requerido"),
  ];

  const formatData = (
    candidate: IDeviceLinkValues
  ): ICreateLinkDeviceClientBody => {
    const data: ICreateLinkDeviceClientBody = {
      costumerID: String(candidate.client),
      dispositivoID: Number(planSelected?.deviceID),
      activationDate: formatISO(candidate.activationDate!, {
        representation: "date",
      }),
      linkDate: formatISO(candidate.linkDate!, {
        representation: "date",
      }),
      primaryImei: String(candidate.primaryImei),
      secondaryImei: undefined,
      principalLane: String(candidate.lanePrincipal),
      secondaryLane: undefined,
      planID: Number(candidate.plan?.id),
      insuredValue: Number(planSelected?.insuredValue),
      shouldSendCertificate: candidate.sendEmail,
      sponsorID: String(candidate.sponsor?.nombre),
      planPriceID: Number(candidate.priceOption?.id),
    };
    return data;
  };

  const onSubmit = (
    values: IDeviceLinkValues,
    helpers: FormikHelpers<IDeviceLinkValues>
  ) => {
    const body = formatData(values);
    LinkClientDevice({ body })
      .then(() => {
        toast.info(<NotificationDeviceLinked />);
        history.goBack();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          toast.error(
            <NotificationError error={error.response.data.error.message} />
          );
          history.goBack();
        } else {
          toast.error(<NotificationDeviceNotLinked />);
          history.goBack();
        }
      });
  };

  return { initialValues, validationSchema, onSubmit, isLoading };
};

export default useClientLinkDeviceUtil;
