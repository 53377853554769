import * as yup from "yup";
import to from "await-to-js";
import { Form, Formik, FormikValues } from "formik";
import { useHistory, useParams } from "react-router-dom";
import React, { FunctionComponent, useState } from "react";

import EAxios from "../../../axios/EAxios";
import LoadingButton from "../../loading-button/loading-button";
import FileUploadField from "../../formik/fields/file-upload/file-upload.field";
import { useCoverage } from "../../../hooks/useCoverage";

const UploadSignedAdmissionOrderComponent: FunctionComponent = () => {
  const history = useHistory();
  const coverage = useCoverage();
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const initialValues = {
    upload: null,
  };

  const validationSchema = yup.object({
    upload: yup.mixed().required("Requerido *"),
  });

  const onSubmit = async (values: FormikValues) => {
    const { upload } = values;
    const url = EAxios.endpoints.default.stage.uploadSignedAdmissionOrder(
      coverage,
      stageId
    );
    const data = { ticket_id: eventId, file_to_save: upload };
    const [formData, config] = EAxios.toFormData(data);

    setLoadingSubmit(true);
    const [error, response] = await to(
      EAxios.post<string>(url, formData, config)
    );
    setLoadingSubmit(false);

    if (error) return;
    if (response?.status === 200 || response?.status === 201) history.goBack();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className={"mb-8"}>
          <FileUploadField
            name={"upload"}
            label={"Cargar orden de ingreso"}
            help={"Obligatorio - Formato PDF - Máximo 2MB"}
            maxFiles={1}
            maxSize={1024 * 1024 * 2}
            accept={"application/pdf"}
          />
        </div>
        <div className={"flex flex-row-reverse"}>
          <LoadingButton
            type={"submit"}
            color={"primary"}
            children={"Guardar"}
            variant={"contained"}
            isLoading={isLoadingSubmit}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default UploadSignedAdmissionOrderComponent;
