import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { useStageState } from "../../../providers/stage/stage.provider";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadSignedPaymentOrderComponent from "./upload-signed-payment-order.component";

const UploadSignedPaymentOrderPanel: FunctionComponent<
  ILoadingComponentProps
> = (props) => {
  const { stage } = useStageState();
  return (
    <PanelLoading
      title={`Cargar Orden de ${stage?.data?.current.tipo_flujo.nombre} Firmada`}
      height={props.height}
      width={props.width}
      variant={props.variant}
      hasError={props.hasError}
      isLoading={props.isLoading}
      animation={props.animation}
      children={<UploadSignedPaymentOrderComponent />}
    />
  );
};

export default UploadSignedPaymentOrderPanel;
