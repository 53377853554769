import React from "react";

import UploadSignedTransferFormatPanel from "./upload-signed-transfer-format.panel";

const UploadSignedTransferFormatService = () => {
  return (
    <UploadSignedTransferFormatPanel
      height={500}
      width={"100%"}
      variant={"rect"}
      hasError={false}
      isLoading={false}
    />
  );
};

export default UploadSignedTransferFormatService;
