import axios from "axios";

import Configuration from "../../config/Configuration";
import { useLogin } from "../../hooks/useLogin";

export const microserviceApiAxios = axios.create();

microserviceApiAxios.interceptors.request.use((config) => {
  const { isLoginReportsMicroservice, credentialsReportsMicroservice } =
    useLogin();
  if (isLoginReportsMicroservice) {
    config.headers.Authorization = `${credentialsReportsMicroservice?.type} ${credentialsReportsMicroservice?.token}`;
  }
  return config;
});

microserviceApiAxios.interceptors.request.use((config) => {
  const baseURL = Configuration.microservice.main.api;
  return { baseURL, ...config };
});
