import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactNode } from "react";
import React from "react";
import { When } from "react-if";
import { LinkProps as RouterLinkProps, NavLink } from "react-router-dom";

interface ListItemLinkProps {
  icon?: React.ReactElement | ReactNode;
  primary: string;
  to: string;
}

const useStyle = makeStyles((theme) => ({
  icon: {
    minWidth: "0px",
  },
}));

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;
  const classes = useStyle();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <NavLink
          exact={true}
          to={to}
          ref={ref}
          {...itemProps}
          activeClassName={"Mui-selected"}
        />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <div className={"flex flex-row gap-2 items-center pl-1"}>
          <When condition={Boolean(icon)}>
            <ListItemIcon
              classes={{ root: classes.icon }}
              className={"text-xl"}
            >
              {icon}
            </ListItemIcon>
          </When>
          <ListItemText primary={primary} />
        </div>
      </ListItem>
    </li>
  );
}

export default ListItemLink;
