import { Popover } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";
import { Variant } from "material-ui-popup-state";
import { bindPopover } from "material-ui-popup-state";
import { bindTrigger } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import { usePopupState } from "material-ui-popup-state/hooks";
import { ReactNode } from "react";
import { Fragment } from "react";
import React from "react";

interface IButtonMenu {
  variant: Variant;
  popupId: string;
  icon: ReactNode;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  children?: ReactNode;
  body?: (popupState: PopupState) => ReactNode;
}

const IconButtonMenu = (props: IButtonMenu) => {
  const { popupId, variant } = props;
  const { icon, children, body } = props;
  const { anchorOrigin, transformOrigin } = props;
  const popupState = usePopupState({ variant, popupId });

  return (
    <Fragment>
      <IconButton edge="end" children={icon} {...bindTrigger(popupState)} />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        children={body ? body(popupState) : children}
      />
    </Fragment>
  );
};

export default IconButtonMenu;
