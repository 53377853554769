import React from "react";

import UploadDeviceRequestPanel from "./upload-device-request.panel";

const UploadDeviceRequestService = () => {
  return (
    <UploadDeviceRequestPanel
      height={500}
      width={"100%"}
      variant={"rect"}
      isLoading={false}
      hasError={false}
    />
  );
};

export default UploadDeviceRequestService;
