import { IconButton } from "@material-ui/core";
import { parseISO } from "date-fns";
import { formatDistanceToNowStrict } from "date-fns";
import { es } from "date-fns/locale";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoEye } from "react-icons/io5";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import TableStandard from "../../../../components/tables/table-standard";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { ClientFullName } from "../../../../services/protegeme-v2/client/util/client-format-name";
import { IPendingCoverageListAll } from "../../../../services/protegeme-v2/pending-coverage/pending-coverage-interface";

interface IClientTable {
  data: Array<IPendingCoverageListAll>;
}

const PendingCoverageTable = (props: IClientTable) => {
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  const { canPendingCoverageReadOne } = useAuthorization();

  const { data } = props;
  const columns: Array<Column<IPendingCoverageListAll>> = [
    {
      Header: t("pendingCoverageList.panel.columns.name"),
      accessor: (row) => (
        <ClientFullName
          firstName={row.cliente.name}
          lastName={row.cliente.second_name}
        />
      ),
    },
    {
      id: "identification_number",
      Header: () => (
        <div className={"text-right"}>
          {t("pendingCoverageList.panel.columns.identificationNumber")}
        </div>
      ),
      accessor: (row) => (
        <div className={"text-right"}>{row.cliente.identification_number}</div>
      ),
    },
    {
      id: "email",
      Header: () => (
        <div className={"text-right"}>
          {t("pendingCoverageList.panel.columns.createdAt")}
        </div>
      ),
      accessor: (row) => (
        <div className={"text-right"}>
          {t("pendingCoverageList.panel.columns.createdAtValue", {
            date: formatDistanceToNowStrict(parseISO(row.date_created), {
              locale: es,
            }),
          })}
        </div>
      ),
    },
    {
      id: "actions",
      Header: () => (
        <div className={"flex flex-row justify-center"}>
          {t("clientList.columns.actions")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center gap-2"}>
          <When condition={canPendingCoverageReadOne}>
            <IconButton
              component={Link}
              to={routesV2.pendingCoverageDetails.route({
                pendingCoverageId: row.id,
              })}
              size={"small"}
              children={<IoEye />}
            />
          </When>
        </div>
      ),
    },
  ];

  return (
    <TableStandard
      style={{ border: "0px" }}
      className={"table-auto"}
      stickyHeader={true}
      size={"small"}
      columns={columns}
      data={data}
    />
  );
};

export default PendingCoverageTable;
