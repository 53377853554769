const Configuration = {
  api: {
    hostname: process.env.REACT_APP_PROTEGEME_ORIGIN!,
  },
  googleApi: {
    key: process.env.REACT_APP_GOOGLE_API_KEY!,
  },
  microservice: {
    reports: {
      api: process.env.REACT_APP_MICROSERVICE_REPORTS,
    },
    main: {
      api: process.env.REACT_APP_MICROSERVICE_ORIGIN,
    },
  },
  theme: {
    drawer: {
      width: 266,
    },
    topbar: {
      title: {
        width: 218,
      },
    },
  },
};

export default Configuration;
