export const reportsMicroserviceApiRoutes = {
  auth: {
    users: () => "/api/v1/auth/users",
  },
  reports: {
    core: {
      get: (sponsorId: string, report: string, type: string) =>
        `/api/v1/reports/${sponsorId}/${report}/${type}`,
    },
    especial: {
      daviplata: {
        report: () => "/api/v1/reports-special/daviplata",
      },
    },
  },
};
