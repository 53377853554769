import { createSlice } from "@reduxjs/toolkit";
import { PaletteType } from "@material-ui/core";

export type ThemeState = { isOpenDrawer: boolean; type: PaletteType };

const initialState: ThemeState = {
  isOpenDrawer: true,
  type: "light",
};

export const { reducer: ThemeReducer, actions: ThemeActions } = createSlice({
  name: "theme",
  initialState,
  reducers: {
    drawerToggle: (state: ThemeState) => {
      state.isOpenDrawer = !state.isOpenDrawer;
    },
    typeToggle: (state: ThemeState) => {
      state.type = state.type === "light" ? "dark" : "light";
    },
  },
});
