import { TableBody, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { parse } from "date-fns";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import { es } from "date-fns/locale";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import { getReclamationStateKey } from "../../../../../pages/reclamation/util/reclamation-util";
import { useStageState } from "../../../../../providers/stage/stage.provider";
import { CurrencyFormat } from "../../../../../util/currency.util";
import { DateFormat } from "../../../../../util/date.util";
import ActivationChip from "../../../../activation-chip/activation-chip";

const EventSection = () => {
  const { t } = useTranslation();
  const { event } = useStageState();

  return (
    <Table size={"small"}>
      <TableBody>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Número de Radicado</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{event.data?.id}</span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              Estado de la Reclamación
            </span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              <When condition={Boolean(event?.data)}>
                {t(getReclamationStateKey(event.data))}
              </When>
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Fecha de creación</span>
          </TableCell>
          <When
            condition={event.data?.fecha_creacion}
            children={() => (
              <TableCell align="right">
                <span className={"text-xs"}>
                  <DateFormat
                    source={parseISO(event.data!.fecha_creacion)}
                    format={"PPPp"}
                  />
                </span>
              </TableCell>
            )}
          />
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Sponsor</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{event.data?.sponsor.name}</span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Plan Aplicado</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{event.data?.plan.nombre}</span>
          </TableCell>
        </TableRow>
        <When condition={_.isBoolean(event.data?.plan?.last_payment_made)}>
          <TableRow>
            <TableCell align="left">
              <span className={"text-xs font-bold"}>
                Estado de la Cobertura
              </span>
            </TableCell>
            <TableCell align="right">
              <ActivationChip
                isActive={Boolean(event.data?.plan.last_payment_made)}
              />
            </TableCell>
          </TableRow>
        </When>
        <When
          condition={Boolean(event.data?.valor_abono)}
          children={() => (
            <TableRow>
              <TableCell align="left">
                <span className={"text-xs font-bold"}>
                  Valor del Abono de pago
                </span>
              </TableCell>
              <TableCell align="right">
                <span className={"text-xs"}>
                  <CurrencyFormat value={event.data?.valor_abono} />
                </span>
              </TableCell>
            </TableRow>
          )}
        />
        <When
          condition={Boolean(event.data?.valor_bono)}
          children={() => (
            <TableRow>
              <TableCell align="left">
                <span className={"text-xs font-bold"}>
                  Valor del bono de pago
                </span>
              </TableCell>
              <TableCell align="right">
                <span className={"text-xs"}>
                  <CurrencyFormat value={event.data?.valor_bono} />
                </span>
              </TableCell>
            </TableRow>
          )}
        />
        <When condition={Boolean(event?.data)}>
          <TableRow>
            <TableCell align="left">
              <span className={"text-xs font-bold"}>
                Fecha de activación del plan
              </span>
            </TableCell>
            <TableCell align="right">
              <span className={"text-xs"}>
                {event?.data?.plan?.fecha_activacion &&
                  format(parseISO(event.data?.plan.fecha_activacion), "PPP", {
                    locale: es,
                  })}
              </span>
            </TableCell>
          </TableRow>
        </When>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Cobertura Aplicada</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              {event.data?.plan.cobertura.tipo_cobertura.nombre}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              Fecha en la que sucedió el siniestro
            </span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              <DateFormat
                source={
                  event.data?.fecha_siniestro &&
                  parse(event.data?.fecha_siniestro, "yyyy-MM-dd", new Date())
                }
              />
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              Línea con la que sucedió el siniestro
            </span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{event.data?.linea_siniestro_one}</span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Ciudad del siniestro</span>
          </TableCell>
          <TableCell align="right" className="">
            <p className={"text-xs "}>{event.data?.city_of_event}</p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>
              Descripción el siniestro
            </span>
          </TableCell>
          <TableCell align="right" className="">
            <p className={"text-xs break-words max-w-md text-right "}>
              {event.data?.mensaje}
            </p>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EventSection;
