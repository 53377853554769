import { Formik } from "formik";
import { Field } from "formik";
import { Form } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { IReclamationCreateCommentReq } from "../../../../../../services/protegeme-v2/reclamation/reclamation-service.interface";
import HelpCounter from "../../../../../formik/HelpCounter";
import LoadingButton from "../../../../../loading-button/loading-button";
import NotificationStandard from "../../../../../notification/notification-standard";

interface ICommentaryForm {
  reclamationId: string;
  onCreate: (props: IReclamationCreateCommentReq) => Promise<any>;
  isCreating: boolean;
}

const CommentaryForm = (props: ICommentaryForm) => {
  const { isCreating, onCreate, reclamationId } = props;
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{ message: "" }}
      validationSchema={yup.object().shape({
        message: yup
          .string()
          .max(400)
          .required()
          .label(
            t("reclamationDetails.sections.commentary.form.message.label")
          ),
      })}
      onSubmit={(values, formikHelpers) => {
        onCreate({
          slugs: { reclamationId },
          body: { comment: values.message },
        })
          .then(() => {
            toast.info(
              <NotificationStandard
                body={t(
                  "reclamationDetails.sections.commentary.form.notifications.success"
                )}
              />
            );
          })
          .catch(() => {
            toast.error(
              <NotificationStandard
                body={t(
                  "reclamationDetails.sections.commentary.form.notifications.error"
                )}
              />
            );
          })
          .finally(() => formikHelpers.resetForm());
      }}
    >
      <Form className="flex flex-row gap-2 items-start pt-6">
        <div className={"flex-grow"}>
          <Field
            label={t(
              "reclamationDetails.sections.commentary.form.message.placeholder"
            )}
            name={"message"}
            type={"text"}
            size={"small"}
            rows={1}
            rowsMax={8}
            multiline={true}
            fullWidth={true}
            variant={"outlined"}
            component={TextField}
            helperText={
              <HelpCounter
                counter={0}
                limit={10}
                hasError={true}
                hasTouched={false}
              />
            }
          />
        </div>
        <div className={"pb-1"}>
          <LoadingButton
            type={"submit"}
            color={"primary"}
            variant={"contained"}
            isLoading={isCreating}
            children={t(
              "reclamationDetails.sections.commentary.form.submitBtn.label"
            )}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default CommentaryForm;
