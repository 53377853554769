import React from "react";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "react-query";
import { QueryClient } from "react-query";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nConfig } from "./i18n/i18n.config";
import LoginPage from "./pages/login/login.page";
import { AuthorizationProvider } from "./providers/authorization/authorization.provider";
import { PrivateRouteV2 } from "./providers/router/components/private-route";
import PrivateRoute from "./providers/router/components/private-route";
import PublicRoute from "./providers/router/components/public-route";
import AppRouterProvider from "./providers/router/react-router.provider";
import { useRoutesV2List } from "./providers/router/routes";
import { getRoutes } from "./providers/router/routes";
import AppThemeProvider from "./providers/theme/app-theme.provider";
import { UserProvider } from "./providers/user/user.provider";

import Store from "./redux/store";
import AdminTemplate from "./template/admin.template";

const App = () => {
  const routes = getRoutes();
  const queryClient = new QueryClient();
  const routesV2List = useRoutesV2List();
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={I18nConfig}>
        <AppRouterProvider>
          <Provider store={Store}>
            <AppThemeProvider>
              <ToastContainer />
              <Switch>
                <PublicRoute
                  restricted={true}
                  path={"/login"}
                  component={LoginPage}
                />
                <UserProvider>
                  <AuthorizationProvider>
                    <AdminTemplate>
                      <PrivateRouteV2>
                        <Switch>
                          {routesV2List.map((route) => (
                            <Route
                              key={route.path}
                              exact={true}
                              strict={true}
                              path={route.path}
                              children={route.page}
                            />
                          ))}
                        </Switch>
                      </PrivateRouteV2>
                      {routes.map((route) => (
                        <PrivateRoute
                          key={route.name}
                          path={route.path}
                          exact={true}
                          component={route.component}
                        />
                      ))}
                    </AdminTemplate>
                  </AuthorizationProvider>
                </UserProvider>
              </Switch>
            </AppThemeProvider>
          </Provider>
        </AppRouterProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default App;
