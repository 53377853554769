import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { isBrowser } from "react-device-detect";
import { useSelector } from "react-redux";
import Configuration from "../../config/Configuration";
import { RootState, useAppDispatch } from "../../redux/store";
import { ThemeActions } from "../../redux/theme/theme.slice";
import SidebarList from "./components/sidebar-list/sidebar-list";

const useStyle = makeStyles((theme) => ({
  drawer: {
    width: Configuration.theme.drawer.width,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: Configuration.theme.drawer.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
}));

const Sidebar = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();

  const isOpenDrawer = useSelector<RootState, boolean>(
    (state) => state.theme.isOpenDrawer
  );

  const handleDrawerToggle = () => {
    dispatch(ThemeActions.drawerToggle());
  };

  return (
    <Drawer
      elevation={16}
      variant={isBrowser ? "permanent" : "temporary"}
      open={isOpenDrawer}
      anchor={"left"}
      ModalProps={{ keepMounted: true }}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpenDrawer,
          [classes.drawerClose]: !isOpenDrawer,
        }),
      }}
      className={clsx(
        classes.drawer,
        {
          [classes.drawerOpen]: isOpenDrawer,
          [classes.drawerClose]: !isOpenDrawer,
        },
        "h-full shadow-xl"
      )}
      onClose={handleDrawerToggle}
    >
      <SidebarList />
    </Drawer>
  );
};

export default Sidebar;
