import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import {
  cancelPolicy,
  renewPolicy,
  updateClientDevice,
} from "./client-service";
import { findOneClientDevice } from "./client-service";
import { findAllClientDevices } from "./client-service";
import { clientFindOne } from "./client-service";
import { clientUpdate } from "./client-service";
import { linkClientDevice } from "./client-service";
import { clientList } from "./client-service";
import { clientCreate } from "./client-service";
import {
  IClientPolicyCancelBody,
  IClientPolicyRenewBody,
  IUpdateClientDeviceReq,
} from "./client-service.interface";
import { IClientFindOneResData } from "./client-service.interface";
import { IClientUpdateReq } from "./client-service.interface";
import { IClientListResData } from "./client-service.interface";
import { ILinkClientDeviceReqBody } from "./client-service.interface";
import { IClientListReqQuery } from "./client-service.interface";
import { IUseClientFindOne } from "./use-client.interface";
import { IUseCreateClientProps } from "./use-client.interface";

export const useClientCreate = () => {
  return useMutation((props: IUseCreateClientProps) =>
    clientCreate(props.body).then((res) => res.data)
  );
};

export const useClientList = (
  query: IClientListReqQuery,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IClientListResData>(
    ["client/list", query],
    clientList(query),
    options
  );
};

export const useLinkClientDevice = () => {
  return useMutation((body: ILinkClientDeviceReqBody) =>
    linkClientDevice(body).then((res) => res.data)
  );
};

export const useClientFindOne = (data: IUseClientFindOne) => {
  const { clientId, options } = data;
  return useQuery<IClientFindOneResData>(
    ["client/findOne", clientId],
    () => clientFindOne(clientId).then((res) => res.data),
    options
  );
};

export const useClientUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (request: IClientUpdateReq) => {
      return clientUpdate(request).then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("client/findOne");
      },
    }
  );
};

export const useFindAllClientDevices = (clientId: number) => {
  return useQuery(["client/list-devices", clientId], () =>
    findAllClientDevices(clientId)
  );
};

export const useFindOneClientDevice = (deviceId: number) => {
  const key = ["client/device", deviceId];
  return useQuery(key, () =>
    findOneClientDevice(deviceId).then((res) => res.data)
  );
};

export const useUpdateClientDevice = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: IUpdateClientDeviceReq) =>
      updateClientDevice(data).then((res) => res.data),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries(["client/device", req.deviceId]);
      },
    }
  );
};

export const useCancelClientPolicy = () => {
  return useMutation((body: IClientPolicyCancelBody) =>
    cancelPolicy(body).then((res) => res.data)
  );
};

export const useRenewClientPolicy = () => {
  return useMutation((body: IClientPolicyRenewBody) =>
    renewPolicy(body).then((res) => res.data)
  );
};
