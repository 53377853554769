import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import es from "date-fns/locale/es";
import { Field } from "formik";
import { useFormikContext } from "formik";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import EAxios from "../../../../axios/EAxios";
import { ICity, IGender } from "../../../../types/event";
import AutocompleteField from "../../../formik/fields/autocomplete.field";
import TextBoxField from "../../../formik/fields/textBoxField";
import VerticalField from "../../../formik/vertical-field";

const StepSinisterComponent = () => {
  const { getFieldMeta } = useFormikContext();
  const [genders, setGenders] = useState<IGender[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  useEffect(() => {
    const url = EAxios.endpoints.default.user.listCities();
    EAxios.get<ICity[]>(url).then((result) => {
      const cities: ICity[] = result.data;
      const citiesGroupByDepartment = _.orderBy(
        cities,
        ["departamento.nombre", "nombre"],
        ["asc", "asc"]
      );
      setCities(citiesGroupByDepartment);
    });
  }, [setCities]);

  useEffect(() => {
    const url = EAxios.endpoints.default.user.listGenders();
    EAxios.get<IGender[]>(url).then((result) => setGenders(result.data));
  }, [setGenders]);

  return (
    <div>
      <div className={"mb-8"}>
        <VerticalField
          label={"Fecha en la que sucedió el siniestro"}
          help={"Requerido"}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <Field
              autoOk={true}
              openTo="year"
              size={"small"}
              fullWidth={true}
              name={"sinisterDate"}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              inputVariant={"outlined"}
              component={KeyboardDatePicker}
              views={["year", "month", "date"]}
            />
          </MuiPickersUtilsProvider>
        </VerticalField>
      </div>
      <div className={"mb-8"}>
        <AutocompleteField
          name={"city"}
          size={"small"}
          options={cities}
          help={"Requerido"}
          disableClearable={true}
          getOptionLabel={(option) => option.nombre}
          label={"Ciudad en la que sucedió el siniestro"}
          groupBy={(options) => options.departamento.nombre}
          getOptionSelected={(option, value) => option?.id === value?.id}
        />
      </div>
      <div className={"mb-3"}>
        <TextBoxField
          name={"sinisterLane"}
          limit={10}
          value={getFieldMeta("sinisterLane").value}
          help={"Requerido - Solo Números - Máximo 10 caracteres"}
          label={"Número de línea con la que sucedió el siniestro"}
        />
      </div>
      <div className={"mb-3"}>
        <TextBoxField
          name={"message"}
          rows={3}
          limit={280}
          rowsMax={5}
          fullWidth={true}
          multiline={true}
          label={"Descripción del siniestro"}
          help={"Requerido - Máximo 280 caracteres"}
          value={getFieldMeta("message").value}
        />
      </div>
      <div className={"mb-8"}>
        <AutocompleteField
          size={"small"}
          name={"gender"}
          options={genders}
          help={"Requerido"}
          disableClearable={true}
          label={"Género del reclamante"}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option?.id === value?.id}
        />
      </div>
    </div>
  );
};

export default StepSinisterComponent;
