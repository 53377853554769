import React from "react";
import { Formik } from "formik";
import { Form } from "formik";

import FormFields from "./components/form-fields";
import FormButtons from "./components/form-buttons";
import useClientCreateFormUtil from "./use-client-create-form.util";

const ClientCreateForm = () => {
  const { initialValues, validationSchema, submitForm } =
    useClientCreateFormUtil();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitForm}
    >
      <Form>
        <div className={"grid grid-cols-2 2xl:grid-cols-3 gap-4"}>
          <FormFields />
          <div className={"col-span-2 2xl:col-span-3"}>
            <FormButtons />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ClientCreateForm;
