import React from "react";

import RegisterMaintenanceIngressPanel from "./register-maintenance-ingress.panel";
import { ILoadingComponentProps } from "../../loading/loading.component";

const RegisterMaintenanceIngressService = () => {
  const loadingProps: ILoadingComponentProps = {
    isLoading: false,
    hasError: false,
    variant: "rect",
    width: "100%",
    height: 500,
  };

  return <RegisterMaintenanceIngressPanel {...loadingProps} />;
};

export default RegisterMaintenanceIngressService;
