import { useField } from "formik";
import { DropzoneOptions } from "react-dropzone";
import React, { Fragment, FunctionComponent } from "react";

import FileUploadInput from "./file-upload.input";
import InputLabelComponent from "../../components/input-label.component";
import ValidationLabelComponent from "../../components/validation-label.component";

type IFileUploadField = {
  name: string;
  label: string;
  validate?: (value: any) => void | string | Promise<any>;
  help?: string;
};

const FileUploadField: FunctionComponent<IFileUploadField & DropzoneOptions> = (
  props
) => {
  const { name, label, help, validate, ...dropzoneOptions } = props;
  const [field, meta, helpers] = useField<File>({ name, validate });
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <Fragment>
      <InputLabelComponent label={label} help={help} />
      <div className={"flex flex-col"}>
        <FileUploadInput
          field={field}
          meta={meta}
          helpers={helpers}
          {...dropzoneOptions}
        />
        <div className={"flex flex-row justify-between"}>
          <div>
            <ValidationLabelComponent
              hasError={hasError}
              label={String(meta.error)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FileUploadField;
