import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import EAxios from "../../axios/EAxios";
import Configuration from "../../config/Configuration";
import { ICausalByStageState, ICausalResult } from "../../types/event";

const initialState: ICausalByStageState = {
  causals: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "causal_by_stage",
  initialState,
  reducers: {
    getCausalByStageLoading: (state: ICausalByStageState) => {
      state.causals = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getCausalByStageSuccess: (
      state: ICausalByStageState,
      action: PayloadAction<ICausalResult[]>
    ) => {
      state.causals = action.payload;
      state.isLoading = false;
    },
    getCausalByStageFailed: (state: ICausalByStageState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getCausalByStageRequest =
  (stageId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(CausalByStageActions.getCausalByStageLoading());
      const hostname = Configuration.api.hostname;
      const response = await EAxios.get<ICausalResult[]>(
        `${hostname}/api/v1/causal/etapaorden/${stageId}/`
      );
      dispatch(CausalByStageActions.getCausalByStageSuccess(response.data));
    } catch (error) {
      dispatch(CausalByStageActions.getCausalByStageFailed());
    }
  };

export const CausalByStageActions = { ...actions, getCausalByStageRequest };
export const CausalByStageReducer = reducer;
