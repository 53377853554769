import { AxiosInstance } from "axios";
import axios, { AxiosRequestConfig } from "axios";

import { useLogin } from "../hooks/useLogin";
import Configuration from "../config/Configuration";

const endpoints = {
  default: {
    v1: {
      ticketHistory: (id: string) => `/api/v1/ticket/old-reclamation/${id}`,
      listTicketHistory: () => `/api/v1/ticket/old-reclamation`,
    },
    actualization: {
      listAll: () => "/api/v1/user/client/history/update",
      upload: () => "/api/v1/user/client/update/file-excel",
    },
    document: {
      listRequiredDocumentByCoverage: (coverage: number) =>
        `/api/v1/documento/tipo-cobertura-cobertura/${coverage}`,
      getDocumentsByEvent: (eventId: string) =>
        `/api/v1/documento/ticket/${eventId}`,
      getSignedUrl: (documentID: string) =>
        `/api/v1/documento/view/${documentID}`,
    },
    coverage: {
      listByClientDevicePlan: (clientDevicePlanId: number) =>
        `/api/v1/cobertura/tipo-cobertura-cobertura/dispositivo-cliente-plan/${clientDevicePlanId}`,
    },
    plan: {
      listAllByClientDevice: (clientDeviceId: number) =>
        `/api/v1/dispositivo/dispositivo-cliente-plan/dispositivo-cliente/${clientDeviceId}`,
    },
    device: {
      listAllByClient: (clientId: number) =>
        `/api/v1/dispositivo/dispositivo-cliente/user/${clientId}`,
    },
    user: {
      listAllClients: () => "/api/v1/user/client",
      login: () => `/api/v1/auth/login`,
      me: () => `/api/v1/user/me`,
      listGenders: () => "/api/v1/user/gender",
      listCities: () => "/api/v1/shared/city",
    },
    event: {
      create: () => "/api/v1/ticket/",
      getEvents: () => "/api/v1/ticket",
      getEvent: (eventId: string) => `/api/v1/ticket/${eventId}`,
      getCausalsByEvent: (eventId: string) =>
        `/api/v1/causal/ticket/${eventId}`,
      getCurrentStage: (eventId: string) =>
        `/api/v1/etapa/actual/ticket/${eventId}`,
      getHistoryStages: (eventId: string) =>
        `/api/v1/etapa/historico/ticket/${eventId}`,
      getExpectedStages: (eventId: string) => `/api/v1/etapa/ticket/${eventId}`,
    },
    stage: {
      uploadDiagnosticResult: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/orden-entrega`,
      saveDeviceAndCDE: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/save/equipo-cde`,
      uploadDeviceRequest: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/solicitud-equipo`,
      uploadSignedPurchaseOrder: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/orden-compra`,
      requestDeviceAgain: (coverage: string, stageOrderId: any) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/back/equipo-cde`,
      saveManualVerification: (eventId: string) =>
        `/api/v1/causal/ticket/${eventId}/save/verificacion-manual`,
      uploadRejectionOrder: (stageOrderId: string) =>
        `/api/v1/etapa/flujo/reject/etapa-orden/${stageOrderId}/upload/orden-rechazo`,
      registerMaintenanceIngress: (coverage: string) =>
        `/api/v1/etapa/flujo/${coverage}/save/pre-ingreso`,
      uploadSignedAdmissionOrder: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/orden-ingreso`,
      uploadSignedTransferFormat: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/formato-traspaso-firmado`,
      uploadPaymentSupport: (coverage: string, stageOrderId: string) =>
        `api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/registro-pago-${coverage}`,
      uploadSignedPaymentOrder: (coverage: string, stageOrderId: string) =>
        `api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/orden-${coverage}`,
      uploadReparationOrder: (coverage: string, stageOrderId: string) =>
        `/api/v1/documento/upload/flujo/fractura/etapa/orden/${stageOrderId}`,
    },
  },
};

const toFormData = (data: any): [FormData, AxiosRequestConfig] => {
  const config: AxiosRequestConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return [formData, config];
};

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const { isLogin, credentials } = useLogin();
  if (isLogin) {
    config.headers.Authorization = `${credentials?.type} ${credentials?.token}`;
  }
  return config;
});

axiosInstance.interceptors.request.use((config) => {
  const baseURL = Configuration.api.hostname;
  return { baseURL, ...config };
});

const EAxios =
  // endpoints: typeof endpoints;
  // toFormData: typeof toFormData;
  // {
  //   ...axiosInstance,
  //   // endpoints,
  //   // toFormData,
  // };
  Object.assign({ endpoints, toFormData }, axiosInstance);
export default EAxios;
