import React, { Fragment } from "react";

import DocumentField from "../components/create-form/document.field";

const DocumentStepComponent = () => {
  return (
    <Fragment>
      <DocumentField name={"documents"} />
    </Fragment>
  );
};

export default DocumentStepComponent;
