import _ from "lodash";
import React from "react";
import NumberFormat from "react-number-format";

type ICurrencyFormat = { value: string | number | undefined };

export const CurrencyFormat = (props: ICurrencyFormat) => {
  if (_.isNil(props.value)) return null;
  return (
    <NumberFormat
      value={props.value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};
