import React, { useState } from "react";
import { Icon } from "@material-ui/core";

import { useParams } from "react-router-dom";
import EAxios from "../../../../../../axios/EAxios";
import { useCoverage } from "../../../../../../hooks/useCoverage";
import LoadingButton from "../../../../../loading-button/loading-button";
import { StageActions } from "../../../../../../providers/stage/stage.actions";
import { useStageState } from "../../../../../../providers/stage/stage.provider";
import { useStageDispatch } from "../../../../../../providers/stage/stage.provider";

const OpenButton = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const coverage = useCoverage();
  const dispatch = useStageDispatch();
  const { event, stage } = useStageState();
  const [isLoading, setLoading] = useState(false);

  const isEventFinish = event.data?.estado.id_system === "FINALIZADO";
  const isEventInErrorFlow =
    stage.data?.current.tipo_flujo.id_system === "RECHAZO";
  const isHurto =
    stage.data?.current.tipo_flujo.id_system === "HURTOSIMPLE" ||
    stage.data?.current.tipo_flujo.id_system === "HURTOCALIFICADO";
  const isAccidentalTotalDamage =
    stage.data?.current.tipo_flujo.id_system === "DAÑOTOTALACCIDENTAL";

  if (
    !isEventFinish ||
    (!isHurto && !isAccidentalTotalDamage) ||
    isEventInErrorFlow
  )
    return null;

  const stageId = stage.data?.current?.id;

  const reopen = async () => {
    setLoading(true);
    const url = EAxios.endpoints.default.stage.requestDeviceAgain(
      coverage,
      stageId
    );
    const data = { ticket_id: eventId };
    const response = await EAxios.post<string>(url, data);
    setLoading(false);
    if (response.status === 200 || response.status === 201) {
      await StageActions.fetchEvent(dispatch, eventId);
      await StageActions.fetchStage(dispatch, eventId);
      await StageActions.fetchCausals(dispatch, eventId);
      await StageActions.fetchDocuments(dispatch, eventId);
    }
  };

  return (
    <div className={"flex flex-row-reverse mt-6"}>
      <LoadingButton
        color="primary"
        variant="contained"
        isLoading={isLoading}
        onClick={() => reopen()}
        children={"Abrir Evento"}
        endIcon={<Icon>send</Icon>}
      />
    </div>
  );
};

export default OpenButton;
