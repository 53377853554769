import { makeStyles } from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles((theme) => {
  return {
    acincoTypography: {
      fontFamily: "'Nunito', sans-serif",
      fontWeight: 800,
    },
    borderColor: {
      borderColor: `${theme.palette.divider} !important`,
    },
    bgPrimary: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    bgRed: {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
    bgGreen: {
      backgroundColor: `${theme.palette.success.main} !important`,
    },
    bgYellow: {
      backgroundColor: `${theme.palette.warning.main} !important`,
    },
    bgGray: {
      backgroundColor: `${theme.palette.text.disabled} !important`,
    },
    bgInherit: {
      backgroundColor: `inherit !important`,
    },
  };
});
