import { ReactNode } from "react";
import React from "react";

interface INotificationStandard {
  body: string | ReactNode;
}

const NotificationStandard = (props: INotificationStandard) => {
  const { body } = props;
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div>{body}</div>
    </div>
  );
};

export default NotificationStandard;
