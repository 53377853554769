export type ICredentials = {
  type: string;
  token: string;
};

export type ICredentialsReportsMicroservice = {
  type: string;
  token: string;
  expiresIn: number;
};

function getCredentials(): ICredentials | undefined {
  const credentials =
    localStorage.getItem("credentials") ||
    sessionStorage.getItem("credentials");
  if (!credentials) return undefined;
  return JSON.parse(credentials);
}

const setCredentials = (credentials: ICredentials, remember: boolean) => {
  const storage = remember ? localStorage : sessionStorage;
  storage.setItem("credentials", JSON.stringify(credentials));
};

function getCredentialsReportsMicroservice():
  | ICredentialsReportsMicroservice
  | undefined {
  const credentials =
    localStorage.getItem("credentials-reports-microservice") ||
    sessionStorage.getItem("credentials-reports-microservice");
  if (!credentials) return undefined;
  return JSON.parse(credentials);
}

const setCredentialsReportsMicroservice = (
  credentials: ICredentialsReportsMicroservice,
  remember: boolean
) => {
  const storage = remember ? localStorage : sessionStorage;
  storage.setItem(
    "credentials-reports-microservice",
    JSON.stringify(credentials)
  );
};

const clearCredentials = () => {
  localStorage.removeItem("credentials");
  sessionStorage.removeItem("credentials");
  localStorage.removeItem("credentials-reports-microservice");
  sessionStorage.removeItem("credentials-reports-microservice");
};

export const useLogin = () => {
  const credentials = getCredentials();
  const credentialsReportsMicroservice = getCredentialsReportsMicroservice();
  const login = setCredentials;
  const loginReportsMicroservice = setCredentialsReportsMicroservice;
  const logout = clearCredentials;
  const isLogin = Boolean(credentials?.token);
  const isLoginReportsMicroservice = Boolean(
    credentialsReportsMicroservice?.token
  );
  return {
    isLogin,
    credentials,
    credentialsReportsMicroservice,
    isLoginReportsMicroservice,
    login,
    loginReportsMicroservice,
    logout,
  };
};
