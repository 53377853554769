import React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

import { IV1HistoryTicket } from "../../../../../types/v1HistoryTicket";
import V1HistoryListTicketPagination from "../v1-history-list-ticket-pagination";
import V1HistoryListTicketTableActions from "../v1-history-list-ticket-table/v1-history-list-ticket-table-actions";
import V1HistoryListTicketTable from "../v1-history-list-ticket-table/v1-history-list-ticket-table";

type IV1HistoryTicketComponentParams = {
  tickets: IV1HistoryTicket[];
};

const V1HistoryListTicketComponent = (
  props: IV1HistoryTicketComponentParams
) => {
  const { t } = useTranslation();
  const { tickets } = props;
  const columns: Array<Column<IV1HistoryTicket>> = [
    {
      Header: t("v1_history_ticket.eventNumber"),
      accessor: "num_evento",
    },
    {
      Header: t("v1_history_ticket.clientIdNumber"),
      accessor: "identificacion",
    },
    {
      Header: t("v1_history_ticket.devicePhoneNumber"),
      accessor: "linea_movil",
    },
    {
      Header: t("v1_history_ticket.eventType"),
      accessor: "tipo_evento",
    },
    {
      Header: t("v1_history_ticket.eventState"),
      accessor: "estado",
    },
    {
      Header: t("v1HistoryListTicketTable.actionColumn"),
      accessor: "id",
      Cell: (cell) => <V1HistoryListTicketTableActions eventId={cell.value} />,
    },
  ];
  return (
    <div>
      <V1HistoryListTicketTable tickets={tickets} columns={columns} />
      <V1HistoryListTicketPagination />
    </div>
  );
};

export default V1HistoryListTicketComponent;
