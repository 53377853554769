import _ from "lodash";
import clsx from "clsx";
import { Else } from "react-if";
import { Then } from "react-if";
import { If } from "react-if";
import { When } from "react-if";
import { parseISO } from "date-fns";
import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import Table from "@material-ui/core/Table";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

import { DateFormat } from "../../../../util/date.util";
import CheckField from "../../../formik/fields/check.field";
import { ICreateFormValues } from "../event-create.component";
import { CurrencyFormat } from "../../../../util/currency.util";
import { ClientFullName } from "../../../../services/protegeme-v2/client/util/client-format-name";

const getDocumentsValues = (formValues: ICreateFormValues) => {
  const documents = formValues.documents;
  const values = documents.map((_document, index) => ({
    name: `Documento ${index + 1}`,
    path: `documents[${index}].name`,
    type: "string",
  }));
  return values;
};

const getDetails = (formValues: ICreateFormValues) => [
  {
    name: "Datos del Plan",
    values: [
      { name: "Nombre del plan", path: "plan.plan.nombre", type: "string" },
      {
        name: "Valor asegurado",
        path: "plan.valor_asegurado",
        type: "currency",
      },
      {
        name: "Fecha de vinculación",
        path: "plan.fecha_vinculacion",
        type: "date",
      },
      {
        name: "Fecha de activación del plan",
        path: "plan.fecha_activacion",
        type: "date",
      },
    ],
  },
  {
    name: "Datos del Dispositivo",
    values: [
      {
        name: "Modelo del Dispositivo",
        path: "device.dispositivo.nombre",
        type: "string",
      },
      {
        name: "Fabricante del Dispositivo",
        path: "device.dispositivo.fabricante.nombre",
        type: "string",
      },
      {
        name: "IMEI principal asegurado",
        path: "device.imei_uno",
        type: "string",
      },
      {
        name: "IMEI secundario asegurado",
        path: "device.imei_dos",
        type: "string",
      },
    ],
  },
  {
    name: "Datos del Siniestro",
    values: [
      { name: "Cobertura Aplicada", path: "coverage.nombre", type: "string" },
      {
        name: "Línea con la que sucedió el siniestro",
        path: "sinisterLane",
        type: "string",
      },
      {
        name: "Fecha en la que sucedió el siniestro",
        path: "sinisterDate",
        type: "date",
      },
      {
        name: "Departamento en el que sucedió el siniestro",
        path: "city.departamento.nombre",
        type: "string",
      },
      {
        name: "Ciudad en la que sucedió el siniestro",
        path: "city.nombre",
        type: "string",
      },
      { name: "Descripción del siniestro", path: "message", type: "string" },
      { name: "Género del reclamante", path: "gender.name", type: "string" },
    ],
  },
  {
    name: "Documentos Cargados",
    values: getDocumentsValues(formValues),
  },
];

const StepDetailsComponent = () => {
  const { values } = useFormikContext<ICreateFormValues>();
  console.log("values", values);
  const details = getDetails(values);

  return (
    <Fragment>
      <div>
        <div className={"mb-8"}>
          <div className={"font-bold mb-2"}>Datos del Cliente</div>
          <Table size={"small"}>
            <TableBody>
              <When
                condition={values.client?.name || values.client?.second_name}
              >
                <TableRow>
                  <TableCell align="left">
                    <span className={"text-xs font-bold"}>
                      Nombre del cliente
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={"text-xs"}>
                      <ClientFullName
                        firstName={values.client?.name || ""}
                        lastName={values.client?.second_name || ""}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </When>
              <When condition={values.client?.identification_number}>
                <TableRow>
                  <TableCell align="left">
                    <span className={"text-xs font-bold"}>
                      Número de identificación
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={"text-xs"}>
                      {values.client?.identification_number}
                    </span>
                  </TableCell>
                </TableRow>
              </When>
              <When condition={values.client?.email}>
                <TableRow>
                  <TableCell align="left">
                    <span className={"text-xs font-bold"}>
                      Correo electrónico
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={"text-xs"}>{values.client?.email}</span>
                  </TableCell>
                </TableRow>
              </When>
              <When condition={values.device?.linea_uno}>
                <TableRow>
                  <TableCell align="left">
                    <span className={"text-xs font-bold"}>
                      Línea principal asegurada
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={"text-xs"}>
                      {values.device?.linea_uno}
                    </span>
                  </TableCell>
                </TableRow>
              </When>
              <When condition={values.device?.linea_dos}>
                <TableRow>
                  <TableCell align="left">
                    <span className={"text-xs font-bold"}>
                      Línea secundaria asegurada
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={"text-xs"}>
                      {values.device?.linea_dos}
                    </span>
                  </TableCell>
                </TableRow>
              </When>
            </TableBody>
          </Table>
        </div>

        {details.map((detail, index) => (
          <div
            key={index}
            className={clsx(
              "mb-8",
              !_.last(details) && "pb-8 border-b border-black border-opacity-25"
            )}
          >
            <div className={"font-bold mb-2"}>{detail.name}</div>
            {!_.isEmpty(detail.values) && (
              <Table size={"small"}>
                <TableBody>
                  {detail.values.map((value, index) => (
                    <Fragment key={index}>
                      {!_.isNil(_.get(values, value.path)) &&
                        !_.isEmpty(String(_.get(values, value.path))) && (
                          <TableRow>
                            <TableCell align="left">
                              <span className={"text-xs font-bold"}>
                                {value.name}
                              </span>
                            </TableCell>
                            <TableCell align="right">
                              {value.type === "string" && (
                                <span className={"text-xs"}>
                                  {_.get(values, value.path)}
                                </span>
                              )}
                              {value.type === "date" && (
                                <span className={"text-xs"}>
                                  <If
                                    condition={_.isString(
                                      _.get(values, value.path)
                                    )}
                                  >
                                    <Then
                                      children={() => (
                                        <DateFormat
                                          source={parseISO(
                                            _.get(values, value.path)
                                          )}
                                        />
                                      )}
                                    />
                                    <Else
                                      children={() => (
                                        <DateFormat
                                          source={_.get(values, value.path)}
                                        />
                                      )}
                                    />
                                  </If>
                                </span>
                              )}
                              {value.type === "currency" && (
                                <span className={"text-xs"}>
                                  <CurrencyFormat
                                    value={_.get(values, value.path)}
                                  />
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        ))}
      </div>
      <div className={"mb-8"}>
        <CheckField name={"validate"} label={"Confirmar el envio."} />
      </div>
    </Fragment>
  );
};

export default StepDetailsComponent;
