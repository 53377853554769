import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import "tailwindcss/dist/utilities.min.css";
import {
  createMuiTheme,
  CssBaseline,
  PaletteType,
  ThemeProvider,
} from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

import { RootState } from "../../redux/store";

interface ITemplateProps {
  children: ReactNode;
}

/**
 * ToDo Fix table border
 * @param children
 * @constructor
 */
const AppThemeProvider = ({ children }: ITemplateProps) => {
  const type = useSelector<RootState, PaletteType>((state) => state.theme.type);
  const lightTheme = createMuiTheme({ palette: { type: "light" } });
  const darkTheme = createMuiTheme({ palette: { type: "dark" } });
  const customTheme = createMuiTheme(
    {
      overrides: {
        MuiTable: {
          root: {
            border: `0.5px solid ${
              type === "light"
                ? lightTheme.palette.divider
                : darkTheme.palette.divider
            };`,
          },
        },
        MuiTableCell: {
          root: {},
        },
      },
      palette: {
        type,
        primary: {
          main: type === "light" ? "#003272" : "#222",
        },
      },
    },
    esES
  );

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
