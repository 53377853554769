import { Grid } from "@material-ui/core";
import React from "react";

import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import FixedScrollSectionTemplate from "../../../../template/fixed-scroll-section.template";
import CallRecordCreateComponent from "./components/call-create.component";

const CallRecordPage = () => {
  const { canCallRecordCreate } = useAuthorization();
  return (
    <AuthorizationComponent
      isAuthorized={canCallRecordCreate}
      autoRedirect={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className="flex flex-row justify-center">
              <div className="w-4/5 2xl:w-3/5">
                <CallRecordCreateComponent />
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default CallRecordPage;
