import { FormikValues } from "formik";
import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import CausalTableField from "../../../components/formik/fields/causal-table-field/causal-table.field";
import FileUploadField from "../../../components/formik/fields/file-upload/file-upload.field";
import LoadingButton from "../../../components/loading-button/loading-button";
import { useCoverage } from "../../../hooks/useCoverage";
import { ICausalsByStageIdData } from "../../../services/protegeme-v2/causal/causal-service.interface";
import { IUploadDiagnosticResultReqData } from "../../../services/protegeme-v2/stage/stage-service.interface";
import { useUploadDiagnosticResult } from "../../../services/protegeme-v2/stage/useStage";

type IUploadDiagnosticForm = {
  causals: ICausalsByStageIdData[];
};

const UploadDiagnosticForm = (props: IUploadDiagnosticForm) => {
  const { causals } = props;
  const coverage = useCoverage();
  const history = useHistory();
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const { mutateAsync: uploadDeliveryOrder, isLoading } =
    useUploadDiagnosticResult();

  const initialValues = {
    upload: null,
    causals: causals?.map((value) => ({ id: value.id, resultado: null })) || [],
  };

  const validationSchema = yup.object({
    upload: yup.mixed().required("Requerido *"),
    causals: yup.array().of(
      yup.object({
        id: yup.number().required(),
        resultado: yup
          .string()
          .nullable()
          .typeError("Requerido *")
          .required("Requerido *"),
      })
    ),
  });

  function formatValues(values: FormikValues): IUploadDiagnosticResultReqData {
    const causalsFormatted = values.causals.map((values: any) => ({
      ...values,
      resultado: values.resultado === "true",
    }));
    const data = {
      file_to_save: values.upload,
      ticket_id: eventId,
      causales: JSON.stringify(causalsFormatted),
    };
    return data;
  }

  const onSubmit = async (values: FormikValues) => {
    const data = formatValues(values);
    uploadDeliveryOrder({ coverage, stageOrderId: stageId, data }).then(() => {
      history.goBack();
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className={"mb-8"}>
          <FileUploadField
            name={"upload"}
            label={"Cargar resultado del diagnostico"}
            help={"Obligatorio - Formato PDF - Máximo 2MB"}
            accept={"application/pdf"}
            maxFiles={1}
            maxSize={1024 * 1024 * 2}
          />
        </div>

        <div className={"mb-5"}>
          <label htmlFor="causales">
            Seleccionar causales que apliquen a la orden de entrega:
          </label>
          <CausalTableField causals={causals} />
        </div>

        <div className={"flex flex-row-reverse"}>
          <LoadingButton
            type={"submit"}
            color={"primary"}
            children={"Guardar"}
            variant={"contained"}
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default UploadDiagnosticForm;
