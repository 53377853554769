import { Icon, Radio } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { FieldInputProps } from "formik/dist/types";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  buttonRejectChecked: {
    color: theme.palette.error.main,
  },
  buttonRejectHover: {
    "&:hover": {
      color: theme.palette.error.light,
    },
  },
}));

interface IProps {
  field: FieldInputProps<string>;
  value: string;
}

const RejectRadio: FunctionComponent<IProps> = (props) => {
  const { field, value } = props;
  const classes = useStyle();
  return (
    <Radio
      {...field}
      color={"default"}
      classes={{
        root: classes.buttonRejectHover,
        checked: classes.buttonRejectChecked,
      }}
      icon={<Icon>cancel</Icon>}
      checkedIcon={<Icon>cancel</Icon>}
      value={value}
      checked={field.value === value}
    />
  );
};

export default RejectRadio;
