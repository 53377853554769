import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import RegisterMaintenanceIngressComponent from "./register-maintenance-ingress.component";

const RegisterMaintenanceIngressPanel: FunctionComponent<
  ILoadingComponentProps
> = ({ ...loadingProps }) => (
  <PanelLoading
    title={`Registar ingreso de reparación - Cooper`}
    {...loadingProps}
  >
    <RegisterMaintenanceIngressComponent />
  </PanelLoading>
);

export default RegisterMaintenanceIngressPanel;
