import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadSignedPurchaseOrderComponent from "./upload-signed-purchase-order.component";

const UploadSignedPurchaseOrderPanel: FunctionComponent<
  ILoadingComponentProps
> = (loadingProps) => (
  <PanelLoading title={`Cargar Orden de Compra Firmada`} {...loadingProps}>
    <UploadSignedPurchaseOrderComponent />
  </PanelLoading>
);

export default UploadSignedPurchaseOrderPanel;
