import React, { Fragment } from "react";

import { useStepContext } from "./step.provider";
import StepDetailsComponent from "./step-details.component";
import CoverageStepComponent from "./coverage-step.component";
import DocumentStepComponent from "./document-step.component";
import StepSinisterComponent from "./step-sinister.component";

const StepComponent = () => {
  const { currentStep } = useStepContext();

  const firstStep = <CoverageStepComponent />;
  const secondStep = <DocumentStepComponent />;
  const thirdStep = <StepSinisterComponent />;
  const fourthStep = <StepDetailsComponent />;

  return (
    <Fragment>
      {currentStep === 0 && firstStep}
      {currentStep === 1 && secondStep}
      {currentStep === 2 && thirdStep}
      {currentStep === 3 && fourthStep}
    </Fragment>
  );
};

export default StepComponent;
