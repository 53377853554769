import React, { FunctionComponent } from "react";

import { ICausalResult } from "../../../types/event";
import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import ManualVerificationComponent from "./manual-verification.component";

export interface IManualVerificationPanelProps {
  causals?: ICausalResult[];
}

const ManualVerificationPanel: FunctionComponent<
  ILoadingComponentProps & IManualVerificationPanelProps
> = ({ causals, ...loadingProps }) => (
  <PanelLoading title={"Verificación Manual de Causales"} {...loadingProps}>
    {causals && <ManualVerificationComponent causals={causals} />}
  </PanelLoading>
);

export default ManualVerificationPanel;
