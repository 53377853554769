import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import useMethods from "use-methods";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { When } from "react-if";
import { IconButton } from "@material-ui/core";
import { AddIcCall } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { Tooltip } from "@material-ui/core";
import { useQueryClient } from "react-query";

import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import TableListPageTemplate from "../../../../template/table-list-page/table-list-page-template";
import { ITableListPageState } from "../../../../template/table-list-page/table-list-page-template";
import { getTableListMethods } from "../../../../template/table-list-page/table-list-page-template";
import { useCallList } from "../../../../services/protegeme-v2/contac-center/call/use-call";
import { useCallReport } from "../../../../services/protegeme-v2/contac-center/call/use-call";
import CallListTable from "./components/call-list-table";
import FilterButtonMenu from "./components/filter-button-menu";
import useTableListPage from "../../../../template/table-list-page/hooks/use-table-list-page";

export interface ICallsListStateFilters {
  incomingPhoneNumber?: string;
  identificationNumber?: string;
  laneSaved?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
}

type ICallListState = ITableListPageState<ICallsListStateFilters>;

const initialState: ICallListState = {
  filters: {
    incomingPhoneNumber: "",
    identificationNumber: "",
    laneSaved: "",
    maxDate: null,
    minDate: null,
  },
  count: 0,
  currentPage: 0,
  perPage: 25,
  perPageOptions: [25, 50, 100],
};

const methods = (state: ICallListState) => ({
  ...getTableListMethods(state),
});

const CallListPage = () => {
  const { t } = useTranslation();
  const { canCallRecordCreate, canCallReadAll, canCallReport } =
    useAuthorization();
  const [availableLink, setAvailableLink] = useState(false);
  const { callRecordCreate } = useRoutesV2();
  const [state, stateMethods] = useMethods(methods, initialState);
  const { updateCount, updateFilters, updateCurrentPage } = stateMethods;
  const callsQuery = useCallList({
    request: {
      filters: {
        incoming_phone_number: state.filters.incomingPhoneNumber,
        cedula: state.filters.identificationNumber,
        ensured_line: state.filters.laneSaved,
        max_date: state.filters.maxDate,
        min_date: state.filters.minDate,
      },
      pagination: {
        pageNumber: state.currentPage + 1,
        pageSize: state.perPage,
      },
    },
  });

  const { data: calls, isLoading, refetch: refecthCalls } = callsQuery;

  const queryClient = useQueryClient();

  const reportQuery = useCallReport(
    {
      incoming_phone_number: state.filters.incomingPhoneNumber,
      cedula: state.filters.identificationNumber,
      ensured_line: state.filters.laneSaved,
      max_date: state.filters.maxDate,
      min_date: state.filters.minDate,
    },
    { enabled: false, refetchOnWindowFocus: false, refetchOnMount: false }
  );
  const {
    data: url,
    isLoading: isLoadingReport,
    isError: isErrorReport,
    refetch,
  } = reportQuery;

  const refectDocument = () => {
    refetch();
    setAvailableLink(false);
  };

  useTableListPage({
    state,
    refetch: refecthCalls,
    count: calls?.count,
    updateCount,
  });

  useEffect(() => {
    queryClient.cancelQueries("call/report");
  }, [queryClient, state.filters]);

  useEffect(() => {
    if (url) {
      const link = document.createElement("a");
      const href = url?.data?.url_excel;
      if (href) {
        link.href = href;
        link.click();
        setAvailableLink(false);
      }
    } else {
      setAvailableLink(false);
    }
  }, [url]);

  return (
    <AuthorizationComponent isAuthorized={canCallReadAll} autoRedirect={true}>
      <TableListPageTemplate
        title={
          <div className="w-full h-full flex flew-row justify-between items-center">
            <div>{t("callList.label")}</div>
            <div className={"flex flex-row gap-2"}>
              <When condition={canCallRecordCreate}>
                <IconButton
                  edge="end"
                  aria-haspopup="true"
                  children={<AddIcCall />}
                  component={Link}
                  to={callRecordCreate.route()}
                />
              </When>
              <FilterButtonMenu
                state={state.filters}
                updateFilters={updateFilters}
                updateCurrentPage={updateCurrentPage}
              />
              <When condition={canCallReport}>
                <Tooltip title={"Descargar excel"}>
                  <span>
                    <IconButton
                      edge="end"
                      aria-haspopup="true"
                      children={<HiOutlineDocumentDownload />}
                      onClick={() => refectDocument()}
                      disabled={
                        availableLink || isLoadingReport || isErrorReport
                      }
                    />
                  </span>
                </Tooltip>
              </When>
            </div>
          </div>
        }
        isLoading={isLoading}
        isEmpty={_.isEmpty(calls?.results)}
        table={
          <When
            condition={Boolean(calls)}
            children={() => <CallListTable data={calls} />}
          />
        }
        tableListMethods={stateMethods}
        tableListState={state}
      />
    </AuthorizationComponent>
  );
};
export default CallListPage;
