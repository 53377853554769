import { ActualizationActions } from "./actualization.actions";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  actualizationInitialValue,
  actualizationReducer,
  IActualizationDispatch,
  IActualizationState,
} from "./actualization.reducer";

const ActualizationStateContext = React.createContext<
  IActualizationState | undefined
>(undefined);
const ActualizationDispatchContext = React.createContext<
  IActualizationDispatch | undefined
>(undefined);

export const ActualizationProvider: FunctionComponent = ({ children }) => {
  const [context, dispatch] = useReducer(
    actualizationReducer,
    actualizationInitialValue
  );

  useEffect(() => {
    ActualizationActions.fetchActualizationListAll(dispatch);
  }, []);

  return (
    <ActualizationStateContext.Provider
      value={context as IActualizationState | undefined}
    >
      <ActualizationDispatchContext.Provider value={dispatch}>
        {children}
      </ActualizationDispatchContext.Provider>
    </ActualizationStateContext.Provider>
  );
};

export const useActualizationState = (): IActualizationState => {
  const state = useContext(ActualizationStateContext);
  if (!state)
    throw new Error(
      "useActualizationState must be used within a ActualizationProvider"
    );
  return state;
};

export const useActualizationDispatch = (): IActualizationDispatch => {
  const dispatch = useContext(ActualizationDispatchContext);
  if (!dispatch)
    throw new Error(
      "useActualizationDispatch must be used within a ActualizationProvider"
    );
  return dispatch;
};
