import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface IAuditChip {
  isAudited: boolean;
  className?: string;
}

const useStyle = makeStyles((theme) => {
  return {
    activeChip: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light),
    },
    inactiveChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
  };
});

const AuditChip = (params: IAuditChip) => {
  const { isAudited, className } = params;
  const style = useStyle();
  const { t } = useTranslation();
  const title = isAudited
    ? t("components.auditChip.isAudited")
    : t("components.auditChip.isNotAudited");
  return (
    <Fragment>
      <Chip
        className={className}
        classes={{ root: isAudited ? style.activeChip : style.inactiveChip }}
        label={<span className={"text-xs"}>{title}</span>}
        size={"small"}
      />
    </Fragment>
  );
};

export default AuditChip;
