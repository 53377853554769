import { TableBody, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { parseISO } from "date-fns";
import React from "react";
import { When } from "react-if";
import { useStageState } from "../../../../../providers/stage/stage.provider";
import { ClientFullName } from "../../../../../services/protegeme-v2/client/util/client-format-name";
import { DateFormat } from "../../../../../util/date.util";

const EventClientSection = () => {
  const { event } = useStageState();
  const client = event.data?.cliente;
  return (
    <Table size={"small"}>
      <TableBody>
        <When
          condition={Boolean(client)}
          children={() => (
            <TableRow>
              <TableCell align="left">
                <span className={"text-xs font-bold"}>Nombre del cliente</span>
              </TableCell>
              <TableCell align="right">
                <span className={"text-xs"}>
                  <ClientFullName
                    firstName={client!.name}
                    lastName={client!.second_name}
                  />
                </span>
              </TableCell>
            </TableRow>
          )}
        />
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Número de documento</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{client?.identification_number}</span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Correo electrónico</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{client?.email}</span>
          </TableCell>
        </TableRow>
        <When
          condition={Boolean(client?.birthday)}
          children={() => (
            <TableRow>
              <TableCell align="left">
                <span className={"text-xs font-bold"}>Fecha de nacimiento</span>
              </TableCell>
              <TableCell align="right">
                <span className={"text-xs"}>
                  <DateFormat source={parseISO(client!.birthday)} />
                </span>
              </TableCell>
            </TableRow>
          )}
        />
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Género del cliente</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              {event.data?.genero_reclamante.name}
            </span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EventClientSection;
