import React, { FunctionComponent } from "react";

import EventSection from "./event.section";
import PanelLoading from "../../../../panel/panel.loading";
import { ILoadingComponentProps } from "../../../../loading/loading.component";
import { useStageState } from "../../../../../providers/stage/stage.provider";

const EventPanel: FunctionComponent<ILoadingComponentProps> = (props) => {
  const { event } = useStageState();
  const { hasError, isLoading } = event;
  return (
    <PanelLoading
      hasError={hasError}
      title={`Datos de la Reclamación`}
      isLoading={isLoading}
      width={props.width || "100%"}
      animation={props.animation}
      height={props.height || 200}
      variant={props.variant || "rect"}
      children={<EventSection />}
    />
  );
};

export default EventPanel;
