import React, { FunctionComponent } from "react";

import PanelLoading from "../../../../panel/panel.loading";
import EventDocumentSection from "./event.document.section";
import { ILoadingComponentProps } from "../../../../loading/loading.component";
import { useStageState } from "../../../../../providers/stage/stage.provider";

const EventDocumentPanel: FunctionComponent<ILoadingComponentProps> = (
  props
) => {
  const { documents } = useStageState();
  const { hasError, isLoading } = documents;
  return (
    <PanelLoading
      hasError={hasError}
      title={`Documentos`}
      isLoading={isLoading}
      width={props.width || "100%"}
      animation={props.animation}
      height={props.height || 200}
      variant={props.variant || "rect"}
      children={<EventDocumentSection />}
    />
  );
};

export default EventDocumentPanel;
