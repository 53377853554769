import React from "react";
import { useStepContextLinkDevice } from "./step.provider";
import { indicators } from "./step-indicator.component";

const StepTitleLinkDeviceComponent = () => {
  const { currentStep } = useStepContextLinkDevice();
  return (
    <div className="text-base font-bold mb-8">
      {currentStep + 1} {indicators[currentStep].label}
    </div>
  );
};

export default StepTitleLinkDeviceComponent;
