import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import GoogleMapReact from "google-map-react";
import React, { Fragment } from "react";
import Configuration from "../../../../config/Configuration";
import { useGlobalStyles } from "../../../../styles/useGlobalStyles";
import DeviceMarker from "./device-marker";

interface ILocalizationSection {
  latitude: number;
  longitude: number;
  label?: string;
}

const darkThemeStyles = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#242f3e" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];

const LocalizationSection = (props: ILocalizationSection) => {
  const { latitude, longitude, label } = props;
  const style = useGlobalStyles();
  const theme = useTheme();
  const gmapStyles = theme.palette.type === "dark" ? darkThemeStyles : [];
  const googleApiKey = Configuration.googleApi.key;
  return (
    <Fragment>
      <div className={clsx("h-96 border", style.borderColor)}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleApiKey }}
          center={{ lat: latitude, lng: longitude }}
          defaultZoom={18}
          options={{
            styles: gmapStyles,
          }}
        >
          <DeviceMarker lat={latitude} lng={longitude} label={label} />
        </GoogleMapReact>
      </div>
    </Fragment>
  );
};

export default LocalizationSection;
