import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Else } from "react-if";
import { Then } from "react-if";
import { If } from "react-if";

const useStyle = makeStyles((theme) => {
  return {
    activeChip: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light),
    },
    inactiveChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
  };
});

interface IActivationChip {
  isActive: boolean;
}

const ActivationChip = (props: IActivationChip) => {
  const { isActive } = props;
  const style = useStyle();
  const { t } = useTranslation();
  return (
    <Chip
      className={"flex"}
      size={"small"}
      variant={"default"}
      classes={{ root: isActive ? style.activeChip : style.inactiveChip }}
      label={
        <span className={"font-medium uppercase px-3"}>
          <If condition={isActive}>
            <Then>{t("components.activationChip.active")}</Then>
            <Else>{t("components.activationChip.inactive")}</Else>
          </If>
        </span>
      }
    />
  );
};

export default ActivationChip;
