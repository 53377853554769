import { Box } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { parseISO } from "date-fns";
import { formatDistanceToNowStrict } from "date-fns";
import { es } from "date-fns/locale";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import { Default } from "react-if";
import { Switch } from "react-if";
import { Case } from "react-if";
import { useAuthorization } from "../../../../../providers/authorization/authorization.provider";
import { ClientFullName } from "../../../../../services/protegeme-v2/client/util/client-format-name";
import { IReclamationCreateCommentReq } from "../../../../../services/protegeme-v2/reclamation/reclamation-service.interface";
import { IReclamationComment } from "../../../../../services/protegeme-v2/reclamation/reclamation-service.interface";
import { IUser } from "../../../../../types/authorization";
import Panel from "../../../../panel/panel";
import emptySvg from "./assets/undraw_blank_canvas_3rbb.svg";
import CommentaryForm from "./components/commentary-form";

export interface ICommentarySection {
  me: IUser;
  isLoading: boolean;
  reclamationId: string;
  commentaries?: IReclamationComment[];
  isCreating: boolean;
  onCreate: (props: IReclamationCreateCommentReq) => Promise<any>;
}

const CommentarySectionView = (props: ICommentarySection) => {
  const authorization = useAuthorization();
  const { isCreating, reclamationId, isLoading } = props;
  const { commentaries, onCreate, me } = props;
  const { t } = useTranslation();
  const isEmpty = _.isEmpty(commentaries);
  return (
    <Panel
      title={
        <div className="w-full h-full flex flex-row justify-between items-center">
          <div>{t("reclamationDetails.sections.commentary.label")}</div>
        </div>
      }
    >
      <Switch>
        <Case condition={isLoading}>
          <Skeleton height={400} width={"100%"} variant={"rect"} />
        </Case>
        <Default
          children={() => (
            <div className={"flex flex-col divide-y"}>
              <Switch>
                <Case condition={isEmpty}>
                  <div className={"flex flex-col gap-4 pb-8 items-center"}>
                    <div>
                      <img
                        className={"h-32"}
                        src={emptySvg}
                        alt={"undraw_blank_canvas_3rbb"}
                      />
                    </div>
                    <div className={"text-center"}>
                      {t("reclamationDetails.sections.commentary.empty")}
                    </div>
                  </div>
                </Case>
                <Default
                  children={() => {
                    return commentaries!.map((commentary, index) => {
                      const isFirst = index === 0;
                      const isMe = me.id === commentary.user.id;
                      return (
                        <div
                          key={commentary.fecha_created}
                          className={clsx(
                            "flex flex-row gap-4 py-4",
                            isFirst && "pt-0"
                          )}
                        >
                          <div
                            className={
                              "h-100 flex flex-col justify-start items-start"
                            }
                          >
                            <Avatar className="font-medium">
                              {String(commentary.user.name).charAt(0)}
                            </Avatar>
                          </div>
                          <div className={"flex-grow flex flex-col gap-2"}>
                            <div
                              className={clsx("flex flex-row justify-between")}
                            >
                              <div className="flex flex-col items-start justify-start">
                                <div
                                  className={"font-medium flex flex-row gap-1"}
                                >
                                  <ClientFullName
                                    firstName={commentary.user.name}
                                    lastName={commentary.user.second_name}
                                  />
                                  <When condition={isMe}>
                                    <div>
                                      {t(
                                        "reclamationDetails.sections.commentary.me"
                                      )}
                                    </div>
                                  </When>
                                </div>
                                <Box
                                  color={"text.secondary"}
                                  className="text-xs"
                                >
                                  {commentary.user.user_type.name}
                                </Box>
                              </div>
                              <Box
                                color={"text.secondary"}
                                className="flex flex-col items-end justify-start text-xs"
                              >
                                <div
                                  className={"text-right flex flex-row gap-1"}
                                >
                                  <div>
                                    {t(
                                      "reclamationDetails.sections.commentary.preDateDistance"
                                    )}
                                  </div>
                                  <div>
                                    {formatDistanceToNowStrict(
                                      parseISO(commentary.fecha_created),
                                      { locale: es }
                                    )}
                                  </div>
                                </div>
                              </Box>
                            </div>
                            <div className={"font-normal"}>
                              {commentary.comment}
                            </div>
                          </div>
                        </div>
                      );
                    });
                  }}
                />
              </Switch>
              <When
                condition={authorization.canReclamationCommentCreate}
                children={() => (
                  <CommentaryForm
                    reclamationId={reclamationId}
                    onCreate={onCreate}
                    isCreating={isCreating}
                  />
                )}
              />
            </div>
          )}
        />
      </Switch>
    </Panel>
  );
};

export default CommentarySectionView;
