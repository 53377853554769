import React from "react";
import { Form, Formik } from "formik";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useV1HistoryListState } from "../../providers/v1-history-list.provider";
import { useV1HistoryListActions } from "../../providers/v1-history-list.actions";
import MaterialTextField from "../../../../../components/formik/fields/material-text.field";
import { IV1HistoryListProviderActionSetFilterParams } from "../../../../../types/v1HistoryTicket";

type FormValues = IV1HistoryListProviderActionSetFilterParams;

const V1HistoryListTicketPanelFilterForm = () => {
  const { t } = useTranslation();
  const { filter } = useV1HistoryListState();
  const { setFilter } = useV1HistoryListActions();
  const { clearFilter } = useV1HistoryListActions();
  const { setFormRef } = useV1HistoryListActions();
  const { eventId, phoneNumber, identificationNumber } = filter;

  const closeForm = () => {
    setFormRef(undefined);
  };

  const initialValue: FormValues = {
    eventId,
    phoneNumber,
    identificationNumber,
  };

  const onSubmit = (values: FormValues) => {
    setFilter(values);
    closeForm();
  };

  const onReset = () => {
    clearFilter();
    closeForm();
  };

  return (
    <div className={"w-96 flex flex-col p-5"}>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize={true}
        initialValues={initialValue}
      >
        <Form>
          <div className={"grid grid-cols-1 gap-4"}>
            <div>{t("v1HistoryListTicketFilterForm.instructions")}</div>
            <MaterialTextField
              variant={"outlined"}
              name={"eventId"}
              label={t("v1_history_ticket.eventId")}
            />
            <MaterialTextField
              variant={"outlined"}
              name={"identificationNumber"}
              label={t("v1_history_ticket.clientIdNumber")}
            />
            <MaterialTextField
              variant={"outlined"}
              name={"phoneNumber"}
              label={t("v1_history_ticket.devicePhoneNumber")}
            />
            <div className={"flex flex-row justify-end"}>
              <div className={"grid grid-cols-2 gap-4"}>
                <Button
                  onClick={onReset}
                  variant={"outlined"}
                  children={t("v1HistoryListTicketFilterForm.reset")}
                />
                <Button
                  type={"submit"}
                  color="primary"
                  variant="contained"
                  children={t("v1HistoryListTicketFilterForm.submit")}
                />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default V1HistoryListTicketPanelFilterForm;
