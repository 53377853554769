export const microserviceApiRoutes = {
  protegeme: {
    sponsor: {
      getList: (): string => "/api/v1/protegeme/policy/sponsors",
    },
    plans: {
      getList: (): string => "/api/v1/protegeme/policy/imei",
    },
    client: {
      linkDevice: (): string => "/api/v1/protegeme/policy/link-device",
    },
  },
};
