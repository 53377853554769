import { format } from "date-fns";
import _ from "lodash";
import React from "react";
import { useState } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik/dist/types";
import { useUser } from "../../../../../providers/user/user.provider";

import { IFormikValue } from "./call-create.interface";
import { useCallCreate } from "../../../../../services/protegeme-v2/contac-center/call/use-call";
import { ICreateCallBody } from "../../../../../services/protegeme-v2/contac-center/call/call-service.interface";
import NotificationCallCreated from "./notifications/notification-call-created";
import NotificationCallError from "./notifications/notification-call-error";

interface IFormatData {
  values: IFormikValue;
  user: number;
  date: string;
}

const formatData = (params: IFormatData): ICreateCallBody => {
  const { values, user, date } = params;
  const data = {};
  _.set(data, "user", user);
  _.set(data, "call_date", date);
  _.set(data, "incoming_phone_number", values.number_caller);
  _.set(data, "client_id", Number(values.client?.id));
  _.set(data, "comments", values.observations);
  _.set(data, "ensured_line", values.number_saved);
  _.set(data, "types", values.type);
  _.set(data, "reasons", [Number(values.motive?.id)]);
  _.set(data, "product_id", Number(values.product?.id));
  return data as ICreateCallBody;
};

const useCallRecordFormUtil = () => {
  const { t } = useTranslation();
  const user = useUser();
  const { mutateAsync: callCreate } = useCallCreate();
  const [loading, setLoading] = useState<boolean>(false);
  const initialValues: IFormikValue = {
    number_caller: "",
    client: null,
    identification: {
      type: null,
      number: "",
    },
    number_saved: "",
    observations: "",
    type: null,
    product: null,
    motive: null,
  };

  const validationSchema = yup.object().shape({
    user: yup.string(),
    date: yup.string().nullable(),
    client: yup
      .object()
      .shape({
        id: yup.string().nullable().required(t("form.errors.required")),
      })
      .typeError(t("form.errors.required"))
      .required(t("form.errors.required")),
    number_caller: yup
      .string()
      .matches(/^[1-9]\d*$/, t("form.errors.digits"))
      .typeError(t("form.errors.digits"))
      .length(10, t("createCallRecord.form.errors.exactDigits"))
      .required(t("form.errors.required")),
    type: yup.number().nullable().required(t("form.errors.required")),
    number_saved: yup
      .string()
      .matches(/^[1-9]\d*$/, t("form.errors.digits"))
      .typeError(t("form.errors.digits"))
      .length(10, t("createCallRecord.form.errors.exactDigits"))
      .required(t("form.errors.required")),
    observations: yup
      .string()
      .max(1000, t("createCallRecord.form.errors.maxCharacter"))
      .required(t("form.errors.required")),
    product: yup.object().shape({}).nullable(),
    motive: yup
      .object()
      .shape({})
      .nullable()
      .required(t("form.errors.required")),
  });

  const submitForm = async (
    values: IFormikValue,
    formikHelpers: FormikHelpers<IFormikValue>
  ) => {
    const data = formatData({
      values,
      user: user!.id,
      date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    });
    setLoading(true);
    callCreate({ body: data })
      .then(() => toast.success(<NotificationCallCreated />))
      .catch(() => {
        toast.error(<NotificationCallError />);
      })
      .finally(() => {
        setLoading(false);
        formikHelpers.resetForm();
      });
  };

  return { initialValues, validationSchema, submitForm, loading };
};
export default useCallRecordFormUtil;
