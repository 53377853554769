import { IconButton } from "@material-ui/core";
import { parseISO } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoEye } from "react-icons/io5";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import TableStandard from "../../../../components/tables/table-standard";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { ClientFullName } from "../../../../services/protegeme-v2/client/util/client-format-name";
import { IReclamationFind } from "../../../../services/protegeme-v2/reclamation/reclamation-service.interface";
import { DateFormat } from "../../../../util/date.util";
import { getReclamationStateKey } from "../../util/reclamation-util";
import { getReclamationTypeKey } from "../../util/reclamation-util";
import AuditChip from "./audit-chip";

interface IClientTable {
  data: Array<IReclamationFind>;
}

const ReclamationListTable = (props: IClientTable) => {
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  const authorization = useAuthorization();

  const { data } = props;
  let columnsRaw: Array<Column<IReclamationFind> | boolean> = [
    {
      id: "id",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.id")}
        </div>
      ),
      accessor: (event) => <div className={"text-center"}>{event.id}</div>,
    },
    {
      Header: t("reclamationList.panel.columns.client"),
      accessor: (event) => (
        <ClientFullName
          firstName={event.cliente!.name}
          lastName={event.cliente!.second_name}
        />
      ),
    },
    {
      id: "identificationNumber",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.identificationNumber")}
        </div>
      ),
      accessor: (event) => (
        <div className={"text-right"}>
          {event?.cliente?.identification_number}
        </div>
      ),
    },
    {
      id: "lanePrincipal",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.lanePrincipal")}
        </div>
      ),
      accessor: (event) => <div className={"text-right"}>{event?.linea}</div>,
    },
    {
      id: "sponsor",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.sponsor")}
        </div>
      ),
      accessor: (event) => (
        <div className={"text-center"}>{event?.sponsor.name}</div>
      ),
    },
    {
      id: "plan",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.plan")}
        </div>
      ),
      accessor: (event) => (
        <div className={"text-center"}>{event?.plan?.nombre}</div>
      ),
    },
    {
      id: "type",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.type")}
        </div>
      ),
      accessor: (event) => (
        <div className={"text-center"}>{t(getReclamationTypeKey(event))}</div>
      ),
    },
    {
      id: "state",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.state")}
        </div>
      ),
      accessor: (event) => (
        <div className={"text-center"}>{t(getReclamationStateKey(event))}</div>
      ),
    },
    {
      id: "createdAt",
      Header: (
        <div className={"text-center"}>
          {t("reclamationList.panel.columns.createdAt")}
        </div>
      ),
      accessor: (event) => (
        <div className={"text-center"}>
          <DateFormat source={parseISO(event.fecha_creacion)} format={"Pp"} />
        </div>
      ),
    },
    authorization.canReclamationAuditReadAll && {
      id: "audited",
      Header: () => (
        <div className={"flex flex-row justify-center"}>
          {t("reclamationList.panel.columns.audited")}
        </div>
      ),
      accessor: (event) => (
        <div className={"flex flex-row justify-center"}>
          <AuditChip className={"w-full"} isAudited={event.audited} />
        </div>
      ),
    },
    {
      id: "actions",
      Header: () => (
        <div className={"flex flex-row justify-center"}>
          {t("reclamationList.panel.columns.actions")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center gap-2"}>
          <When condition={authorization.canReclamationReadAll}>
            <IconButton
              component={Link}
              to={routesV2.reclamationDetails.route(row.id)}
              size={"small"}
              children={<IoEye />}
            />
          </When>
          <When
            condition={
              authorization.canReclamationAuditUpdateOne &&
              row.estado.id_system === "FINALIZADO" &&
              !row.audited
            }
          >
            <IconButton
              component={Link}
              to={routesV2.reclamationAuditRegister.route({
                reclamationId: row.id,
              })}
              size={"small"}
              children={routesV2.reclamationAuditRegister.icon}
            />
          </When>
          <When
            condition={
              authorization.canReclamationAuditReadOne &&
              row.estado.id_system === "FINALIZADO" &&
              row.audited
            }
          >
            <IconButton
              component={Link}
              to={routesV2.reclamationAuditDetails.route({
                reclamationId: row.id,
              })}
              size={"small"}
              children={routesV2.reclamationAuditDetails.icon}
            />
          </When>
        </div>
      ),
    },
  ];

  const columns: Array<Column<IReclamationFind>> = columnsRaw.reduce<
    Array<Column<IReclamationFind>>
  >((previousValue, currentValue) => {
    if (typeof currentValue === "boolean") {
      return previousValue;
    }
    return [...previousValue, currentValue];
  }, []);

  return (
    <TableStandard
      style={{ border: "0px" }}
      className={" text-xs"}
      stickyHeader={true}
      size={"small"}
      columns={columns}
      data={data}
    />
  );
};

export default ReclamationListTable;
