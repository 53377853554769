import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import {
  IClientPolicyCancelBody,
  IClientPolicyRenewBody,
  IUpdateClientDeviceReq,
} from "./client-service.interface";
import { IFindOneClientDeviceRes } from "./client-service.interface";
import { IFindAllClientDevicesRes } from "./client-service.interface";
import { IClientUpdateRes } from "./client-service.interface";
import { IClientUpdateReq } from "./client-service.interface";
import { IClientFindOneResData } from "./client-service.interface";
import { ILinkClientDeviceReqBody } from "./client-service.interface";
import { IClientListResData } from "./client-service.interface";
import { IClientListReqQuery } from "./client-service.interface";
import { ICreateClientRes } from "./client-service.interface";
import { ICreateClientReqBody } from "./client-service.interface";

export const clientCreate = (candidate: ICreateClientReqBody) => {
  const url = protegemeV2ApiRoutes.v2.client.create();
  return protegemeV2ApiAxios.post<Error, ICreateClientRes>(url, candidate);
};

export const linkClientDevice = (body: ILinkClientDeviceReqBody) => {
  const url = protegemeV2ApiRoutes.v2.client.linkDevice();
  return protegemeV2ApiAxios.post(url, body);
};

export const clientList =
  (params: IClientListReqQuery) => (): Promise<IClientListResData> => {
    const url = protegemeV2ApiRoutes.v2.client.list();
    return protegemeV2ApiAxios
      .get<IClientListResData>(url, {
        params: {
          limit: params.limit,
          page: params.page,
          document: params.document || undefined,
          sponsor: params.sponsor || undefined,
          name: params.name || undefined,
          imei: params.imei || undefined,
          phone_number: params.phone_number || undefined,
          lastname: params.lastname || undefined,
        },
      })
      .then((res) => res.data);
  };

export const clientFindOne = (clientId: number) => {
  const url = protegemeV2ApiRoutes.v2.client.findOne(clientId);
  return protegemeV2ApiAxios.get<IClientFindOneResData>(url);
};

export const clientUpdate = (data: IClientUpdateReq) => {
  const { body, clientId } = data;
  const url = protegemeV2ApiRoutes.v2.client.update(clientId);
  return protegemeV2ApiAxios.put<IClientUpdateRes>(url, body);
};

export const findAllClientDevices = (clientId: number) => {
  const url = protegemeV2ApiRoutes.v2.client.findAllDevices(clientId);
  return protegemeV2ApiAxios.get<IFindAllClientDevicesRes>(url);
};

export const findOneClientDevice = (deviceId: number) => {
  const url = protegemeV2ApiRoutes.v2.client.findOneDevice(deviceId);
  return protegemeV2ApiAxios.get<IFindOneClientDeviceRes>(url);
};

export const updateClientDevice = (data: IUpdateClientDeviceReq) => {
  const url = protegemeV2ApiRoutes.v2.client.updateDevice(data.deviceId);
  return protegemeV2ApiAxios.put(url, data.body);
};

export const disablePolicy = (policyId: number) => {
  const url = protegemeV2ApiRoutes.v2.client.disablePolicy(policyId);
  return protegemeV2ApiAxios.post(url);
};

export const cancelPolicy = (body: IClientPolicyCancelBody) => {
  const url = protegemeV2ApiRoutes.v2.client.cancelPolicy();
  return protegemeV2ApiAxios.post(url, body);
};

export const renewPolicy = (body: IClientPolicyRenewBody) => {
  const url = protegemeV2ApiRoutes.v2.client.renewPolicy();
  return protegemeV2ApiAxios.post(url, body);
};
