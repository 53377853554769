import React from "react";
import PanelLoading from "../../../../../components/panel/panel.loading";
import { ILoadingComponentProps } from "../../../../../components/loading/loading.component";
import V1HistoryListTicketComponent from "../v1-history-list-ticket/v1-history-list-ticket.component";

import V1HistoryListTicketPanelTitle from "./v1-history-list-ticket-panel-title";
import { useV1HistoryListState } from "../../providers/v1-history-list.provider";

const V1HistoryListTicketPanel = (props: ILoadingComponentProps) => {
  const { tickets } = useV1HistoryListState();
  return (
    <PanelLoading
      title={<V1HistoryListTicketPanelTitle />}
      height={props.height}
      width={props.width}
      variant={props.variant}
      hasError={tickets.error}
      isLoading={tickets.loading}
      animation={props.animation}
      children={<V1HistoryListTicketComponent tickets={tickets.data!!} />}
    />
  );
};

export default V1HistoryListTicketPanel;
