import React from "react";
import { useTranslation } from "react-i18next";

const NotificatioCanceledPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div>{t("clientPolicyCancel.notification.success")}</div>
    </div>
  );
};

export default NotificatioCanceledPolicy;
