import React, { Fragment } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface IEnableChip {
  isEnable: boolean;
  className?: string;
}

const useStyle = makeStyles((theme) => {
  return {
    activeChip: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light),
    },
    inactiveChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
  };
});

const EnableChip = (params: IEnableChip) => {
  const { isEnable, className } = params;
  const style = useStyle();
  const { t } = useTranslation();
  const title = isEnable
    ? t("clientPolicyRenew.components.enableChip")
    : t("clientPolicyRenew.components.disableChip");

  return (
    <Fragment>
      <Chip
        className={className}
        classes={{ root: isEnable ? style.activeChip : style.inactiveChip }}
        label={<span className={"text-sm"}>{title}</span>}
        size={"medium"}
      />
    </Fragment>
  );
};

export default EnableChip;
