import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  contLoader: {
    zIndex: 9,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0px",
    left: "0px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
});

const LoadingPageSpinner = () => {
  const styles = useStyles();
  return (
    <div className={styles.contLoader}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default LoadingPageSpinner;
