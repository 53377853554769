import { Table } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ReactNode } from "react";
import React from "react";

export interface IPair {
  key: ReactNode | string;
  value: ReactNode | string;
}

interface ITablePair {
  data: Array<IPair>;
  keyClassName?: string;
  valueClassName?: string;
  tableClassName?: string;
  hasBorder?: boolean;
}

const useStyle = makeStyles(() => ({
  withoutBorder: {
    border: "none",
  },
}));

const TablePair = (props: ITablePair) => {
  const { data, keyClassName, valueClassName } = props;
  const { tableClassName, hasBorder = true } = props;
  const style = useStyle();
  return (
    <Table
      size={"small"}
      className={tableClassName}
      classes={{ root: clsx(!hasBorder && style.withoutBorder) }}
    >
      <TableBody>
        {data.map(({ key, value }, index) => (
          <TableRow key={index}>
            <TableCell className={keyClassName}>
              <div className={keyClassName}>{key}</div>
            </TableCell>
            <TableCell>
              <div className={valueClassName}>{value}</div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TablePair;
