import { useDispatch } from "react-redux";
import { Action, configureStore } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import { ThemeReducer } from "./theme/theme.slice";
import { EventReducer } from "./events/event.slice";
import { EventListReducer } from "./events/event-list.slice";
import { CausalByStageReducer } from "./causal/causal-by-stage.slice";
import { CausalByEventReducer } from "./causal/causal-by-event.slice";
import { SaveCausalByStageReducer } from "./causal/save-causal-by-stage.slice";
import { DocumentByEventReducer } from "./events/document/document-by-event.slice";
import { PlanStageByEventReducer } from "./events/stage/plan-stage-by-event.slice";
import { EventStageByEventReducer } from "./events/stage/event-stage-by-event.slice";
import { CurrentStageByEventReducer } from "./events/stage/current-stage-by-event.slice";
import { SaveDocumentByStageReducer } from "./causal/save-document-by-stage.slice";

const Store = configureStore({
  reducer: {
    theme: ThemeReducer,
    eventList: EventListReducer,
    event: EventReducer,
    causalByStage: CausalByStageReducer,
    causalByEvent: CausalByEventReducer,
    saveCausalByEvent: SaveCausalByStageReducer,
    saveDocumentByEvent: SaveDocumentByStageReducer,
    documentsByEvent: DocumentByEventReducer,
    currentStageByEvent: CurrentStageByEventReducer,
    planStageByEvent: PlanStageByEventReducer,
    eventStageByEvent: EventStageByEventReducer,
  },
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default Store;
