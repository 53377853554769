import { Toolbar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { If } from "react-if";
import { Then } from "react-if";
import { Else } from "react-if";
import { When } from "react-if";
import { useParams } from "react-router-dom";
import { useListCausalsByStageId } from "../../../services/protegeme-v2/causal/use-stage";
import StagePageTemplate from "../../../template/stage-page.template";
import UploadDiagnosticForm from "./upload-diagnostic-form";

const UploadDiagnosticPage = () => {
  const { stageId } = useParams<{ stageId: string }>();
  const { data, isLoading, isSuccess } = useListCausalsByStageId(stageId);

  return (
    <StagePageTemplate>
      <Paper className={"h-full flex flex-col"}>
        <Toolbar>
          <If condition={isLoading}>
            <Then>
              <Skeleton variant={"text"} width={"50%"} />
            </Then>
            <Else>
              <Typography variant={"h6"} className={"m-0"}>
                Cargar Diagnostico
              </Typography>
            </Else>
          </If>
        </Toolbar>
        <Divider />
        <Paper elevation={0} square={true} className={"px-6 pt-6 pb-8 h-full"}>
          <When condition={isLoading}>
            <Skeleton variant={"rect"} width={"100%"} height={500} />
          </When>
          <When
            condition={isSuccess}
            children={() => <UploadDiagnosticForm causals={data!} />}
          />
        </Paper>
      </Paper>
    </StagePageTemplate>
  );
};

export default UploadDiagnosticPage;
