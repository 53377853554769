import clsx from "clsx";
import React from "react";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import {
  Icon,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";

import { useStageState } from "../../../../../providers/stage/stage.provider";

const useStyle = makeStyles((theme) => ({
  colorSuccess: {
    color: theme.palette.success.main,
  },
  colorError: {
    color: theme.palette.error.main,
  },
}));

const EventCausalSection = () => {
  const { causals } = useStageState();
  const classes = useStyle();
  return (
    <Table size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs"}>Nombre</span>
          </TableCell>
          <TableCell align="left">
            <span className={"text-xs"}>Etapa</span>
          </TableCell>
          <TableCell align="center">
            <span className={"text-xs"}>Resultado</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {causals.data?.map((causal) => (
          <TableRow key={causal?.id}>
            <TableCell align="left">
              <span className={"text-xs text-justify"}>
                {causal.texto_front}
              </span>
            </TableCell>
            <TableCell align="left">
              <span className={"text-xs text-justify"}>
                {causal.etapa.nombre}
              </span>
            </TableCell>
            <TableCell align="center">
              <div className={"flex flex-col items-center"}>
                <Tooltip
                  title={
                    causal.resultado.id_system === "APROBADO"
                      ? "APROBADO"
                      : "RECHAZADO"
                  }
                >
                  <Icon
                    className={clsx(
                      causal.resultado.id_system === "APROBADO"
                        ? classes.colorSuccess
                        : classes.colorError
                    )}
                  >
                    {causal.resultado.id_system === "APROBADO"
                      ? "check"
                      : "close"}
                  </Icon>
                </Tooltip>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EventCausalSection;
