import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import { IGetUserMe } from "./user-service.interface";
import { IListAllGendersResData } from "./user-service.interface";
import { IListAllDocumentTypeResData } from "./user-service.interface";

export const userGetMe = () => {
  const url = protegemeV2ApiRoutes.v2.user.getMe();
  return protegemeV2ApiAxios.get<IGetUserMe>(url);
};

export const listAllGenders = () => {
  const url = protegemeV2ApiRoutes.v2.user.listAllGenders();
  return protegemeV2ApiAxios
    .get<IListAllGendersResData>(url)
    .then((res) => res.data);
};

export const listAllDocumentType = () => {
  const url = protegemeV2ApiRoutes.v2.user.listAllDocumentType();
  return protegemeV2ApiAxios
    .get<IListAllDocumentTypeResData>(url)
    .then((res) => res.data);
};
