import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { Default } from "react-if";
import { Case } from "react-if";
import { Switch } from "react-if";
import { useParams } from "react-router-dom";
import Panel from "../../../../components/panel/panel";
import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useFindOneClientDevice } from "../../../../services/protegeme-v2/client/use-client";
import FixedScrollSectionTemplate from "../../../../template/fixed-scroll-section.template";
import UpdateForm from "./components/update-form";

const ClientDeviceUpdatePage = () => {
  const authorization = useAuthorization();
  const { t } = useTranslation();
  const { deviceId } = useParams<any>();
  const clientDevice = useFindOneClientDevice(deviceId);
  return (
    <AuthorizationComponent
      isAuthorized={authorization.canClientDeviceUpdateOne}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-4/5 2xl:w-3/5"}>
                <Panel title={t("clientDeviceUpdate.panel.label")}>
                  <Switch>
                    <Case
                      condition={clientDevice.isLoading}
                      children={
                        <Skeleton
                          width={"100%"}
                          height={500}
                          variant={"rect"}
                        />
                      }
                    />
                    <Default
                      children={() => (
                        <UpdateForm clientDevice={clientDevice.data!.data} />
                      )}
                    />
                  </Switch>
                </Panel>
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ClientDeviceUpdatePage;
