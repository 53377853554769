import React from "react";
import { Box } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { Else } from "react-if";
import { Then } from "react-if";
import { If } from "react-if";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import { DateFormat } from "../../../../util/date.util";
import { CurrencyFormat } from "../../../../util/currency.util";
import { IFindAllClientDevice } from "../../../../services/protegeme-v2/client/client-service.interface";

interface Props {
  deviceClient: IFindAllClientDevice | undefined;
}

const DetailSection = ({ deviceClient }: Props) => {
  const { t } = useTranslation();
  return (
    <Table size={"small"}>
      <TableBody>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.id")}</TableCell>
          <TableCell>
            <div className={"font-bold"}>{deviceClient?.policy[0]?.id}</div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.plan")}</TableCell>
          <TableCell>
            <div className={"font-bold"}>
              {deviceClient?.policy[0]?.plan.nombre}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.sponsor")}</TableCell>
          <TableCell>
            <div className={"font-bold"}>
              {deviceClient?.policy[0]?.plan.sponsor.nombre}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.activationDate")}</TableCell>
          <TableCell>
            <If condition={Boolean(deviceClient?.policy[0]?.fecha_vinculacion)}>
              <Then
                children={() => (
                  <div className={"font-bold"}>
                    <DateFormat
                      source={parseISO(
                        deviceClient
                          ? deviceClient.policy[0]?.fecha_activacion
                          : "2033-01-01"
                      )}
                    />
                  </div>
                )}
              />
              <Else>
                <Box color={"error.main"} className={"font-bold"}>
                  {t("clientPolicyDetails.panel.notFound")}
                </Box>
              </Else>
            </If>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.bondingDate")}</TableCell>
          <TableCell>
            <If condition={Boolean(deviceClient?.policy[0]?.fecha_vinculacion)}>
              <Then
                children={() => (
                  <div className={"font-bold"}>
                    <DateFormat
                      source={parseISO(
                        deviceClient
                          ? deviceClient.policy[0]?.fecha_vinculacion
                          : "2033-01-01"
                      )}
                    />
                  </div>
                )}
              />
              <Else>
                <Box color={"error.main"} className={"font-bold"}>
                  {t("clientPolicyDetails.panel.notFound")}
                </Box>
              </Else>
            </If>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.renovationDate")}</TableCell>
          <TableCell>
            <If condition={Boolean(deviceClient?.policy[0]?.fecha_renovacion)}>
              <Then
                children={() => (
                  <div className={"font-bold"}>
                    <DateFormat
                      source={parseISO(
                        deviceClient
                          ? deviceClient.policy[0]?.fecha_renovacion
                          : "2033-01-01"
                      )}
                    />
                  </div>
                )}
              />
              <Else>
                <Box color={"error.main"} className={"font-bold"}>
                  {t("clientPolicyDetails.panel.notFound")}
                </Box>
              </Else>
            </If>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyDetails.panel.validity")}</TableCell>
          <TableCell>
            <div className={"font-bold"}>
              {Number(deviceClient?.policy[0]?.validity) - 1}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {t("clientPolicyDetails.panel.insurranceValue")}
          </TableCell>
          <TableCell>
            <If condition={Boolean(deviceClient?.policy[0]?.valor_asegurado)}>
              <Then
                children={() => (
                  <div className={"font-bold"}>
                    <CurrencyFormat
                      value={deviceClient?.policy[0]?.valor_asegurado}
                    />
                  </div>
                )}
              />
              <Else>
                <Box color={"error.main"} className={"font-bold"}>
                  {t("clientPolicyDetails.panel.notFound")}
                </Box>
              </Else>
            </If>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DetailSection;
