import to from "await-to-js";
import { useHistory, useParams } from "react-router-dom";
import React, { FunctionComponent, useState, Fragment } from "react";

import EAxios from "../../../axios/EAxios";
import LoadingButton from "../../loading-button/loading-button";
import { useCoverage } from "../../../hooks/useCoverage";
import { Box } from "@material-ui/core";

const RegisterMaintenanceIngressComponent: FunctionComponent = () => {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const cobertura = useCoverage();
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handlerSave = () => async () => {
    const url =
      EAxios.endpoints.default.stage.registerMaintenanceIngress(cobertura);
    const data = { ticket_id: eventId };

    setLoadingSubmit(true);
    const [error, response] = await to(EAxios.post(url, data));
    setLoadingSubmit(false);

    if (error) return;
    if (response?.status === 200 || response?.status === 201) history.goBack();
  };

  const link = (
    <Box color="primary.main" clone>
      <a
        className={"font-bold hover:underline"}
        rel="noreferrer"
        target={"_blank"}
        href={
          "http://54.83.51.162/TrackingCooper/Default.aspx?ReturnUrl=%2fTrackingCooper%2fReportes%2fTrazabilidadXMarca.aspx"
        }
        children={"link"}
      />
    </Box>
  );

  return (
    <Fragment>
      <div className={"mb-5"}>
        <p className={"mb-5"}>
          Instrucciones para realizar el ingreso del dispositivo a el servicio
          técnico especializado:
        </p>
        <ol className="mx-5 list-decimal">
          <li className={"mb-2"}>
            <p>Inicia sesión en la plataforma Tracking Cooper.</p>
            <p className={"italic text-xs"}>
              (Ingresar a través del siguiente {link}).
            </p>
          </li>
          <li className={"mb-2"}>
            <p>
              Realizar el pre-ingreso con los datos correspondientes a la
              reclamación en curso.
            </p>
            <p className={"italic text-xs"}>
              (Ver datos en la sección izquierda).
            </p>
          </li>
          <li className={"mb-2"}>
            <p>
              Finalizado el registro, guarda el pre-ingreso en el presente
              formulario.
            </p>
            <p className={"italic text-xs"}>(Dar click en el botón guardar).</p>
          </li>
        </ol>
      </div>
      <div className={"flex flex-row-reverse"}>
        <LoadingButton
          type={"submit"}
          color={"primary"}
          children={"Guardar"}
          variant={"contained"}
          onClick={handlerSave()}
          isLoading={isLoadingSubmit}
        />
      </div>
    </Fragment>
  );
};

export default RegisterMaintenanceIngressComponent;
