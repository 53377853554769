import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import useMethods from "use-methods";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { usePendingCoverageListAll } from "../../../services/protegeme-v2/pending-coverage/pending-coverage-hooks";
import useTableListPage from "../../../template/table-list-page/hooks/use-table-list-page";
import { getTableListMethods } from "../../../template/table-list-page/table-list-page-template";
import TableListPageTemplate from "../../../template/table-list-page/table-list-page-template";
import { ITableListPageState } from "../../../template/table-list-page/table-list-page-template";
import FilterButtonMenuContainer from "./components/filter-button-menu-container";
import PendingCoverageTable from "./components/pending-coverage-table";

export interface IPendingCoverageFilters {
  documentNumber: string;
}

type IState = ITableListPageState<IPendingCoverageFilters>;

const initialState: IState = {
  filters: {
    documentNumber: "",
  },
  count: 0,
  currentPage: 0,
  perPage: 25,
  perPageOptions: [25, 50, 100],
};

const methods = (state: IState) => ({
  ...getTableListMethods(state),
});

const PendingCoverageListContainer = () => {
  const { t } = useTranslation();
  const authorization = useAuthorization();
  const [state, stateMethods] = useMethods(methods, initialState);
  const { updateCount, updateFilters } = stateMethods;

  const pendingCoverageListAllQuery = usePendingCoverageListAll({
    request: {
      filters: {
        identificationNumber: state.filters.documentNumber,
      },
      limit: state.perPage,
      page: state.currentPage + 1,
    },
  });

  const {
    refetch,
    data: pendingCoverages,
    isLoading,
  } = pendingCoverageListAllQuery;

  useTableListPage({
    state,
    refetch,
    count: pendingCoverages?.count,
    updateCount,
  });

  return (
    <AuthorizationComponent
      isAuthorized={authorization.canPendingCoverageReadAll}
      autoRedirect={true}
    >
      <TableListPageTemplate
        title={
          <div className="w-full h-full flex flex-row justify-between items-center">
            <div>{t("pendingCoverageList.panel.label")}</div>
            <FilterButtonMenuContainer
              filters={state.filters}
              updateFilters={updateFilters}
            />
          </div>
        }
        isLoading={isLoading}
        isEmpty={_.isEmpty(pendingCoverages?.data)}
        table={
          <When
            condition={Boolean(pendingCoverages?.data)}
            children={() => (
              <PendingCoverageTable data={pendingCoverages!.data} />
            )}
          />
        }
        tableListMethods={stateMethods}
        tableListState={state}
      />
    </AuthorizationComponent>
  );
};

export default PendingCoverageListContainer;
