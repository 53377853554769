import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { reclamationFindOne } from "./reclamation-service";
import { reclamationAuditSave } from "./reclamation-service";
import { reclamationAuditDetails } from "./reclamation-service";
import { reclamationAuditList } from "./reclamation-service";
import { reclamationFind } from "./reclamation-service";
import { reclamationListAllComments } from "./reclamation-service";
import { reclamationCreateComment } from "./reclamation-service";
import { reclamationExport } from "./reclamation-service";
import { statusReclamationList } from "./reclamation-service";
import { IUseReclamationFindOne } from "./reclamation-service.interface";
import { IReclamationAuditSaveReq } from "./reclamation-service.interface";
import { IUseReclamationAuditDetails } from "./reclamation-service.interface";
import { IUseReclamationAuditList } from "./reclamation-service.interface";
import { IUseReclamationFind } from "./reclamation-service.interface";
import { IReclamationCreateCommentReq } from "./reclamation-service.interface";

export const useReclamationFind = (params: IUseReclamationFind) => {
  const { options, request } = params;
  const key = ["reclamation/find", request];
  return useQuery(
    key,
    () => reclamationFind(request).then((res) => res.data),
    options
  );
};

export const useReclamationFindOne = (params: IUseReclamationFindOne) => {
  const { options, request } = params;
  const key = ["reclamation/find/one", request.reclamationId];
  return useQuery(
    key,
    () => reclamationFindOne(request).then((res) => res.data),
    options
  );
};

export const useReclamationListAllComments = (reclamationId: string) => {
  return useQuery(["reclamation/comments", reclamationId], () =>
    reclamationListAllComments(reclamationId).then((res) => res.data)
  );
};

export const useReclamationExport = () => {
  return useMutation(() => reclamationExport().then((res) => res.data));
};

export const useReclamationCreateComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (props: IReclamationCreateCommentReq) =>
      reclamationCreateComment(props).then((res) => res.data),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries([
          "reclamation/comments",
          req.slugs.reclamationId,
        ]);
      },
    }
  );
};

export const useReclamationAuditList = (params: IUseReclamationAuditList) => {
  const { options, request } = params;
  const key = ["reclamation/audit/list", request.reclamationId];
  const query = useQuery(
    key,
    () => reclamationAuditList(request).then((res) => res.data),
    options
  );
  return query;
};

export const useReclamationAuditDetails = (
  params: IUseReclamationAuditDetails
) => {
  const { options, request } = params;
  const key = ["reclamation/audit/details", request.reclamationId];
  const query = useQuery(
    key,
    () => reclamationAuditDetails(request).then((res) => res.data),
    options
  );
  return query;
};

export const useReclamationAuditSave = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params: IReclamationAuditSaveReq) =>
      reclamationAuditSave(params).then((res) => res.data),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries([
          "reclamation/audit/details",
          req.reclamationId,
        ]);
        queryClient.invalidateQueries([
          "reclamation/audit/list",
          req.reclamationId,
        ]);
      },
    }
  );
  return mutation;
};

export const useStatusReclamationList = () => {
  return useQuery("reclamation/status/list", statusReclamationList);
};
