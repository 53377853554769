import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadDiagnosticComponent, {
  ISelectDeviceToDeliverComponentProps,
} from "./select-device-to-deliver.component";

type panelType = FunctionComponent<
  ILoadingComponentProps & ISelectDeviceToDeliverComponentProps
>;

const SelectDeviceToDeliverPanel: panelType = (props) => (
  <PanelLoading
    title={`Seleccionar el dispositivo y la tienda de entrega`}
    height={props.height}
    width={props.width}
    variant={props.variant}
    hasError={props.hasError}
    isLoading={props.isLoading}
    animation={props.animation}
  >
    <UploadDiagnosticComponent
      devicesToDeliver={props.devicesToDeliver}
      experienceCenters={props.experienceCenters}
    />
  </PanelLoading>
);

export default SelectDeviceToDeliverPanel;
