import { TextField as TextFieldMui } from "@material-ui/core";
import { FormikValues } from "formik";
import { Field } from "formik";
import { useFormikContext } from "formik";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import { Autocomplete } from "formik-material-ui-lab";
import _ from "lodash";
import { useEffect } from "react";
import React from "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";

import EAxios from "../../../../../axios/EAxios";
import { IClientList } from "../../../../../services/protegeme-v2/client/client-service.interface";
import { useClientList } from "../../../../../services/protegeme-v2/client/use-client";
import { getClientFullName } from "../../../../../services/protegeme-v2/client/util/client-format-name";
import { ClientFullName } from "../../../../../services/protegeme-v2/client/util/client-format-name";
import { IClient, IDispositivoCliente } from "../../../../../types/event";
import VerticalField from "../../../../formik/vertical-field";
import { useStepContext } from "../../steps/step.provider";

type IClientSelectField = {
  name: string;
  label: string;
  help?: string;
  conditionalName: string;
};

const ClientSelectField = (props: IClientSelectField) => {
  const { setFieldValue } = useFormikContext();
  const { name, label, help, conditionalName } = props;
  const { setDevices } = useStepContext();
  const { errors, touched } = useFormikContext<FormikValues>();
  const [localClients, setLocalClients] = useState<IClientList[]>([]);
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce<string>(search, 500);
  const {
    refetch: clientRefetch,
    data: clientData,
    isLoading: isClientLoading,
  } = useClientList({ document: debouncedSearch }, { enabled: false });

  useEffect(() => {
    if (_.isEmpty(debouncedSearch)) {
      setLocalClients([]);
      return;
    }
    clientRefetch();
  }, [debouncedSearch, clientRefetch]);

  useEffect(() => {
    const candidates = clientData?.data || [];
    setLocalClients(candidates);
  }, [setLocalClients, clientData]);

  const updateDeviceList = (value: IClient) => {
    const url = EAxios.endpoints.default.device.listAllByClient(value.id);
    EAxios.get<IDispositivoCliente[]>(url).then((response) =>
      setDevices(response.data)
    );
  };

  const clearDevice = () => {
    setFieldValue(conditionalName, null);
    setDevices([]);
  };

  return (
    <VerticalField bold={false} isRequired={true} help={help} label={label}>
      <Field
        size={"small"}
        name={name}
        fullWidth={true}
        options={localClients}
        component={Autocomplete}
        loading={isClientLoading}
        filterOptions={(options: any) => options}
        getOptionLabel={(option: any) =>
          `${option.identification_number} - ${getClientFullName({
            firstName: option.name,
            lastName: option.second_name,
          })}`
        }
        onInputChange={(event: any, value: any) => {
          setSearch(value);
        }}
        onChange={(event: any, value: any) => {
          setFieldValue(name, value);
          if (!value) clearDevice();
          else updateDeviceList(value);
        }}
        renderOption={(option: any) => (
          <div className={"flex flex-row gap-1"}>
            <div className={"font-mono whitespace-pre"}>
              {String(option?.identification_number).padStart(11)}
            </div>
            <div className={"font-mono"}>-</div>
            <div>
              <ClientFullName
                firstName={option!.name}
                lastName={option!.second_name}
              />
            </div>
          </div>
        )}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextFieldMui
            {...params}
            variant={"outlined"}
            error={touched?.client && !!errors?.client}
            helperText={touched.client && errors.client}
          />
        )}
      />
    </VerticalField>
  );
};

export default ClientSelectField;
