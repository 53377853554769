import React from "react";

import PanelLoading from "../../panel/panel.loading";
import V1HistoryTicketComponent from "../v1-history-ticket/v1-history-ticket.component";
import { ILoadingComponentProps } from "../../loading/loading.component";
import { IV1HistoryTicket } from "../../../types/v1HistoryTicket";
import { useTranslation } from "react-i18next";

type IV1HistoryTicketPanel = {
  ticket?: IV1HistoryTicket;
} & ILoadingComponentProps;

const V1HistoryTicketPanelComponent = (props: IV1HistoryTicketPanel) => {
  const { t } = useTranslation();
  return (
    <PanelLoading
      title={t("v1HistoryTicketPanel.label", { id: props.ticket?.id })}
      width={props.width}
      height={props.height}
      variant={props.variant}
      hasError={props.hasError}
      isLoading={props.isLoading}
      animation={props.animation}
      isLoadingTitle={props.isLoading}
      hasErrorTitle={Boolean(props.hasError)}
      children={<V1HistoryTicketComponent ticket={props.ticket!!} />}
    />
  );
};

export default V1HistoryTicketPanelComponent;
