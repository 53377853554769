import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@material-ui/core";
import { IoDocumentText } from "react-icons/io5";

import { useActualizationState } from "../context/actualization.provider";
import { DateFormat } from "../../../util/date.util";
import { getDocumentSignUrl } from "../../../util/Document.util";
import {
  getActualizationsSorted,
  getActualizationVigentDate,
} from "../../../util/actualization.util";

const ActualizationHistoryComponent = () => {
  const { actualization } = useActualizationState();

  const actualizationSorted = getActualizationsSorted(actualization.data);
  const actualizationVigentDate =
    getActualizationVigentDate(actualizationSorted);

  const openDocument = async (documentId: number) => {
    const url = await getDocumentSignUrl(documentId);
    window.open(url, "_blank");
    return null;
  };

  return (
    <div className={"flex h-full items-center"}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha de Actualización</TableCell>
            <TableCell align="center">Fecha de Carga</TableCell>
            <TableCell align="center">Autor de la Carga</TableCell>
            <TableCell align="center">Actualizaciones Exitosas</TableCell>
            <TableCell align="center">Actualizaciones Fallidas</TableCell>
            <TableCell align="center">Descargar Documentos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actualizationSorted?.map((value) => (
            <TableRow key={value.id}>
              <TableCell>
                <DateFormat source={value.fecha_actualizacion} format={"P"} />
                {value.fecha_actualizacion === actualizationVigentDate && (
                  <span className={"italic"}>{" - Vigente"}</span>
                )}
              </TableCell>
              <TableCell align="center">
                <DateFormat source={value.fecha_carga} format={"Pp"} />
              </TableCell>
              <TableCell align="center">{value.user_upload.name}</TableCell>
              <TableCell align="center">
                {value.actualizacion_exitosa}
              </TableCell>
              <TableCell align="center">
                {value.actualizacion_fallida}
              </TableCell>
              <TableCell align="center">
                <div className={"flex flex-row justify-around"}>
                  <Box color={"success.main"}>
                    <Tooltip title={`Documento Cargado`} color={"inherit"}>
                      <IconButton
                        onClick={() =>
                          openDocument(value.documento_upload_data.id)
                        }
                        children={<IoDocumentText />}
                      />
                    </Tooltip>
                  </Box>
                  {value.actualizacion_fallida > 0 && (
                    <Box color={"error.main"}>
                      <Tooltip title={`Documento Errores`} color={"inherit"}>
                        <IconButton
                          onClick={() =>
                            openDocument(value.documento_return_error.id)
                          }
                          children={<IoDocumentText />}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ActualizationHistoryComponent;
