import * as yup from "yup";
import { Field } from "formik";
import React, { FunctionComponent } from "react";
import { Form, Formik, FormikValues } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { useHistory, useParams } from "react-router-dom";
import { TextField as TextFieldMui } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";

import VerticalField from "../../formik/vertical-field";
import LoadingButton from "../../loading-button/loading-button";
import { useGetCompensationType } from "../../../services/protegeme-v2/stage/useStage";
import { useSelectCompensationType } from "../../../services/protegeme-v2/stage/useStage";

const SelectTypeCompensationComponent: FunctionComponent = () => {
  const history = useHistory();
  const { eventId: reclamationId } = useParams<{ eventId: string }>();

  const compensationType = useGetCompensationType({
    reclamationId,
  });

  const selectCompensationType = useSelectCompensationType();

  const initialValues = {
    type: null,
  };

  const validationSchema = yup.object({
    type: yup
      .object({
        id: yup
          .number()
          .positive()
          .typeError("Requerido *")
          .required("Requerido *"),
        nombre: yup.string().required("Requerido *"),
      })
      .typeError("Requerido *"),
  });

  const onSubmit = async (values: FormikValues) => {
    await selectCompensationType
      .mutateAsync({
        data: {
          data: {
            ticket_id: Number(reclamationId),
            tipo_indemnizacion_id: values.type.id,
          },
        },
      })
      .then(() => {
        history.goBack();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <VerticalField
            isRequired={true}
            label={"Seleccione el tipo de indenmización"}
          >
            <Field
              size={"small"}
              fullWidth={true}
              component={Autocomplete}
              name={"type"}
              loading={compensationType.isLoading}
              options={compensationType?.data?.data || []}
              getOptionLabel={(option: any) => option?.nombre || ""}
              getOptionSelected={(option: any, value: any) =>
                option.id === value.id
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMui
                  {...params}
                  variant={"outlined"}
                  helperText={errors?.type}
                  error={touched?.type && !!errors?.type}
                />
              )}
            />
          </VerticalField>
          <div className={"flex flex-row-reverse mt-4"}>
            <LoadingButton
              type={"submit"}
              color={"primary"}
              children={"Guardar"}
              variant={"contained"}
              isLoading={selectCompensationType.isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SelectTypeCompensationComponent;
