import React from "react";

interface IProps {
  error: string;
}

const NotificationError = ({ error }: IProps) => {
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div>{error}</div>
    </div>
  );
};

export default NotificationError;
