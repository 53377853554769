import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import React from "react";
import { Formik } from "formik";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import { AiOutlineUserAdd } from "react-icons/ai";
import { When } from "react-if";
import { Link } from "react-router-dom";

import { PanelLoading } from "../../../../../components/panel/panel.loading";
import { useAuthorization } from "../../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../../providers/router/routes";
import FormField from "./components/form-fields";
import useCallRecordFormUtil from "./call-create.util";
import FormButton from "./components/form-button";

const CallRecordComponent = () => {
  const { initialValues, validationSchema, submitForm, loading } =
    useCallRecordFormUtil();
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  const authorization = useAuthorization();
  return (
    <PanelLoading
      title={
        <div className="w-full h-full flex flex-row justify-between items-center">
          <div>
            <Typography variant={"h6"} className={"m-0"}>
              {t("createCallRecord.panel.title")}
            </Typography>
          </div>
          <div className={"flex flex-row gap-2"}>
            <When condition={authorization.canClientCreate}>
              <IconButton
                edge="end"
                aria-haspopup="true"
                children={<AiOutlineUserAdd />}
                component={Link}
                to={`${routesV2.clientCreate.route()}?return=${routesV2.callRecordCreate.route()}`}
              />
            </When>
          </div>
        </div>
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        <Form>
          <FormField />
          <FormButton loading={loading} />
        </Form>
      </Formik>
    </PanelLoading>
  );
};

export default CallRecordComponent;
