import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadSignedAdmissionOrderComponent from "./upload-signed-admission-order.component";

const UploadSignedAdmissionOrderPanel: FunctionComponent<
  ILoadingComponentProps
> = ({ ...loadingProps }) => (
  <PanelLoading title={`Cargar Orden de Ingreso Firmada`} {...loadingProps}>
    <UploadSignedAdmissionOrderComponent />
  </PanelLoading>
);

export default UploadSignedAdmissionOrderPanel;
