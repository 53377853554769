import React from "react";
import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import es from "date-fns/locale/es";
import { parseISO, format } from "date-fns";
import { KeyboardDatePicker } from "formik-material-ui-pickers";

import VerticalField from "../../../../../components/formik/vertical-field";
import IconButtonMenu from "../../../../../components/icon-button-menu/icon-button-menu";
import { ICallsListStateFilters } from "../call-list.page";
import useCallListFilterFormUtil from "./filter-button.util";

type IFilterPanel = {
  state: ICallsListStateFilters;
  updateFilters: (filters: ICallsListStateFilters) => void;
  updateCurrentPage: (currentPage: number) => void;
};
const FilterButtonMenu = (props: IFilterPanel) => {
  const { t } = useTranslation();
  const { state, updateFilters, updateCurrentPage } = props;
  const { validationSchema } = useCallListFilterFormUtil();

  const clearFilters = (popupState: any) => {
    popupState.close();
    updateFilters({
      incomingPhoneNumber: "",
      identificationNumber: "",
      laneSaved: "",
      maxDate: null,
      minDate: null,
    });
    updateCurrentPage(0);
  };
  return (
    <IconButtonMenu
      variant={"popover"}
      popupId={"filter-menu"}
      icon={<IoSearch />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      body={(popupState) => (
        <div className={"w-96 p-8"}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              incomingPhoneNumber: state.incomingPhoneNumber,
              identificationNumber: state.identificationNumber,
              laneSaved: state.laneSaved,
              maxDate: state.maxDate,
              minDate: state.minDate,
            }}
            onSubmit={(values, helpers) => {
              if (values.maxDate)
                values.maxDate = parseISO(
                  format(values.maxDate!, "yyyy-MM-dd'T'23:59:59")
                );
              if (values.minDate)
                values.minDate = parseISO(
                  format(values.minDate!, "yyyy-MM-dd'T'00:00:00")
                );
              updateCurrentPage(0);
              updateFilters(values);
              helpers.resetForm();
              popupState.close();
            }}
            validationSchema={validationSchema}
          >
            <Form>
              <div className={"grid grid-cols-1 gap-2"}>
                <div>{t("callList.filter.label")}</div>
                <VerticalField label={t("callList.filter.incomingPhoneNumber")}>
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    name={"incomingPhoneNumber"}
                    variant={"outlined"}
                    component={TextField}
                  />
                </VerticalField>
                <VerticalField
                  label={t("callList.filter.identificationNumber")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    name={"identificationNumber"}
                    variant={"outlined"}
                    component={TextField}
                  />
                </VerticalField>
                <VerticalField label={t("callList.filter.laneSaved")}>
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    name={"laneSaved"}
                    variant={"outlined"}
                    component={TextField}
                  />
                </VerticalField>
                <VerticalField label={t("callList.filter.minDate")}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <Field
                      autoOk={true}
                      openTo="year"
                      size={"small"}
                      fullWidth={true}
                      name={"minDate"}
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      inputVariant={"outlined"}
                      component={KeyboardDatePicker}
                      views={["year", "month", "date"]}
                    />
                  </MuiPickersUtilsProvider>
                </VerticalField>
                <VerticalField label={t("callList.filter.maxDate")}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                    <Field
                      autoOk={true}
                      openTo="year"
                      size={"small"}
                      fullWidth={true}
                      name={"maxDate"}
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      inputVariant={"outlined"}
                      component={KeyboardDatePicker}
                      views={["year", "month", "date"]}
                    />
                  </MuiPickersUtilsProvider>
                </VerticalField>
                <div className={"flex flex-row-reverse gap-1 mt-1.5"}>
                  <Button
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    children={t("callList.filter.submitBtn")}
                  />
                  <Button
                    onClick={() => clearFilters(popupState)}
                    variant={"outlined"}
                    color={"primary"}
                    children={t("callList.filter.clearBtn")}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    />
  );
};

export default FilterButtonMenu;
