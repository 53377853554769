import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete/Autocomplete";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Value,
} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { useField } from "formik";
import React, { Fragment } from "react";
import HelpIconComponent from "../components/help-icon.component";
import InputLabelComponent from "../components/input-label.component";

import ValidationLabelComponent from "../components/validation-label.component";

type IExperienceCenterAutocompleteField<T> = {
  name: string;
  label: string;
  help?: string;
  options: T[];
  placeholder?: string;
  size?: "small" | "medium";
  groupBy?: (options: T) => string;
  disableClearable?: boolean | undefined;
  getOptionLabel?: (option: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  loading?: boolean;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value?: Value<T, undefined, undefined, undefined>,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  noOptionsText?: string;
  renderOption?: (
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
};

const AutocompleteField = <T extends any>(
  props: IExperienceCenterAutocompleteField<T>
) => {
  const { name, label, help, placeholder, ...autocompleteProps } = props;
  const [field, meta, helpers] = useField<T>(name);
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <Fragment>
      <div className={"mb-2 flex flex-row"}>
        <InputLabelComponent label={label} />
        <HelpIconComponent tooltip={help} />
      </div>
      <div className={"flex flex-col"}>
        <Autocomplete
          value={field.value}
          onChange={(event, value) => value && helpers.setValue(value)}
          renderInput={(params) => (
            <TextField
              size={autocompleteProps.size}
              error={hasError}
              variant={"outlined"}
              placeholder={placeholder}
              disabled={params.disabled}
              id={params.id}
              InputLabelProps={params.InputLabelProps}
              InputProps={params.InputProps}
              fullWidth={params.fullWidth}
              inputProps={params.inputProps}
            />
          )}
          {...autocompleteProps}
        />
        <div className={"flex flex-row justify-between"}>
          <div>
            <ValidationLabelComponent hasError={hasError} label={meta.error} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AutocompleteField;
