import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import { IDeviceListReq } from "./device-service.interface";
import { IDeviceListRes } from "./device-service.interface";

export const deviceList = (data: IDeviceListReq) => {
  const { params } = data;
  const url = protegemeV2ApiRoutes.v2.device.list();
  return protegemeV2ApiAxios.get<IDeviceListRes>(url, { params });
};
