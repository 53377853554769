import { useField } from "formik";
import React, { Fragment } from "react";
import { TextField } from "@material-ui/core";

import ValidationLabelComponent from "../components/validation-label.component";
import HelpIconComponent from "../components/help-icon.component";
import InputLabelComponent from "../components/input-label.component";
import CounterLabelComponent from "../components/counter-label.component";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

type ITextBoxField = {
  name: string;
  label: string;
  help?: string;
  limit?: number;
} & TextFieldProps;

const TextBoxField = (props: ITextBoxField) => {
  const { label, help, limit, name, ...textFieldProps } = props;
  const [field, meta] = useField<string>(name);
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <Fragment>
      <div className={"mb-2 flex flex-row"}>
        <InputLabelComponent label={label} />
        <HelpIconComponent tooltip={help} />
      </div>
      <div className={"flex flex-col"}>
        <TextField
          {...textFieldProps}
          className={"w-full"}
          variant={"outlined"}
          size={"small"}
          error={hasError}
          onBlur={(event) => field.onBlur(field.name)(event)}
          onChange={(event) => field.onChange(field.name)(event)}
        />
        <div className={"flex flex-row justify-between"}>
          <div>
            <ValidationLabelComponent hasError={hasError} label={meta.error} />
          </div>
          <div>
            <CounterLabelComponent
              counter={meta?.value?.length || 0}
              limit={limit}
              hasError={hasError}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TextBoxField;
