import React, { ReactNode } from "react";
import { TableCell, TableRow } from "@material-ui/core";

type IV1HistoryTicketCellComponent = {
  title: string;
  value: ReactNode;
};

const V1HistoryTicketCellComponent = (props: IV1HistoryTicketCellComponent) => {
  const { title, value } = props;
  if (!value) return null;
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );
};

export default V1HistoryTicketCellComponent;
