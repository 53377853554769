import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import * as yup from "yup";
import VerticalField from "../../../../components/formik/vertical-field";
import IconButtonMenu from "../../../../components/icon-button-menu/icon-button-menu";
import { IPendingCoverageFilters } from "../pending-coverage-list.container";

type IFilterButtonMenuContainer = {
  filters: IPendingCoverageFilters;
  updateFilters: (filters: IPendingCoverageFilters) => void;
};

const FilterButtonMenuContainer = (props: IFilterButtonMenuContainer) => {
  const { t } = useTranslation();
  const { filters, updateFilters } = props;

  return (
    <IconButtonMenu
      variant={"popover"}
      popupId={"filter-menu"}
      icon={<IoSearch />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      body={(popupState) => (
        <div className={"w-96 p-8"}>
          <Formik
            enableReinitialize={true}
            initialValues={{ documentNumber: filters.documentNumber }}
            onSubmit={(values, helpers) => {
              updateFilters(values);
              helpers.resetForm();
              popupState.close();
            }}
            validationSchema={yup.object().shape({
              documentNumber: yup
                .number()
                .typeError(({ label }) => t("form.errors.number", { label }))
                .label(t("clientList.filter.documentNumberInput")),
            })}
          >
            <Form>
              <div className={"grid grid-cols-1 gap-4"}>
                <div>{t("clientList.filter.label")}</div>
                <VerticalField
                  label={t("clientList.filter.documentNumberInput")}
                >
                  <Field
                    type={"text"}
                    size={"small"}
                    fullWidth={true}
                    variant={"outlined"}
                    component={TextField}
                    name={"documentNumber"}
                  />
                </VerticalField>
                <div className={"flex flex-row-reverse gap-4"}>
                  <Button
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    children={t("clientList.filter.submitBtn")}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    />
  );
};

export default FilterButtonMenuContainer;
