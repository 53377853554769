import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import UploadDeviceRequestService from "../../components/stage/upload-device-request/upload-device-request.service";

const UploadDeviceRequestPage = () => {
  return (
    <StagePageTemplate>
      <UploadDeviceRequestService />
    </StagePageTemplate>
  );
};

export default UploadDeviceRequestPage;
