import clsx from "clsx";
import { Field } from "formik";
import Table from "@material-ui/core/Table";
import NumberFormat from "react-number-format";
import React, { FunctionComponent } from "react";
import { FieldMetaProps } from "formik/dist/types";
import { makeStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { IDeviceSponsor } from "../../../types/event";
import ResolveRadio from "../inputs/causal-input/components/resolve.radio";
import ValidationLabelComponent from "../components/validation-label.component";

interface IProps {
  name: string;
  devicesToDeliver: IDeviceSponsor[];
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
}

const useStyle = makeStyles((theme) => ({
  tableError: {
    border: `0.5px solid ${theme.palette.error.light}`,
  },
}));

const DeviceTableField: FunctionComponent<IProps> = (props) => {
  const classes = useStyle();
  const { devicesToDeliver, getFieldMeta, name } = props;
  const hasError = Boolean(
    getFieldMeta(name).error && getFieldMeta(name).touched
  );
  return (
    <>
      <Table
        size={"small"}
        classes={{ root: clsx(hasError && classes.tableError) }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <span className={"text-xs"}>Nombre</span>
            </TableCell>
            <TableCell align="center">
              <span className={"text-xs"}>precio</span>
            </TableCell>
            <TableCell align="center">
              <span className={"text-xs"}>delta</span>
            </TableCell>
            <TableCell align="center">
              <span className={"text-xs"}>stock</span>
            </TableCell>
            <TableCell align="center">
              <span className={"text-xs"}>acción</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devicesToDeliver?.map((deviceToDeliver) => (
            <TableRow key={deviceToDeliver.id}>
              <TableCell align="center">
                <span className={"text-xs text-justify"}>
                  {deviceToDeliver.nombre}
                </span>
              </TableCell>
              <TableCell align="center">
                <span className={"text-xs text-justify"}>
                  <NumberFormat
                    value={deviceToDeliver.precio}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
              </TableCell>
              <TableCell align="center">
                <span className={"text-xs text-justify"}>
                  <NumberFormat
                    value={deviceToDeliver.diferencia}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
              </TableCell>
              <TableCell align="center">
                <span className={"text-xs text-justify"}>
                  {deviceToDeliver.stock}
                </span>
              </TableCell>
              <TableCell align="center">
                <Field
                  name={name}
                  type={"number"}
                  value={String(deviceToDeliver.id)}
                  component={ResolveRadio}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={"ml-4"}>
        <ValidationLabelComponent
          hasError={hasError}
          label={String(getFieldMeta(name).error)}
        />
      </div>
    </>
  );
};

export default DeviceTableField;
