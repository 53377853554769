import React from "react";
import { useTranslation } from "react-i18next";

const NotificationCallError = () => {
  const { t } = useTranslation();
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div>{t("createCallRecord.notification.error")}</div>
    </div>
  );
};

export default NotificationCallError;
