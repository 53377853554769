import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Timeline } from "@material-ui/lab";
import { useEffect } from "react";
import { useState } from "react";
import React, { FunctionComponent } from "react";
import { BsArrowsAngleContract, BsArrowsAngleExpand } from "react-icons/bs";
import { useStageState } from "../../../../../providers/stage/stage.provider";
import { ILoadingComponentProps } from "../../../../loading/loading.component";
import PanelLoading from "../../../../panel/panel.loading";
import ProgressItem from "./components/progress-item";
import { getAsyncTimelineItems } from "./util/timeline-util";

const EventStagePanel: FunctionComponent<ILoadingComponentProps> = (props) => {
  const { stage, event } = useStageState();
  const { hasError, isLoading } = stage;
  const [isCollapsed, setCollapse] = useState(true);
  const [timeline, setTimeline] = useState<Array<any>>([]);

  useEffect(() => {
    const timeline = getAsyncTimelineItems({
      event: event?.data,
      stageCurrent: stage?.data?.current,
      stagesCompleted: stage?.data?.history,
      stagesExpected: stage?.data?.expected,
    });
    setTimeline(timeline);
  }, [stage, event]);

  return (
    <PanelLoading
      hasError={hasError}
      title={
        <div className="w-full h-full flex flex-row justify-between items-center">
          <Typography variant={"h6"} className={"w-full m-0"}>
            Progreso
          </Typography>
          <div className={"flex flex-row gap-2"}>
            <IconButton
              size={"small"}
              edge="end"
              aria-haspopup="true"
              onClick={() => {
                setCollapse((prevState) => !prevState);
              }}
              children={
                isCollapsed ? (
                  <BsArrowsAngleExpand />
                ) : (
                  <BsArrowsAngleContract />
                )
              }
            />
          </div>
        </div>
      }
      isLoading={isLoading}
      width={props.width || "100%"}
      animation={props.animation}
      height={props.height || 200}
      variant={props.variant || "rect"}
      children={
        <Timeline align="left">
          {timeline.map((item: any) => (
            <ProgressItem
              key={item.id}
              name={item.name}
              collapsed={isCollapsed}
              platform={item?.platform}
              role={item?.role}
              flow={item?.flow}
              author={item?.author}
              connector={item?.connector}
              progress={item?.progress}
              startDate={item?.startDate}
              endDate={item?.endDate}
              hasNext={item?.hasNext}
              avatar={item?.avatar}
              result={item?.result}
            />
          ))}
        </Timeline>
      }
    />
  );
};

export default EventStagePanel;
