import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LoginComponent from "../../components/login/login.component";

const useStyle = makeStyles(() => ({
  acincoTypography: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800,
  },
  loginBackground: {
    backgroundImage: "url(/login-bg.svg)",
    backgroundSize: "cover",
  },
}));

const LoginPage = () => {
  const classes = useStyle();
  return (
    <div className={classes.loginBackground}>
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Paper elevation={3} className={"p-10 w-96"}>
          <div className="max-w-md w-full space-y-8">
            <LoginComponent />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default LoginPage;
