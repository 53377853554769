import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FormikHelpers } from "formik/dist/types";

import EAxios from "../../../axios/EAxios";
import LoadingButton from "../../loading-button/loading-button";
import NotificationStandard from "../../notification/notification-standard";
import { useActualizationDispatch } from "../context/actualization.provider";
import FileUploadField from "../../formik/fields/file-upload/file-upload.field";
import { toast } from "react-toastify";
import { IResponse } from "../../../types/event";
import { IActualization } from "../../../types/actualization";
import { ActualizationActions } from "../context/actualization.actions";

type IForm = {
  upload: File | null;
};

const ActualizationUploadComponent = () => {
  const { t } = useTranslation();
  const actualizationDispatch = useActualizationDispatch();
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const formInitialValues: IForm = {
    upload: null,
  };
  const formValidationSchema = yup.object({
    upload: yup.mixed().required("Requerido *"),
  });
  const formOnSubmitHandler = async (
    values: IForm,
    helpers: FormikHelpers<IForm>
  ) => {
    const { upload } = values;
    const url = EAxios.endpoints.default.actualization.upload();
    const data = { file_to_save: upload };
    const [formData, config] = EAxios.toFormData(data);

    setLoadingSubmit(true);
    const response = await EAxios.post<IResponse<IActualization>>(
      url,
      formData,
      config
    );
    setLoadingSubmit(false);

    if (response?.status === 200 || response?.status === 201) {
      helpers.resetForm();
      ActualizationActions.fetchActualizationListAll(actualizationDispatch);
      toast.info(
        <NotificationStandard
          body={t("actualization.panels.upload.notificationSuccess")}
        />
      );
    } else {
      toast.error(
        <NotificationStandard
          body={t("actualization.panels.upload.notificationError")}
        />
      );
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formOnSubmitHandler}
    >
      <Form className={"w-full"}>
        <div className={"mb-8"}>
          <FileUploadField
            name={"upload"}
            label={"Cargar actualización"}
            help={"Obligatorio - Formato XLSX"}
            accept={
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            maxFiles={1}
          />
        </div>

        <div className={"flex flex-row-reverse"}>
          <LoadingButton
            type={"submit"}
            color={"primary"}
            children={"Guardar"}
            variant={"contained"}
            isLoading={isLoadingSubmit}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default ActualizationUploadComponent;
