import React from "react";
import AutocompleteField from "../../../../../../components/formik/fields/autocomplete.field";
import { useStepContextLinkDevice } from "../../stepper/step.provider";

interface IPlanSelectField {
  name: string;
  label: string;
  help?: string;
}

const CoveragesSelectField = (props: IPlanSelectField) => {
  const { name, label, help } = props;
  const { planSelected } = useStepContextLinkDevice();
  return (
    <AutocompleteField
      name={name}
      label={label}
      help={help}
      size={"small"}
      options={planSelected?.pricingOptions || []}
      getOptionLabel={(option) =>
        `${option.payment_frequency} - ${option.payment_quantity} - $ ${String(
          option.taxed_price_unit
        )
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
      }
      getOptionSelected={(option, value) => option.id === value.id}
    />
  );
};
export default CoveragesSelectField;
