import { Typography } from "@material-ui/core";
import React from "react";
import { When } from "react-if";

interface IHelpCounter {
  counter: number;
  limit: number;
  hasError: boolean;
  hasTouched: boolean;
}

const HelpCounter = (props: IHelpCounter) => {
  const { counter, limit, hasError, hasTouched } = props;
  if (!limit || !hasTouched) return null;
  return (
    <When
      condition={hasTouched}
      children={() => (
        <Typography
          variant={"inherit"}
          children={`${counter}/${limit}`}
          className={"flex flex-row-reverse"}
          color={hasError ? "error" : "inherit"}
        />
      )}
    />
  );
};

export default HelpCounter;
