import { Divider } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { Field } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Default } from "react-if";
import { Case } from "react-if";
import { Switch } from "react-if";
import { When } from "react-if";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import CausalInput from "../../../components/formik/inputs/causal-input/causal.input";
import LoadingButton from "../../../components/loading-button/loading-button";
import NotificationStandard from "../../../components/notification/notification-standard";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../providers/router/routes";
import { ClientFullName } from "../../../services/protegeme-v2/client/util/client-format-name";
import { useCallAuditSave } from "../../../services/protegeme-v2/contac-center/call/use-call";
import { useCallFindOne } from "../../../services/protegeme-v2/contac-center/call/use-call";
import { useCallAuditList } from "../../../services/protegeme-v2/contac-center/call/use-call";
import { ICallAuditList } from "../../../services/protegeme-v2/contac-center/call/use-call.interface";

function CallAuditRegister(props: { auditData: ICallAuditList }) {
  const { t } = useTranslation();
  const { auditData } = props;
  return (
    <div className={"grid grid-cols-5 gap-4"}>
      <div className={"col-span-2 flex flex-col gap-6 p-4"}>
        <div>
          <div className={"font-medium text-xl"}>
            <ClientFullName
              firstName={auditData.user.name}
              lastName={auditData.user.second_name}
            />
          </div>
          <div className={"font-medium italic"}>
            {auditData.user.user_type.name}
          </div>
        </div>
        <div className={"flex flex-col gap-1"}>
          <div className={"font-medium"}>
            {t("callAuditRegister.panel.stages")}
          </div>
          <ul className={"list-inside list-disc"}>
            <li>Registrar llamada</li>
          </ul>
        </div>
      </div>
      <div className={"col-span-3"}>
        <Paper square={true} variant={"outlined"} className={"p-4"}>
          <div className={"flex flex-col gap-4"}>
            <div className={"text-xl font-medium text-center"}>
              {t("callAuditRegister.panel.form")}
            </div>
            {auditData.secciones.map((section) => (
              <Fragment key={section.id}>
                <When condition={section.tipo.id_system === "NOCRITICO"}>
                  <div>{t("callAuditRegister.panel.noCriticalQuestions")}</div>
                </When>
                <When condition={section.tipo.id_system === "CRITICO"}>
                  <div>{t("callAuditRegister.panel.criticalQuestions")}</div>
                </When>
                <Table size={"small"} className={"table-fixed"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t("callAuditRegister.panel.question")}
                      </TableCell>
                      <TableCell className={"w-32"}>
                        <div className={"text-center"}>
                          {t("callAuditRegister.panel.answer")}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.categorias.map((category) =>
                      category.items.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <Field
                              name={`[${auditData.user.id}].[${section.id}].[${category.id}].[${item.id}].result`}
                              validate={async (
                                value: any
                              ): Promise<string | void> => {
                                try {
                                  const schema = yup
                                    .string()
                                    .typeError(t("form.errors.required"))
                                    .required();
                                  await schema.validate(value);
                                  return undefined;
                                } catch (error: any) {
                                  return error.message;
                                }
                              }}
                              children={CausalInput}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </Fragment>
            ))}
          </div>
        </Paper>
      </div>
    </div>
  );
}

const CallAuditRegisterPage = () => {
  const authorization = useAuthorization();
  const { t } = useTranslation();
  const history = useHistory();
  const routesV2 = useRoutesV2();
  const { callId } = useParams<{ callId: string }>();

  const callFindOne = useCallFindOne({
    callId: Number(callId),
  });
  const { data, isLoading, isSuccess, isError } = useCallAuditList({
    request: { callId },
    options: { retry: false },
  });

  useEffect(() => {
    if (isError) {
      toast.error(
        <NotificationStandard
          body={t("callAuditRegister.notifications.notFound")}
        />
      );
      history.push(routesV2.reclamationListAll.route());
    }
  }, [isError, history, routesV2, t]);

  const callAuditSave = useCallAuditSave();

  const [initialValue, setInitialValue] = useState<any>(undefined);

  useEffect(() => {
    if (initialValue === undefined && data !== undefined) {
      let value: any = {};
      data?.forEach((audit) =>
        audit.secciones.forEach((section) =>
          section.categorias.forEach((category) =>
            category.items.forEach((item) => {
              _.setWith(
                value,
                `[${audit.user.id}].[${section.id}].[${category.id}].[${item.id}].result`,
                null,
                Object
              );
            })
          )
        )
      );
      setInitialValue(value);
    }
  }, [data, initialValue]);

  return (
    <AuthorizationComponent
      isAuthorized={authorization.canReclamationAuditUpdateOne}
      autoRedirect={true}
    >
      <When
        condition={callFindOne.isSuccess}
        children={() => (
          <Switch>
            <Case condition={callFindOne.data?.audited}>
              <Redirect to={routesV2.callAuditDetails.route({ callId })} />
            </Case>

            <Default
              children={() => (
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValue}
                  onSubmit={(values, formikHelpers) => {
                    const data = {
                      users: Object.entries(values).map(
                        ([id, sections]: any) => ({
                          id,
                          sections: Object.entries(sections).map(
                            ([id, categories]: any) => ({
                              id,
                              categories: Object.entries(categories).map(
                                ([id, items]: any) => ({
                                  id,
                                  items: Object.entries(items).map(
                                    ([id, item]: any) => ({
                                      id,
                                      result: item.result,
                                    })
                                  ),
                                })
                              ),
                            })
                          ),
                        })
                      ),
                    };
                    callAuditSave
                      .mutateAsync({ callId, data })
                      .then(() => {
                        toast.info(
                          <NotificationStandard
                            body={t("callAuditRegister.notifications.success")}
                          />
                        );
                        history.push(
                          routesV2.callAuditDetails.route({
                            callId,
                          })
                        );
                      })
                      .catch(() => {
                        toast.error(
                          <NotificationStandard
                            body={t("callAuditRegister.notifications.error")}
                          />
                        );
                      })
                      .finally(() => {
                        formikHelpers.resetForm();
                      });
                  }}
                >
                  <Form>
                    <div className={"p-8 gap-8"}>
                      <Paper className={"flex flex-col"}>
                        <Toolbar variant={"regular"}>
                          <div
                            className={
                              "w-full flex flex-row items-end justify-between"
                            }
                          >
                            <div className={"text-2xl font-medium"}>
                              {t("callAuditRegister.panel.title", {
                                callId,
                              })}
                            </div>
                          </div>
                        </Toolbar>
                        <Divider />
                        <Paper elevation={0} square={true}>
                          <div className={"px-6 py-6 flex flex-col gap-6"}>
                            <When
                              condition={isLoading}
                              children={() => (
                                <Skeleton
                                  variant={"rect"}
                                  height={500}
                                  width={"100%"}
                                />
                              )}
                            />
                            <When
                              condition={isSuccess}
                              children={() => (
                                <Fragment>
                                  {data!.map((auditData, index) => (
                                    <Fragment key={auditData.user.id}>
                                      <CallAuditRegister
                                        auditData={auditData}
                                      />
                                      <When
                                        condition={index + 1 < data!.length}
                                      >
                                        <hr />
                                      </When>
                                    </Fragment>
                                  ))}
                                  <div className={"flex flex-row-reverse"}>
                                    <LoadingButton
                                      type={"submit"}
                                      color={"primary"}
                                      children={t(
                                        "callAuditRegister.panel.save"
                                      )}
                                      variant={"contained"}
                                      isLoading={callAuditSave.isLoading}
                                    />
                                  </div>
                                </Fragment>
                              )}
                            />
                          </div>
                        </Paper>
                      </Paper>
                    </div>
                  </Form>
                </Formik>
              )}
            />
          </Switch>
        )}
      />
    </AuthorizationComponent>
  );
};

export default CallAuditRegisterPage;
