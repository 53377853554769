import { IconButton } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import { Else } from "react-if";
import { Then } from "react-if";
import { If } from "react-if";
import { Case } from "react-if";
import { Switch } from "react-if";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Panel from "../../../../components/panel/panel";
import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";
import { useFindOneClientDevice } from "../../../../services/protegeme-v2/client/use-client";
import FixedScrollSectionTemplate from "../../../../template/fixed-scroll-section.template";

const ClientDeviceDetailsPage = () => {
  const authorization = useAuthorization();
  const { clientId, deviceId } = useParams<any>();
  const clientDevice = useFindOneClientDevice(deviceId);
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  return (
    <AuthorizationComponent
      isAuthorized={authorization.canClientDeviceReadOne}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-4/5 2xl:w-3/5"}>
                <Panel
                  title={
                    <div className="w-full h-full flex flex-row justify-between items-center">
                      <div>
                        {t("clientDeviceDetails.panel.label", { deviceId })}
                      </div>
                      <div className={"flex flex-row gap-2"}>
                        <When
                          condition={authorization.canClientDeviceUpdateOne}
                        >
                          <IconButton
                            edge="end"
                            aria-haspopup="true"
                            children={routesV2.clientDeviceUpdate.icon}
                            component={Link}
                            to={routesV2.clientDeviceUpdate.route({
                              clientId,
                              deviceId,
                            })}
                          />
                        </When>
                      </div>
                    </div>
                  }
                >
                  <Switch>
                    <Case
                      condition={
                        clientDevice.isLoading || clientDevice.isLoading
                      }
                      children={
                        <Skeleton
                          width={"100%"}
                          height={500}
                          variant={"rect"}
                        />
                      }
                    />
                    <Case
                      condition={true}
                      children={() => (
                        <Table size={"small"}>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {t("clientDeviceDetails.panel.id")}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  {clientDevice.data!.data.id}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {t(
                                  "clientDeviceDetails.panel.deviceManufacturer"
                                )}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  {
                                    clientDevice.data!.data.dispositivo
                                      .fabricante.nombre
                                  }
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {t("clientDeviceDetails.panel.deviceModel")}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  {String(
                                    clientDevice.data!.data.dispositivo.nombre
                                  ).replaceAll("_", " ")}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {t("clientDeviceDetails.panel.imeiPrincipal")}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  {clientDevice.data!.data.imei_uno}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {t("clientDeviceDetails.panel.imeiSecondary")}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  <If
                                    condition={Boolean(
                                      clientDevice.data!.data?.imei_dos
                                    )}
                                  >
                                    <Then
                                      children={() =>
                                        clientDevice.data!.data!.imei_dos
                                      }
                                    />
                                    <Else>
                                      <Box color="error.main">
                                        {t(
                                          "clientDeviceDetails.panel.notFound"
                                        )}
                                      </Box>
                                    </Else>
                                  </If>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {t("clientDeviceDetails.panel.lanePrincipal")}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  {clientDevice.data!.data.linea_uno}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {t("clientDeviceDetails.panel.laneSecondary")}
                              </TableCell>
                              <TableCell>
                                <div className={"font-bold"}>
                                  <If
                                    condition={Boolean(
                                      clientDevice.data!.data?.linea_dos
                                    )}
                                  >
                                    <Then
                                      children={() =>
                                        clientDevice.data!.data!.linea_dos
                                      }
                                    />
                                    <Else>
                                      <Box color="error.main">
                                        {t(
                                          "clientDeviceDetails.panel.notFound"
                                        )}
                                      </Box>
                                    </Else>
                                  </If>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    />
                  </Switch>
                </Panel>
              </div>
            </div>
          </FixedScrollSectionTemplate>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ClientDeviceDetailsPage;
