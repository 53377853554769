import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import SelectTypeCompensationComponent from "./select-type-compensation.component";

const SelectTypeCompensationPanel: FunctionComponent<ILoadingComponentProps> = (
  props
) => (
  <PanelLoading
    title={"Seleccionar Tipo de Indemnización"}
    height={props.height}
    width={props.width}
    variant={props.variant}
    hasError={props.hasError}
    isLoading={props.isLoading}
    animation={props.animation}
    children={<SelectTypeCompensationComponent />}
  />
);

export default SelectTypeCompensationPanel;
