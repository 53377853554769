import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";

import { useGetReportReportsMicroservice } from "../../../services/reports-microservice/reports/use-reports-reports-microservice";
import NotificationReportError from "./notifications/notification-reports-error";

export type IFormikValue = {
  sponsorID: string;
  startDate: Date | null;
  endDate: Date | null;
  typeFile: string;
  typeReport: string;
};

const useReportCoreFormUtil = () => {
  const { t } = useTranslation();
  const { mutateAsync: getReport, isLoading: loading } =
    useGetReportReportsMicroservice();
  const initialValues: IFormikValue = {
    sponsorID: "",
    startDate: null,
    endDate: null,
    typeFile: "",
    typeReport: "",
  };

  const validationSchema = yup.object().shape({
    sponsorID: yup
      .object()
      .shape({})
      .typeError(t("form.errors.required"))
      .required(t("form.errors.required")),
    typeFile: yup
      .object()
      .shape({})
      .typeError(t("form.errors.required"))
      .required(t("form.errors.required")),
    typeReport: yup
      .object()
      .shape({})
      .typeError(t("form.errors.required"))
      .required(t("form.errors.required")),
    startDate: yup.string().nullable().required(t("form.errors.required")),
    endDate: yup.string().nullable().required(t("form.errors.required")),
  });

  const submitForm = async (
    values: IFormikValue,
    formikHelpers: FormikHelpers<IFormikValue>
  ) => {
    const sponsorID: any = values.sponsorID;
    const typeReport: any = values.typeReport;
    const typeFile: any = values.typeFile;
    getReport({
      props: {
        sponsorID: String(sponsorID.id_system?.toLowerCase()),
        report: String(typeReport.typeReport),
        type: "1",
      },
      params: {
        startDate: String(values.startDate?.toISOString().split("T")[0]),
        endDate: String(values.endDate?.toISOString().split("T")[0]),
        typeFile: String(typeFile.typeFile),
      },
    })
      .then((res) => {
        const link = document.createElement("a");
        link.href = res.data.data;
        link.click();
      })
      .catch(() => {
        toast.error(<NotificationReportError />);
      })
      .finally(() => {
        formikHelpers.resetForm();
      });
  };

  return { initialValues, validationSchema, submitForm, loading };
};

export default useReportCoreFormUtil;
