import React from "react";

import UploadReparationOrderPanel from "./upload-reparation-order.panel";
import { ILoadingComponentProps } from "../../loading/loading.component";

const UploadReparationOrderService = () => {
  const loadingProps: ILoadingComponentProps = {
    isLoading: false,
    hasError: false,
    variant: "rect",
    width: "100%",
    height: 500,
  };

  return <UploadReparationOrderPanel {...loadingProps} />;
};

export default UploadReparationOrderService;
