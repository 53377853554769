import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import UploadReparationOrderService from "../../components/stage/upload-reparation-order/upload-reparation-order.service";

const UploadReparationOrderPage = () => {
  return (
    <StagePageTemplate>
      <UploadReparationOrderService />
    </StagePageTemplate>
  );
};

export default UploadReparationOrderPage;
