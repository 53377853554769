import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import es from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "formik-material-ui-pickers";

import VerticalField from "../../../../../../components/formik/vertical-field";
import TextBoxField from "../../../../../../components/formik/fields/textBoxField";

interface Props {
  minDate: string | undefined;
  maxDate: string | undefined;
}

const FormFields = ({ minDate, maxDate }: Props) => {
  const { t } = useTranslation();
  const { getFieldMeta } = useFormikContext();
  return (
    <Fragment>
      <VerticalField isRequired={true} label={t("clientPolicyCancel.panel.ID")}>
        <Field
          type={"text"}
          size={"small"}
          fullWidth={true}
          name={"policyID"}
          variant={"outlined"}
          component={TextField}
        />
      </VerticalField>
      <VerticalField
        isRequired={true}
        label={t("clientPolicyCancel.panel.cancelationDate")}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <Field
            autoOk={true}
            openTo="year"
            size={"small"}
            fullWidth={true}
            name={"cancelationDate"}
            format="dd/MM/yyyy"
            maxDate={maxDate}
            inputVariant={"outlined"}
            component={KeyboardDatePicker}
            views={["year", "month", "date"]}
            minDate={minDate}
          />
        </MuiPickersUtilsProvider>
      </VerticalField>
      <div className="col-span-2 2xl:col-span-3 mr-3">
        <VerticalField
          isRequired={true}
          label={t("clientPolicyCancel.panel.reason")}
        >
          <TextBoxField
            name={"cancelationReason"}
            rows={3}
            limit={500}
            rowsMax={5}
            fullWidth={true}
            multiline={true}
            label={""}
            value={getFieldMeta("cancelationReason").value}
          />
        </VerticalField>
      </div>
    </Fragment>
  );
};

export default FormFields;
