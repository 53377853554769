import Grid from "@material-ui/core/Grid";
import React from "react";
import EventCausalPanel from "./components/causal/event.causal.panel";
import EventClientPanel from "./components/client/event.client.panel";
import CommentarySectionContainer from "./components/commentary-section/commentary-section.container";
import EventContinuePanel from "./components/continue/event.continue.panel";
import EventDevicePanel from "./components/device/event.device.panel";
import EventDocumentPanel from "./components/document/event.document.panel";

import EventPanel from "./components/event/event.panel";
import EventStagePanel from "./components/stage/event.stage.panel";

const EventViewComponent = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <EventContinuePanel />
          </Grid>
          <Grid item>
            <EventClientPanel />
          </Grid>
          <Grid item>
            <EventCausalPanel />
          </Grid>
          <Grid item>
            <EventDocumentPanel />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <EventPanel />
          </Grid>
          <Grid item>
            <EventDevicePanel />
          </Grid>
          <Grid item>
            <EventStagePanel />
          </Grid>
          <Grid item>
            <CommentarySectionContainer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventViewComponent;
