import { Box, List, ListSubheader, Toolbar } from "@material-ui/core";
import React from "react";
import { When } from "react-if";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../providers/router/routes";

import ListItemLink from "../list-item-link/list-item-link";

const SidebarList = () => {
  const authorization = useAuthorization();
  const routesV2 = useRoutesV2();
  return (
    <Box display={"flex"} flexDirection={"column"} className={"h-full"}>
      <Toolbar />

      <List
        dense={true}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Main
          </ListSubheader>
        }
      >
        <ListItemLink
          to={routesV2.home.path}
          primary={routesV2.home.label()}
          icon={routesV2.home.icon}
        />
      </List>
      <When condition={authorization.canHistoryTicketReadAll}>
        <List
          dense={true}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Protégeme v1
            </ListSubheader>
          }
        >
          <When condition={authorization.canHistoryTicketReadAll}>
            <ListItemLink
              to={routesV2.legacyListTickets.path}
              primary={routesV2.legacyListTickets.label()}
              icon={routesV2.legacyListTickets.icon}
            />
          </When>
        </List>
      </When>
      <When
        condition={
          authorization.canClientReadAll ||
          authorization.canClientLinkDevice ||
          authorization.canActualizationRead
        }
      >
        <List
          dense={true}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Clientes
            </ListSubheader>
          }
        >
          <When condition={authorization.canClientReadAll}>
            <ListItemLink
              to={routesV2.clientList.path}
              primary={routesV2.clientList.label()}
              icon={routesV2.clientList.icon}
            />
          </When>

          <When condition={authorization.canActualizationRead}>
            <ListItemLink
              to={routesV2.actualization.path}
              primary={routesV2.actualization.label()}
              icon={routesV2.actualization.icon}
            />
          </When>

          <When condition={authorization.canPendingCoverageReadAll}>
            <ListItemLink
              to={routesV2.pendingCoverageList.route()}
              primary={routesV2.pendingCoverageList.label()}
              icon={routesV2.pendingCoverageList.icon}
            />
          </When>
        </List>
      </When>
      <When
        condition={
          authorization.canReclamationReadAll ||
          authorization.canReclamationCreate
        }
      >
        <List
          dense={true}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Reclamaciones
            </ListSubheader>
          }
        >
          <When condition={authorization.canReclamationReadAll}>
            <ListItemLink
              to={routesV2.reclamationListAll.path}
              primary={routesV2.reclamationListAll.label()}
              icon={routesV2.reclamationListAll.icon}
            />
          </When>
          <When condition={authorization.canReclamationCreate}>
            <ListItemLink
              to={routesV2.reclamationCreate.path}
              primary={routesV2.reclamationCreate.label()}
              icon={routesV2.reclamationCreate.icon}
            />
          </When>
        </List>
      </When>
      <When condition={authorization.canCallReadAll}>
        <List
          dense={true}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Call Center
            </ListSubheader>
          }
        >
          <When condition={authorization.canCallReadAll}>
            <ListItemLink
              to={routesV2.callRecordList.path}
              primary={routesV2.callRecordList.label()}
              icon={routesV2.callRecordList.icon}
            />
          </When>
        </List>
      </When>
      <When condition={authorization.canGenerateReports}>
        <List
          dense={true}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Reportes
            </ListSubheader>
          }
        >
          <When condition={authorization.canGenerateReports}>
            <ListItemLink
              to={routesV2.reportsGenerate.path}
              primary={routesV2.reportsGenerate.label()}
              icon={routesV2.reportsGenerate.icon}
            />
          </When>
        </List>
      </When>
    </Box>
  );
};

export default SidebarList;
