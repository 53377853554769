import React from "react";
import Table from "@material-ui/core/Table";
import { TableRow } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";

import { DateFormat } from "../../../../../../util/date.util";
import { useStageState } from "../../../../../../providers/stage/stage.provider";

const CurrentTable = () => {
  const { stage } = useStageState();
  const current = stage.data?.current;
  return (
    <Table size={"small"}>
      <TableBody>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Nombre de la etapa</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>{current?.etapa.nombre}</span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Fecha de inicio</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              <DateFormat source={current?.fecha_creado} format={"PPPp"} />
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs font-bold"}>Tipo de etapa</span>
          </TableCell>
          <TableCell align="right">
            <span className={"text-xs"}>
              {current?.executed_by_system ? "Automática" : "Manual"}
            </span>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CurrentTable;
