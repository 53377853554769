import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  acincoTypography: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 800,
  },
}));

const AcincoFont: FunctionComponent = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.acincoTypography}>{children}</div>;
};

export default AcincoFont;
