import { FieldProps } from "formik/dist/Field";
import React, { FunctionComponent } from "react";
import { Box, Tooltip } from "@material-ui/core";
import RejectRadio from "./components/reject.radio";
import ResolveRadio from "./components/resolve.radio";

/**
 * ToDo improve formik input
 * @param props
 * @constructor
 */
const CausalInput: FunctionComponent<FieldProps> = (props) => {
  const { field, meta } = props;

  return (
    <div className={"flex flex-col items-center"}>
      <div className={"flex flex-row"}>
        <Tooltip title={`Rechazar`}>
          <span>
            <RejectRadio field={field} value={"false"} />
          </span>
        </Tooltip>
        <Tooltip title={`Aprobar`}>
          <span>
            <ResolveRadio field={field} value={"true"} />
          </span>
        </Tooltip>
      </div>
      {meta.error && meta.touched && (
        <Box
          className={"text-xs font-bold"}
          color={"error.main"}
          component={"span"}
        >
          {meta.error}
        </Box>
      )}
    </div>
  );
};

export default CausalInput;
