import React from "react";

import { IFindAllClientDevice } from "../../../../services/protegeme-v2/client/client-service.interface";
import Renew from "./renew/renew-section";

interface Props {
  deviceClient: IFindAllClientDevice | undefined;
}

const RenewSection = ({ deviceClient }: Props) => {
  return <Renew deviceClient={deviceClient} />;
};

export default RenewSection;
