import { protegemeV2ApiAxios } from "../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../protegeme-v2-api.routes";
import { IPendingCoverageIdentifyDeviceByIdResponse } from "./pending-coverage-interface";
import { IPendingCoverageIdentifyDeviceByIdRequest } from "./pending-coverage-interface";
import { IPendingCoverageFindByIdResponse } from "./pending-coverage-interface";
import { IPendingCoverageFindByIdRequest } from "./pending-coverage-interface";
import { IListAllResponse } from "./pending-coverage-interface";
import { IListAllRequest } from "./pending-coverage-interface";

export const pendingCoverageListAll = (props: IListAllRequest) => {
  const { filters, limit, page } = props;
  const url = protegemeV2ApiRoutes.v2.pendingCoverage.listAll();
  return protegemeV2ApiAxios.get<IListAllResponse>(url, {
    params: {
      limit,
      page: page,
      client_number_identification: filters?.identificationNumber,
    },
  });
};

export const pendingCoverageFindById = (
  props: IPendingCoverageFindByIdRequest
) => {
  const { pendingCoverageId } = props;
  const url =
    protegemeV2ApiRoutes.v2.pendingCoverage.findById(pendingCoverageId);
  return protegemeV2ApiAxios.get<IPendingCoverageFindByIdResponse>(url);
};

export const pendingCoverageIdentifyDeviceById = (
  props: IPendingCoverageIdentifyDeviceByIdRequest
) => {
  const { pendingCoverageId } = props;
  const url =
    protegemeV2ApiRoutes.v2.pendingCoverage.identifyDevice(pendingCoverageId);
  return protegemeV2ApiAxios.post<IPendingCoverageIdentifyDeviceByIdResponse>(
    url
  );
};
