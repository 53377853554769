import React from "react";
import StagePageTemplate from "../../template/stage-page.template";
import UploadPaymentSupportService from "../../components/stage/upload-payment-support/upload-payment-support.service";

const UploadPaymentSupportPage = () => {
  return (
    <StagePageTemplate>
      <UploadPaymentSupportService />
    </StagePageTemplate>
  );
};

export default UploadPaymentSupportPage;
