import React, { ReactNode } from "react";
import { Divider, Paper, Toolbar, Typography } from "@material-ui/core";

import LoadingComponent, {
  ILoadingComponentProps,
} from "../loading/loading.component";

interface IPanelPropsV2 extends ILoadingComponentProps {
  title: String | ReactNode;
  children: ReactNode;
  isLoadingTitle?: boolean;
  hasErrorTitle?: boolean;
}

export const PanelLoading = ({
  title,
  children,
  isLoadingTitle,
  hasErrorTitle,
  ...props
}: IPanelPropsV2) => {
  const isTextTitle = typeof title === "string";
  return (
    <Paper className={"h-full flex flex-col"}>
      <Toolbar variant={"dense"}>
        <LoadingComponent
          isLoading={isLoadingTitle}
          hasError={hasErrorTitle}
          variant={"text"}
          width={"50%"}
        >
          {isTextTitle ? (
            <Typography variant={"h6"} className={"m-0"}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </LoadingComponent>
      </Toolbar>
      <Divider />
      <Paper elevation={0} square={true} className={"px-6 pt-6 pb-8 h-full"}>
        <LoadingComponent {...props}>{children}</LoadingComponent>
      </Paper>
    </Paper>
  );
};

export default PanelLoading;
