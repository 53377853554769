import React from "react";
import { useTranslation } from "react-i18next";
import { TableBody, TableRow, Table, TableCell } from "@material-ui/core";
import { Switch, Case } from "react-if";
import { DateFormat } from "../../../../../util/date.util";
import { parseISO } from "date-fns";

import { IFindAllClientDevice } from "../../../../../services/protegeme-v2/client/client-service.interface";

interface Props {
  deviceClient: IFindAllClientDevice | undefined;
}

const CanceledSection = ({ deviceClient }: Props) => {
  const { t } = useTranslation();
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell className="w-6/12">
            {t("clientPolicyCanceled.panel.cancelationDate")}
          </TableCell>
          <TableCell className="w-6/12">
            <Switch>
              <Case
                condition={Boolean(deviceClient?.policy[0].fecha_cancelacion)}
              >
                <div className="font-bold ml-4">
                  <DateFormat
                    source={parseISO(
                      String(deviceClient?.policy[0].fecha_cancelacion)
                    )}
                  />
                </div>
              </Case>
              <Case condition={Boolean(deviceClient?.policy[0].end_date)}>
                <div className="font-bold ml-4">
                  <DateFormat
                    source={parseISO(String(deviceClient?.policy[0].end_date))}
                  />
                </div>
              </Case>
            </Switch>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientPolicyCanceled.panel.reason")}</TableCell>
          <TableCell>
            <Switch>
              <Case
                condition={Boolean(deviceClient?.policy[0].cancelation_reason)}
              >
                <div className="font-bold ml-4">
                  {deviceClient?.policy[0].cancelation_reason}
                </div>
              </Case>
              <Case
                condition={!Boolean(deviceClient?.policy[0].cancelation_reason)}
              >
                <div className="font-bold ml-4">
                  {t("clientPolicyCanceled.panel.reasonDefault")}
                </div>
              </Case>
            </Switch>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CanceledSection;
