import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadReparationOrderComponent from "./upload-reparation-order.component";

const UploadReparationOrderPanel: FunctionComponent<ILoadingComponentProps> = ({
  ...loadingProps
}) => (
  <PanelLoading title={`Cargar orden de reparación firmada`} {...loadingProps}>
    <UploadReparationOrderComponent />
  </PanelLoading>
);

export default UploadReparationOrderPanel;
