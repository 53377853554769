import React from "react";
import { useTranslation } from "react-i18next";

const NotificationDeviceNotLinked = () => {
  const { t } = useTranslation();
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div>{t("clientLinkDevice.notifications.deviceNotLinked.body")}</div>
    </div>
  );
};

export default NotificationDeviceNotLinked;
