import * as yup from "yup";
import { Form, Formik, FormikValues } from "formik";
import { useHistory, useParams } from "react-router-dom";
import React, { FunctionComponent, useState } from "react";

import { useAppDispatch } from "../../../redux/store";
import LoadingButton from "../../loading-button/loading-button";
import { ICausalResult, ISaveCausalByStage } from "../../../types/event";
import CausalTableField from "../../formik/fields/causal-table-field/causal-table.field";
import { SaveCausalByStageActions } from "../../../redux/causal/save-causal-by-stage.slice";

export interface IManualVerificationComponentProps {
  causals: ICausalResult[];
}

const ManualVerificationComponent: FunctionComponent<
  IManualVerificationComponentProps
> = (props) => {
  const { causals } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const initialValues = {
    causals: causals?.map((value) => ({ id: value.id, resultado: null })) || [],
  };
  const validationSchema = yup.object({
    causals: yup.array().of(
      yup.object({
        id: yup.number().required(),
        resultado: yup
          .string()
          .nullable()
          .typeError("Requerido *")
          .required("Requerido *"),
      })
    ),
  });
  const onSubmit = (values: FormikValues) => {
    const formatValues = values.causals.map((values: any) => ({
      ...values,
      resultado: values.resultado === "true",
    }));
    const requestData: ISaveCausalByStage = {
      body: {
        etapa_orden_id: stageId,
        causales: formatValues,
      },
      params: {
        eventId,
      },
    };
    dispatch(
      SaveCausalByStageActions.saveCausalByStageRequest(
        requestData,
        history,
        setLoadingSubmit
      )
    );
  };

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      <Form>
        <div className={"mb-5"}>
          <label htmlFor="causales">
            Seleccionar causales que apliquen a la orden de servicio:
          </label>
          <CausalTableField causals={causals} />
        </div>

        <div className={"flex flex-row-reverse"}>
          <LoadingButton
            isLoading={isLoadingSubmit}
            color={"primary"}
            variant={"contained"}
            type={"submit"}
            children={"Guardar"}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default ManualVerificationComponent;
