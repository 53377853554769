import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import useMethods from "use-methods";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { useReclamationFind } from "../../../services/protegeme-v2/reclamation/use-reclamation";
import useTableListPage from "../../../template/table-list-page/hooks/use-table-list-page";
import { ITableListPageState } from "../../../template/table-list-page/table-list-page-template";
import { getTableListMethods } from "../../../template/table-list-page/table-list-page-template";
import TableListPageTemplate from "../../../template/table-list-page/table-list-page-template";
import ReclamationListFilterMenuContainer from "./components/reclamation-list-filter-menu-container";
import ReclamationListTable from "./components/reclamation-list-table";

export interface IReclamationListFilters {
  email: string;
  reclamationId: string;
  phoneNumber: string;
  identificationNumber: string;
  sponsor: Array<string>;
  status: Array<string>;
}

type IState = ITableListPageState<IReclamationListFilters>;

const initialState: IState = {
  filters: {
    email: "",
    reclamationId: "",
    phoneNumber: "",
    identificationNumber: "",
    sponsor: [],
    status: [],
  },
  count: 0,
  currentPage: 0,
  perPage: 25,
  perPageOptions: [25, 50, 100],
};

const methods = (state: IState) => ({
  ...getTableListMethods(state),
});

export const ReclamationListPage = () => {
  const { t } = useTranslation();
  const authorization = useAuthorization();
  const [state, stateMethods] = useMethods(methods, initialState);
  const { updateCount, updateFilters, updateCurrentPage } = stateMethods;

  const reclamationFindQuery = useReclamationFind({
    request: {
      filters: {
        phoneNumber: state.filters.phoneNumber,
        email: state.filters.email,
        reclamationId: state.filters.reclamationId,
        identificationNumber: state.filters.identificationNumber,
        sponsor: state.filters.sponsor.map((d: any) => d.id_system),
        status: state.filters.status.map((d: any) => d.id_system),
      },
      pagination: {
        pageNumber: state.currentPage + 1,
        pageSize: state.perPage,
      },
    },
  });

  const { refetch, data: reclamations, isLoading } = reclamationFindQuery;

  useTableListPage({
    state,
    refetch,
    count: reclamations?.count,
    updateCount,
  });
  return (
    <AuthorizationComponent
      isAuthorized={authorization.canReclamationReadAll}
      autoRedirect={true}
    >
      <TableListPageTemplate
        title={
          <div className="w-full h-full flex flex-row justify-between items-center">
            <div>{t("reclamationList.panel.label")}</div>
            <ReclamationListFilterMenuContainer
              filters={state.filters}
              updateFilters={updateFilters}
              updateCurrentPage={updateCurrentPage}
            />
          </div>
        }
        isLoading={isLoading}
        isEmpty={_.isEmpty(reclamations?.results)}
        table={
          <When
            condition={Boolean(reclamations?.results)}
            children={() => (
              <ReclamationListTable data={reclamations!.results} />
            )}
          />
        }
        tableListMethods={stateMethods}
        tableListState={state}
      />
    </AuthorizationComponent>
  );
};
