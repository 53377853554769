import * as yup from "yup";
import { to } from "await-to-js";
import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FormikHelpers } from "formik/dist/types";
import { AxiosError, AxiosResponse } from "axios";
import { Field, Form, Formik, FormikValues } from "formik";

import EAxios from "../../axios/EAxios";
import LoginHeader from "./components/login-header";
import { ICredentials, useLogin } from "../../hooks/useLogin";
import MaterialTextField from "../formik/fields/material-text.field";
import { useAuthReportsMicroservice } from "../../services/reports-microservice/auth/use-auth-reports-microservice";

const LoginComponent = () => {
  const { mutateAsync: LoginReportsMicroservice } =
    useAuthReportsMicroservice();
  const { login, loginReportsMicroservice } = useLogin();
  const history = useHistory();

  const initialValues: FormikValues = {
    username: "",
    password: "",
    remember: false,
  };

  const validationSchema = yup.object({
    username: yup.string().email().required("Requerido *"),
    password: yup.string().required("Requerido *"),
    remember: yup.boolean().required(),
  });

  const handlerLoginError = (
    error: AxiosError,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (error.response?.status === 401) {
      helpers.setFieldError("username", "Invalid email");
      helpers.setFieldError("password", "Invalid password");
    }
  };

  const onSubmit = async (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const { remember, ...dataValues } = values;
    const url = EAxios.endpoints.default.user.login();
    const data = { ...dataValues, grant_type: "password" };
    const [error, response] = await to<AxiosResponse<ICredentials>, AxiosError>(
      EAxios.post(url, data)
    );
    const res = await LoginReportsMicroservice({
      username: values.username,
      password: values.password,
      grant_type: "password",
    });
    if (error) return handlerLoginError(error, helpers);
    loginReportsMicroservice(res.data, remember);
    login(response!.data, remember);
    history.push("/");
  };

  return (
    <Fragment>
      <LoginHeader />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form className="mt-8 space-y-6" action="#" method="POST">
          <div className="rounded-md">
            <div className={"mb-5"}>
              <MaterialTextField
                label={"Email"}
                name={"username"}
                className={"w-full"}
                variant={"outlined"}
              />
            </div>
            <div>
              <MaterialTextField
                name={"password"}
                type={"password"}
                label={"Contraseña"}
                className={"w-full"}
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Field type="checkbox" name="remember" id="remember_me" />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-gray-900"
              >
                Recuérdame
              </label>
            </div>
            <div className="text-sm">
              <a
                href={"/login"}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>
          <div className={"flex justify-center"}>
            <Button
              variant={"contained"}
              color={"primary"}
              type="submit"
              children={"Ingresar"}
            />
          </div>
        </Form>
      </Formik>
    </Fragment>
  );
};

LoginComponent.propTypes = {};

export default LoginComponent;
