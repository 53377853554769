import React, { FunctionComponent } from "react";

import { PanelLoading } from "../../panel/panel.loading";
import { ILoadingComponentProps } from "../../loading/loading.component";
import UploadDiagnosticComponent from "./upload-device-request.component";

type panelType = FunctionComponent<ILoadingComponentProps>;

const UploadDeviceRequestPanel: panelType = (props) => (
  <PanelLoading
    title={`Cargar Resultado de la Solicitud`}
    height={props.height}
    width={props.width}
    variant={props.variant}
    hasError={props.hasError}
    isLoading={props.isLoading}
    animation={props.animation}
  >
    <UploadDiagnosticComponent />
  </PanelLoading>
);

export default UploadDeviceRequestPanel;
