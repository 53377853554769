import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ICausalByStageState } from "../../../types/event";
import { RootState, useAppDispatch } from "../../../redux/store";
import ManualVerificationPanel from "./manual-verification.panel";
import { ILoadingComponentProps } from "../../loading/loading.component";
import { CausalByStageActions } from "../../../redux/causal/causal-by-stage.slice";

const ManualVerificationService = () => {
  const { stageId } = useParams<{ stageId: string }>();
  const dispatch = useAppDispatch();
  const { causals, hasError, isLoading } = useSelector<
    RootState,
    ICausalByStageState
  >((state) => state.causalByStage);
  const loadingProps: ILoadingComponentProps = {
    isLoading,
    hasError,
    variant: "rect",
    width: "100%",
    height: 500,
  };

  useEffect(() => {
    dispatch(CausalByStageActions.getCausalByStageRequest(stageId));
  }, [dispatch, stageId]);

  return <ManualVerificationPanel causals={causals} {...loadingProps} />;
};

export default ManualVerificationService;
