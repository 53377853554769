import { parseISO } from "date-fns";
import { format } from "date-fns";
import _ from "lodash";
import { IStage } from "../../../../../../types/event";
import { IStageCurrent } from "../../../../../../types/event";
import { IEvento } from "../../../../../../types/event";
import { IStageCompleted } from "../../../../../../types/event";

interface IGetProgress {
  eventIsFinished: boolean;
  isCurrentStage: boolean;
  stageHasCompleted: boolean;
}

const getItemProgress = (params: IGetProgress) => {
  const { eventIsFinished, isCurrentStage, stageHasCompleted } = params;
  if (eventIsFinished) {
    return "success";
  }
  if (isCurrentStage) {
    return "inProgress";
  }
  if (stageHasCompleted) {
    return "success";
  }
  return "pending";
};

interface IGetItemConnector {
  stageCompletedIsApproved: boolean;
  stageCompletedIsRejected: boolean;
  stageIsRejectType: boolean;
}

const getItemConnector = (params: IGetItemConnector) => {
  const {
    stageCompletedIsApproved,
    stageCompletedIsRejected,
    stageIsRejectType,
  } = params;
  if (stageIsRejectType || stageCompletedIsRejected) {
    return "error";
  }
  if (stageCompletedIsApproved) {
    return "success";
  }
  return "default";
};

interface ITimelineItemParams {
  event: IEvento;
  stagesExpected: IStage[];
  stagesCompleted: IStageCompleted[];
  stageCurrent: IStageCurrent;
}

const getTimelineItems = (params: ITimelineItemParams) => {
  const { event, stagesExpected, stagesCompleted, stageCurrent } = params;

  const eventIsFinished = event.estado.id_system === "FINALIZADO";

  const timelineItems = stagesExpected.map((stageExpected, index) => {
    const stageCompletedData = stagesCompleted.find(
      (stage) => stage.id === stageExpected.id
    );

    const isCurrentStage = stageCurrent.etapa.id === stageCompletedData?.id;
    const stageHasCompleted = Boolean(stageCompletedData?.resultado);

    const id = stageExpected.id;
    const name = stageExpected.nombre;
    const avatar = stageExpected.executed_by_system ? "system" : "analyst";
    const platform = index === 0 ? event.plataforma_created?.name : undefined;
    const progress = getItemProgress({
      eventIsFinished,
      stageHasCompleted,
      isCurrentStage,
    });
    const connector = getItemConnector({
      stageCompletedIsApproved:
        stageCompletedData?.resultado.nombre === "Aprobado",
      stageCompletedIsRejected:
        stageCompletedData?.resultado.nombre === "Rechazado",
      stageIsRejectType: stageExpected.tipo_flujo.id_system === "RECHAZO",
    });

    if (progress === "inProgress" || progress === "pending") {
      const timelineItem = {
        id,
        name,
        avatar,
        progress,
        platform,
      };
      return timelineItem;
    }

    const author = stageCompletedData?.user_analyzed?.name;

    const role =
      stageCompletedData?.user_analyzed?.name !== "Protegeme"
        ? stageCompletedData?.user_analyzed?.user_type?.name
        : undefined;

    const startDate = stageCompletedData?.fecha_inicio
      ? format(parseISO(stageCompletedData?.fecha_inicio), "Pp")
      : undefined;

    const endDate = stageCompletedData?.fecha_finalizado
      ? format(parseISO(stageCompletedData?.fecha_finalizado), "Pp")
      : undefined;

    const timelineItem = {
      id,
      name,
      avatar,
      progress,
      platform,
      connector,
      author,
      role,
      startDate,
      endDate,
    };
    return timelineItem;
  });

  const lastStage = {
    id: -1,
    name: "Terminar Reclamación",
    progress: eventIsFinished ? "success" : "pending",
    result: eventIsFinished
      ? _.last(stagesCompleted)?.tipo_flujo.id_system === "RECHAZO"
        ? "Rechazado"
        : "Aprobado"
      : undefined,
    hasNext: false,
  };

  return [...timelineItems, lastStage];
};

interface IGetAsyncItemConnector {
  event?: IEvento;
  stagesExpected?: IStage[];
  stagesCompleted?: IStageCompleted[];
  stageCurrent?: IStageCurrent;
}

export const getAsyncTimelineItems = (params: IGetAsyncItemConnector) => {
  const { event, stagesExpected, stagesCompleted, stageCurrent } = params;

  if (!event || !stagesExpected || !stagesCompleted || !stageCurrent) {
    const timelineItems: Array<any> = [];
    return timelineItems;
  }

  const timelineItems = getTimelineItems({
    event,
    stageCurrent,
    stagesCompleted,
    stagesExpected,
  });
  return timelineItems;
};
