import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import UploadSignedPurchaseOrderService from "../../components/stage/upload-signed-purchase-order/upload-signed-purchase-order.service";

const UploadSignedPurchaseOrderPage = () => {
  return (
    <StagePageTemplate>
      <UploadSignedPurchaseOrderService />
    </StagePageTemplate>
  );
};

export default UploadSignedPurchaseOrderPage;
