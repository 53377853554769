import React from "react";
import { useFormikContext } from "formik";

import EAxios from "../../../../../axios/EAxios";
import { useStepContext } from "../../steps/step.provider";
import {
  IClientDevicePlan,
  IDispositivoCliente,
} from "../../../../../types/event";
import AutocompleteField from "../../../../formik/fields/autocomplete.field";

type IDeviceSelectField = {
  name: string;
  label: string;
  conditionalName: string;
  help?: string;
};

const DeviceSelectField = (props: IDeviceSelectField) => {
  const { name, label, conditionalName, help } = props;
  const { devices, setPlans } = useStepContext();
  const { setFieldValue } = useFormikContext();

  const updatePlanList = (value: IDispositivoCliente) => {
    const url = EAxios.endpoints.default.plan.listAllByClientDevice(value.id);
    EAxios.get<IClientDevicePlan[]>(url).then((response) =>
      setPlans(response.data)
    );
  };

  const clearPlan = () => {
    setFieldValue(conditionalName, null);
    setPlans([]);
  };

  const handleOnChangeEvent = (
    value: IDispositivoCliente | undefined | null
  ) => {
    setFieldValue(name, value);
    if (!value) clearPlan();
    else updatePlanList(value);
  };

  return (
    <AutocompleteField
      name={name}
      help={help}
      label={label}
      size={"small"}
      options={devices}
      getOptionLabel={(option) =>
        `${option.dispositivo.nombre} - IMEI ${option.imei_uno} - Línea ${option.linea_uno}`
      }
      getOptionSelected={(option, value) => option.id === value.id}
      onInputChange={() => clearPlan()}
      onChange={(_event, value) => handleOnChangeEvent(value)}
    />
  );
};

export default DeviceSelectField;
