import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import TablePair from "../../../../../components/table-pair/table-pair";

const CallDetailsSection = (props: any) => {
  const { t } = useTranslation();
  const { callDetails } = props;
  const values = [
    {
      key: t("callDetails.panel.call.details.reason"),
      value: (
        <div className="font-bold overflow-ellipsis overflow-hidden">
          {_.isEmpty(callDetails.reasons)
            ? t("callDetails.panel.call.empty")
            : callDetails.reasons.map((d: any) => d.name)}
        </div>
      ),
    },
    {
      key: t("callDetails.panel.call.details.product"),
      value: callDetails?.product?.nombre || (
        <Box color={"error.main"} className={"font-bold"}>
          {t("callDetails.panel.call.empty")}
        </Box>
      ),
    },
    {
      key: t("callDetails.panel.call.details.type"),
      value: (
        <div className="font-bold overflow-ellipsis overflow-hidden">
          {_.isEmpty(callDetails.types)
            ? t("callDetails.panel.call.empty")
            : callDetails.types.map((d: any) => d.name)}
        </div>
      ),
    },
    {
      key: t("callDetails.panel.call.details.observations"),
      value: callDetails.comments,
    },
  ];
  return <TablePair data={values} valueClassName={"font-bold"} />;
};

export default CallDetailsSection;
