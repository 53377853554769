import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { fieldToTextField } from "formik-material-ui";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomAutcomplete = withStyles({
  tag: {
    height: 28,
    margin: 0,
  },
})(Autocomplete);

const AutocompleteSelectChips = ({ textFieldProps, ...props }: any) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name }: any = field;

  return (
    <CustomAutcomplete
      {...props}
      {...field}
      onChange={(_, value) => setFieldValue(name, value)}
      onBlur={() => setTouched({ [name]: true })}
      renderInput={(props) => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

export default AutocompleteSelectChips;
