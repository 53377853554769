import { Box } from "@material-ui/core";
import { parseISO } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { If } from "react-if";
import { Else } from "react-if";
import { Then } from "react-if";
import TablePair from "../../../../components/table-pair/table-pair";
import { IClientFindOne } from "../../../../services/protegeme-v2/client/client-service.interface";
import { DateFormat } from "../../../../util/date.util";

interface IClientSection {
  client: IClientFindOne;
}

const ClientSection = (props: IClientSection) => {
  const { client } = props;
  const { t } = useTranslation();
  const values = [
    { key: t("clientDetails.panel.client.id"), value: client.id },
    { key: t("clientDetails.panel.client.firstName"), value: client.name },
    {
      key: t("clientDetails.panel.client.lastName"),
      value: client.second_name,
    },
    {
      key: t("clientDetails.panel.client.documentType"),
      value: client.identification_type.name,
    },
    {
      key: t("clientDetails.panel.client.documentNumber"),
      value: client.identification_number,
    },
    {
      key: t("clientDetails.panel.client.documentExpeditionDate"),
      value: (
        <If condition={Boolean(client.identification_expedition_date)}>
          <Then
            children={() => (
              <DateFormat
                source={parseISO(client.identification_expedition_date!)}
              />
            )}
          />
          <Else>
            <Box color={"error.main"} className={"font-bold"}>
              {t("clientDetails.panel.client.empty")}
            </Box>
          </Else>
        </If>
      ),
    },
    {
      key: t("clientDetails.panel.client.email"),
      value: (
        <div className="font-bold overflow-ellipsis overflow-hidden">
          {client.email}
        </div>
      ),
    },
    { key: t("clientDetails.panel.client.gender"), value: client.gender.name },
    {
      key: t("clientDetails.panel.client.birthday"),
      value: (
        <If condition={Boolean(client.birthday)}>
          <Then
            children={() => <DateFormat source={parseISO(client.birthday!)} />}
          />
          <Else>
            <Box color={"error.main"} className={"font-bold"}>
              {t("clientDetails.panel.client.empty")}
            </Box>
          </Else>
        </If>
      ),
    },
    {
      key: t("clientDetails.panel.client.address"),
      value: (
        <If condition={Boolean(client.address)}>
          <Then
            children={() => (
              <div className="font-bold overflow-ellipsis overflow-hidden">
                {client.address}
              </div>
            )}
          />
          <Else>
            <Box color={"error.main"} className={"font-bold"}>
              {t("clientDetails.panel.client.empty")}
            </Box>
          </Else>
        </If>
      ),
    },
  ];
  return <TablePair data={values} valueClassName={"font-bold"} />;
};

export default ClientSection;
