import { FormControlLabel, FormHelperText } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { TextField as TextFieldMui } from "@material-ui/core";
import { ErrorMessage } from "formik";
import { Field } from "formik";
import { useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import { TextField } from "formik-material-ui";
import { Autocomplete } from "formik-material-ui-lab";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import VerticalField from "../../../../../../components/formik/vertical-field";
import { useReasonList } from "../../../../../../services/protegeme-v2/contac-center/call/use-call";
import { useTypeCallList } from "../../../../../../services/protegeme-v2/contac-center/call/use-call";
import { usePlanList } from "../../../../../../services/protegeme-v2/plan/use-plan";
import { IFormikValue } from "../call-create.interface";
import ClientFormField from "./client-field-select";

const FormFields = () => {
  const { t } = useTranslation();
  const { errors, touched, isSubmitting } = useFormikContext<IFormikValue>();
  const plansQuery = usePlanList();
  const reasonsQuery = useReasonList();
  const typesQuery = useTypeCallList();

  return (
    <Fragment>
      <div className={"grid grid-cols-2 gap-4"}>
        <div className={"col-span-2"}>
          <VerticalField
            inputClassName={"pl-3"}
            help={t("createReclamation.clientSelect.help")}
            isRequired={true}
            label={t("createCallRecord.form.fields.identificationNumber.label")}
          >
            <ClientFormField name={"client"} autoFocus={true} />
          </VerticalField>
        </div>
        <div className={"col-span-2"}>
          <VerticalField
            inputClassName={"pl-3"}
            isRequired={true}
            label={t("createCallRecord.form.fields.motive.label")}
          >
            <Field
              size={"small"}
              fullWidth={true}
              name={"motive"}
              variant={"outlined"}
              component={Autocomplete}
              loading={reasonsQuery.isLoading}
              options={
                reasonsQuery.data?.filter((d) => d.is_active === true) || []
              }
              getOptionSelected={(option: any, value: any) => option !== value}
              getOptionLabel={(option: any) => option?.name || ""}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMui
                  {...params}
                  variant={"outlined"}
                  helperText={touched?.product && errors.product}
                  error={touched?.motive && !!errors.motive}
                />
              )}
            />
          </VerticalField>
        </div>
        <VerticalField
          isRequired={true}
          inputClassName={"pl-3"}
          label={t("createCallRecord.form.fields.type.call")}
        >
          <Field component={RadioGroup} name="type">
            <div className={"flex flex-row"}>
              {typesQuery?.data?.map((type) => (
                <FormControlLabel
                  key={type.id}
                  value={String(type.id)}
                  label={type.name}
                  className={"w-100"}
                  control={
                    <Radio
                      size={"small"}
                      className={"w-100"}
                      disabled={isSubmitting}
                    />
                  }
                  disabled={isSubmitting}
                />
              ))}
            </div>
            <FormHelperText className="Mui-error MuiFormHelperText-contained MuiFormHelperText-marginDense MuiFormHelperText-root">
              <ErrorMessage name="type" />
            </FormHelperText>
          </Field>
        </VerticalField>
        <VerticalField
          inputClassName={"pl-3"}
          label={t("createCallRecord.form.fields.product.label")}
        >
          <Field
            size={"small"}
            fullWidth={true}
            name={"product"}
            variant={"outlined"}
            component={Autocomplete}
            loading={plansQuery.isLoading}
            options={plansQuery.data?.data || []}
            groupBy={(option: any) => option?.sponsor?.nombre}
            getOptionLabel={(option: any) => option?.nombre || ""}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMui
                {...params}
                className={""}
                variant={"outlined"}
                helperText={touched?.product && errors.product}
                error={touched?.product && !!errors.product}
              />
            )}
          />
        </VerticalField>
        <VerticalField
          isRequired={true}
          inputClassName={"pl-3"}
          label={t("createCallRecord.form.fields.numberCaller.label")}
        >
          <Field
            type={"text"}
            size={"small"}
            fullWidth={true}
            name={"number_caller"}
            variant={"outlined"}
            component={TextField}
          />
        </VerticalField>
        <VerticalField
          isRequired={true}
          inputClassName={"pl-3"}
          label={t("createCallRecord.form.fields.laneSaved.label")}
        >
          <Field
            type={"text"}
            size={"small"}
            fullWidth={true}
            name={"number_saved"}
            variant={"outlined"}
            component={TextField}
          />
        </VerticalField>
        <div className={"col-span-2"}>
          <VerticalField
            isRequired={true}
            inputClassName={"pl-3"}
            label={t("createCallRecord.form.fields.observations.label")}
          >
            <Field
              type={"text"}
              size={"small"}
              fullWidth={true}
              name={"observations"}
              variant={"outlined"}
              component={TextField}
              rows={3}
              limit={1000}
              rowsMax={100}
              multiline={true}
            />
          </VerticalField>
        </div>
      </div>
    </Fragment>
  );
};

export default FormFields;
