import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../../store";
import EAxios from "../../../axios/EAxios";
import Configuration from "../../../config/Configuration";
import { IEventStageByEventState, IStageCompleted } from "../../../types/event";

const initialState: IEventStageByEventState = {
  eventStage: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "event_stage_by_event",
  initialState,
  reducers: {
    getEventStageByEventLoading: (state: IEventStageByEventState) => {
      state.eventStage = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getEventStageByEventSuccess: (
      state: IEventStageByEventState,
      action: PayloadAction<IStageCompleted[]>
    ) => {
      state.eventStage = action.payload;
      state.isLoading = false;
    },
    getEventStageByEventFailed: (state: IEventStageByEventState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getEventStageByEventRequest =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(EventStageByEventActions.getEventStageByEventLoading());
      const hostname = Configuration.api.hostname;
      const response = await EAxios.get<IStageCompleted[]>(
        `${hostname}/api/v1/etapa/historico/ticket/${eventId}`
      );
      dispatch(
        EventStageByEventActions.getEventStageByEventSuccess(response.data)
      );
    } catch (error) {
      dispatch(EventStageByEventActions.getEventStageByEventFailed());
    }
  };

export const EventStageByEventActions = {
  ...actions,
  getEventStageByEventRequest,
};
export const EventStageByEventReducer = reducer;
