import EAxios from "../axios/EAxios";
import { IDocumentSignUrlResult } from "../types/event";

export const getDocumentSignUrl = async (
  documentId: string | number
): Promise<string> => {
  const url = EAxios.endpoints.default.document.getSignedUrl(
    String(documentId)
  );
  const response = await EAxios.get<IDocumentSignUrlResult>(url);
  return response.data.url_document_temp;
};
