import React, { Fragment } from "react";

import { useStepContextLinkDevice } from "./step.provider";
import ImeiStepComponent from "./steps/imei-step.component";
import CoverageStepComponent from "./steps/coverage-step.component";
import DetailsStepComponent from "./steps/details-step.component";

const StepComponentLinkDevice = () => {
  const { currentStep } = useStepContextLinkDevice();
  const firstStep = <ImeiStepComponent />;
  const secondStep = <CoverageStepComponent />;
  const thirdStep = <DetailsStepComponent />;
  return (
    <Fragment>
      {currentStep === 0 && firstStep}
      {currentStep === 1 && secondStep}
      {currentStep === 2 && thirdStep}
    </Fragment>
  );
};

export default StepComponentLinkDevice;
