import axios from "axios";

import { useLogin } from "../../hooks/useLogin";
import Configuration from "../../config/Configuration";

export const protegemeV2ApiAxios = axios.create();

protegemeV2ApiAxios.interceptors.request.use((config) => {
  const { isLogin, credentials } = useLogin();
  if (isLogin) {
    config.headers.Authorization = `${credentials?.type} ${credentials?.token}`;
  }
  return config;
});

protegemeV2ApiAxios.interceptors.request.use((config) => {
  const baseURL = Configuration.api.hostname;
  return { baseURL, ...config };
});
