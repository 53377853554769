import React from "react";
import { Typography } from "@material-ui/core";

type ICounterLabelComponent = {
  counter?: number;
  limit?: number;
  hasError?: boolean;
};

const CounterLabelComponent = (props: ICounterLabelComponent) => {
  const { counter, limit, hasError } = props;
  if (!limit) return null;
  return (
    <Typography variant={"subtitle2"} color={hasError ? "error" : "inherit"}>
      {counter}/{limit}
    </Typography>
  );
};

export default CounterLabelComponent;
