import React from "react";
import { Button, Icon } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { useStageState } from "../../../../../../providers/stage/stage.provider";

const continueMap = new Map<string, string>([
  ["APROBACIONMANUAL", "manual-verification"],
  ["CARGARCARTARECHAZOFIRMADA", "rejection-order"],
  ["CARGARODENREPARACIONFIRMADA", "reparation-order"],
  ["REGISTROPREINGRESO", "register-maintenance-ingress"],
  ["CARGARDIAGNOSTICO", "upload-diagnostic"],
  ["CARGARORDENENTREGA", "upload-delivery-order"],
  ["SELECCIONEQUIPOCDE", "select-device-to-deliver"],
  ["CARGARORDENABONOFIRMADA", "upload-signed-payment-order"],
  ["REGISTRARPAGOABONO", "upload-payment-support"],
  ["CARGARRESULTADOSOLICITUD", "upload-device-request"],
  ["CARGARORDENCOMPRAFIRMADA", "upload-signed-purchase-order"],
  ["CARGARORDENINGRESO", "upload-signed-admission-order"],
  ["CARGARFORMATOTRASPASODILIGENCIADO", "upload-signed-transfer-format"],
  ["SELECCIONARTIPOINDEMNIZACION", "select-type-compensation"],
  ["REGISTRARPAGOBONO", "upload-payment-support"],
  ["CARGARORDENBONOFIRMADA", "upload-signed-payment-order"],
]);

const NextButton = () => {
  const location = useLocation();
  const { stage, event } = useStageState();
  const currentRoute = location.pathname;

  const currentStage = stage.data?.current;
  const isEventFinish = event.data?.estado.id_system === "FINALIZADO";
  const isCurrentStageIsExecutedBySystem = Boolean(
    currentStage?.executed_by_system
  );

  if (isEventFinish || isCurrentStageIsExecutedBySystem) return null;

  const stageIdSystem = String(currentStage?.etapa.id_system);
  const stageId = currentStage?.id;

  return (
    <div className={"flex flex-row-reverse mt-6"}>
      <Button
        color="primary"
        component={Link}
        variant="contained"
        children={"Continuar"}
        endIcon={<Icon>send</Icon>}
        to={`${currentRoute}/stage/${stageId}/${continueMap.get(
          stageIdSystem
        )}`}
      />
    </div>
  );
};

export default NextButton;
