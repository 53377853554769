import React from "react";
import { Box, Typography } from "@material-ui/core";

type IValidationLabel = {
  label?: string;
  hasError: boolean;
};

const ValidationLabelComponent = (props: IValidationLabel) => {
  const { hasError, label } = props;
  if (!label || !hasError) return null;
  return (
    <Box className={"text-xs font-bold"} color={"error.main"}>
      <Typography variant={"subtitle2"}>{label}</Typography>
    </Box>
  );
};

export default ValidationLabelComponent;
