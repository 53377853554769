import React from "react";
import { Dispatch } from "react";
import { ReactNode } from "react";
import { useReducer } from "react";
import { useContext } from "react";
import { createContext } from "react";

import { IState } from "../../../../types/v1HistoryTicket";
import { IActions } from "../../../../types/v1HistoryTicket";
import { initialValue, reducer } from "./v1-history-list.reducer";
import { IV1HistoryListStateContext } from "../../../../types/v1HistoryTicket";
import { IV1HistoryListSetStateContext } from "../../../../types/v1HistoryTicket";

const V1HistoryListStateContext =
  createContext<IV1HistoryListStateContext>(undefined);

const V1HistoryListSetStateContext =
  createContext<IV1HistoryListSetStateContext>(undefined);

type IV1HistoryListProvider = { children: ReactNode };

export const V1HistoryListProvider = (props: IV1HistoryListProvider) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialValue);
  return (
    <V1HistoryListStateContext.Provider value={state}>
      <V1HistoryListSetStateContext.Provider value={dispatch}>
        {children}
      </V1HistoryListSetStateContext.Provider>
    </V1HistoryListStateContext.Provider>
  );
};

export const useV1HistoryListState = (): IState => {
  const state = useContext(V1HistoryListStateContext);
  if (typeof state === "undefined") {
    throw new Error(
      "useV1HistoryListState must be used within a V1HistoryListProvider"
    );
  }
  return state;
};

export const useV1HistoryListDispatch = (): Dispatch<IActions> => {
  const dispatch = useContext(V1HistoryListSetStateContext);
  if (typeof dispatch === "undefined") {
    throw new Error(
      "useV1HistoryListDispatch must be used within a V1HistoryListProvider"
    );
  }
  return dispatch;
};
