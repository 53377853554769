import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import UploadSignedTransferFormatService from "../../components/stage/upload-signed-transfer-format/upload-signed-transfer-format.service";

const UploadSignedTransferFormatPage = () => {
  return <StagePageTemplate children={<UploadSignedTransferFormatService />} />;
};

export default UploadSignedTransferFormatPage;
