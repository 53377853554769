import clsx from "clsx";
import React, { ReactNode, Fragment } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  IoCheckboxOutline,
  IoCloudUploadOutline,
  IoHeartOutline,
  IoSkullOutline,
} from "react-icons/io5";

import { useStepContext } from "./step.provider";

type IIndicator = {
  step: number;
  label: string;
  icon: ReactNode;
};

export const indicators: IIndicator[] = [
  { step: 0, label: "Seleccionar Cobertura", icon: <IoHeartOutline /> },
  { step: 1, label: "Subir Documentos", icon: <IoCloudUploadOutline /> },
  { step: 2, label: "Detalles del Siniestro", icon: <IoSkullOutline /> },
  { step: 3, label: "Confirmar Datos", icon: <IoCheckboxOutline /> },
];

const StepIndicatorComponent = () => {
  const { currentStep } = useStepContext();
  const isActive = (step: number) => step === currentStep;

  return (
    <Fragment>
      {indicators.map(({ step, label, icon }) => (
        <Box
          key={step}
          color={isActive(step) ? "primary.main" : "text.secondary"}
        >
          <div
            className={clsx(
              "border-b border-black border-opacity-25",
              isActive(step) && "rounded bg-blue-50"
            )}
          >
            <div className={"h-20 w-full items-center flex flex-row"}>
              <Typography className={"py-3 pl-3"} variant={"h5"}>
                {icon}
              </Typography>
              <div className={"flex flex-col p-3"}>
                <div
                  className={"flex flex-row text-base font-bold items-center"}
                >
                  <div>{step + 1})</div>
                  <div className={"pl-2"}>{label}</div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      ))}
    </Fragment>
  );
};

export default StepIndicatorComponent;
