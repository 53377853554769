import React, { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";

type AppRouterProviderProps = { children: ReactNode };

const AppRouterProvider = ({ children }: AppRouterProviderProps) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

export default AppRouterProvider;
