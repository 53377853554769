import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import Panel from "../../../components/panel/panel";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import FixedScrollSectionTemplate from "../../../template/fixed-scroll-section.template";
import ClientLinkDeviceComponent from "./components/client-link-device.component";
import StepProviderLinkDevice from "./components/stepper/step.provider";

const ClientLinkDevicePage = () => {
  const { canClientCreate } = useAuthorization();
  const { t } = useTranslation();
  return (
    <AuthorizationComponent isAuthorized={canClientCreate} autoRedirect={true}>
      <StepProviderLinkDevice>
        <Grid container direction="row">
          <Grid item xs={12}>
            <FixedScrollSectionTemplate>
              <div className={"flex flex-row justify-center"}>
                <div className={"w-4/5 2xl:w-3/5"}>
                  <Panel title={t("clientLinkDevice.panel.title")}>
                    <ClientLinkDeviceComponent />
                  </Panel>
                </div>
              </div>
            </FixedScrollSectionTemplate>
          </Grid>
        </Grid>
      </StepProviderLinkDevice>
    </AuthorizationComponent>
  );
};

export default ClientLinkDevicePage;
