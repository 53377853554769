import React from "react";
import { useStepContext } from "./step.provider";
import { indicators } from "./step-indicator.component";

const StepTitleComponent = () => {
  const { currentStep } = useStepContext();
  return (
    <div className={"text-base font-bold mb-8"}>
      {currentStep + 1}) {indicators[currentStep].label}
    </div>
  );
};

export default StepTitleComponent;
