import { useParams } from "react-router-dom";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { StageActions } from "./stage.actions";
import {
  IStageDispatch,
  IState,
  stageInitialValue,
  stageReducer,
} from "./stage.reducer";

const StageStateContext = React.createContext<IState | undefined>(undefined);
const StageDispatchContext = React.createContext<IStageDispatch | undefined>(
  undefined
);

export const StageProvider: FunctionComponent = ({ children }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [context, dispatch] = useReducer(stageReducer, stageInitialValue);

  useEffect(() => {
    StageActions.fetchEvent(dispatch, eventId);
    StageActions.fetchCausals(dispatch, eventId);
    StageActions.fetchDocuments(dispatch, eventId);
    StageActions.fetchStage(dispatch, eventId);
  }, [eventId]);

  return (
    <StageStateContext.Provider value={context}>
      <StageDispatchContext.Provider value={dispatch}>
        {children}
      </StageDispatchContext.Provider>
    </StageStateContext.Provider>
  );
};

export const useStageState = (): IState => {
  const state = useContext(StageStateContext);
  if (!state)
    throw new Error("useStageState must be used within a StageProvider");
  return state;
};

export const useStageDispatch = (): IStageDispatch => {
  const dispatch = useContext(StageDispatchContext);
  if (!dispatch)
    throw new Error("useStageDispatch must be used within a StageProvider");
  return dispatch;
};
