import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from "react";
import {
  IClient,
  IDispositivoCliente,
  IClientDevicePlan,
  ICoverage,
  IDocumentRequired,
} from "../../../../types/event";

type IContext = {
  clients: IClient[];
  devices: IDispositivoCliente[];
  plans: IClientDevicePlan[];
  coverages: ICoverage[];
  documents: IDocumentRequired[];
  currentStep: number;
  isLoadingSubmit: boolean;
  setDevices: Dispatch<SetStateAction<IDispositivoCliente[]>>;
  setClients: Dispatch<SetStateAction<IClient[]>>;
  setPlans: Dispatch<SetStateAction<IClientDevicePlan[]>>;
  setCoverages: Dispatch<SetStateAction<ICoverage[]>>;
  setDocuments: Dispatch<SetStateAction<IDocumentRequired[]>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setLoadingSubmit: Dispatch<SetStateAction<boolean>>;
};

export const StepContext = React.createContext<IContext>({} as IContext);
export const useStepContext = () => useContext<IContext>(StepContext);

const StepProvider: FunctionComponent = ({ children }) => {
  const [plans, setPlans] = useState<IClientDevicePlan[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [devices, setDevices] = useState<IDispositivoCliente[]>([]);
  const [coverages, setCoverages] = useState<ICoverage[]>([]);
  const [documents, setDocuments] = useState<IDocumentRequired[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const context: IContext = {
    clients,
    devices,
    plans,
    coverages,
    documents,
    setClients,
    setDevices,
    setPlans,
    setCoverages,
    setDocuments,
    currentStep,
    setCurrentStep,
    isLoadingSubmit,
    setLoadingSubmit,
  };

  return (
    <StepContext.Provider value={context}>{children}</StepContext.Provider>
  );
};

export default StepProvider;
