import { reportsMicroserviceApiAxios } from "../reports-microservice-api.axios";
import { reportsMicroserviceApiRoutes } from "../reports-microservice-api.routes";
import {
  IGetReportReqData,
  IGetReportResData,
} from "./reports-reports-microservice.interface";

export const getReportReportsMicroservice = ({
  props: { report, sponsorID, type },
  params,
}: IGetReportReqData) => {
  const url = reportsMicroserviceApiRoutes.reports.core.get(
    sponsorID,
    report,
    type
  );
  return reportsMicroserviceApiAxios.post<IGetReportResData>(
    url,
    {},
    { params }
  );
};
