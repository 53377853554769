import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import LoadingButton from "../../../../../../components/loading-button/loading-button";

type IProps = {
  loading: boolean;
};

const FormButton = ({ loading }: IProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={"flex flex-row justify-center mt-4"}>
        <LoadingButton
          type={"submit"}
          color={"primary"}
          variant={"contained"}
          isLoading={loading}
          children={t("createCallRecord.form.fields.submit.label")}
        />
      </div>
    </Fragment>
  );
};

export default FormButton;
