import { Options } from "axios-hooks";
import { makeUseAxios } from "axios-hooks";
import { AxiosRequestConfig } from "axios";

import { protegemeV2ApiAxios } from "../../services/protegeme-v2/protegeme-v2-api.axios";

const useProtegemeAxios = makeUseAxios({
  axios: protegemeV2ApiAxios,
});

export const useProtegemeApi = (
  config: AxiosRequestConfig | string,
  options?: Options
) => {
  return useProtegemeAxios(config, options);
};
