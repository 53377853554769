import { Icon, Radio } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { FieldInputProps } from "formik/dist/types";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  buttonResolveChecked: {
    color: theme.palette.success.main,
  },
  buttonResolveHover: {
    "&:hover": {
      color: theme.palette.success.light,
    },
  },
}));

interface IProps {
  field: FieldInputProps<string>;
  value: string;
}

const ResolveRadio: FunctionComponent<IProps> = (props) => {
  const { field, value } = props;
  const classes = useStyle();
  return (
    <Radio
      {...field}
      color={"default"}
      classes={{
        root: classes.buttonResolveHover,
        checked: classes.buttonResolveChecked,
      }}
      icon={<Icon>check_circle</Icon>}
      checkedIcon={<Icon>check_circle</Icon>}
      value={value}
      checked={field.value === value}
    />
  );
};

export default ResolveRadio;
