import React, { FunctionComponent } from "react";
import { CssBaseline, Toolbar } from "@material-ui/core";

import Topbar from "../components/topbar/topbar";
import Sidebar from "../components/sidebar/sidebar";
import Footbar from "../components/footbar/footbar";
import Breadcrumb from "../components/breadcrumb/breadcrumb";

const AdminTemplate: FunctionComponent = ({ children }) => {
  return (
    <div className={"flex h-screen"}>
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <main className={"w-full flex flex-col"}>
        <Toolbar />
        <Breadcrumb />
        {!!children && children}
        <Footbar />
      </main>
    </div>
  );
};

export default AdminTemplate;
