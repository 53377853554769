import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { StageProvider } from "../providers/stage/stage.provider";
import FixedScrollSectionTemplate from "./fixed-scroll-section.template";
import EventStageViewComponent from "../components/event/event-view/event-stage-view.component";
import { AuthorizationComponent } from "../providers/authorization/authorization.component";
import { useAuthorization } from "../providers/authorization/authorization.provider";

const StagePageTemplate: FunctionComponent = ({ children }) => {
  const { canReclamationUpdateAll } = useAuthorization();
  return (
    <AuthorizationComponent
      isAuthorized={canReclamationUpdateAll}
      autoRedirect={true}
    >
      <StageProvider>
        <Grid container direction="row">
          <Grid item xs={6}>
            <FixedScrollSectionTemplate>
              <EventStageViewComponent />
            </FixedScrollSectionTemplate>
          </Grid>
          <Grid item xs={6} className={"p-6"}>
            <FixedScrollSectionTemplate>{children}</FixedScrollSectionTemplate>
          </Grid>
        </Grid>
      </StageProvider>
    </AuthorizationComponent>
  );
};

export default StagePageTemplate;
