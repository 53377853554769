import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { selectCompensationType } from "./stage-service";
import { getCompensationType } from "./stage-service";
import { uploadDiagnosticResult } from "./stage-service";
import { ISelectCompensationTypeReq } from "./stage-service.interface";
import { IGetCompensationTypeRes } from "./stage-service.interface";
import { IUseGetCompensationType } from "./stage-service.interface";
import { IUploadDiagnosticResultReq } from "./stage-service.interface";

export const useUploadDiagnosticResult = () => {
  return useMutation((props: IUploadDiagnosticResultReq) =>
    uploadDiagnosticResult(props)
  );
};

export const useGetCompensationType = (props: IUseGetCompensationType) => {
  const { reclamationId, options } = props;
  return useQuery<IGetCompensationTypeRes>(
    ["stage/list-type-compensation", reclamationId],
    () => getCompensationType({ reclamationId }).then((res) => res.data),
    options
  );
};

export const useSelectCompensationType = () => {
  return useMutation((props: ISelectCompensationTypeReq) =>
    selectCompensationType(props).then((res) => res.data)
  );
};
