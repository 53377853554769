import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import UploadSignedAdmissionOrderService from "../../components/stage/upload-signed-admission-order/upload-signed-admission-order.service";

const UploadSignedAdmissionOrderPage = () => {
  return (
    <StagePageTemplate>
      <UploadSignedAdmissionOrderService />
    </StagePageTemplate>
  );
};

export default UploadSignedAdmissionOrderPage;
