import to from "await-to-js";
import { Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import EAxios from "../../../../axios/EAxios";
import LoadingButton from "../../../loading-button/loading-button";
import { useCoverage } from "../../../../hooks/useCoverage";

const RequestRejectedComponent = () => {
  const history = useHistory();
  const coverage = useCoverage();
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const onSubmit = async () => {
    const url = EAxios.endpoints.default.stage.requestDeviceAgain(
      coverage,
      stageId || 0
    );
    const data = { ticket_id: eventId };

    setLoadingSubmit(true);
    const [error, response] = await to(EAxios.post<string>(url, data));
    setLoadingSubmit(false);

    if (error) return;
    if (response?.status === 200 || response?.status === 201) history.goBack();
  };

  return (
    <Fragment>
      <Typography color="textPrimary" display="block" variant="h6">
        Solicitud Rechazada
      </Typography>
      <div className={"flex justify-center w-full"}>
        <div className={"w-11/12"}>
          <p className={"text-justify"}>
            La solicitud de dispositivo en Centro de servicio fue rechazada por
            Suppla, por lo cual se debe realizar otra solicitud nuevamente.
          </p>
          <div className={"flex flex-row-reverse mb-6"}>
            <LoadingButton
              isLoading={isLoadingSubmit}
              onClick={() => onSubmit()}
              color={"primary"}
              variant={"contained"}
              type={"submit"}
            >
              Volver a solicitar
            </LoadingButton>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RequestRejectedComponent;
