import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import UploadRejectionOrderService from "../../components/stage/upload-rejection-order/upload-rejection-order.service";

const UploadRejectionOrderPage = () => {
  return (
    <StagePageTemplate>
      <UploadRejectionOrderService />
    </StagePageTemplate>
  );
};

export default UploadRejectionOrderPage;
