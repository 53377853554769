import clsx from "clsx";
import React from "react";
import * as colors from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Toolbar, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  footBar: {
    backgroundColor: theme.palette.background.paper,
  },
  heartIcon: {
    fontSize: "inherit",
    color:
      theme.palette.type === "light"
        ? colors.red["500"]
        : theme.palette.primary.main,
  },
}));

const Footbar = () => {
  const classes = useStyle();

  return (
    <div className={"bottom-0 shadow w-full mt-auto"}>
      <Toolbar
        variant={"dense"}
        className={clsx(classes.footBar, "shadow justify-between")}
      >
        <div className={"flex"}>
          <Typography variant={"body2"}>
            Copyright © 2020. All rights reserved.
          </Typography>
        </div>
        <div className={"flex"}>
          <Typography variant={"body2"}>
            Made with{" "}
            <Icon className={clsx(classes.heartIcon)} fontSize={"inherit"}>
              favorite
            </Icon>{" "}
            by E-celeris Software Factory
          </Typography>
        </div>
      </Toolbar>
    </div>
  );
};

export default Footbar;
