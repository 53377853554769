import _ from "lodash";
import React, { ReactNode } from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  AiOutlineFileText,
  AiOutlineAudit,
  AiOutlineFileSearch,
  AiOutlineFileAdd,
  AiOutlineEdit,
  AiOutlineUser,
  AiOutlineUserAdd,
  AiOutlineHome,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import {
  RiFileUserLine,
  RiFileHistoryLine,
  RiFileShield2Line,
} from "react-icons/ri";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { BiHistory, BiLink } from "react-icons/bi";
import { AddIcCall } from "@material-ui/icons";
import { MdPhoneInTalk, MdLocalPhone } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import ActualizationPage from "../../pages/actualization.page";
import ClientCreatePage from "../../pages/client/create/client-create-page";
import ClientDetailsPage from "../../pages/client/details/client-details-page";
import ClientDeviceDetailsPage from "../../pages/client/device/details/client-device-details-page";
import ClientDeviceUpdatePage from "../../pages/client/device/update/client-device-update-page";
import ClientLinkDevicePage from "../../pages/client/link-device/client-link-device-page";
import ClientListPage from "../../pages/client/list/client-list-page";
import ClientUpdatePage from "../../pages/client/update/client-update-page";
import CallAuditDetailsPage from "../../pages/contact-center/audit/call-audit-details-page";
import CallAuditRegisterPage from "../../pages/contact-center/audit/call-audit-register-page";
import EventCreatePage from "../../pages/event.create.page";
import EventPage from "../../pages/event/event.page";
import HomePage from "../../pages/home/home.page";
import PendingCoverageDetailsContainer from "../../pages/pending-coverage/details/pending-coverage-details-container";
import PendingCoverageListContainer from "../../pages/pending-coverage/list/pending-coverage-list.container";
import ReclamationAuditDetailsPage from "../../pages/reclamation/audit/details/reclamation-audit-details-page";
import ReclamationAuditRegisterPage from "../../pages/reclamation/audit/register/reclamation-audit-register-page";
import { ReclamationListPage } from "../../pages/reclamation/list/reclamation-list-page";
import ManualVerificationPage from "../../pages/stage/manual-verification.stage.page";
import RegisterMaintenanceIngressPage from "../../pages/stage/register-maintenance-ingress.page";
import SelectDeviceToDeliverPage from "../../pages/stage/select-device-to-deliver.page";
import SelectTypeCompensationPage from "../../pages/stage/select-type-compensation.page";
import UploadDeviceRequestPage from "../../pages/stage/upload-device-request.page";
import UploadDiagnosticPage from "../../pages/stage/upload-diagnostic/upload-diagnostic-page";
import UploadPaymentSupportPage from "../../pages/stage/upload-payment-support.page";
import UploadRejectionOrderPage from "../../pages/stage/upload-rejection-order.page";
import UploadReparationOrderPage from "../../pages/stage/upload-reparation-order.page";
import UploadSignedAdmissionOrderPage from "../../pages/stage/upload-signed-admission-order.page";
import UploadSignedPaymentOrderPage from "../../pages/stage/upload-signed-payment-order.page";
import UploadSignedPurchaseOrderPage from "../../pages/stage/upload-signed-purchase-order..page";
import UploadSignedTransferFormatPage from "../../pages/stage/upload-signed-transfer-format.page";
import { V1HistoryListTicketPage } from "../../pages/v1-history/v1-history-list-ticket/v1-history-list-ticket.page";
import { V1HistoryTicketPage } from "../../pages/v1-history/v1-history-ticket/v1-history-ticket.page";
import CallRecordPage from "../../pages/contact-center/create/calls/call-create.page";
import CallListPage from "../../pages/contact-center/list/calls/call-list.page";
import CallDetailsPage from "../../pages/contact-center/details/calls/call-details-page";
import ClientPolicyDetailsPage from "../../pages/client/policy/client-policy-page";
import ReportsGeneratePage from "../../pages/reports/reports-generate-page";

export interface IRouteV2 {
  path: string;
  icon: ReactNode;
  page: ReactNode;
  label: (props: any) => string;
  route?: (args?: any) => string;
  breadcrumb?: ReactNode;
}

export const useRoutesV2 = () => {
  const { t } = useTranslation();
  return {
    home: {
      path: "/",
      label: () => t("home.label"),
      icon: <AiOutlineHome />,
      page: <HomePage />,
    },
    reclamationListAll: {
      path: "/reclamation",
      route: () => "/reclamation",
      label: () => t("reclamationListAll.label"),
      icon: <AiOutlineFileSearch />,
      page: <ReclamationListPage />,
    },
    reclamationDetails: {
      path: "/reclamation/:eventId",
      route: (reclamationId: string | number) =>
        `/reclamation/${reclamationId}`,
      label: ({ eventId }: any) =>
        t("reclamationDetails.label", { reclamationId: eventId }),
      icon: <AiOutlineFileSearch />,
      page: <EventPage />,
    },
    reclamationAuditRegister: {
      path: "/reclamation/:reclamationId/audit/register",
      route: ({ reclamationId }: any) =>
        `/reclamation/${reclamationId}/audit/register`,
      label: ({ reclamationId }: any) =>
        t("reclamationAuditRegister.breadcrumb", { reclamationId }),
      icon: <AiOutlineAudit />,
      page: <ReclamationAuditRegisterPage />,
    },
    reclamationAuditDetails: {
      path: "/reclamation/:reclamationId/audit/details",
      route: ({ reclamationId }: any) =>
        `/reclamation/${reclamationId}/audit/details`,
      label: ({ reclamationId }: any) =>
        t("reclamationAuditDetails.breadcrumb", { reclamationId }),
      icon: <AiOutlineFileText />,
      page: <ReclamationAuditDetailsPage />,
    },
    reclamationCreate: {
      path: "/reclamation-create",
      label: () => t("reclamationCreate.label"),
      icon: <AiOutlineFileAdd />,
      page: <EventCreatePage />,
    },
    legacyListTickets: {
      path: "/legacy/ticket",
      label: () => t("legacyListTickets.label"),
      icon: <BiHistory />,
      page: <V1HistoryListTicketPage />,
    },
    legacyTicket: {
      route: (ticketId: number) => `/legacy/ticket/${ticketId}`,
      path: "/legacy/ticket/:ticketId",
      icon: <RiFileHistoryLine />,
      label: ({ ticketId }: any) => `Ticket ${ticketId}`,
      page: <V1HistoryTicketPage />,
    },
    clientList: {
      path: "/client",
      label: () => t("clientList.label"),
      icon: <FiUsers />,
      page: <ClientListPage />,
    },
    clientCreate: {
      path: "/client/create",
      route: () => "/client/create",
      label: () => t("clientCreate.label"),
      icon: <AiOutlineUserAdd />,
      page: <ClientCreatePage />,
    },
    clientDetails: {
      path: "/client/:clientId",
      route: (identification_number: string | number) =>
        `/client/${identification_number}`,
      label: ({ clientId }: any) => t("clientDetails.label", { clientId }),
      icon: <AiOutlineUser />,
      page: <ClientDetailsPage />,
    },
    clientUpdate: {
      path: "/client/:clientId/update",
      route: (clientId: number) => `/client/${clientId}/update`,
      label: ({ clientId }: any) => t("clientUpdate.label", { clientId }),
      icon: <AiOutlineEdit />,
      page: <ClientUpdatePage />,
    },
    clientDeviceDetails: {
      path: "/client/:clientId/device/:deviceId",
      route: ({ clientId, deviceId }: any) =>
        `/client/${clientId}/device/${deviceId}`,
      label: ({ deviceId }: any) =>
        t("clientDeviceDetails.label", { deviceId }),
      icon: <IoPhonePortraitOutline />,
      page: <ClientDeviceDetailsPage />,
    },
    clientPolicyDetails: {
      path: "/client/:clientId/device/:deviceId/policy/:policyId",
      route: ({ clientId, policyId, deviceId }: any) =>
        `/client/${clientId}/device/${deviceId}/policy/${policyId}`,
      label: ({ policyId }: any) =>
        t("clientPolicyDetails.label", { policyId }),
      icon: <RiFileShield2Line />,
      page: <ClientPolicyDetailsPage />,
    },
    clientLinkDevice: {
      path: "/client/:clientId/link-device",
      route: ({ clientId }: any) => `/client/${clientId}/link-device`,
      label: () => t("clientLinkDevice.label"),
      icon: <BiLink />,
      page: <ClientLinkDevicePage />,
    },
    clientDeviceUpdate: {
      path: "/client/:clientId/device/:deviceId/update",
      route: ({ clientId, deviceId }: any) =>
        `/client/${clientId}/device/${deviceId}/update`,
      label: ({ deviceId }: any) => t("clientDeviceUpdate.label", { deviceId }),
      icon: <AiOutlineEdit />,
      page: <ClientDeviceUpdatePage />,
    },
    actualization: {
      path: "/actualization",
      label: () => t("actualization.label"),
      icon: <AiOutlineCloudUpload />,
      page: <ActualizationPage />,
    },
    pendingCoverageList: {
      path: "/pending-coverage",
      route: () => "/pending-coverage",
      label: () => t("pendingCoverageList.label"),
      icon: <RiFileUserLine />,
      page: <PendingCoverageListContainer />,
    },
    pendingCoverageDetails: {
      path: "/pending-coverage/:pendingCoverageId",
      route: ({ pendingCoverageId }: any) =>
        `/pending-coverage/${pendingCoverageId}`,
      label: ({ pendingCoverageId }: any) =>
        t("pendingCoverageDetails.label", { pendingCoverageId }),
      icon: <RiFileUserLine />,
      page: <PendingCoverageDetailsContainer />,
    },
    callRecordCreate: {
      path: "/call/create",
      route: () => "/call/create",
      label: () => t("createCallRecord.panel.title"),
      icon: <AddIcCall />,
      page: <CallRecordPage />,
    },
    callRecordList: {
      path: "/call",
      route: () => "/call",
      label: () => t("callList.label"),
      icon: <MdPhoneInTalk />,
      page: <CallListPage />,
    },
    callDetails: {
      path: "/call/:callId",
      route: (callId: any) => `/call/${callId}`,
      label: ({ callId }: any) => t("callDetails.label", { callId }),
      icon: <MdLocalPhone />,
      page: <CallDetailsPage />,
    },
    callAuditRegister: {
      path: "/call/:callId/audit/register",
      route: ({ callId }: any) => `/call/${callId}/audit/register`,
      label: ({ callId }: any) => t("callAuditRegister.breadcrumb", { callId }),
      icon: <AiOutlineAudit />,
      page: <CallAuditRegisterPage />,
    },
    callAuditDetails: {
      path: "/call/:callId/audit/details",
      route: ({ callId }: any) => `/call/${callId}/audit/details`,
      label: ({ callId }: any) => t("callAuditDetails.breadcrumb", { callId }),
      icon: <AiOutlineFileText />,
      page: <CallAuditDetailsPage />,
    },
    reportsGenerate: {
      path: "/reports",
      route: () => `/reports`,
      label: () => t("reports.label"),
      icon: <HiOutlineDocumentReport />,
      page: <ReportsGeneratePage />,
    },
  };
};

export const useRoutesV2List = (): IRouteV2[] => {
  const routesV2 = useRoutesV2();
  const routesV2Array = _.values(routesV2);
  return routesV2Array;
};

export const useRoutesV2BreadcrumbList = (): BreadcrumbsRoute[] => {
  const routesV2List = useRoutesV2List();
  const routesBreadcrumb = routesV2List.map((route) => ({
    ...route,
    breadcrumb: (props: any) => (
      <div className={"flex flex-row items-center gap-2 text-base"}>
        <div className={"text-2xl"}>{route.icon}</div>
        <div className={"font-medium"}>{route.label(props.match.params)}</div>
      </div>
    ),
  }));
  return routesBreadcrumb;
};

export interface IRoute {
  path: string;
  name?: string;
  icon: string;
  type?: string;
  label: string | ((url: string) => string);
  component: FunctionComponent;
}

type IAuthRoute =
  | ({ private: true } & IRoute)
  | ({ private: false; restricted: boolean } & IRoute);

export const getRoutes = (): IAuthRoute[] => [
  {
    path: "/mis-eventos/evento/:eventId",
    name: "event",
    icon: "wysiwyg",
    type: "event",
    private: true,
    label: function (url: string) {
      // const matcher = match<{ eventId: string }>(this.path);
      // const { params } = matcher(url as string) as MatchResult<{
      //   eventId: string;
      // }>;
      return `Evento`;
    },
    component: EventPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/manual-verification",
    name: "manual-verification",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Verificacion Manual de Causales",
    component: ManualVerificationPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/rejection-order",
    name: "rejection-order",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Orden de Rechazo",
    component: UploadRejectionOrderPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/reparation-order",
    name: "tigo/53/reparation-order",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Orden de Reparación",
    component: UploadReparationOrderPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/register-maintenance-ingress",
    name: "tigo/53/register-maintenance-ingress",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Registar ingreso de reparación",
    component: RegisterMaintenanceIngressPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-diagnostic",
    name: "tigo/53/upload-diagnostic",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Diagnostico",
    component: UploadDiagnosticPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/select-device-to-deliver",
    name: "tigo/53/select-device-to-deliver",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Seleccionar el dispositivo a entregar",
    component: SelectDeviceToDeliverPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-device-request",
    name: "tigo/53/upload-device-request",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Resultado de la Solicitud",
    component: UploadDeviceRequestPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-signed-purchase-order",
    name: "tigo/53/upload-signed-purchase-order",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Orden de Compra Firmada",
    component: UploadSignedPurchaseOrderPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-signed-admission-order",
    name: "tigo/53/upload-signed-admission-order",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Orden de Ingreso Firmada",
    component: UploadSignedAdmissionOrderPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-signed-transfer-format",
    name: "tigo/53/upload-signed-transfer-format",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Formato de Transpaso Firmado",
    component: UploadSignedTransferFormatPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-signed-payment-order",
    name: "tigo/53/upload-signed-payment-order",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Orden de Pago Firmada",
    component: UploadSignedPaymentOrderPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/upload-payment-support",
    name: "tigo/53/upload-payment-support",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Cargar Soporte de Pago",
    component: UploadPaymentSupportPage,
  },
  {
    path: "/reclamation/:eventId/stage/:stageId/select-type-compensation",
    name: "select-type-compensation",
    icon: "edit",
    type: "stage",
    private: true,
    label: "Seleccionar Tipo de Indemnización",
    component: SelectTypeCompensationPage,
  },
];

export const routes = {
  v2: {
    client: {
      create: () => "/client/create",
      view: (clientId: number | string) => `/client/create/${clientId}`,
    },
  },
};

export const getRouteByName = (nameCandidate: string) =>
  getRoutes().find(({ name }) => name === nameCandidate);

export const getRouteByPath = (pathCandidate: string) =>
  getRoutes().find(({ path }) => path === pathCandidate);

export const getRouteByMatch = (routeCandidate: string) =>
  getRoutes().find(({ path }) => {
    // let matcher = match(path);
    // let result = matcher(routeCandidate);
    return "!!result";
  });
