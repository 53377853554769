import _ from "lodash";
import React from "react";
import * as yup from "yup";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { FormikHelpers } from "formik/dist/types";

import EAxios from "../../../axios/EAxios";
import StepComponent from "./steps/step.component";
import { useStepContext } from "./steps/step.provider";
import StepTitleComponent from "./steps/step-Title.component";
import ButtonsStepComponent from "./steps/buttons-step.component";
import StepIndicatorComponent from "./steps/step-indicator.component";
import {
  ICity,
  IClient,
  IClientDevicePlan,
  ICoverage,
  IDispositivoCliente,
  IFormDocument,
  IGender,
} from "../../../types/event";
import to from "await-to-js";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export type ICreateFormValues = {
  client: IClient | null;
  device: IDispositivoCliente | null;
  plan: IClientDevicePlan | null;
  coverage: ICoverage | null;
  documents: IFormDocument[];
  gender: IGender | null;
  message: string | null;
  sinisterLane: string | null;
  sinisterDate: Date | null;
  city: ICity | null;
  validate: boolean | null;
};

const EventCreateComponent = () => {
  const { currentStep, setCurrentStep, setLoadingSubmit } = useStepContext();
  const initialValues: ICreateFormValues = {
    client: null,
    device: null,
    plan: null,
    coverage: null,
    documents: [],
    gender: null,
    message: "",
    sinisterLane: "",
    sinisterDate: null,
    city: null,
    validate: false,
  };

  const validationOne = {
    client: yup
      .object({ id: yup.number().required() })
      .typeError("Requerido *")
      .required("Requerido *"),
    device: yup
      .object({ id: yup.number().required() })
      .typeError("Requerido *")
      .required("Requerido *"),
    plan: yup
      .object({ id: yup.number().required() })
      .typeError("Requerido *")
      .required("Requerido *"),
    coverage: yup
      .object({ id: yup.string().required() })
      .typeError("Requerido *")
      .required("Requerido *"),
  };

  const validationTwo = {};

  const validationThree = {
    gender: yup
      .object({ id: yup.string().required() })
      .typeError("Requerido *")
      .required("Requerido *"),
    city: yup
      .object({ id: yup.string().required() })
      .typeError("Requerido *")
      .required("Requerido *"),
    message: yup
      .string()
      .required("Requerido *")
      .max(280, "Máximo 280 caracteres"),
    sinisterDate: yup.date().typeError("Requerido *").required("Requerido *"),
    sinisterLane: yup
      .string()
      .typeError("Requerido *")
      .matches(/^[0-9]*$/, "Debe contener solo valores numéricos")
      .length(10, "Debe contener exactamente 10 caracteres")
      .required("Requerido *"),
  };

  const validationSchema = [
    yup.object(validationOne).required("Requerido *"),
    yup.object(validationTwo).required("Requerido *"),
    yup.object(validationThree).required("Requerido *"),
    yup
      .object({
        ...validationOne,
        ...validationTwo,
        ...validationThree,
        validate: yup
          .boolean()
          .oneOf([true], "Requerido *")
          .required("Requerido *"),
      })
      .required("Requerido *"),
  ];

  const formatSumbitData = (values: ICreateFormValues) => {
    const documentData = {};
    values.documents
      .filter((value) => Boolean(value.file))
      .forEach((value) => _.set(documentData, String(value.id), value.file));
    return {
      cliente_id: values.client?.id,
      imei_dispositivo_one: values.device?.imei_uno,
      plan_id: values.plan?.plan?.id,
      tipo_cobertura_cobertura_id: values.coverage?.id,
      tipo_ticket_id: 1,
      mensaje_ticket: values.message,
      linea_siniestro_one: values.sinisterLane,
      fecha_siniestro: format(values.sinisterDate!, "yyyy-MM-dd"),
      genero_reclamante_id: values.gender?.id,
      ciudad_id: values.city?.id,
      ...documentData,
    };
  };

  const onSubmit = async (
    values: ICreateFormValues,
    formikHelpers: FormikHelpers<ICreateFormValues>
  ) => {
    const url = EAxios.endpoints.default.event.create();
    const data = formatSumbitData(values as ICreateFormValues);
    const [formData, config] = EAxios.toFormData(data);
    setLoadingSubmit(true);
    type IResponse = {
      data: { id: string };
      error?: { id_system: string; message: string };
    };
    const [, response] = await to(
      EAxios.post<IResponse>(url, formData, config)
    );
    setLoadingSubmit(false);
    if (response?.data?.error?.id_system === "TICKETINPROCESS") {
      const message = (
        <div className={"flex flex-col gap-4"}>
          <div>
            El cliente actualmente tiene una reclamación activa con ese
            dispositivo
          </div>
          <div>Número de Radicado: {response.data.data.id}</div>
          <div className={"flex flex-row-reverse"}>
            <Box color={"primary.contrastText"}>
              <Button
                size={"small"}
                color={"inherit"}
                variant={"outlined"}
                component={Link}
                to={`/reclamation/${response.data.data.id}`}
                children={"ver reclamación"}
              />
            </Box>
          </div>
        </div>
      );
      toast.error(message);
      return;
    }

    if (response?.status === 200 || response?.status === 201) {
      const ticketId = response.data.data.id;
      const message = (
        <div className={"flex flex-col gap-4"}>
          <div>Evento Creado Exitosamente</div>
          <div>Número de Radicado: {ticketId}</div>
          <div className={"flex flex-row-reverse"}>
            <Box color={"primary.contrastText"}>
              <Button
                size={"small"}
                color={"inherit"}
                variant={"outlined"}
                component={Link}
                to={`/reclamation/${ticketId}`}
                children={"ver reclamación"}
              />
            </Box>
          </div>
        </div>
      );
      toast.success(message);
      formikHelpers.resetForm();
      setCurrentStep(0);
    }
  };

  return (
    <div className={"flex flex-row"}>
      <div
        className={"w-2/6 pl-4 pr-10 border-r border-black border-opacity-25"}
      >
        <StepIndicatorComponent />
      </div>

      <div className={"w-full px-16 pt-8"}>
        <StepTitleComponent />
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema[currentStep]}
          onSubmit={onSubmit}
        >
          <Form>
            <StepComponent />
            <ButtonsStepComponent />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default EventCreateComponent;
