import React from "react";
import { TablePagination } from "@material-ui/core";

import { useV1HistoryListState } from "../providers/v1-history-list.provider";
import { useV1HistoryListActions } from "../providers/v1-history-list.actions";

const V1HistoryListTicketPagination = () => {
  const { pagination } = useV1HistoryListState();
  const { setPaginationPageSize } = useV1HistoryListActions();
  const { setPaginationPageNumber } = useV1HistoryListActions();

  const handleChangePageNumber = (event: any, newPage: number) => {
    setPaginationPageNumber(newPage + 1);
  };

  const handleChangePageSize = (event: any) => {
    const size = event.target.value;
    setPaginationPageSize(size);
  };

  return (
    <TablePagination
      component="div"
      count={pagination.pageTotal}
      page={pagination.pageNumber - 1}
      rowsPerPage={pagination.pageSize}
      onChangePage={handleChangePageNumber}
      rowsPerPageOptions={[10, 25, 50]}
      onChangeRowsPerPage={handleChangePageSize}
    />
  );
};

export default V1HistoryListTicketPagination;
