import React from "react";
import { useFormikContext } from "formik";

import AutocompleteField from "../../../../../../components/formik/fields/autocomplete.field";
import { useStepContextLinkDevice } from "../../stepper/step.provider";
import { useGetSponsorWithPlanMicroservice } from "../../../../../../services/microservice/sponsor/use-sponsor-microservice";
import { ISponsor } from "../../interfaces/link-device.types";

interface ISponsorSelectField {
  name: string;
  label: string;
  conditionalName: string;
  help?: string;
}

const SponsorSelectField = (props: ISponsorSelectField) => {
  const { name, label, conditionalName, help } = props;
  const { setSponsors, setPlans } = useStepContextLinkDevice();
  const { setFieldValue } = useFormikContext();
  const { data: Sponsors } = useGetSponsorWithPlanMicroservice({
    onSuccess: (res) => {
      setSponsors(res.data.sponsors);
    },
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const handleOnChangeEvent = (value: ISponsor | undefined | null) => {
    setFieldValue(name, value);
    setPlans(value?.plans);
    if (!value) clearPlan();
  };

  const clearPlan = () => {
    setFieldValue(conditionalName, null);
    setPlans([]);
  };
  return (
    <AutocompleteField
      name={name}
      label={label}
      help={help}
      size={"small"}
      options={Sponsors?.data.sponsors || []}
      getOptionLabel={(option) => `${option.nombre}`}
      getOptionSelected={(option, value) => option.nombre === value.nombre}
      onInputChange={() => clearPlan()}
      onChange={(_event, value) => handleOnChangeEvent(value)}
    />
  );
};
export default SponsorSelectField;
