import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Case } from "react-if";
import { Switch } from "react-if";
import { useParams } from "react-router-dom";

import Panel from "../../../components/panel/panel";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useFindAllClientDevices } from "../../../services/protegeme-v2/client/use-client";
import FixedScrollSectionTemplate from "../../../template/fixed-scroll-section.template";
import DetailsSection from "./components/details-section";
import CancelSection from "./components/cancel-section";
import RenewSection from "./components/renew-section";

const ClientPolicyDetailsPage = () => {
  const authorization = useAuthorization();
  const { clientId, policyId, deviceId } = useParams<any>();
  const findAllClientDevices = useFindAllClientDevices(clientId);
  const deviceClient = findAllClientDevices.data?.data.filter(
    (d) => d.id === Number(deviceId)
  )[0];
  const { t } = useTranslation();
  return (
    <AuthorizationComponent
      isAuthorized={authorization.canClientPolicyReadOne}
      autoRedirect={true}
    >
      <Grid container direction="row">
        <Grid item xs={12}>
          <FixedScrollSectionTemplate>
            <div className={"flex flex-row justify-center"}>
              <div className={"w-4/5 2xl:w-3/5"}>
                <Panel
                  title={
                    <div className="w-full h-full flex flex-row justify-between items-center">
                      <div>
                        {t("clientPolicyDetails.panel.label", { policyId })}
                      </div>
                    </div>
                  }
                >
                  <Switch>
                    <Case
                      condition={findAllClientDevices.isLoading}
                      children={
                        <Skeleton
                          width={"100%"}
                          height={500}
                          variant={"rect"}
                        />
                      }
                    />
                    <Case condition={!findAllClientDevices.isLoading}>
                      <DetailsSection deviceClient={deviceClient} />
                    </Case>
                  </Switch>
                </Panel>
              </div>
            </div>
          </FixedScrollSectionTemplate>
          <FixedScrollSectionTemplate classname="-mt-7">
            <div className={"flex flex-row justify-center"}>
              <div className={"w-4/5 2xl:w-3/5"}>
                <Panel
                  title={
                    <div className="w-full h-full flex flex-row justify-between items-center">
                      {deviceClient?.policy[0].is_active ? (
                        <div>
                          {t("clientPolicyCancel.panel.label", { policyId })}
                        </div>
                      ) : (
                        <div>
                          {t("clientPolicyCanceled.panel.label", { policyId })}
                        </div>
                      )}
                    </div>
                  }
                >
                  <Switch>
                    <Case
                      condition={findAllClientDevices.isLoading}
                      children={
                        <Skeleton
                          width={"100%"}
                          height={500}
                          variant={"rect"}
                        />
                      }
                    />
                    <Case condition={!findAllClientDevices.isLoading}>
                      <CancelSection deviceClient={deviceClient} />
                    </Case>
                  </Switch>
                </Panel>
              </div>
            </div>
          </FixedScrollSectionTemplate>
          <AuthorizationComponent
            isAuthorized={authorization.canClientViewRenewPolicy}
          >
            <FixedScrollSectionTemplate classname="-mt-7">
              <div className={"flex flex-row justify-center"}>
                <div className={"w-4/5 2xl:w-3/5"}>
                  <Panel
                    title={
                      <div className="w-full h-full flex flex-row justify-between items-center">
                        {t("clientPolicyRenew.panel.label", { policyId })}
                      </div>
                    }
                  >
                    <Switch>
                      <Case
                        condition={findAllClientDevices.isLoading}
                        children={
                          <Skeleton
                            width={"100%"}
                            height={500}
                            variant={"rect"}
                          />
                        }
                      />
                      <Case condition={!findAllClientDevices.isLoading}>
                        <RenewSection deviceClient={deviceClient} />
                      </Case>
                    </Switch>
                  </Panel>
                </div>
              </div>
            </FixedScrollSectionTemplate>
          </AuthorizationComponent>
        </Grid>
      </Grid>
    </AuthorizationComponent>
  );
};

export default ClientPolicyDetailsPage;
