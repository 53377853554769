import _ from "lodash";
import React from "react";
import { Fragment } from "react";

interface IClientFormatName {
  firstName: string;
  lastName: string;
}

export const getClientFullName = (props: IClientFormatName) => {
  const { firstName, lastName } = props;
  const formatName = _.flow(_.lowerCase, _.startCase);
  const fullName = formatName(`${firstName} ${lastName}`);
  return fullName;
};

export const ClientFullName = (props: IClientFormatName) => {
  const fullName = getClientFullName(props);
  return <Fragment>{fullName}</Fragment>;
};
