import EAxios from "../../../axios/EAxios";
import { IActualizationDispatch } from "./actualization.reducer";
import { IActualization } from "../../../types/actualization";
import { IResponse } from "../../../types/event";

export const ActualizationActions = {
  fetchActualizationListAll: (dispatch: IActualizationDispatch) => {
    const url = EAxios.endpoints.default.actualization.listAll();
    dispatch({ type: "FETCH_LIST_ALL" });
    EAxios.get<IResponse<IActualization[]>>(url)
      .then((value) =>
        dispatch({ type: "SUCCESS_LIST_ALL", payload: value.data.data! })
      )
      .catch(() => dispatch({ type: "ERROR_LIST_ALL" }));
  },
};
