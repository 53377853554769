import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import LoadingButton from "../../../../../../components/loading-button/loading-button";
import { useRenewClientPolicy } from "../../../../../../services/protegeme-v2/client/use-client";
import NotificatioRenewPolicy from "../notifications/notification-renew-policy";
import NotificatioErrorRenew from "../notifications/notification-error-renew";

interface Params {
  clientId: string;
  deviceId: string;
  policyId: string;
}

const RenewButton = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const params: Params = useParams();
  const { mutateAsync: RenewPolicy, isLoading } = useRenewClientPolicy();

  const handleButton = async () => {
    try {
      await RenewPolicy({ policy_id: Number(params.policyId) });
      toast.info(<NotificatioRenewPolicy />);
    } catch (error) {
      toast.error(<NotificatioErrorRenew />);
    } finally {
      push(`/client/${params.clientId}`);
    }
  };
  return (
    <div className="flex flex-row justify-start ml-4">
      <LoadingButton
        onClick={() => handleButton()}
        color="primary"
        className="w-1/4"
        variant="contained"
        isLoading={isLoading}
        children={t("clientPolicyRenew.panel.button")}
      />
    </div>
  );
};

export default RenewButton;
