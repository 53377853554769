import _ from "lodash";
import React from "react";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Icon,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { If } from "react-if";
import { Else } from "react-if";
import { Then } from "react-if";

import { useStageState } from "../../../../../providers/stage/stage.provider";
import { getDocumentSignUrl } from "../../../../../util/Document.util";

const useStyle = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.info.main,
    margin: "0px",
    padding: "0px",
  },
}));

const openDocument = async (documentId: string) => {
  const url = await getDocumentSignUrl(documentId);
  window.open(url, "_blank");
  return null;
};

//ToDo add column "etapa"
const EventDocumentSection = () => {
  const classes = useStyle();
  const { documents } = useStageState();
  return (
    <Table size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <span className={"text-xs"}>Nombre</span>
          </TableCell>
          <TableCell align="center">
            <span className={"text-xs"}>Etapa</span>
          </TableCell>
          <TableCell align="center">
            <span className={"text-xs"}>Acción</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents.data?.map((document) => (
          <TableRow key={document.id}>
            <TableCell align="left">
              <span className={"text-xs flex flex-row items-center"}>
                <Box color={"error.main"} className={"flex mr-2"}>
                  <Icon>insert_drive_file</Icon>
                </Box>
                {document.nombre}
              </span>
            </TableCell>
            <TableCell align="center">
              <If condition={_.isString(document?.etapa?.nombre)}>
                <Then>
                  <span className={"text-xs"}>{document?.etapa?.nombre}</span>
                </Then>
                <Else>-</Else>
              </If>
            </TableCell>
            <TableCell align="center">
              <Tooltip title={`Abrir Documento: ${document.nombre}`}>
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={() => openDocument(document.id)}
                >
                  <Icon>remove_red_eye</Icon>
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EventDocumentSection;
