import produce from "immer";

import { IActions, IState } from "../../../../types/v1HistoryTicket";

export const initialValue: IState = {
  tickets: {
    loading: true,
    error: undefined,
    data: undefined,
  },
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    pageTotal: 0,
  },
  filter: {
    phoneNumber: "",
    identificationNumber: "",
    eventId: "",
  },
  formRef: undefined,
};

const reduce = (accumulator: IState, current: IActions) => {
  switch (current.type) {
    case "TICKET_LIST_FETCH": {
      accumulator.tickets.loading = true;
      accumulator.tickets.data = undefined;
      accumulator.tickets.error = undefined;
      return accumulator;
    }
    case "TICKET_LIST_SUCCESS": {
      accumulator.tickets.loading = false;
      accumulator.tickets.data = current.payload;
      return accumulator;
    }
    case "TICKET_LIST_ERROR": {
      accumulator.tickets.loading = false;
      accumulator.tickets.error = current.payload;
      return accumulator;
    }
    case "FILTER_CLEAR": {
      accumulator.filter.eventId = "";
      accumulator.filter.phoneNumber = "";
      accumulator.filter.identificationNumber = "";
      return accumulator;
    }
    case "FILTER_SET": {
      accumulator.pagination.pageNumber = 1;
      accumulator.filter.eventId = current.payload.eventId;
      accumulator.filter.phoneNumber = current.payload.phoneNumber;
      accumulator.filter.identificationNumber =
        current.payload.identificationNumber;
      return accumulator;
    }
    case "PAGINATION_SET_PAGETOTAL": {
      accumulator.pagination.pageTotal = current.payload;
      return accumulator;
    }
    case "PAGINATION_SET_PAGESIZE": {
      accumulator.pagination.pageSize = current.payload;
      return accumulator;
    }
    case "PAGINATION_SET_PAGENUMBER": {
      accumulator.pagination.pageNumber = current.payload;
      return accumulator;
    }
    case "FORM_REF_SET": {
      accumulator.formRef = current.payload;
      return accumulator;
    }
    default: {
      return accumulator;
    }
  }
};

export const reducer = produce(reduce, {});
