import React from "react";
import { IconButton } from "@material-ui/core";
import { IoEye } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { parseISO } from "date-fns";
import { Column } from "react-table";

import { useAuthorization } from "../../../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../../../providers/router/routes";
import TableStandard from "../../../../../components/tables/table-standard";
import { ClientFullName } from "../../../../../services/protegeme-v2/client/util/client-format-name";
import { DateFormat } from "../../../../../util/date.util";
import { ICallList } from "../../../../../services/protegeme-v2/contac-center/call/call-service.interface";
import AuditChip from "../../../../reclamation/list/components/audit-chip";

interface ICallTable {
  data: any;
}

const CallListTable = (props: ICallTable) => {
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  const authorization = useAuthorization();

  const { data } = props;
  const columnsRaw: Array<Column<ICallList> | boolean> = [
    { Header: t("callList.panel.columns.id"), accessor: "id" },
    {
      Header: t("callList.panel.columns.incommmingCall"),
      accessor: (row: any) => (
        <div className={"text-left"}>{row.incoming_phone_number}</div>
      ),
    },
    {
      Header: t("callList.panel.columns.laneSaved"),
      accessor: (row: any) => (
        <div className={"text-left"}>{row.ensured_line}</div>
      ),
    },
    {
      Header: t("callList.panel.columns.client"),
      accessor: (row: any) => (
        <ClientFullName
          firstName={row.client.name}
          lastName={row.client.second_name}
        />
      ),
    },
    {
      Header: t("callList.panel.columns.identificationNumber"),
      accessor: (row: any) => (
        <div className={"text-left"}>{row.client.identification_number}</div>
      ),
    },
    {
      Header: t("callList.panel.columns.date"),
      accessor: (row: any) => <DateFormat source={parseISO(row.call_date)} />,
    },
    {
      Header: t("callList.panel.columns.user"),
      accessor: (row: any) => (
        <ClientFullName
          firstName={row.user_created_by.name}
          lastName={row.user_created_by.second_name}
        />
      ),
    },
    authorization.canCallAuditReadAll && {
      id: "audited",
      Header: () => (
        <div className={"flex flex-row justify-center"}>
          {t("callList.panel.columns.audited")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center"}>
          <AuditChip className={"w-full"} isAudited={row.audited} />
        </div>
      ),
    },
    {
      id: "actions",
      Header: () => (
        <div className="flex flex-row justify-center">
          {t("callList.panel.columns.actions")}
        </div>
      ),
      accessor: (row) => (
        <div className={"flex flex-row justify-center gap-2"}>
          <When condition={authorization.canCallRead}>
            <IconButton
              component={Link}
              to={routesV2.callDetails.route(row.id)}
              size={"small"}
              children={<IoEye />}
            />
          </When>
          <When condition={authorization.canCallAuditUpdateOne && !row.audited}>
            <IconButton
              component={Link}
              to={routesV2.callAuditRegister.route({
                callId: row.id,
              })}
              size={"small"}
              children={routesV2.callAuditRegister.icon}
            />
          </When>
          <When condition={authorization.canCallAuditReadOne && row.audited}>
            <IconButton
              component={Link}
              to={routesV2.callAuditDetails.route({
                callId: row.id,
              })}
              size={"small"}
              children={routesV2.callAuditDetails.icon}
            />
          </When>
        </div>
      ),
    },
  ];

  const columns: Array<Column<ICallList>> = columnsRaw.reduce<
    Array<Column<ICallList>>
  >((previousValue, currentValue) => {
    if (typeof currentValue === "boolean") {
      return previousValue;
    }
    return [...previousValue, currentValue];
  }, []);

  return (
    <TableStandard
      style={{ border: "0px" }}
      className={"text-xs"}
      stickyHeader={true}
      size={"small"}
      columns={columns}
      data={data.results}
    />
  );
};
export default CallListTable;
