import React from "react";
import { Box, Icon, Tooltip } from "@material-ui/core";

export type IHelpIconComponent = {
  tooltip?: string;
};

const HelpIconComponent = (props: IHelpIconComponent) => {
  const { tooltip } = props;
  if (!tooltip) return null;
  return (
    <div className={"ml-2"}>
      <Box color={"text.secondary"} className={"flex"}>
        <Tooltip placement={"top"} className={"cursor-pointer"} title={tooltip}>
          <Icon color={"inherit"}>help_outline</Icon>
        </Tooltip>
      </Box>
    </div>
  );
};

export default HelpIconComponent;
