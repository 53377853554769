import { TimelineDot } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
import { BsQuestion } from "react-icons/bs";
import { IoMdCheckmark, IoMdPerson } from "react-icons/io";
import { BiChip } from "react-icons/bi";
import { useGlobalStyles } from "../../../../../../styles/useGlobalStyles";

interface IProgressDot {
  icon?: "system" | "analyst" | "finish" | "default";
  status?: "pending" | "inProgress" | "success";
}

const ProgressDot = (props: IProgressDot) => {
  const { icon = "default", status = "pending" } = props;
  const globalStyles = useGlobalStyles();
  const iconComponent = {
    system: <BiChip size={"1.5em"} />,
    analyst: <IoMdPerson size={"1.5em"} />,
    finish: <IoMdCheckmark size={"1.5em"} />,
    default: <BsQuestion size={"1.5em"} />,
  };

  const dotColor = {
    pending: globalStyles.bgGray,
    inProgress: globalStyles.bgYellow,
    success: globalStyles.bgGreen,
  };

  return (
    <TimelineDot
      color={"inherit"}
      className={clsx(dotColor[status], "text-white", "border-transparent")}
    >
      {iconComponent[icon]}
    </TimelineDot>
  );
};

export default ProgressDot;
