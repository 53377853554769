import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useActualizationState } from "../context/actualization.provider";
import {
  getActualizationsSorted,
  getActualizationsVigent,
  getActualizationVigentDate,
} from "../../../util/actualization.util";
import { DateFormat } from "../../../util/date.util";

const ActualizationCurrentComponent = () => {
  const { actualization } = useActualizationState();

  const actualizationSorted = getActualizationsSorted(actualization.data);
  const actualizationsVigent = getActualizationsVigent(actualizationSorted);

  const actualizationVigentDate =
    getActualizationVigentDate(actualizationSorted);

  const actualizationVigentTotalSuccess = actualizationsVigent
    .map((value) => value.actualizacion_exitosa)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  return (
    <div className={"flex h-full items-center"}>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              <span className={"font-bold"}>
                Fecha de Actualización Vigente
              </span>
            </TableCell>
            <TableCell align="center">
              <DateFormat source={actualizationVigentDate} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"font-bold"}>Total de Cargas Vigentes</span>
            </TableCell>
            <TableCell align="center">{actualizationsVigent.length}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className={"font-bold"}>
                Total de Actualizaciones Exitosas Vigentes
              </span>
            </TableCell>
            <TableCell align="center">
              {actualizationVigentTotalSuccess}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default ActualizationCurrentComponent;
