import { Box } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { ReactNode } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import { When } from "react-if";

type IHorizontalField = {
  children: ReactNode;
  label: string | ReactNode;
  isRequired?: boolean;
  help?: string;
  bold?: boolean;
  inputClassName?: string;
};

const useStyles = makeStyles((theme) => ({
  noMaxWidth: {
    maxWidth: "none",
  },
}));

const VerticalField = (props: IHorizontalField) => {
  const {
    children,
    label,
    isRequired,
    help,
    inputClassName,
    bold = true,
  } = props;
  const classes = useStyles();
  return (
    <div className={"flex flex-col gap-2 items-start"}>
      <div className={clsx("flex flex-row gap-1", bold && "font-bold")}>
        <When condition={isRequired}>
          <Box color={"error.main"}>*</Box>
        </When>
        {label}:
        <When condition={Boolean(help)}>
          <Tooltip
            classes={{ tooltip: classes.noMaxWidth }}
            arrow={true}
            placement={"top"}
            className={"cursor-pointer"}
            title={help!}
          >
            <Box>
              <IoAlertCircleOutline className={"text-xl"} color={"inherit"} />
            </Box>
          </Tooltip>
        </When>
      </div>
      <div className={clsx("w-full", inputClassName)}>{children}</div>
    </div>
  );
};

export default VerticalField;
