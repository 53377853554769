import React from "react";
import { useTranslation } from "react-i18next";

const NotificationClientCreated = () => {
  const { t } = useTranslation();
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div>{t("notification.createClientSuccess.body")}</div>
    </div>
  );
};

export default NotificationClientCreated;
