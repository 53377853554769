export const protegemeV2ApiRoutes = {
  v2: {
    pendingCoverage: {
      listAll: () => "/api/v1/cobertura/pendiente",
      findById: (pendingCoverageId: string) =>
        `/api/v1/cobertura/pendiente/${pendingCoverageId}`,
      verifyOne: (pendingCoverageId: string) =>
        `/api/v1/cobertura/pendiente/${pendingCoverageId}`,
      identifyDevice: (pendingCoverageId: number) =>
        `/api/v1/cobertura/pendiente/${pendingCoverageId}/match-dispositivo`,
    },
    reclamation: {
      find: () => "/api/v1/ticket",
      findOne: (reclamationId: string) => `/api/v1/ticket/${reclamationId}`,
      export: () => "/api/v1/ticket/reclamation/download",
      listAllComments: (reclamationId: string) =>
        `/api/v1/ticket/${reclamationId}/comment`,
      createComment: (reclamationId: string) =>
        `/api/v1/ticket/${reclamationId}/comment`,
      auditList: (reclamationId: string) =>
        `/api/v1/auditoria/items/ticket/${reclamationId}`,
      auditSave: (reclamationId: string) =>
        `/api/v1/auditoria/items/ticket/${reclamationId}`,
      auditDetails: (reclamationId: string) =>
        `/api/v1/auditoria/ticket/${reclamationId}`,
    },
    device: {
      list: () => "/api/v1/dispositivo",
    },
    plan: {
      list: () => "/api/v1/plan",
    },
    causal: {
      listCausalsByStageId: (stageId: string) =>
        `/api/v1/causal/etapaorden/${stageId}`,
    },
    stage: {
      uploadDiagnosticResult: (coverage: string, stageOrderId: string) =>
        `/api/v1/etapa/flujo/${coverage}/etapa-orden/${stageOrderId}/upload/diagnostico`,
      getCompensationType: (reclamationId: string) =>
        `/api/v1/etapa/tipo-indemnizacion/ticket/${reclamationId}`,
      selectCompensationType: () => "/api/v1/etapa/tipo-indemnizacion",
    },
    client: {
      create: () => "/api/v1/user/client",
      list: () => "/api/v1/user/client",
      linkDevice: () => "/api/v1/dispositivo/cliente-plan",
      findOne: (clientId: number) => `/api/v1/user/client/${clientId}`,
      update: (clientId: number) => `/api/v1/user/client/${clientId}`,
      findAllDevices: (clientId: number) =>
        `/api/v1/dispositivo/dispositivo-cliente/user/${clientId}`,
      findOneDevice: (deviceId: number) =>
        `/api/v1/dispositivo/dispositivo-cliente/${deviceId}`,
      updateDevice: (deviceId: number) =>
        `/api/v1/dispositivo/dispositivo-cliente/${deviceId}`,
      disablePolicy: (policyId: number) => `/api/v1/user/policy/${policyId}`,
      cancelPolicy: () => "/api/v1/dispositivo/dispositivo-cliente-plan/cancel",
      renewPolicy: () => "/api/v1/dispositivo/dispositivo-cliente-plan/renew",
    },
    user: {
      getMe: () => "/api/v1/user/me",
      listAllGenders: () => "/api/v1/user/gender",
      listAllDocumentType: () => "/api/v1/user/identification-type",
    },
    call: {
      audit: {
        listItems: (callId: number | string) =>
          `/api/v1/auditoria/items/call-center/${callId}`,
        save: (callId: string) =>
          `/api/v1/auditoria/items/call-center/${callId}`,
        findOne: (callId: string) => `/api/v1/auditoria/call-center/${callId}`,
      },
      create: () => "/api/v1/callcenter/",
      list: () => "/api/v1/callcenter",
      findOne: (callId: number) => `/api/v1/callcenter/${callId}`,
      reasons: () => "/api/v1/callcenter/reason",
      types: () => "/api/v1/callcenter/type",
      report: () => "/api/v1/callcenter/download",
    },
    sponsor: {
      list: () => "/api/v1/sponsor",
    },
    statusReclamtion: {
      list: () => "/api/v1/ticket/status",
    },
  },
};
