import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import PlanSelectField from "../components/create-form/plan-select.field";
import ClientSelectField from "../components/create-form/client-select.field";
import DeviceSelectField from "../components/create-form/device-select.field";
import CoverageSelectField from "../components/create-form/coverage-select.field";

const CoverageStepComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={"mb-8"}>
        <ClientSelectField
          name={"client"}
          help={t("createReclamation.clientSelect.help")}
          conditionalName={"device"}
          label={t("createReclamation.clientSelect.label")}
        />
      </div>
      <div className={"mb-8"}>
        <DeviceSelectField
          name={"device"}
          help={"Requerido"}
          conditionalName={"plan"}
          label={"Seleccionar el dispositivo del cliente"}
        />
      </div>
      <div className={"mb-8"}>
        <PlanSelectField
          name={"plan"}
          help={"Requerido"}
          conditionalName={"coverage"}
          label={"Seleccionar el plan del dispositivo"}
        />
      </div>
      <div className={"mb-8"}>
        <CoverageSelectField
          name={"coverage"}
          help={"Requerido"}
          label={"Selecciona la cobertura a aplicar en la reclamación"}
        />
      </div>
    </Fragment>
  );
};

export default CoverageStepComponent;
