import React from "react";

import UploadSignedAdmissionOrderPanel from "./upload-signed-admission-order.panel";
import { ILoadingComponentProps } from "../../loading/loading.component";

const UploadSignedAdmissionOrderService = () => {
  const loadingProps: ILoadingComponentProps = {
    isLoading: false,
    hasError: false,
    variant: "rect",
    width: "100%",
    height: 500,
  };

  return <UploadSignedAdmissionOrderPanel {...loadingProps} />;
};

export default UploadSignedAdmissionOrderService;
