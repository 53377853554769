import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import SelectDeviceToDeliverService from "../../components/stage/select-device-to-deliver/select-device-to-deliver.service";

const SelectDeviceToDeliverPage = () => {
  return (
    <StagePageTemplate>
      <SelectDeviceToDeliverService />
    </StagePageTemplate>
  );
};

export default SelectDeviceToDeliverPage;
