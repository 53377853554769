import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import { Field } from "formik";
import React from "react";
import { FaQuestion, FaRegFilePdf } from "react-icons/fa";
import { ICausal } from "../../../../types/event";
import CausalInput from "../../inputs/causal-input/causal.input";

interface IProps {
  causals: ICausal[];
}

const CausalTableField = (props: IProps) => {
  const { causals } = props;
  return (
    <Table size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <span className={"text-xs"}>Nombre</span>
          </TableCell>
          <TableCell align="center">
            <span className={"text-xs"}>Texto de Ayuda</span>
          </TableCell>
          <TableCell align="center">
            <span className={"text-xs"}>Texto de Carta</span>
          </TableCell>
          <TableCell align="center">
            <span className={"text-xs"}>Resultado</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {causals?.map((causal, index) => (
          <TableRow key={causal.id}>
            <TableCell align="left">
              <span className={"text-xs text-justify"}>{causal.nombre}</span>
            </TableCell>
            <TableCell align="center">
              <div className={"flex flex-col items-center"}>
                <Box color={"text.secondary"} className={"flex"}>
                  <Tooltip
                    className={"cursor-pointer"}
                    title={causal.texto_front}
                    children={
                      <div>
                        <FaQuestion />
                      </div>
                    }
                  />
                </Box>
              </div>
            </TableCell>
            <TableCell align="center">
              <div className={"flex flex-col items-center"}>
                <Box color={"text.secondary"} className={"flex"}>
                  <Tooltip
                    className={"cursor-pointer"}
                    title={causal.texto_carta_pdf}
                    children={
                      <div>
                        <FaRegFilePdf />
                      </div>
                    }
                  />
                </Box>
              </div>
            </TableCell>
            <TableCell align="left">
              <Field
                name={`causals[${index}].resultado`}
                children={CausalInput}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CausalTableField;
