import produce, { Draft } from "immer";
import {
  ICausalResult,
  IDocument,
  IEvento,
  IStageResult,
} from "../../types/event";

type IPropState<T> = { data?: T; isLoading: boolean; hasError: boolean };

export type IState = {
  event: IPropState<IEvento>;
  documents: IPropState<IDocument[]>;
  causals: IPropState<ICausalResult[]>;
  stage: IPropState<IStageResult>;
};

type IPropName = keyof IState;

export type IAction =
  | { type: "FETCH" | "ERROR"; payload: { name: IPropName } }
  | {
      type: "SUCCESS";
      payload: { name: IPropName; data: IState[IPropName]["data"] };
    };

export type IStageDispatch = (action: IAction) => void;

export const stageReducer = produce((draft: Draft<IState>, action: IAction) => {
  switch (action.type) {
    case "FETCH": {
      draft[action.payload.name].data = undefined;
      draft[action.payload.name].hasError = false;
      draft[action.payload.name].isLoading = true;
      return draft;
    }
    case "SUCCESS": {
      draft[action.payload.name].data = action.payload.data;
      draft[action.payload.name].hasError = false;
      draft[action.payload.name].isLoading = false;
      return draft;
    }
    case "ERROR": {
      draft[action.payload.name].data = undefined;
      draft[action.payload.name].hasError = true;
      draft[action.payload.name].isLoading = false;
      return draft;
    }
  }
});

const initData = { data: undefined, hasError: false, isLoading: false };

export const stageInitialValue: IState = {
  event: initData,
  documents: initData,
  causals: initData,
  stage: initData,
};
