import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Field, useFormikContext } from "formik";
import es from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { TextField as TextFieldMui } from "@material-ui/core";

import VerticalField from "../../../../components/formik/vertical-field";
import { useSponsorList } from "../../../../services/protegeme-v2/sponsor/use-sponsor";
import { IFormikValue } from "../report-core.util";

const FormFields = () => {
  const { t } = useTranslation();
  const { data: Sponsors, isLoading: isLoadingSponsors } = useSponsorList();
  const { errors, touched } = useFormikContext<IFormikValue>();
  return (
    <Fragment>
      <div className="grid grid-cols-2 gap-4">
        <VerticalField
          inputClassName={"pl-3"}
          isRequired={true}
          label={t("reports.general.panel.form.fields.sponsorID.label")}
        >
          <Field
            size={"small"}
            fullWidth={true}
            name={"sponsorID"}
            variant={"outlined"}
            loading={isLoadingSponsors}
            component={Autocomplete}
            options={Sponsors?.data || []}
            getOptionSelected={(option: any, value: any) => option !== value}
            getOptionLabel={(opt: any) => opt?.nombre || ""}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMui
                {...params}
                variant={"outlined"}
                helperText={touched?.sponsorID && errors.sponsorID}
                error={touched?.sponsorID && !!errors.sponsorID}
              />
            )}
          />
        </VerticalField>
        <VerticalField
          inputClassName={"pl-3"}
          isRequired={true}
          label={t("reports.general.panel.form.fields.typeFile.label")}
        >
          <Field
            size={"small"}
            fullWidth={true}
            name={"typeFile"}
            variant={"outlined"}
            loading={isLoadingSponsors}
            component={Autocomplete}
            options={
              [
                {
                  nombre: "Excel",
                  typeFile: "EXCEL",
                },
                {
                  nombre: "Csv",
                  typeFile: "CSV",
                },
              ] || []
            }
            getOptionSelected={(option: any, value: any) => option !== value}
            getOptionLabel={(opt: any) => opt?.nombre || ""}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMui
                {...params}
                variant={"outlined"}
                helperText={touched?.typeFile && errors.typeFile}
                error={touched?.typeFile && !!errors.typeFile}
              />
            )}
          />
        </VerticalField>
        <VerticalField
          inputClassName={"pl-3"}
          isRequired={true}
          label={t("reports.general.panel.form.fields.typeReport.label")}
        >
          <Field
            size={"small"}
            fullWidth={true}
            name={"typeReport"}
            variant={"outlined"}
            loading={isLoadingSponsors}
            component={Autocomplete}
            options={
              [
                {
                  nombre: "Reclamaciones",
                  typeReport: "claims",
                },
                {
                  nombre: "Polizas",
                  typeReport: "policies",
                },
              ] || []
            }
            getOptionSelected={(option: any, value: any) => option !== value}
            getOptionLabel={(opt: any) => opt?.nombre || ""}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMui
                {...params}
                variant={"outlined"}
                helperText={touched?.typeFile && errors.typeFile}
                error={touched?.typeFile && !!errors.typeFile}
              />
            )}
          />
        </VerticalField>
        <VerticalField
          inputClassName={"pl-3"}
          isRequired={true}
          label={t("reports.general.panel.form.fields.startDate.label")}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <Field
              autoOk={true}
              openTo="year"
              size={"small"}
              fullWidth={true}
              name={"startDate"}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              inputVariant={"outlined"}
              component={KeyboardDatePicker}
              views={["year", "month", "date"]}
            />
          </MuiPickersUtilsProvider>
        </VerticalField>
        <VerticalField
          inputClassName={"pl-3"}
          isRequired={true}
          label={t("reports.general.panel.form.fields.endDate.label")}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <Field
              autoOk={true}
              openTo="year"
              size={"small"}
              fullWidth={true}
              name={"endDate"}
              format="dd/MM/yyyy"
              maxDate={new Date()}
              inputVariant={"outlined"}
              component={KeyboardDatePicker}
              views={["year", "month", "date"]}
              className="my-2"
            />
          </MuiPickersUtilsProvider>
        </VerticalField>
      </div>
    </Fragment>
  );
};

export default FormFields;
