import { Typography } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import useWindowSize from "react-use/lib/useWindowSize";
import { useGlobalStyles } from "../../styles/useGlobalStyles";

import image from "./undraw_online_resume_re_ru7s.svg";

const HomePage = () => {
  const { t } = useTranslation();
  const style = useGlobalStyles();
  const { width, height } = useWindowSize();
  return (
    <Fragment>
      <Confetti recycle={false} width={width} height={height} />
      <div className={"flex flex-col items-center gap-8 my-6"}>
        <div>
          <Typography
            className={style.acincoTypography}
            color={"primary"}
            variant={"h3"}
            children={t("home.welcome")}
          />
        </div>
        <div>
          <img
            className={"w-96"}
            src={image}
            alt="undraw_online_resume_re_ru7s.svg"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default HomePage;
