import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import EAxios from "../../../axios/EAxios";
import SelectDeviceToDeliverPanel from "./select-device-to-deliver.panel";
import { IDeviceSponsor, IExperienceCenter } from "../../../types/event";

const SelectDeviceToDeliverService = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  const [devicesToDeliver, setDevicesToDeliver] = useState<IDeviceSponsor[]>(
    []
  );
  const [experienceCenters, setExperienceCenters] = useState<
    IExperienceCenter[]
  >([]);

  useEffect(() => {});

  useEffect(() => {
    setLoading(true);
    const devicePromise = EAxios.get<IDeviceSponsor[]>(
      `/api/v1/dispositivo/equivalente/ticket/${eventId}`
    );
    const cdePromise = EAxios.get<IExperienceCenter[]>(
      `/api/v1/cde/ticket/${eventId}`
    );
    Promise.all([devicePromise, cdePromise])
      .then((value) => {
        setDevicesToDeliver(value[0].data);
        setExperienceCenters(value[1].data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [eventId]);

  return (
    <SelectDeviceToDeliverPanel
      height={500}
      width={"100%"}
      variant={"rect"}
      isLoading={isLoading}
      hasError={hasError}
      devicesToDeliver={devicesToDeliver}
      experienceCenters={experienceCenters}
    />
  );
};

export default SelectDeviceToDeliverService;
