import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../../store";
import EAxios from "../../../axios/EAxios";
import Configuration from "../../../config/Configuration";
import { ICurrentStageByEventState, IStageCurrent } from "../../../types/event";

const initialState: ICurrentStageByEventState = {
  currentStage: undefined,
  isLoading: false,
  hasError: false,
};

const { reducer, actions } = createSlice({
  name: "current_stage_by_event",
  initialState,
  reducers: {
    getCurrentStageByEventLoading: (state: ICurrentStageByEventState) => {
      state.currentStage = undefined;
      state.isLoading = true;
      state.hasError = false;
    },
    getCurrentStageByEventSuccess: (
      state: ICurrentStageByEventState,
      action: PayloadAction<IStageCurrent>
    ) => {
      state.currentStage = action.payload;
      state.isLoading = false;
    },
    getCurrentStageByEventFailed: (state: ICurrentStageByEventState) => {
      state.hasError = true;
      state.isLoading = false;
    },
  },
});

const getCurrentStageByEventRequest =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(CurrentStageByEventActions.getCurrentStageByEventLoading());
      const hostname = Configuration.api.hostname;
      const response = await EAxios.get<IStageCurrent>(
        `${hostname}/api/v1/etapa/actual/ticket/${eventId}`
      );
      dispatch(
        CurrentStageByEventActions.getCurrentStageByEventSuccess(response.data)
      );
    } catch (error) {
      dispatch(CurrentStageByEventActions.getCurrentStageByEventFailed());
    }
  };

export const CurrentStageByEventActions = {
  ...actions,
  getCurrentStageByEventRequest,
};
export const CurrentStageByEventReducer = reducer;
