import React from "react";

import StagePageTemplate from "../../template/stage-page.template";
import RegisterMaintenanceIngressService from "../../components/stage/register-maintenance-ingress/register-maintenance-ingress.service";

const RegisterMaintenanceIngressPage = () => {
  return (
    <StagePageTemplate>
      <RegisterMaintenanceIngressService />
    </StagePageTemplate>
  );
};

export default RegisterMaintenanceIngressPage;
