import React from "react";
import { Popover, PopoverOrigin } from "@material-ui/core";

import { useV1HistoryListState } from "../../providers/v1-history-list.provider";
import { useV1HistoryListActions } from "../../providers/v1-history-list.actions";
import V1HistoryListTicketPanelFilterForm from "./v1-history-list-ticket-panel-filter-form";

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const V1HistoryListTicketPanelFilter = () => {
  const { formRef } = useV1HistoryListState();
  const { setFormRef } = useV1HistoryListActions();

  const isFormOpen = Boolean(formRef);

  const handleCloseFilterPanel = () => {
    setFormRef(undefined);
  };

  return (
    <Popover
      open={isFormOpen}
      anchorEl={formRef}
      anchorOrigin={anchorOrigin}
      onClose={handleCloseFilterPanel}
      transformOrigin={transformOrigin}
      children={<V1HistoryListTicketPanelFilterForm />}
    />
  );
};

export default V1HistoryListTicketPanelFilter;
