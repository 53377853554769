import { microserviceApiAxios } from "../microservice-api.axios";
import { microserviceApiRoutes } from "../microservice-api.routes";
import {
  ICreateLinkDeviceClientReq,
  ICreateLinkDeviceClientRes,
  IGetInsurrancesPlanReqData,
  IGetInsurrancesPlanResData,
  ISponsorWithPlanResData,
} from "./sponsor-microservice.interface";

export const getSponsorWithPlansMicroservice = () => {
  const url = microserviceApiRoutes.protegeme.sponsor.getList();
  return microserviceApiAxios
    .get<ISponsorWithPlanResData>(url)
    .then((res) => res.data);
};

export const getInsurrancesPlansMicroservice = (
  props: IGetInsurrancesPlanReqData
) => {
  const url = microserviceApiRoutes.protegeme.plans.getList();
  return microserviceApiAxios
    .post<IGetInsurrancesPlanResData>(url, props)
    .then((res) => res.data);
};

export const createLinkDeviceClient = ({
  body,
}: ICreateLinkDeviceClientReq) => {
  const url = microserviceApiRoutes.protegeme.client.linkDevice();
  return microserviceApiAxios
    .post<ICreateLinkDeviceClientRes>(url, body)
    .then((res) => res.data);
};
