import React from "react";
import { Icon } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useRoutesV2 } from "../../../../../providers/router/routes";

type IV1HistoryListTicketTableActions = {
  eventId: number;
};

const V1HistoryListTicketTableActions = (
  props: IV1HistoryListTicketTableActions
) => {
  const { eventId } = props;
  const { t } = useTranslation();
  const routesV2 = useRoutesV2();
  return (
    <div>
      <Tooltip
        title={t("v1HistoryList-table-actions.seeDetailsButtonTooltip")}
        children={
          <IconButton
            color="primary"
            component={RouterLink}
            to={routesV2.legacyTicket.route(eventId)}
            children={<Icon>remove_red_eye</Icon>}
          />
        }
      />
    </div>
  );
};

export default V1HistoryListTicketTableActions;
