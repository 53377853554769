import { Ability, AbilityBuilder } from "@casl/ability";
import { IRole } from "../../types/authorization";
import { IAbility } from "../../types/authorization";
import { IAbilityTuple } from "../../types/authorization";
import { IAuthorization } from "../../types/authorization";

export const getAbilityByRole = (role: IRole | undefined): IAbility => {
  const ability = new Ability<IAbilityTuple>();
  const { can, rules } = new AbilityBuilder<IAbility>(Ability);
  can("manager", "all");
  switch (role) {
    case "GERENTE":
    case "DIRECTOR": {
      can("protegeme/v2/event/reclamation/open.all", "all");
      can("protegeme/v2/event/reclamation/update.all", "all");
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/create", "all");
      can("protegeme/v2/actualization/read", "all");
      can("protegeme/v2/actualization/upload", "all");
      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");
      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/link-device", "all");
      can("protegeme/v2/client/create", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/device/read.one", "all");
      can("protegeme/v2/client/device/update.one", "all");
      can("protegeme/v2/client/device/disable-imei-validation", "all");
      can("protegeme/v2/client/policy/read.one", "all");
      can("protegeme/v2/client/policy/disable.one", "all");
      can("protegeme/v2/client/policy/cancel.one", "all");
      can("protegeme/v2/client/policy/cancel/read.one", "all");
      can("protegeme/v2/client/policy/renew.one", "all");
      can("protegeme/v2/client/policy/renew/read.one", "all");
      can("protegeme/v2/pending-coverage/read.all", "all");
      can("protegeme/v2/pending-coverage/read.one", "all");
      can("protegeme/v2/pending-coverage/update", "all");
      can("protegeme/v2/event/reclamation/comment/create.all", "all");
      can("protegeme/v2/event/reclamation/audit/read.all", "all");
      can("protegeme/v2/event/reclamation/audit/read.one", "all");
      can("protegeme/v2/event/reclamation/audit/update.one", "all");
      can("protegeme/v2/call/read", "all");
      can("protegeme/v2/call/read.all", "all");
      can("protegeme/v2/call/create", "all");
      can("protegeme/v2/call/report", "all");
      can("protegeme/v2/call/audit/read.all", "all");
      can("protegeme/v2/call/audit/read.one", "all");
      can("protegeme/v2/call/audit/update.one", "all");
      can("protegeme/v2/reports/generate", "all");
      break;
    }
    case "ANALISTALEGAL": {
      can("protegeme/v2/event/reclamation/update.all", "all");
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/create", "all");
      can("protegeme/v2/event/reclamation/audit/read.one", "all");
      can("protegeme/v2/event/reclamation/audit/read.all", "all");

      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");

      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/create", "all");
      can("protegeme/v2/client/policy/read.one", "all");
      can("protegeme/v2/client/policy/cancel.one", "all");
      can("protegeme/v2/client/policy/cancel/read.one", "all");

      can("protegeme/v2/call/read", "all");
      can("protegeme/v2/call/read.all", "all");
      can("protegeme/v2/call/create", "all");
      can("protegeme/v2/call/report", "all");

      can("protegeme/v2/event/reclamation/comment/create.all", "all");
      break;
    }
    case "ANALISTA": {
      can("protegeme/v2/event/reclamation/update.all", "all");
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/create", "all");
      can("protegeme/v2/event/reclamation/audit/read.one", "all");

      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");

      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/create", "all");
      can("protegeme/v2/client/policy/read.one", "all");
      can("protegeme/v2/client/link-device", "all");
      can("protegeme/v2/client/device/read.one", "all");
      can("protegeme/v2/client/device/update.one", "all");
      can("protegeme/v2/client/policy/cancel.one", "all");
      can("protegeme/v2/client/policy/cancel/read.one", "all");

      can("protegeme/v2/call/read", "all");
      can("protegeme/v2/call/read.all", "all");
      can("protegeme/v2/call/create", "all");
      can("protegeme/v2/call/report", "all");

      can("protegeme/v2/event/reclamation/comment/create.all", "all");
      can("protegeme/v2/reports/generate", "all");
      break;
    }
    case "ASESORCALL": {
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/create", "all");

      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");

      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/create", "all");
      can("protegeme/v2/client/policy/read.one", "all");

      can("protegeme/v2/event/reclamation/comment/create.all", "all");

      can("protegeme/v2/call/read", "all");
      can("protegeme/v2/call/read.all", "all");
      can("protegeme/v2/call/create", "all");
      break;
    }
    case "AUDITOR": {
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/create", "all");
      can("protegeme/v2/event/reclamation/audit/read.one", "all");
      can("protegeme/v2/event/reclamation/audit/read.all", "all");
      can("protegeme/v2/event/reclamation/audit/update.one", "all");

      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");

      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/policy/read.one", "all");

      can("protegeme/v2/event/reclamation/comment/create.all", "all");

      can("protegeme/v2/call/read", "all");
      can("protegeme/v2/call/read.all", "all");
      can("protegeme/v2/call/report", "all");
      can("protegeme/v2/call/audit/read.all", "all");
      can("protegeme/v2/call/audit/read.one", "all");
      can("protegeme/v2/call/audit/update.one", "all");
      break;
    }
    case "COORDINADOR": {
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/create", "all");
      can("protegeme/v2/event/reclamation/update.all", "all");
      can("protegeme/v2/event/reclamation/open.all", "all");

      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");

      can("protegeme/v2/client/create", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/link-device", "all");
      can("protegeme/v2/client/policy/read.one", "all");
      can("protegeme/v2/client/policy/cancel.one", "all");
      can("protegeme/v2/client/policy/cancel/read.one", "all");

      can("protegeme/v2/event/reclamation/comment/create.all", "all");
      can("protegeme/v2/event/reclamation/audit/read.all", "all");
      can("protegeme/v2/event/reclamation/audit/read.one", "all");
      can("protegeme/v2/event/reclamation/audit/update.one", "all");

      can("protegeme/v2/call/read", "all");
      can("protegeme/v2/call/read.all", "all");
      can("protegeme/v2/call/create", "all");
      can("protegeme/v2/call/report", "all");
      can("protegeme/v2/call/audit/read.all", "all");
      can("protegeme/v2/call/audit/read.one", "all");
      can("protegeme/v2/call/audit/update.one", "all");

      can("protegeme/v2/reports/generate", "all");
      break;
    }
    case "SERVICIOTECNICO": {
      can("protegeme/v2/event/reclamation/read.all", "all");
      can("protegeme/v2/event/reclamation/audit/read.one", "all");
      can("protegeme/v2/event/reclamation/audit/read.all", "all");

      can("protegeme/v1/ticket/read", "all");
      can("protegeme/v1/ticket/read.all", "all");

      can("protegeme/v2/client/read", "all");
      can("protegeme/v2/client/read.all", "all");
      can("protegeme/v2/client/update", "all");
      can("protegeme/v2/client/policy/read.one", "all");

      can("protegeme/v2/event/reclamation/comment/create.all", "all");
      break;
    }
  }
  ability.update(rules);
  return ability;
};

export const getAuthorizationByAbility = (
  ability: IAbility
): IAuthorization => {
  const policies: IAuthorization = {
    canActualizationRead: ability.can("protegeme/v2/actualization/read", "all"),
    canActualizationUpload: ability.can(
      "protegeme/v2/actualization/upload",
      "all"
    ),
    canReclamationCreate: ability.can(
      "protegeme/v2/event/reclamation/create",
      "all"
    ),
    canReclamationReadAll: ability.can(
      "protegeme/v2/event/reclamation/read.all",
      "all"
    ),
    canReclamationOpenAll: ability.can(
      "protegeme/v2/event/reclamation/open.all",
      "all"
    ),
    canReclamationUpdateAll: ability.can(
      "protegeme/v2/event/reclamation/update.all",
      "all"
    ),
    canReclamationAuditReadAll: ability.can(
      "protegeme/v2/event/reclamation/audit/read.all",
      "all"
    ),
    canReclamationAuditReadOne: ability.can(
      "protegeme/v2/event/reclamation/audit/read.one",
      "all"
    ),
    canReclamationAuditUpdateOne: ability.can(
      "protegeme/v2/event/reclamation/audit/update.one",
      "all"
    ),
    canHistoryTicketRead: ability.can("protegeme/v1/ticket/read", "all"),
    canHistoryTicketReadAll: ability.can("protegeme/v1/ticket/read.all", "all"),
    canClientCreate: ability.can("protegeme/v2/client/create", "all"),
    canClientLinkDevice: ability.can("protegeme/v2/client/link-device", "all"),
    canClientReadAll: ability.can("protegeme/v2/client/read.all", "all"),
    canClientRead: ability.can("protegeme/v2/client/read", "all"),
    canClientUpdate: ability.can("protegeme/v2/client/update", "all"),
    canClientDeviceReadOne: ability.can(
      "protegeme/v2/client/device/read.one",
      "all"
    ),
    canClientDeviceUpdateOne: ability.can(
      "protegeme/v2/client/device/update.one",
      "all"
    ),
    canClientPolicyReadOne: ability.can(
      "protegeme/v2/client/policy/read.one",
      "all"
    ),
    canClientPolicyDisableOne: ability.can(
      "protegeme/v2/client/policy/disable.one",
      "all"
    ),
    canReclamationCommentCreate: ability.can(
      "protegeme/v2/event/reclamation/comment/create.all",
      "all"
    ),
    canPendingCoverageReadAll: ability.can(
      "protegeme/v2/pending-coverage/read.all",
      "all"
    ),
    canPendingCoverageReadOne: ability.can(
      "protegeme/v2/pending-coverage/read.one",
      "all"
    ),
    canPendingCoverageUpdate: ability.can(
      "protegeme/v2/pending-coverage/update",
      "all"
    ),
    canCallRead: ability.can("protegeme/v2/call/read", "all"),
    canCallReadAll: ability.can("protegeme/v2/call/read.all", "all"),
    canCallRecordCreate: ability.can("protegeme/v2/call/create", "all"),
    canCallReport: ability.can("protegeme/v2/call/report", "all"),
    canCallAuditReadAll: ability.can("protegeme/v2/call/audit/read.all", "all"),
    canCallAuditReadOne: ability.can("protegeme/v2/call/audit/read.one", "all"),
    canCallAuditUpdateOne: ability.can(
      "protegeme/v2/call/audit/update.one",
      "all"
    ),
    canGenerateReports: ability.can("protegeme/v2/reports/generate", "all"),
    canClientCancelPolicy: ability.can(
      "protegeme/v2/client/policy/cancel.one",
      "all"
    ),
    canClientViewCancelPolicy: ability.can(
      "protegeme/v2/client/policy/cancel/read.one",
      "all"
    ),
    canDisableImeiValidation: ability.can(
      "protegeme/v2/client/device/disable-imei-validation",
      "all"
    ),
    canClientRenewPolicy: ability.can(
      "protegeme/v2/client/policy/renew.one",
      "all"
    ),
    canClientViewRenewPolicy: ability.can(
      "protegeme/v2/client/policy/renew/read.one",
      "all"
    ),
  };
  return policies;
};
