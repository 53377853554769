import _ from "lodash";
import { IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { Default } from "react-if";
import { Case } from "react-if";
import { Switch } from "react-if";
import { When } from "react-if";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Panel from "../../../components/panel/panel";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../providers/router/routes";
import { useFindAllClientDevices } from "../../../services/protegeme-v2/client/use-client";
import { useClientFindOne } from "../../../services/protegeme-v2/client/use-client";
import ClientSection from "./components/client-section";
import DevicesSection from "./components/devices-section";
import PolicySection from "./components/policy-section";

const ClientDetailsPage = () => {
  const { canClientRead, canClientLinkDevice, canClientUpdate } =
    useAuthorization();
  const routesV2 = useRoutesV2();
  const { t } = useTranslation();
  const { clientId } = useParams<any>();
  const clientFindOne = useClientFindOne({ clientId: Number(clientId) });
  const findAllClientDevices = useFindAllClientDevices(clientId);
  return (
    <AuthorizationComponent isAuthorized={canClientRead} autoRedirect={true}>
      <div className={"p-8 grid grid-cols-2 gap-8"}>
        <div className={"row"}>
          <Panel
            title={
              <div className="w-full h-full flex flex-row justify-between items-center">
                <div>{t("clientDetails.panel.client.label")}</div>
                <div className={"flex flex-row gap-2"}>
                  <When condition={canClientUpdate}>
                    <IconButton
                      edge="end"
                      aria-haspopup="true"
                      children={routesV2.clientUpdate.icon}
                      component={Link}
                      to={routesV2.clientUpdate.route(Number(clientId))}
                    />
                  </When>
                </div>
              </div>
            }
          >
            <Switch>
              <Case
                condition={clientFindOne.isLoading}
                children={
                  <Skeleton height={200} width={"100%"} variant={"rect"} />
                }
              />
              <Case
                condition={Boolean(clientFindOne.data)}
                children={() => (
                  <ClientSection client={clientFindOne.data!.data} />
                )}
              />
            </Switch>
          </Panel>
        </div>
        <div className={"grid-cols-1"}>
          <div className={"row"}>
            <Panel
              title={
                <div className="w-full h-full flex flex-row justify-between items-center">
                  <div>{t("clientDetails.panel.device.label")}</div>
                  <div className={"flex flex-row gap-2"}>
                    <When condition={canClientLinkDevice}>
                      <IconButton
                        edge="end"
                        aria-haspopup="true"
                        children={routesV2.clientLinkDevice.icon}
                        component={Link}
                        to={routesV2.clientLinkDevice.route({ clientId })}
                      />
                    </When>
                  </div>
                </div>
              }
            >
              <Switch>
                <Case condition={findAllClientDevices.isLoading}>
                  <Skeleton width={"100%"} height={300} variant={"rect"} />
                </Case>
                <Default
                  children={() => (
                    <div className={"overflow-x-auto"}>
                      <DevicesSection
                        devices={findAllClientDevices.data!.data}
                      />
                    </div>
                  )}
                />
              </Switch>
            </Panel>
          </div>
          <div className={"row mt-4"}>
            <Panel
              title={
                <div className="w-full h-full flex flex-row justify-between items-center">
                  <div>{t("clientDetails.panel.policy.label")}</div>
                </div>
              }
            >
              <Switch>
                <Case condition={findAllClientDevices.isLoading}>
                  <Skeleton width={"100%"} height={300} variant={"rect"} />
                </Case>
                <Default
                  children={() => (
                    <div className={"overflow-y-auto overflow-x-auto"}>
                      <PolicySection
                        policies={findAllClientDevices.data!.data.filter(
                          (d) => !_.isEmpty(d.policy)
                        )}
                      />
                    </div>
                  )}
                />
              </Switch>
            </Panel>
          </div>
        </div>
      </div>
    </AuthorizationComponent>
  );
};

export default ClientDetailsPage;
