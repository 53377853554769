import React from "react";
import { Switch, Case, When, Unless } from "react-if";

import { IFindAllClientDevice } from "../../../../services/protegeme-v2/client/client-service.interface";
import CanceledSection from "./canceled/canceled-section";
import CancelForm from "./cancel/cancel-policy-form";
import { AuthorizationComponent } from "../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../providers/authorization/authorization.provider";

interface Props {
  deviceClient: IFindAllClientDevice | undefined;
}

const CancelSection = ({ deviceClient }: Props) => {
  const authorization = useAuthorization();
  return (
    <Switch>
      <Case condition={deviceClient?.policy[0].is_active}>
        <When condition={Boolean(deviceClient?.policy[0].fecha_cancelacion)}>
          <AuthorizationComponent
            isAuthorized={authorization.canClientViewCancelPolicy}
          >
            <CanceledSection deviceClient={deviceClient} />
          </AuthorizationComponent>
        </When>
        <Unless condition={Boolean(deviceClient?.policy[0].fecha_cancelacion)}>
          <AuthorizationComponent
            isAuthorized={authorization.canClientCancelPolicy}
          >
            <CancelForm deviceClient={deviceClient} />
          </AuthorizationComponent>
        </Unless>
      </Case>
      <Case condition={!deviceClient?.policy[0].is_active}>
        <AuthorizationComponent
          isAuthorized={authorization.canClientViewCancelPolicy}
        >
          <CanceledSection deviceClient={deviceClient} />
        </AuthorizationComponent>
      </Case>
    </Switch>
  );
};

export default CancelSection;
