import { UseQueryOptions, useMutation, useQuery } from "react-query";
import {
  getInsurrancesPlansMicroservice,
  getSponsorWithPlansMicroservice,
  createLinkDeviceClient,
} from "./sponsor-microservice";
import {
  ICreateLinkDeviceClientReq,
  IGetInsurrancesPlanReqData,
  ISponsorWithPlanResData,
} from "./sponsor-microservice.interface";

export const useGetSponsorWithPlanMicroservice = (
  options?: UseQueryOptions<any>
) => {
  return useQuery<ISponsorWithPlanResData>(
    "sponsor/list",
    getSponsorWithPlansMicroservice,
    options
  );
};

export const useGetInsurrancesPlansMicroservice = () => {
  return useMutation((props: IGetInsurrancesPlanReqData) =>
    getInsurrancesPlansMicroservice(props).then((res) => res.data)
  );
};

export const useCreateLinkDeviceClientMicroservice = () => {
  return useMutation((props: ICreateLinkDeviceClientReq) =>
    createLinkDeviceClient(props).then((res) => res.data)
  );
};
