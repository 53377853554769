import { IconButton } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUserAdd } from "react-icons/ai";
import { When } from "react-if";
import { Link } from "react-router-dom";
import useMethods from "use-methods";
import { AuthorizationComponent } from "../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../providers/authorization/authorization.provider";
import { useRoutesV2 } from "../../../providers/router/routes";
import { useClientList } from "../../../services/protegeme-v2/client/use-client";
import useTableListPage from "../../../template/table-list-page/hooks/use-table-list-page";
import { ITableListPageState } from "../../../template/table-list-page/table-list-page-template";
import { getTableListMethods } from "../../../template/table-list-page/table-list-page-template";
import TableListPageTemplate from "../../../template/table-list-page/table-list-page-template";
import ClientTable from "./components/client-table";
import FilterButtonMenu from "./components/filter-button-menu";
import { ISponsor } from "../../../services/protegeme-v2/sponsor/sponsor-service.interface";

export interface IClientListStateFilters {
  documentNumber?: string;
  sponsor?: ISponsor | null;
  name?: string;
  phoneNumber?: string;
  imei?: string;
  lastName?: string;
}

export type IClientListState = ITableListPageState<IClientListStateFilters>;
const initialState: IClientListState = {
  filters: {
    documentNumber: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    imei: "",
    sponsor: null,
  },
  count: 0,
  currentPage: 0,
  perPage: 25,
  perPageOptions: [25, 50, 100],
};

const methods = (state: IClientListState) => ({
  ...getTableListMethods(state),
});

const ClientListPage = () => {
  const { canClientCreate, canClientReadAll } = useAuthorization();
  const routesV2 = useRoutesV2();
  const { t } = useTranslation();
  const [state, stateMethods] = useMethods(methods, initialState);
  const clientsQuery = useClientList({
    document: state.filters.documentNumber,
    sponsor: state.filters.sponsor?.id_system,
    name: state.filters.name,
    imei: state.filters.imei,
    phone_number: state.filters.phoneNumber,
    lastname: state.filters.lastName,
    limit: state.perPage,
    page: state.currentPage + 1,
  });

  const { updateCount, updateFilters, updateCurrentPage } = stateMethods;
  const { refetch, data: clients, isLoading } = clientsQuery;

  useTableListPage({ state, refetch, count: clients?.count, updateCount });

  return (
    <AuthorizationComponent isAuthorized={canClientReadAll} autoRedirect={true}>
      <TableListPageTemplate
        title={
          <div className="w-full h-full flex flex-row justify-between items-center">
            <div>{t("clientList.label")}</div>
            <div className={"flex flex-row gap-2"}>
              <When condition={canClientCreate}>
                <IconButton
                  edge="end"
                  aria-haspopup="true"
                  children={<AiOutlineUserAdd />}
                  component={Link}
                  to={routesV2.clientCreate.route()}
                />
              </When>
              <FilterButtonMenu
                state={state}
                updateFilters={updateFilters}
                updateCurrentPage={updateCurrentPage}
              />
            </div>
          </div>
        }
        isLoading={isLoading}
        isEmpty={_.isEmpty(clients?.data)}
        table={
          <When
            condition={Boolean(clients?.data)}
            children={() => <ClientTable data={clients!.data} />}
          />
        }
        tableListMethods={stateMethods}
        tableListState={state}
      />
    </AuthorizationComponent>
  );
};

export default ClientListPage;
