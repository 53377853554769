import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import PlanSelectField from "../../components/form/plan-select.field";
import SponsorSelectField from "../../components/form/sponsor-select.field";
import TextBoxField from "../../../../../../components/formik/fields/textBoxField";
import { AuthorizationComponent } from "../../../../../../providers/authorization/authorization.component";
import { useAuthorization } from "../../../../../../providers/authorization/authorization.provider";
import CheckField from "../../../../../../components/formik/fields/check.field";

const ImeiStepComponent = () => {
  const { t } = useTranslation();
  const { getFieldMeta } = useFormikContext();
  const { canDisableImeiValidation } = useAuthorization();
  return (
    <Fragment>
      <div className="mb-8">
        <TextBoxField
          name={"primaryImei"}
          limit={15}
          value={getFieldMeta("primaryImei").value || ""}
          label={t("clientLinkDevice.form.fields.imeiPrincipal.label")}
        />
      </div>
      <div className="mb-8">
        <SponsorSelectField
          name={"sponsor"}
          label={t("clientLinkDevice.form.fields.sponsor.label")}
          conditionalName={"plan"}
        />
      </div>
      <div className="mb-8">
        <PlanSelectField
          name={"plan"}
          label={t("clientLinkDevice.form.fields.plan.label")}
        />
      </div>
      <AuthorizationComponent isAuthorized={canDisableImeiValidation}>
        <div className="mb-8">
          <CheckField
            name={"disableImeiValidation"}
            label={t(
              "clientLinkDevice.form.fields.disableImeiValidation.label"
            )}
          />
        </div>
      </AuthorizationComponent>
    </Fragment>
  );
};

export default ImeiStepComponent;
