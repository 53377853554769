import React from "react";

import NextButton from "./components/next.button";
import OpenButton from "./components/open.button";
import CurrentTable from "./components/current.table";
import { useAuthorization } from "../../../../../providers/authorization/authorization.provider";
import { AuthorizationComponent } from "../../../../../providers/authorization/authorization.component";

const EventContinueSection = () => {
  const { canReclamationUpdateAll, canReclamationOpenAll } = useAuthorization();
  return (
    <div className={"flex flex-col"}>
      <CurrentTable />
      <AuthorizationComponent
        isAuthorized={canReclamationUpdateAll}
        children={<NextButton />}
      />
      <AuthorizationComponent
        isAuthorized={canReclamationOpenAll}
        children={<OpenButton />}
      />
    </div>
  );
};

export default EventContinueSection;
