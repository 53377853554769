import {
  Divider,
  Paper,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import { BsArrowsAngleContract, BsArrowsAngleExpand } from "react-icons/bs";
import React, { ReactNode, useState } from "react";

interface IPanelProps {
  title: String | ReactNode;
  children: ReactNode;
  show: Boolean;
}

const PanelDropdown = ({ title, children, show }: IPanelProps) => {
  const [showPaper, setShowPaper] = useState(show);
  return (
    <Paper className={"flex flex-col h-full"}>
      <Toolbar variant={"dense"}>
        <Typography variant={"h6"} className={"w-full m-0"}>
          <div className="w-full h-full flex flex-row justify-between items-center">
            {title}
            {showPaper ? (
              <IconButton
                onClick={() => setShowPaper(!showPaper)}
                children={<BsArrowsAngleContract />}
              />
            ) : (
              <IconButton
                onClick={() => setShowPaper(!showPaper)}
                children={<BsArrowsAngleExpand />}
              />
            )}
          </div>
        </Typography>
      </Toolbar>
      {showPaper ? (
        <>
          <Divider />
          <Paper
            elevation={0}
            square={true}
            className={"flex-grow flex flex-col overflow-y-auto px-6 py-6"}
            children={children}
          />
        </>
      ) : null}
    </Paper>
  );
};

export default PanelDropdown;
