import { Divider } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { TimelineContent } from "@material-ui/lab";
import { TimelineSeparator } from "@material-ui/lab";
import { TimelineOppositeContent } from "@material-ui/lab";
import { TimelineItem } from "@material-ui/lab";
import React from "react";
import { When } from "react-if";
import TablePair from "../../../../../table-pair/table-pair";
import ProgressConnector from "./progress-connector";
import ProgressDot from "./progress-dot";

export interface IProgressItem {
  name: string;
  author?: string;
  startDate?: string;
  endDate?: string;
  flow?: string;
  role?: string;
  platform?: string;
  hasNext?: boolean;
  collapsed?: boolean;
  connector?: "error" | "default" | "success";
  avatar?: "system" | "analyst" | "finish" | "default";
  progress?: "pending" | "inProgress" | "success";
  result?: string;
}
const ProgressItem = (props: IProgressItem) => {
  const { name, author, startDate, endDate, flow, role, platform } = props;
  const { hasNext = true, connector = "default", result } = props;
  const { avatar = "default", progress = "pending", collapsed = false } = props;
  const data: Array<any> = [];
  author && data.push({ key: "Usuario", value: author });
  role && data.push({ key: "Rol", value: role });
  platform && data.push({ key: "Plataforma", value: platform });
  startDate && data.push({ key: "fecha de inicio", value: startDate });
  endDate && data.push({ key: "fecha de terminación", value: endDate });
  flow && data.push({ key: "Flujo", value: flow });
  result && data.push({ key: "Resultado", value: result });
  return (
    <TimelineItem className={"pl-4"}>
      <TimelineOppositeContent className={"hidden"} />
      <TimelineSeparator>
        <ProgressDot icon={hasNext ? avatar : "finish"} status={progress} />
        <When condition={hasNext}>
          <ProgressConnector variant={connector} />
        </When>
      </TimelineSeparator>
      <TimelineContent>
        <Paper variant="outlined" className={"whitespace-nowrap"}>
          <div className={"py-2 px-3 whitespace-normal"}>
            <div className={"text-sm font-medium"}>{name}</div>
          </div>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <Divider />
            <TablePair
              hasBorder={false}
              keyClassName={"w-2/5 text-xs"}
              tableClassName={"table-fixed"}
              valueClassName={"whitespace-normal text-xs"}
              data={data}
            />
          </Collapse>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ProgressItem;
