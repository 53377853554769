import * as yup from "yup";
import to from "await-to-js";
import { Typography } from "@material-ui/core";
import { Form, Formik, FormikValues } from "formik";
import { useHistory, useParams } from "react-router-dom";
import React, { Fragment, FunctionComponent, useState } from "react";

import EAxios from "../../../../axios/EAxios";
import TextBoxField from "../../../formik/fields/textBoxField";
import LoadingButton from "../../../loading-button/loading-button";
import FileUploadField from "../../../formik/fields/file-upload/file-upload.field";
import { useCoverage } from "../../../../hooks/useCoverage";

const RequestAcceptedComponent: FunctionComponent = () => {
  const history = useHistory();
  const coverage = useCoverage();
  const { eventId, stageId } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const initialValues = {
    imeiPrincipal: "",
    imeiSecondary: "",
    upload: null,
  };

  const validationSchema = yup.object({
    imeiPrincipal: yup
      .string()
      .required("Obligatorio")
      .matches(/^[0-9]*$/, "Debe contener solo valores numéricos")
      .length(15, "Debe contener exactamente 15 caracteres"),
    imeiSecondary: yup
      .string()
      .matches(/^[0-9]*$/, "Debe contener solo valores numéricos")
      .length(15, "Debe contener exactamente 15 caracteres"),
    upload: yup.mixed().required("Obligatorio"),
  });

  // ToDo remove Serial with BE authorization
  const formatData = (values: FormikValues) => ({
    ticket_id: eventId,
    file_to_save: values.upload,
    imei_uno: values.imeiPrincipal,
    imei_dos: values.imeiSecondary,
    serial: "123456789",
  });

  const onSubmit = async (values: FormikValues) => {
    const url = EAxios.endpoints.default.stage.uploadDeviceRequest(
      coverage,
      stageId
    );
    const data = formatData(values);
    const [formData, config] = EAxios.toFormData(data);

    setLoadingSubmit(true);
    const [error, response] = await to(
      EAxios.post<string>(url, formData, config)
    );
    setLoadingSubmit(false);

    if (error) return;
    if (response?.status === 200 || response?.status === 201) history.goBack();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Fragment>
        <div className={"mb-6"}>
          <Typography color="textPrimary" display="block" variant="h6">
            Solicitud Aprobada
          </Typography>
        </div>

        <div className={"flex justify-center w-full"}>
          <Form className={"w-11/12"}>
            <div className={"mb-5"}>
              <TextBoxField
                name={"imeiPrincipal"}
                label={"Ingresa el IMEI principal del dispositivo aprobado"}
                help={"Obligatorio - Numérico - 15 Caracteres"}
                limit={15}
              />
            </div>

            <div className={"mb-5"}>
              <TextBoxField
                name={"imeiSecondary"}
                label={"Ingresa el IMEI secundario del dispositivo aprobado"}
                help={"Opcional - Numérico - 15 Caracteres"}
                limit={15}
              />
            </div>

            <div className={"mb-8"}>
              <FileUploadField
                name={"upload"}
                label={"Cargar Solicitud Aceptada"}
                help={
                  "Obligatorio - Formato PDF o JPG - Máximo 2MB - Subir la Captura del Email de Confirmación enviado por Suppla"
                }
                accept={"application/pdf,image/jpeg"}
                maxFiles={1}
                maxSize={1024 * 1024 * 2}
              />
            </div>

            <div className={"flex flex-row-reverse mb-6"}>
              <LoadingButton
                isLoading={isLoadingSubmit}
                color={"primary"}
                variant={"contained"}
                type={"submit"}
                children={"Guardar"}
              />
            </div>
          </Form>
        </div>
      </Fragment>
    </Formik>
  );
};

export default RequestAcceptedComponent;
