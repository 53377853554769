import { TextField as TextFieldMui } from "@material-ui/core";
import { FormikValues } from "formik";
import { Field } from "formik";
import { useFormikContext } from "formik";
import { AutocompleteRenderInputParams } from "formik-material-ui-lab";
import { Autocomplete } from "formik-material-ui-lab";
import _ from "lodash";
import { useEffect } from "react";
import React from "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { IClientList } from "../../../../../../services/protegeme-v2/client/client-service.interface";
import { useClientList } from "../../../../../../services/protegeme-v2/client/use-client";
import { getClientFullName } from "../../../../../../services/protegeme-v2/client/util/client-format-name";
import { ClientFullName } from "../../../../../../services/protegeme-v2/client/util/client-format-name";

type IClientSelectField = {
  name: string;
  autoFocus: boolean;
};

const ClientFieldSelect = (props: IClientSelectField) => {
  const { name, autoFocus } = props;
  const { setFieldValue } = useFormikContext();
  const { errors, touched } = useFormikContext<FormikValues>();
  const [localClients, setLocalClients] = useState<IClientList[]>([]);
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce<string>(search, 500);
  const {
    refetch: clientRefetch,
    data: clientData,
    isLoading: isClientLoading,
  } = useClientList({ document: debouncedSearch }, { enabled: false });

  useEffect(() => {
    if (_.isEmpty(debouncedSearch)) {
      setLocalClients([]);
      return;
    }
    clientRefetch();
  }, [debouncedSearch, clientRefetch]);

  useEffect(() => {
    const candidates = clientData?.data || [];
    setLocalClients(candidates);
  }, [setLocalClients, clientData]);

  return (
    <Field
      className={"w-full"}
      size={"small"}
      name={name}
      fullWidth={true}
      options={localClients}
      component={Autocomplete}
      loading={isClientLoading}
      filterOptions={(options: any) => options}
      getOptionLabel={(option: any) =>
        `${option.identification_number} - ${getClientFullName({
          firstName: option.name,
          lastName: option.second_name,
        })}`
      }
      onInputChange={(event: any, value: any) => {
        setSearch(value);
      }}
      onChange={(event: any, value: any) => {
        if (value && value.name && value.second_name) {
          setFieldValue(name, value);
        }
      }}
      renderOption={(option: any) => (
        <div className={"flex flex-row gap-1"}>
          <div className={"font-mono whitespace-pre"}>
            {String(option?.identification_number).padStart(11)}
          </div>
          <div className={"font-mono"}>-</div>
          <div>
            <ClientFullName
              firstName={option!.name}
              lastName={option!.second_name}
            />
          </div>
        </div>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextFieldMui
          {...params}
          autoFocus={autoFocus}
          variant={"outlined"}
          error={touched?.client && !!errors?.client}
          helperText={touched.client && errors.client && "Requerido"}
        />
      )}
    />
  );
};

export default ClientFieldSelect;
