import { protegemeV2ApiAxios } from "../../protegeme-v2-api.axios";
import { protegemeV2ApiRoutes } from "../../protegeme-v2-api.routes";
import { ICallAuditDetailsRes } from "./call-service.interface";
import { ICallAuditDetailsReq } from "./call-service.interface";
import { ICallAuditSaveRes } from "./call-service.interface";
import { ICreateCallBody } from "./call-service.interface";
import { IReasonListResData } from "./call-service.interface";
import { ITypeListResData } from "./call-service.interface";
import { ICallFindOneResData } from "./call-service.interface";
import { ICallReportResData } from "./call-service.interface";
import { ICallListFind } from "./call-service.interface";
import { ICallExcelAxiosReq } from "./call-service.interface";
import { ICallFindResponse } from "./call-service.interface";
import { ICallAuditSaveReq } from "./use-call.interface";
import { ICallAuditListReq } from "./use-call.interface";
import { ICallAuditListRes } from "./use-call.interface";

export const callCreate = (call: ICreateCallBody) => {
  const url = protegemeV2ApiRoutes.v2.call.create();
  return protegemeV2ApiAxios.post<Error>(url, call);
};

export const callList =
  (params: ICallListFind) => (): Promise<ICallFindResponse> => {
    const { filters, pagination } = params;
    const url = protegemeV2ApiRoutes.v2.call.list();
    return protegemeV2ApiAxios
      .get<ICallFindResponse>(url, {
        params: {
          limit: pagination.pageSize,
          page: pagination.pageNumber,
          cedula: filters.cedula || undefined,
          max_date: filters.max_date || undefined,
          min_date: filters.min_date || undefined,
          ensured_line: filters.ensured_line || undefined,
          incoming_phone_number: filters.incoming_phone_number || undefined,
        },
      })
      .then((res) => res.data);
  };

export const callReport = (
  params: ICallExcelAxiosReq
): Promise<ICallReportResData> => {
  const { signal, ...requestData } = params;
  const url = protegemeV2ApiRoutes.v2.call.report();
  return protegemeV2ApiAxios
    .get<ICallReportResData>(url, { params: requestData, signal })
    .then((res) => res.data);
};

export const callFindOne = async (callId: number) => {
  const url = protegemeV2ApiRoutes.v2.call.findOne(callId);
  const request = await protegemeV2ApiAxios.get<ICallFindOneResData>(url);
  return request;
};

export const reasonList = () => {
  const url = protegemeV2ApiRoutes.v2.call.reasons();
  return protegemeV2ApiAxios
    .get<IReasonListResData[]>(url)
    .then((res) => res.data);
};

export const callAuditList = async (params: ICallAuditListReq) => {
  const { callId } = params;
  const url = protegemeV2ApiRoutes.v2.call.audit.listItems(callId);
  const request = await protegemeV2ApiAxios.get<ICallAuditListRes>(url);
  return request;
};

export const callAuditSave = async (params: ICallAuditSaveReq) => {
  const { data, callId } = params;
  const url = protegemeV2ApiRoutes.v2.call.audit.save(callId);
  const request = await protegemeV2ApiAxios.post<ICallAuditSaveRes>(url, data);
  return request;
};

export const callAuditDetails = async (params: ICallAuditDetailsReq) => {
  const { callId } = params;
  const url = protegemeV2ApiRoutes.v2.call.audit.findOne(callId);
  const request = await protegemeV2ApiAxios.get<ICallAuditDetailsRes>(url);
  if ((request.data as any)?.error) {
    throw new Error("EVALUATIONRESULTTICKERNOTFOUND");
  }
  return request;
};

export const typeCallList = () => {
  const url = protegemeV2ApiRoutes.v2.call.types();
  return protegemeV2ApiAxios.get<ITypeListResData>(url);
};
